import {
	Autocomplete,
	FormControlLabel,
	Grid,
	InputAdornment,
	Checkbox,
	FormGroup,
	Button,
	Tooltip,
	useTheme,
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context";
import { PreviewMap } from "../PreviewMap";
import { ReportDataContext } from "../../../context";
import SingleDropZone from "../../SingleDropZone";
import { CoordinatesInput } from "../../CoordInput";

// global
import { URLS } from "../../../global";
import { validateNumberInput } from "../../../utils/validation";
import VideoInput from "../../VideoInput";
import TextInput from "../../Inputs/TextInput";

/**
 * Componente para la creación o edición de un objeto
 * @param {Object} object: objeto a editar o crear
 * @param {Function} setObject: función para actualizar el objeto
 * @param {Function} handleAddObject: función para agregar el objeto a la lista de objetos
 * @param {Function} handleClose: función para cerrar el modal
 * @param {Object} errors: errores de los campos del objeto
 * @param {Boolean} objectEditMode: booleano que indica si se está editando un objeto
 * @returns
 */
export const ObjectFormModal = ({
	object,
	setObject,
	handleAddObject,
	handleClose,
	errors,
	objectEditMode,
	otherObjects,
}) => {
	const [objectType, setObjectType] = useState([]);
	const { axiosInstance, canUseMapTools } = useContext(AuthContext);
	const { reportData } = useContext(ReportDataContext);
	const theme = useTheme();

	// Mover esto fuera para que no se rerenderize cada vez que se agrega un objeto
	useEffect(() => {
		// Petición para obtener los objetos predeterminados
		axiosInstance
			.get(URLS.OBJECT_TYPES)
			.then((response) => {
				setObjectType(
					response.data.map((row) => ({
						...row,
						label: row.name,
						id: row.id,
						metrics: row.unit || "Unidad",
						conversionRate: row.weight || 1, // Peso/densidad por unidad
					}))
				);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [axiosInstance]);

	// Al agregar un campo del objeto, se actualiza dicho campo del objeto y el posible error que tenga
	// name: nombre del campo
	// value: valor del campo
	const handleInputChange = (name, value, validator = (x) => x) => {
		// Si el campo es el tipo, se actualiza el volumen y la cantidad
		if (name === "type" && value) {
			setObject({
				...object,
				[name]: validator(value),
				amount: 1,
				volume: value?.conversionRate || "",
			});
			return;
		} else if (name === "amount" && object.type) {
			setObject({
				...object,
				[name]: validator(value),
				volume: validator(value) * object.type?.conversionRate,
			});
			return;
		} else if (name === "volume" && object.type) {
			setObject({
				...object,
				[name]: validator(value),
				amount: validator(value) / object.type?.conversionRate,
			});
			return;
		}

		setObject({
			...object,
			[name]: validator(value),
		});
	};
	const [mapZoom, setMapZoom] = useState(13);

	return (
		<div style={{ padding: "0 25px" }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<Autocomplete
						disablePortal
						id="type"
						options={objectType}
						value={object.type}
						onChange={(e, value) => handleInputChange("type", value)}
						renderInput={(params) => (
							<TextInput
								label="Tipo de objeto"
								error={errors.type.message}
								helperText={errors.type.message}
								{...params}
								required={true}
							/>
						)}
						isOptionEqualToValue={(option, value) => option.id === value.id}
					/>
				</Grid>
				<Grid item xs={12} md={2}>
					<TextInput
						value={
							object.amount ? object.amount : object?.volume / (object?.type?.conversionRate || 1) || ""
						}
						onChange={(e) => handleInputChange("amount", e.target.value, validateNumberInput)}
						InputProps={{
							endAdornment: <InputAdornment position="end">{object.type?.metrics}</InputAdornment>,
						}}
						error={errors.amount.message}
						label={"Cantidad"}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={2}>
					<TextInput
						value={object.volume ? object.volume : ""}
						onChange={(e) => handleInputChange("volume", e.target.value, validateNumberInput)}
						error={errors.volume.message}
						disabled={!object.type || object.type?.metrics === "Unidad"}
						label={"Volumen"}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={4} alignItems="center">
					<FormGroup row sx={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={object.beacon ? object.beacon : false}
									onChange={(e) => handleInputChange("beacon", e.target.checked)}
								/>
							}
							label="Balizado"
						/>
						<Tooltip title="Marcar el objeto como importante. Todos los objetos son notificados de igual manera, pero esta opción es para objetos de especial interés o preocupación y que deben ser atendidos urgentemente.">
							<FormControlLabel
								control={
									<Checkbox
										checked={object.notify ? object.notify : false}
										onChange={(e) => handleInputChange("notify", e.target.checked)}
									/>
								}
								label="Notificar"
							/>
						</Tooltip>
					</FormGroup>
				</Grid>
				<Grid item xs={12} md={12}>
					<CoordinatesInput
						setter={(latlon) => {
							setObject({
								...object,
								latitude: latlon.latitude,
								longitude: latlon.longitude,
							});
						}}
						coordinates={{ latitude: object.latitude, longitude: object.longitude }}
						center={reportData.cultivationCenter}
						error={{
							latitude: errors.latitude.message,
							longitude: errors.longitude.message,
						}}
						helperText={{
							latitude: errors.latitude.message,
							longitude: errors.longitude.message,
						}}
					/>
				</Grid>

				<Grid item xs={12} md={6} sx={{ height: "300px" }}>
					<div>Ubicación real</div>
					<PreviewMap
						onMapClick={(e) => {
							if (canUseMapTools === "false") return;
							setObject({
								...object,
								latitude: e.latlng.lat.toFixed(6),
								longitude: e.latlng.lng.toFixed(6),
							});
						}}
						latitud={object.latitude}
						longitud={object.longitude}
						input={reportData.cultivationCenter}
						mapZoom={mapZoom}
						setMapZoom={setMapZoom}
						otherObjects={otherObjects}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<TextInput
						value={object.comments}
						multiline
						rows={10}
						onChange={(e) => {
							handleInputChange("comments", e.target.value);
						}}
						maxLength={1000}
						label="Comentarios"
					/>
				</Grid>

				<Grid item xs={6} md={4}>
					<div
						style={{
							paddingLeft: "15px",
							marginBottom: "7px",
							color: "#212121",
							fontWeight: 500,
						}}
					>
						Foto en el fondo <span style={{ color: theme.palette.brandColors.primary }}> *</span>
					</div>
					<SingleDropZone
						handleInputChange={handleInputChange}
						inputChangeName="submarineImage"
						initialFile={object.submarineImage}
						sx={{ border: errors.submarineImage.error ? "1px solid #d32f2f" : "2px dashed grey" }}
						errorText={errors.submarineImage.message}
					/>
				</Grid>
				<Grid item xs={6} md={4}>
					<div
						style={{
							paddingLeft: "15px",
							marginBottom: "7px",
							color: "#212121",
							fontWeight: 500,
						}}
					>
						Foto en la superficie <span style={{ color: theme.palette.brandColors.primary }}> *</span>
					</div>
					<SingleDropZone
						handleInputChange={handleInputChange}
						inputChangeName="inBoatImage"
						initialFile={object.inBoatImage}
					/>
				</Grid>

				<Grid item xs={4}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "100%",
						}}
					>
						<div
							style={{
								paddingLeft: "15px",
								marginBottom: "7px",
								color: "#212121",
								fontWeight: 500,
							}}
						>
							Video (opcional)
						</div>
						<VideoInput video={object.video} setVideo={(video) => setObject({ ...object, video: video })} />
					</div>
				</Grid>

				<Grid item xs={12}>
					<div style={{ display: "flex", gap: "20px", justifyContent: "center", width: "100%" }}>
						{!objectEditMode && (
							<Button
								variant="outlined"
								onClick={handleClose}
								sx={{
									color: theme.palette.brandColors.primary,
									backgroundColor: "white",
									borderRadius: "12px",
									border: `1px solid ${theme.palette.brandColors.primary}`,
									":hover": {
										backgroundColor: "white",
										color: theme.palette.brandColors.primary,
									},
								}}
							>
								Cancelar
							</Button>
						)}

						<Button
							variant="contained"
							onClick={handleAddObject}
							sx={{
								borderRadius: "12px",
								backgroundColor: theme.palette.brandColors.primary,
								color: "white",
								border: `1px solid ${theme.palette.brandColors.primary}`,
								":hover": {
									backgroundColor: theme.palette.brandColors.primary,
									color: "white",
								},
							}}
						>
							{objectEditMode ? "Guardar" : "Agregar"}
						</Button>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};
