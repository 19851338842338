// mui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
// components
import { EditCultivationCenterDialog } from "./";
import { Search, Table } from "../../Table";
import GeneralButton from "../../GeneralButton";
import IconButtons from "../../Icons/IconButtons";
import EditIcon from "../../Icons/EditIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import { useEffect, useState } from "react";


/**
 * Componente para listar y agregar/modificar centros de cultivo
 * @param {Object} props
 * @param {Number} props.companyId ID de la empresa empresa productora a la que pertenecen los centros de cultivo
 */
const CultivationCenters = ({ companyId }) => {
	const [dialog, setDialog] = useState({
		open: false,
		id: null,
		data: null,
	});
	const theme = useTheme();

	const [dataReload, setDataReload] = useState(true);
	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const openDialog = (id = null, data = null) => {
		setDialog({
			open: true,
			id: id,
			data: data,
		});
	};

	const exitDialog = () => {
		setDialog({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};
	const [rows, setRows] = useState([]);
	const [filterInput, setFilterInput] = useState("");

	const deleteCenter = (id) => {
		if (
			!window.confirm(
				"¿Está seguro de eliminar este centro de cultivo?\n" +
					"Esta acción no se puede deshacer y eliminará todos los informes y objetos asociados al centro de cultivo."
			)
		)
			return;

		axiosInstance
			.delete(`${URLS.CENTERS}${id}/`)
			.then((_res) => {
				addMessage("Centro de cultivo eliminado exitosamente", "success");
				setDataReload(true);
			})
			.catch((err) => {
				addMessage("Error al eliminar el centro de cultivo", "error");
			})
			.finally(() => {});
	};

	const columns = [
		{
			field: "name",
			headerName: "Nombre Centro de Cultivo",
			sortable: true,
		},
		{
			field: "code",
			headerName: "Código",
			sortable: true,
		},
		{
			field: "place",
			headerName: "Área del centro",
			flex: 1,
			renderCell: (row) => row?.place?.name,
			valueGetter: (row) => row?.place?.name,
			sortable: true,
		},
		{
			field: "socialReason",
			headerName: "Razón Social",
			sortable: true,
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
					<IconButtons icon={<EditIcon />} onClick={() => openDialog(row.id, row)} />
					<IconButtons icon={<DeleteIcon />} onClick={() => deleteCenter(row.id)} variant="danger" />
				</div>
			),
		},
	];

	useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(`${URLS.SALMON_COMPANY}${companyId}/cultivation_centers/`)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al cargar centros de cultivo", "error");
				})
				.finally(() => {});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [companyId, axiosInstance, addMessage, dataReload, setDataReload]);

	return (
		<>
			<Box
				sx={{
					justifyContent: "space-between",
					display: "flex",
					width: "100%",
					padding: "15px 34px",
					alignItems: "center",
				}}
			>
				<div
					style={{
						color: theme.palette.brandColors.black,
						fontSize: "15px",
						fontWeight: 700,
					}}
				>
					Centros de cultivo
				</div>
				<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
					<Search search={filterInput} setSearch={setFilterInput} />
					<GeneralButton text={"Agregar Centro"} onClick={() => openDialog()} />
				</div>
			</Box>

			<Table rows={rows} columns={columns} filter={filterInput} />

			<EditCultivationCenterDialog
				open={dialog.open}
				onClose={exitDialog}
				data={dialog.data}
				companyId={companyId}
			/>
		</>
	);
};

export default CultivationCenters;
