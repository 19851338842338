// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
import TextInput from "../../Inputs/TextInput";
import GeneralButton from "../../GeneralButton";
import { useCallback, useEffect, useState } from "react";

/**
 * Formulario para crear o editar una area de una empresa productora
 * @param {object} props
 * @param {function} props.onFinished Funcion a ejecutar al terminar de editar o crear
 * @param {object} props.data Datos del area a editar
 * @param {number} props.companyId Id de la empresa productora actual
 */
export const PlaceForm = ({ onFinished = () => {}, data = undefined, companyId = undefined }) => {
	const [currentData, setCurrentData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const requestData = useCallback(async () => {
		setLoading(true);
		axiosInstance
			.get(`${URLS.PLACES}${data.id}/`)
			.then((response) => {
				setCurrentData({
					name: response.data.name,
					salmonCompany: response.data.salmonCompany,
					salmonCompany_id: companyId,
				});
			})
			.catch((error) => {
				console.log(error);
				addMessage("Error al obtener los datos del area.", "error");
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.id, companyId]);

	const onCreation = useCallback(() => {
		setCurrentData({
			name: "",
			salmonCompany_id: companyId,
		});
	}, [companyId]);

	useEffect(() => {
		// si se esta editando, se obtienen los datos
		if (data?.id) requestData();
		// si se esta creando, se inicializan los datos
		else {
			onCreation();
			setLoading(false);
		}
	}, [data?.id, requestData, onCreation]);

	const handleCancel = () => {
		onFinished();
	};

	const handleSave = (e) => {
		e.preventDefault();

		let new_data = { ...currentData };

		// Creacion de objeto por defecto
		// crear configuracion de request
		let config = {
			method: "POST",
			url: URLS.PLACES,
			data: {
				salmonCompany_id: companyId,
				name: new_data.name,
			},
		};
		let message = "Area creada exitosamente";
		let error_message = "Error al crear el area";

		if (data?.id) {
			// Editar
			config.method = "PATCH";
			config.url += `${data.id}/`;
			config.data = { name: new_data.name, id: data.id };
			message = "Area editada exitosamente";
			error_message = "Error al editar el area";
		}

		// enviar request
		setLoading(true);
		axiosInstance(config)
			.then((res) => {
				if (res.data.salmonCompany.length > 1) {
					addMessage(
						"El area se ha agregado, pero no se puede editar porque es utilizada junto a otra empresa productora",
						"warning"
					);
				} else addMessage(message, "success");
				onFinished();
			})
			.catch((err) => {
				if (err.response?.data[0]) addMessage(err.response.data[0], "error");
				else {
					addMessage(error_message, "error");
					setErrors({ ...err.response.data });
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (e) => {
		setCurrentData({ ...currentData, [e.target.name]: e.target.value });
	};

	const handleDelete = () => {
		// crear configuracion de request

		axiosInstance
			.delete(`${URLS.PLACES}${data.id}/`)
			.then((res) => {
				addMessage("Petición de Eliminación de Área Enviada", "success");
				onFinished();
			})
			.catch((err) => {
				addMessage("Ya hay una petición de Eliminación", "error");
			});
	};

	return (
		<form
			onSubmit={handleSave}
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "10px",
				padding: "20px 30px",
			}}
		>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<TextInput
				label={"Nombre"}
				InputLabelProps={{ shrink: true }}
				name={"name"}
				value={currentData["name"] || ""}
				onChange={handleChange}
				fullWidth
				required
				error={errors["name"]}
				helperText={errors["name"]}
				disabled={loading}
			/>

			<Box sx={{ display: "flex", justifyContent: "space-between" }} mt={2}>
				<Button variant="outlined" onClick={handleDelete} color="error" disabled={loading}>
					Eliminar
				</Button>
				<Stack direction="row" spacing={2} justifyContent="end" flexGrow={1}>
					<GeneralButton text="Cancelar" variant="empty" onClick={handleCancel} disabled={loading} />

					<GeneralButton text="Guardar" onClick={handleSave} disabled={loading} />
				</Stack>
			</Box>
		</form>
	);
};

export default PlaceForm;
