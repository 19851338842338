

const CalendarIcon = ({color}) => {
    return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
			<g clipPath="url(#clip0_196_2307)">
				<path
					d="M12.4729 25.9992C9.16244 25.9992 5.85194 26.0007 2.54144 25.9985C1.26796 25.9978 0.264733 25.1271 0.029906 23.8257C0.00489489 23.6855 0.00281063 23.5388 0.00211587 23.3951C3.16145e-05 17.0399 -0.00135789 10.6848 0.00211587 4.33041C0.00211587 2.87946 1.10122 1.73995 2.49211 1.73128C3.04652 1.72767 3.60163 1.72694 4.15605 1.73128C4.67433 1.73561 5.03282 2.10775 5.02796 2.62728C5.0231 3.1302 4.6653 3.49222 4.15813 3.49727C3.65582 3.50233 3.15282 3.49583 2.64982 3.49944C2.0301 3.50378 1.70426 3.84267 1.70148 4.48866C1.69801 5.19173 1.7119 5.8948 1.69523 6.59788C1.68898 6.86596 1.77791 6.9281 2.02176 6.92738C6.84057 6.92087 11.6594 6.92232 16.4782 6.92304C16.6074 6.92304 16.7408 6.91726 16.8659 6.94472C17.2716 7.03504 17.5398 7.44186 17.5071 7.89708C17.4765 8.32269 17.1375 8.66447 16.7207 8.68831C16.6171 8.69409 16.5129 8.6912 16.4087 8.6912C11.633 8.6912 6.85794 8.6912 2.08221 8.6912C1.82839 8.6912 1.70125 8.82344 1.70079 9.0879C1.70079 13.7659 1.70079 18.4446 1.70079 23.1226C1.70079 23.9283 1.99328 24.2304 2.77418 24.2304C9.25692 24.2304 15.7397 24.2304 22.2224 24.2304C23.0047 24.2304 23.2979 23.929 23.2979 23.1255C23.2979 18.4381 23.2979 13.7507 23.2979 9.06333C23.2979 8.81573 23.1802 8.69169 22.945 8.6912C21.9827 8.6912 21.0212 8.69265 20.0589 8.69048C19.5115 8.68904 19.1419 8.33569 19.1391 7.81543C19.1356 7.27277 19.4941 6.92376 20.0638 6.92304C21.0434 6.92087 22.0223 6.91654 23.0019 6.92665C23.2229 6.92882 23.3083 6.87174 23.3034 6.62606C23.2889 5.91431 23.3014 5.20185 23.2972 4.4901C23.2937 3.84628 22.9644 3.50378 22.3468 3.49944C21.8438 3.49583 21.3415 3.50233 20.8385 3.49727C20.3334 3.49222 19.9735 3.12514 19.9707 2.62295C19.9672 2.09907 20.3195 1.73561 20.844 1.73128C21.3984 1.72694 21.9535 1.72694 22.508 1.73128C23.8982 1.74067 24.9966 2.88091 24.9973 4.33258C25.0007 10.6877 25.0007 17.0421 24.9973 23.3972C24.9966 24.8597 23.8961 25.9964 22.4829 25.9985C19.146 26.0021 15.8091 26 12.4729 26V25.9992Z"
					fill={color || "#848591"}
                    
				/>
				<path
					d="M13.5395 16.4786C13.5395 17.8117 13.5395 19.1449 13.5395 20.4774C13.5395 20.6031 13.5388 20.7303 13.5215 20.8545C13.4485 21.3864 13.0907 21.6985 12.596 21.6696C12.1486 21.6436 11.8096 21.2476 11.8089 20.7339C11.8068 18.4548 11.8075 16.1765 11.8075 13.8975C11.8075 13.8253 11.8047 13.753 11.8075 13.6815C11.8117 13.5434 11.7561 13.5001 11.6324 13.5543C11.449 13.6338 11.2656 13.7147 11.0801 13.7913C10.5646 14.0045 10.0685 13.8188 9.87887 13.344C9.68225 12.8512 9.90805 12.3577 10.4333 12.1301C11.0169 11.8779 11.6005 11.6257 12.1834 11.3714C12.9497 11.0368 13.5326 11.4248 13.5374 12.2905C13.5458 13.6865 13.5395 15.0826 13.5395 16.4786Z"
					fill={color || "#848591"}
				/>
				<path
					d="M12.484 3.49812C11.3751 3.49812 10.2663 3.50173 9.15678 3.49595C8.73368 3.49378 8.37657 3.16356 8.32655 2.75169C8.27028 2.29068 8.50788 1.88892 8.91779 1.76681C9.01575 1.7379 9.12274 1.7314 9.22556 1.7314C11.4085 1.72923 13.5921 1.72851 15.775 1.7314C16.3176 1.7314 16.6796 2.09558 16.6761 2.62379C16.6726 3.1361 16.31 3.49667 15.784 3.49812C14.6835 3.50101 13.5837 3.49884 12.4833 3.49812H12.484Z"
					fill={color || "#848591"}
				/>
				<path
					d="M7.52532 2.61792C7.52532 3.18515 7.5288 3.7531 7.52463 4.32033C7.52046 4.86299 7.16683 5.23223 6.66591 5.2279C6.18792 5.22428 5.82873 4.8442 5.82735 4.32394C5.82318 3.18009 5.82318 2.03552 5.82735 0.891672C5.82943 0.364909 6.18167 0.00144939 6.67216 4.21904e-06C7.15849 -0.00144095 7.51837 0.368522 7.52393 0.888782C7.53019 1.4654 7.52532 2.04203 7.52532 2.61865V2.61792Z"
					fill={color || "#848591"}
				/>
				<path
					d="M19.1745 2.61648C19.1745 3.18371 19.1779 3.75166 19.1738 4.31889C19.1696 4.86227 18.8174 5.23151 18.3157 5.2279C17.8377 5.22428 17.4779 4.84493 17.4758 4.32539C17.4716 3.18154 17.4716 2.03697 17.4758 0.893117C17.4786 0.365631 17.8294 0.0014494 18.3199 4.23552e-06C18.8069 -0.00144093 19.1675 0.367077 19.1731 0.887337C19.1793 1.46396 19.1745 2.04058 19.1745 2.6172V2.61648Z"
					fill={color || "#848591"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_196_2307">
					<rect width="25" height="26" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default CalendarIcon;