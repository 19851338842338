import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	IconButton,
	Box,
	Grid,
	TextField,
	Typography,
	Checkbox,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { ReportDataContext, ReportErrorContext, MessagesContext, AuthContext } from "../../../context";
import { CoordinatesInput } from "../../CoordInput";
import ImageMapModal from "./ImageMapModal";
import { useTheme } from "@mui/material/styles";
import IconButtons from "../../Icons/IconButtons";
import DeleteIcon from "../../Icons/DeleteIcon";

const FeedingBacteriumImages = () => {
	const { reportData, setReportData } = useContext(ReportDataContext); // files = [{file: File, comment: String}]
	const { setCheckErrors } = useContext(ReportErrorContext);
	const { addMessage } = useContext(MessagesContext);
	const [errors, setErrors] = React.useState([]);
	const theme = useTheme();

	useEffect(() => {
		setCheckErrors(() => () => {
			let hasErrors = false;
			const newErrors = [];
			reportData.feedingBacteriumImages.forEach((item, index) => {
				// console.log(item)
				if (!item.latitude || !item.longitude) {
					newErrors.push({ error: true, message: "Coordenadas inválidas" });
				} else {
					newErrors.push({ error: false, message: "" });
				}
			});
			setErrors(newErrors);
			newErrors.forEach((item) => {
				if (item.error) {
					hasErrors = true;
				}
			});
			return hasErrors;
		});
	}, [setCheckErrors, reportData.feedingBacteriumImages]);

	// console.log(errors);
	const maxImages = 100;
	const maxSize = 100 * 1024 * 1024; // 100 MB

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".jpg", ".png"],
		},
		maxFiles: maxImages,
		onDrop: (acceptedFiles) => {
			const updatedFiles = acceptedFiles.map((file) => {
				if (file.size > maxSize) {
					addMessage(
						`La imagen ${file.name} excede el tamaño máximo permitido de ${maxSize / 1024 / 1024}MB`,
						"error"
					);
					return null;
				} else {
					return {
						image: Object.assign(file, { preview: URL.createObjectURL(file) }),
						description: "",
						latitude: "",
						longitude: "",
						hasFeed: false,
						hasBacterium: false,
						hasFeces: false,
						feedRate: 0,
						bacteriumRate: 0,
						fecesRate: 0,
					};
				}
			});

			//no se toman los que son nulos (los que excedieron el tamaño)
			const validFiles = updatedFiles.filter((file) => file !== null);
			setReportData({
				...reportData,
				feedingBacteriumImages: [...reportData.feedingBacteriumImages, ...validFiles],
			});
		},
	});

	const handleCommentChange = (index, comment) => {
		const updatedFiles = [...reportData.feedingBacteriumImages];
		updatedFiles[index].description = comment;
		setReportData({ ...reportData, feedingBacteriumImages: [...updatedFiles] });
	};
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const { canUseMapTools } = useContext(AuthContext);
	const [zoom, setZoom] = useState(16); // [zoom, setZoom
	const onRowClick = (index) => {
		setIsModalOpen(true);
		setSelectedImage(index);
	};
	const onModalClose = () => {
		setIsModalOpen(false);
		setSelectedImage(null);
	};

	return (
		<div style={{ padding: "20px 30px" }}>
			<ImageMapModal
				object={reportData.feedingBacteriumImages[selectedImage]}
				setObject={(obj) => {
					setReportData((prev) => {
						prev.feedingBacteriumImages[selectedImage].latitude = obj.latitude;
						prev.feedingBacteriumImages[selectedImage].longitude = obj.longitude;
						return { ...prev };
					});
				}}
				otherObjects={reportData.feedingBacteriumImages
					.filter((_, i) => i !== selectedImage)
					.filter((item) => item.latitude && item.longitude)}
				reportData={reportData}
				mapZoom={zoom}
				setMapZoom={setZoom}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				onClose={onModalClose}
			/>
			<div
				{...getRootProps({ className: "dropzone" })}
				style={{
					border: `4px dashed ${theme.palette.brandColors.gray}`,
					borderRadius: "10px",
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "200px",
					position: "relative",
				}}
			>
				<input {...getInputProps()} />
				<CloudUploadOutlinedIcon style={{ color: "grey", fontSize: "3rem" }} />
				<p style={{ color: "grey", textAlign: "center", margin: "0px 0" }}>
					<strong>Arrastra las imágenes aquí, o click para seleccionar</strong>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>{reportData.feedingBacteriumImages.length} agregada(s)</em>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>máximo {maxImages}</em>
				</p>
			</div>

			<div>
				{reportData.feedingBacteriumImages.map((item, index) => (
					<Box
						key={index}
						sx={{
							height: "auto",
							display: "flex",
							padding: "20px 0",
							gap: "20px",
						}}
					>
						<img
							src={typeof item.image === "object" ? item.image.preview : item.image}
							alt="Error con la imagen"
							// Data uri is revoked when submited/exit
							style={{
								display: "block",
								height: "100%",
								width: "auto",
								maxHeight: "150px",
								maxWidth: "100%",
								borderRadius: "10px",
							}}
						/>

						<div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
							<div>
								<CoordinatesInput
									setter={(latlon) => {
										setReportData((prev) => {
											prev.feedingBacteriumImages[index].latitude = latlon.latitude;
											prev.feedingBacteriumImages[index].longitude = latlon.longitude;
											return { ...prev };
										});
									}}
									coordinates={{ latitude: item.latitude, longitude: item.longitude }}
									error={{
										latitude: errors[index]?.message,
										longitude: errors[index]?.message,
									}}
									helperText={{
										latitude: errors[index]?.message,
										longitude: errors[index]?.message,
									}}
									key={index}
									column
								>
									{canUseMapTools && (
										<div style={{ display: "flex", alignItems: "center" }}>
											<Button
												sx={{
													backgroundColor: theme.palette.brandColors.primary,
													color: "white",
													padding: "5px 20px",
													borderRadius: "10px",
													boxSizing: "border-box",
													textWrap: "nowrap",
													"&:hover": {
														backgroundColor: theme.palette.brandColors.primary,
														color: "white",
													},
												}}
												onClick={() => onRowClick(index)}
											>
												Ver ubicación en mapa
											</Button>
										</div>
									)}
								</CoordinatesInput>
							</div>
							<div style={{ display: "flex", gap: "10px" }}>
								<div>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											m: "8px 0",
											gap: "15px",
										}}
									>
										<Box sx={{ display: "flex" }}>
											<Checkbox
												checked={item.hasFeed}
												onChange={(e) => {
													setReportData((prev) => {
														prev.feedingBacteriumImages[index].hasFeed = e.target.checked;
														return { ...prev };
													});
												}}
											/>
											<Typography sx={{ alignItems: "center", display: "flex" }}>
												¿Contiene alimento?
											</Typography>
										</Box>
										{item.hasFeed && (
											<FormControl sx={{ width: "100px" }}>
												<InputLabel>Grado</InputLabel>
												<Select
													value={item.feedRate}
													label="Grado"
													onChange={(e) => {
														setReportData((prev) => {
															prev.feedingBacteriumImages[index].feedRate =
																e.target.value;
															return { ...prev };
														});
													}}
												>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
										)}
									</Box>

									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											m: "8px 0",
											gap: "15px",
										}}
									>
										<Box sx={{ display: "flex" }}>
											<Checkbox
												checked={item.hasBacterium}
												onChange={(e) => {
													setReportData((prev) => {
														prev.feedingBacteriumImages[index].hasBacterium =
															e.target.checked;
														return { ...prev };
													});
												}}
											/>
											<Typography sx={{ alignItems: "center", display: "flex" }}>
												¿Contiene bacterias?
											</Typography>
										</Box>
										{item.hasBacterium && (
											<FormControl sx={{ width: "100px" }}>
												<InputLabel>Grado</InputLabel>
												<Select
													value={item.bacteriumRate}
													label="Grado"
													onChange={(e) => {
														setReportData((prev) => {
															prev.feedingBacteriumImages[index].bacteriumRate =
																e.target.value;
															return { ...prev };
														});
													}}
												>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
										)}
									</Box>

									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											m: "8px 0",
											gap: "15px",
										}}
									>
										<Box sx={{ display: "flex" }}>
											<Checkbox
												checked={item.hasFeces}
												onChange={(e) => {
													setReportData((prev) => {
														prev.feedingBacteriumImages[index].hasFeces = e.target.checked;
														return { ...prev };
													});
												}}
											/>
											<Typography sx={{ alignItems: "center", display: "flex" }}>
												¿Contiene heces?
											</Typography>
										</Box>
										{item.hasFeces && (
											<FormControl sx={{ width: "100px" }}>
												<InputLabel>Grado</InputLabel>
												<Select
													value={item.fecesRate}
													label="Grado"
													onChange={(e) => {
														setReportData((prev) => {
															prev.feedingBacteriumImages[index].fecesRate =
																e.target.value;
															return { ...prev };
														});
													}}
												>
													<MenuItem value={0}>0</MenuItem>
													<MenuItem value={1}>1</MenuItem>
													<MenuItem value={2}>2</MenuItem>
													<MenuItem value={3}>3</MenuItem>
												</Select>
											</FormControl>
										)}
									</Box>
								</div>

								<TextField
									label="Añadir un comentario (opcional)..."
									variant="outlined"
									multiline
									rows={5}
									fullWidth
									style={{ flex: 1 }}
									value={item.description}
									onChange={(e) => handleCommentChange(index, e.target.value)}
								/>
							</div>
						</div>
						<IconButtons
							variant="danger"
							icon={<DeleteIcon />}
							onClick={() => {
								// elimina la imagen de la lista
								// si la imagen ya estaba en la db, tambien se agrega a la lista de eliminados
								if (item.id) {
									setReportData({
										...reportData,
										deletedFeedingBacteriumImages: [
											...reportData.deletedFeedingBacteriumImages,
											item,
										],
										feedingBacteriumImages: reportData.feedingBacteriumImages.filter(
											(_, i) => i !== index
										),
									});
								} else {
									setReportData({
										...reportData,
										feedingBacteriumImages: reportData.feedingBacteriumImages.filter(
											(_, i) => i !== index
										),
									});
								}
							}}
						/>
					</Box>
				))}
			</div>
		</div>
	);
};

export default FeedingBacteriumImages;
