import { Stack, Typography, Box, FormControl, DialogContent, Button } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthContext, useMessagesContext } from "../../context";
import { URLS } from "../../global";

const BoatLogForm = ({ setBoatLogId, boatLogData }) => {
	const [data, setData] = useState({
		boat: boatLogData?.boat || "",
		cultivationCenter: boatLogData?.cultivationCenter || "",
		initialDate: boatLogData?.initialDate || "",
		endDate: boatLogData?.endDate || "",
	});
	const [error, setError] = useState({
		boat: false,
		cultivationCenter: false,
		initialDate: false,
		endDate: false,
	});

	useEffect(() => {
		setData({
			boat: boatLogData?.boat || "",
			cultivationCenter: boatLogData?.cultivationCenter || "",
			initialDate: boatLogData?.initialDate || "",
			endDate: boatLogData?.endDate || "",
		});
	}, [boatLogData]);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();
	const [centers, setCenters] = useState([]);
	const [boats, setBoats] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosInstance
			.get(URLS.CENTERS)
			.then((response) => {
				setCenters(response.data);
			})
			.catch((error) => {
				console.error(error);
			});

		axiosInstance
			.get(URLS.BOATS)
			.then((response) => {
				setBoats(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [axiosInstance]);

	const handleSave = () => {
		const errors = {};
		setLoading(true);
		if (!data.boat) {
			errors.boat = true;
		}
		if (!data.cultivationCenter) {
			errors.cultivationCenter = true;
		}
		if (!data.initialDate) {
			errors.initialDate = true;
		}
		if (!data.endDate) {
			errors.endDate = true;
		}

		if (data.initialDate > data.endDate) {
			errors.initialDate = true;
			errors.endDate = true;
		}
		setError(errors);

		if (data.boat && data.cultivationCenter && data.initialDate && data.endDate) {
			const formData = {
				boat: data.boat.id,
				cultivationCenter: data.cultivationCenter.id,
				initialDate: data.initialDate,
				endDate: data.endDate,
			};
			setLoading(true);
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
				method: boatLogData ? "PUT" : "POST",
				url: boatLogData ? URLS.BOAT_LOGS + boatLogData.id + "/" : URLS.BOAT_LOGS,
				data: formData,
			};
			axiosInstance(config)
				.then((response) => {
					setLoading(false);
					setBoatLogId(response.data.id);
					addMessage("Ciclo de Embarcación Guardado", "success");
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
					addMessage("Error al guardar el ciclo de embarcación", "error");
				});
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<DialogContent sx={{ marginTop: "20px", height: "50vh" }}>
				<Stack direction="row" spacing={2}>
					<FormControl sx={{ width: "100%" }}>
						<Typography>Concesión</Typography>
						<Autocomplete
							disablePortal
							required
							id="centros"
							ListboxProps={{ style: { maxHeight: 250 } }}
							options={centers}
							onChange={(e, value) => setData({ ...data, cultivationCenter: value })}
							value={data.cultivationCenter || ""}
							renderInput={(params) => (
								<TextField {...params} error={error.cultivationCenter} helperText={error.cultivationCenter ? "Campo Requerido" : ""} />
							)}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							getOptionLabel={(option) => {
								return option.name || "";
							}}
						/>
					</FormControl>

					<FormControl sx={{ width: "100%" }}>
						<Typography>Embarcación</Typography>
						<Autocomplete
							disablePortal
							required
							error={error.boat}
							id="boats"
							ListboxProps={{ style: { maxHeight: 250 } }}
							options={boats}
							onChange={(e, value) => setData({ ...data, boat: value })}
							value={data.boat || ""}
							renderInput={(params) => <TextField {...params} error={error.boat} helperText={error.boat ? "Campo Requerido" : ""} />}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							getOptionLabel={(option) => {
								return option.name || "";
							}}
						/>
					</FormControl>

					<FormControl sx={{ width: "100%" }}>
						<Typography>Fecha Inicio</Typography>
						<TextField
							required
							id="initialDate"
							type="date"
							error={error.initialDate}
							helperText={error.initialDate ? "Campo Requerido" : ""}
							onChange={(e) =>
								setData({
									...data,
									initialDate: e.target.value,
								})
							}
							value={data.initialDate}
						/>
					</FormControl>

					<FormControl sx={{ width: "100%" }}>
						<Typography>Fecha Término</Typography>
						<TextField
							required
							id="endDate"
							type="date"
							helperText={error.endDate ? "Campo Requerido" : ""}
							error={error.endDate}
							onChange={(e) => setData({ ...data, endDate: e.target.value })}
							value={data.endDate}
						/>
					</FormControl>
				</Stack>
			</DialogContent>

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "20px",
				}}
			>
				<Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="end" flexGrow={1}>
					<Button variant="contained" disabled={loading} onClick={handleSave}>
						Guardar
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default BoatLogForm;
