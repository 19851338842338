// mui components
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

// mui colors
import grey from "@mui/material/colors/grey";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
// components
import { EditSalmonCompanyDialog } from "./";
import FishIcon from "../../Icons/FishIcon";
import IconButtons from "../../Icons/IconButtons";
import EditIcon from "../../Icons/EditIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import { useCallback, useEffect, useState } from "react";

const StackDivider = () => (
	<Box
		component="hr"
		sx={{
			borderColor: "divider",
			borderStyle: "dashed",
			borderWidth: "thin",
			borderRadius: 1,
		}}
	/>
);

/**
 * Componente para mostrar el detalle de una empresa productora y permitir su edicion.
 * @param {Object} props
 * @param {Function} props.onClose Función para cerrar el componente
 * @param {Object} props.data Datos de la empresa productora
 */
const SalmonCompanyDetail = ({ onClose, data }) => {
	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();
	const theme = useTheme();

	const [objectDetails, setObjectDetails] = useState({
		id: data.id,
		address: "",
		managers: [],
	});

	const [loading, setLoading] = useState(true);

	const requestData = useCallback(() => {
		axiosInstance
			.get(`${URLS.SALMON_COMPANY}${data.id}/info/`)
			.then((response) => {
				setObjectDetails(response.data);
			})
			.catch((error) => {
				addMessage("Error al cargar la empresa productora", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [axiosInstance, addMessage, data?.id]);

	useEffect(() => {
		if (data?.id) {
			requestData();
		}
	}, [data?.id, requestData]);

	const [dialogData, setDialogData] = useState({
		open: false,
		id: undefined,
		data: {},
	});

	const editSalmonera = () => {
		let address = objectDetails.address?.split(", ");
		let address_street = address?.length > 0 ? address[0] : "";
		let address_commune = address?.length > 1 ? address[1] : "";
		let address_region = address?.length > 2 ? address[2] : "";

		setDialogData({
			open: true,
			data: {
				id: data.id || objectDetails.id,
				name: objectDetails.name || data.name || "",
				address_street: address_street,
				address_commune: address_commune,
				address_region: address_region,
				address: objectDetails.address || data.location || "",
				logo: objectDetails.logo || "",
				logo_file: null,
				diaryReportFileType: objectDetails.diaryReportFileType,
				consolidatedReportFileType: objectDetails.consolidatedReportFileType,
			},
		});
	};

	const deleteSalmonera = () => {
		if (
			!window.confirm(
				`¿Está seguro que desea eliminar la empresa ${data.name}?\n` +
					`Esta acción no se puede deshacer y eliminará toda la información relacionada a esta empresa.`
			)
		)
			return;

		axiosInstance
			.delete(`${URLS.SALMON_COMPANY}${data.id}/`)
			.then((response) => {
				addMessage("Empresa productora eliminada exitosamente", "success");
				onClose();
			})
			.catch((error) => {
				addMessage("Error al eliminar la empresa productora", "error");
			});
	};

	const closeDialog = () => {
		setDialogData({
			open: false,
			data: {},
		});

		requestData();
	};

	return (
		<Box sx={{ width: "100%" }}>
			<EditSalmonCompanyDialog open={dialogData.open} onClose={closeDialog} data={dialogData.data} />

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					padding: "0 34px",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<FishIcon />
					<div
						style={{
							display: "inline-block",
							marginLeft: 10,
							color: theme.palette.brandColors.primary,
							fontSize: "16px",
							fontWeight: 700,
						}}
					>
						{data.name}
					</div>
				</div>
				<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
					<IconButtons icon={<EditIcon />} onClick={editSalmonera} />
					<IconButtons icon={<DeleteIcon />} onClick={deleteSalmonera} variant="danger" />
					<div
						style={{
							cursor: "pointer",
							textDecoration: "underline",
							fontWeight: 700,
							color: theme.palette.brandColors.primary,
							fontSize: "13px",
						}}
						onClick={onClose}
					>
						Volver al listado
					</div>
				</div>
			</Box>

			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 1,
						bgcolor: "rgba(255, 255, 255, 0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<Stack
				mt={2}
				px={"34px"}
				border="1px solid #F5F5F5"
				borderRadius={1}
				direction="column"
				spacing={2}
				divider={<StackDivider />}
				width="100%"
				position="relative"
			>
				<Stack direction="row" width="100%" py={2} alignItems="center">
					<Stack direction="row" spacing={2} flex={1}>
						<Typography variant="body1" color="text.secondary">
							Dirección:
						</Typography>
						<Typography
							variant="body1"
							color={objectDetails.address || data.location ? "text.primary" : "text.secondary"}
						>
							{objectDetails.address || data.location || "No especificado"}
						</Typography>
					</Stack>

					{objectDetails.logo && (
						<Stack direction="row" spacing={2} flex={1} alignItems="center">
							<Typography variant="body1" color="text.secondary">
								Logo:
							</Typography>
							<Box
								sx={{
									height: 50,
									width: 50,
									overflow: "hidden",
									borderStyle: "solid",
									borderWidth: 1,
									borderColor: grey[500],
									borderRadius: 1,
								}}
							>
								<img
									src={objectDetails.logo || ""}
									alt="logo"
									style={{ height: "100%", width: "100%" }}
								/>
							</Box>
						</Stack>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};

export default SalmonCompanyDetail;
