import CircularCheckbox from "../Inputs/CircularCheckbox";
import { useAuthContext } from "../../context";
import { styled } from "@mui/material/styles";

const Label = styled("div")(({ theme, checked }) => ({
	color: checked ? theme.palette.brandColors.primary : theme.palette.brandColors.danger,
}));

const RenderEstado = ({ row, loading, setLoading, setRows, url }) => {
	const { axiosInstance, USER_TYPE } = useAuthContext();

	const handleChoose = async () => {
		if (row.state === "Revisado") return;
		if (loading) return;
		setLoading(true);
		await axiosInstance
			.patch(`${url}${row.id}/revisar/`)
			.then((response) => {
				setRows((rows) => {
					const newRows = [...rows];
					const index = newRows.findIndex((r) => r.id === row.id);
					newRows[index].state = "Revisado";
					return rows;
				});
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div style={{ display: "flex", width: "100%", alignItems: "center" }}>
			{USER_TYPE === "Admin" ? (
				<CircularCheckbox
					checked={row.state === "Revisado"}
					disabled={loading}
					onChange={handleChoose}
					label={row.state}
				/>
			) : (
				<Label checked={row.state === "Revisado"}>{row.state}</Label>
			)}
		</div>
	);
};

export default RenderEstado;
