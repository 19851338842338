// mui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import { useAuthContext, useMessagesContext } from "../../context";
import { URLS } from "../../global";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useState } from "react";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const CsvImporter = () => {
	const [csvFile, setCsvFile] = useState(null);
	const [feedback, setFeedback] = useState(null);
	const [loading, setLoading] = useState(false);
	const theme = useTheme();

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append("file", csvFile);

		let url = `${URLS.CENTERS}import_csv/`;
		setLoading(true);
		axiosInstance
			.post(url, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				addMessage("Archivo subido correctamente", "success");
				setFeedback(res.data.detail);
			})
			.catch((err) => {
				addMessage("Error al subir archivo", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Box sx={{ padding: "10px 34px" }}>
			<Stack direction="column" spacing={2}>
				<Typography variant="h5" component="div">
					Importar CSV
				</Typography>

				{!feedback ? (
					<>
						<Typography variant="body1" component="div">
							Esta funcionalidad permite importar datos desde un archivo CSV proveniente de la plataforma
							de mapas de Subpesca.
							<br />
							Para obtener un archivo valido dirijase a{" "}
							<a href="https://mapas.subpesca.cl/ideviewer/" target="_blank" rel="noreferrer">
								la plataforma
							</a>{" "}
							y realize una busqueda rápida de concesiones de acuicultura.
							<br />
							Una vez realizada la busqueda encontrará en la parte inferior de la pantalla una tabla que
							se podrá exportar.
						</Typography>

						<Stack
							encType="multipart/form-data"
							onSubmit={handleSubmit}
							component="form"
							spacing={2}
							direction="row"
						>
							<Button
								component="label"
								variant="contained"
								startIcon={<UploadFileIcon />}
								disabled={loading}
								sx={{
									borderRadius: "10px",
									backgroundColor: theme.palette.brandColors.primary,
								}}
							>
								Subir archivo
								<VisuallyHiddenInput
									type="file"
									name="file"
									onChange={(e) => setCsvFile(e.target.files[0])}
									accept=".csv"
									required
								/>
							</Button>

							<Button type="submit" variant="contained" disabled={loading || !csvFile}>
								Enviar
							</Button>
						</Stack>
					</>
				) : (
					<Typography variant="body1" component="div" sx={{ whiteSpace: "pre" }}>
						{feedback}
					</Typography>
				)}
			</Stack>
		</Box>
	);
};

export default CsvImporter;
