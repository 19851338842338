import { useState } from "react";
import GeneralButton from "../../../GeneralButton";
import { GeneralInfo, TabContent } from "../Common";
import GeoImages from "../GeoImages";
import {
	CloseButton,
	RotateInstruction,
	See,
	Shadow,
	SwitchContainer,
	TabContentWrapper,
	Table,
	TableContainer,
	TableHead,
	TableTab,
	TableTitle,
	Td,
	Th,
} from "./ConsolidatedCommon";
import RotateIcon from "../../../Icons/RotateIcon";
import CloseIcon from "@mui/icons-material/Close";

const GeoImagesContent = ({ reportData }) => {
	const [geoImagesSwitch, setGeoImagesSwitch] = useState(0);
	const [tableOpen, setTableOpen] = useState(false);
	const [rotate, setRotate] = useState(false);

	const onOpenTable = () => {
		setRotate(true);

		setTimeout(() => {
			setRotate(false);
			setTableOpen(true);
		}, 1000);
	};

	return (
		<TabContentWrapper>
			{rotate && (
				<Shadow>
					<RotateInstruction>
						<RotateIcon />
					</RotateInstruction>
				</Shadow>
			)}
			<SwitchContainer>
				<GeneralButton
					variant={geoImagesSwitch === 0 ? "contained" : "empty"}
					onClick={() => setGeoImagesSwitch(0)}
					text="Tabla"
					sx={{
						textTransform: "none",
					}}
				/>
				<GeneralButton
					variant={geoImagesSwitch === 1 ? "contained" : "empty"}
					onClick={() => setGeoImagesSwitch(1)}
					text="Imágenes"
					sx={{
						textTransform: "none",
					}}
				/>
			</SwitchContainer>

			<TabContent
				value={geoImagesSwitch}
				index={0}
				style={{
					height: "100%",
					padding: "10px 20px",
					margin: 0,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<TableTab>
					<TableTitle>
						Tabla de prospecciones
						<See onClick={onOpenTable}>Ver</See>
					</TableTitle>
					<TableContainer>
						<Table open={tableOpen}>
							{tableOpen && (
								<CloseButton onClick={() => setTableOpen(false)}>
									<CloseIcon />
								</CloseButton>
							)}
							<TableHead>
								<tr>
									<Th>Fecha</Th>
									<Th>ID</Th>
									<Th>Latitud</Th>
									<Th>Longitud</Th>
									<Th>Descripción</Th>
									<Th>Jaula</Th>
								</tr>
							</TableHead>
							<tbody>
								{reportData?.geoImages?.map((obj) => (
									<tr key={obj.date}>
										<Td>{obj.date}</Td>
										<Td>{obj.id}</Td>
										<Td>{obj.latitude}</Td>
										<Td>{obj.longitude}</Td>
										<Td>{obj.description}</Td>
										<Td>{obj.cage}</Td>
									</tr>
								))}
							</tbody>
						</Table>
					</TableContainer>
				</TableTab>
			</TabContent>

			<TabContent
				value={geoImagesSwitch}
				index={1}
				style={{
					padding: "10px 20px",
					margin: 0,
					display: "flex",
					flexDirection: "column",
					overflow: "unset",
					maxHeight: "unset",
				}}
			>
				<GeoImages images={reportData?.geoImages} />
			</TabContent>
		</TabContentWrapper>
	);
};

export default GeoImagesContent;
