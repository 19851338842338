import { FormControl, Typography, Autocomplete, TextField, Grid, FormHelperText } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { AuthContext, ReportDataContext, ReportErrorContext } from "../../../context";

// global
import { URLS } from "../../../global";
import TextInput from "../../Inputs/TextInput";

/**
 * Formulario de la información general a todos los informes
 */
const GeneralDataForm = ({ hasEndDate }) => {
	const { setCheckErrors } = useContext(ReportErrorContext);
	const [centros, setCentros] = useState([]);
	const { axiosInstance, username } = useContext(AuthContext);
	const { reportData, setReportData } = useContext(ReportDataContext);

	// Errores de los inputs
	const initialErrors = {
		cultivationCenter: false,
		date: false,
		boat: false,
	};

	const [errors, setErrors] = useState(initialErrors);
	useEffect(() => {
		setCheckErrors((_) => () => {
			const newErrors = {};
			for (const key in errors) {
				if (reportData[key] == null || reportData[key] === "") {
					newErrors[key] = "Este campo es requerido";
				}
			}
			setErrors(newErrors);
			return Object.keys(newErrors).length > 0;
		});
	}, [reportData, setCheckErrors]);

	// Pedimos al backend los centros para rellenar el campo de centro con su respectiva área y código
	useEffect(() => {
		axiosInstance.get(`${URLS.CENTERS}uploadPermissionsCenter/`).then((response) => {
			setCentros(
				response.data.map((row) => ({
					label: row.name,
					id: row.id,
					code: row.code,
					place: row.place,
					coordinates: row.coordinates,
					salmonCompany: row.salmonCompany,
					cages: row.cages,
					polygons: row?.polygons,
				}))
			);
		});
	}, [axiosInstance]);

	// Función que actualiza los valores de un campo y los posibles errores del mismo
	const handleInputChange = (name, value) => {
		setReportData({
			...reportData,
			[name]: value,
		});
		setErrors({
			...errors,
			[name]: false,
		});
	};

	//Embarcaciones que se muestran en el autocompletado
	const [boats, setBoats] = useState([]);
	useEffect(() => {
		axiosInstance.get(`${URLS.BOATS}active_boats/`).then((response) => {
			setBoats(
				response.data.map((row) => ({
					...row,
					label: row.name,
				}))
			);
		});
	}, [axiosInstance]);

	return (
		<div style={{ padding: "0 25px" }}>
			<form>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Autocomplete
							disablePortal
							required
							options={centros}
							onChange={(e, value) => handleInputChange("cultivationCenter", value)}
							value={reportData.cultivationCenter}
							renderInput={(params) => (
								<TextInput
									error={errors.cultivationCenter}
									label="Centro"
									required={true}
									{...params}
								/>
							)}
							isOptionEqualToValue={(value, other) => value.id === other.id}
							sx={{ borderRadius: "15px" }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Autocomplete
							disablePortal
							required
							id="boats"
							options={boats}
							onChange={(e, value) => handleInputChange("boat", value)}
							value={reportData.boat || null}
							renderInput={(params) => (
								<TextInput error={errors.boat} label="Embarcación" required={true} {...params} />
							)}
							isOptionEqualToValue={(value, other) => value.id === other.id} // ojito
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextInput label="Usuario" value={username} disabled required={true} />
					</Grid>
					<Grid item xs={12} md={hasEndDate ? 3 : 6}>
						<TextInput
							label="Fecha"
							value={reportData.date}
							onChange={(e) => {
								handleInputChange("date", e.target.value);
							}}
							error={errors.date}
							required
							type="date"
						/>
					</Grid>
					{hasEndDate && (
						<Grid item xs={12} md={3}>
							<TextInput
								label="Fecha de Término (Opcional)"
								value={reportData.endDate}
								onChange={(e) => {
									handleInputChange("endDate", e.target.value);
								}}
								type="date"
							/>
						</Grid>
					)}

					<Grid item xs={12} md={12}>
						<TextInput
							label="Comentarios"
							value={reportData.comments}
							onChange={(e) => {
								handleInputChange("comments", e.target.value);
							}}
							multiline
							rows={8}
							maxLength={2000}
						/>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

export default GeneralDataForm;
