import { useEffect, useState, useCallback, Fragment } from "react";

// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import ListItemText from "@mui/material/ListItemText";

// mui icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// context
import { useAuthContext, useMessagesContext } from "../context";
// global
import { URLS } from "../global";

const StackDivider = () => (
	<Box
		component="hr"
		sx={{
			borderColor: "divider",
			borderStyle: "dashed",
			borderWidth: "thin",
			borderRadius: 1,
		}}
	/>
);

/**
 * Componente para mostrar el detalle de un usuario y editar sus datos
 * @param {Object} props
 * @param {Object} props.id Id del usuario
 */
const UserDetail = ({ id }) => {
	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	const [objectDetails, setObjectDetails] = useState({
		id: id,
		username: "",
		first_name: "",
		last_name: "",
		rut: "",
		email: "",
		phone: "",
		other_contact_info: "",
		is_active: true,
		permissions: "",
	});

	const [loading, setLoading] = useState(true);

	const requestData = useCallback(() => {
		axiosInstance
			.get(`${URLS.USERS}${id}/`)
			.then((response) => {
				setObjectDetails(response.data);
			})
			.catch((_error) => {
				addMessage("Error al cargar los datos del usuario", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [axiosInstance, addMessage, id]);

	useEffect(() => {
		if (id) {
			requestData();
		}
	}, [id, requestData]);

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 1,
						bgcolor: "rgba(255, 255, 255, 0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<Stack
				mt={2}
				py={2}
				px={3}
				border="2px solid #F5F5F5"
				borderRadius={1}
				direction="column"
				spacing={2}
				divider={<StackDivider />}
				width="100%"
				position="relative"
			>
				<Stack direction="row" width="100%" py={2} alignItems="center">
					<Stack direction="row" spacing={2} flex={1}>
						<Typography variant="body1" color="text.secondary">
							Nombre completo:
						</Typography>
						<Typography variant="body1" color="text.primary">
							{`${objectDetails.first_name} ${objectDetails.last_name}`}
						</Typography>
					</Stack>

					<Stack direction="row" spacing={2} flex={1}>
						<Typography variant="body1" color="text.secondary">
							Rut:
						</Typography>
						<Typography variant="body1" color={objectDetails.rut ? "text.primary" : "text.secondary"}>
							{objectDetails.rut || "No especificado"}
						</Typography>
					</Stack>

					<Stack direction="row" spacing={2} flex={1}>
						<Typography variant="body1" color="text.secondary">
							Activo:
						</Typography>
						<Typography variant="body1" color="text.primary">
							{objectDetails.is_active ? "Sí" : "No"}
						</Typography>
					</Stack>
				</Stack>

				<Stack direction="row" width="100%" py={2} alignItems="center">
					<Stack direction="row" spacing={2} flex={1}>
						<Typography variant="body1" color="text.secondary">
							Nombre de usuario:
						</Typography>
						<Typography variant="body1" color="text.primary">
							{objectDetails.username}
						</Typography>
					</Stack>

					<Stack direction="row" spacing={2} flex={1}>
						<Typography variant="body1" color="text.secondary">
							Correo:
						</Typography>
						<Typography variant="body1" color={objectDetails.email ? "text.primary" : "text.secondary"}>
							{objectDetails.email || "No especificado"}
						</Typography>
					</Stack>

					<Stack direction="row" spacing={2} flex={1}>
						<Typography variant="body1" color="text.secondary">
							Teléfono:
						</Typography>
						<Typography variant="body1" color={objectDetails.phone ? "text.primary" : "text.secondary"}>
							{objectDetails.phone || "No especificado"}
						</Typography>
					</Stack>
				</Stack>

				{objectDetails.other_contact_info && (
					<Stack direction="row" width="100%" py={2} alignItems="center">
						<Stack direction="row" spacing={2} flex={1}>
							<Typography variant="body1" color="text.secondary">
								Otros datos de contacto:
							</Typography>
							<Typography variant="body1" color="text.primary">
								{objectDetails.other_contact_info}
							</Typography>
						</Stack>
					</Stack>
				)}
			</Stack>
		</Box>
	);
};

const PasswordForm = ({ id }) => {
	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	const [formData, setFormData] = useState({
		previousPassword: "",
		password: "",
		password2: "",
	});
	const [errors, setErrors] = useState({
		previousPassword: "",
		password: "",
		password2: "",
	});
	const [showPassword, setShowPassword] = useState({
		previous: false,
		password: false,
		password2: false,
	});
	const [loading, setLoading] = useState(false);

	const validatePassword = () => {
		let errors = {
			previousPassword: "",
			password: "",
			password2: "",
		};
		if (!formData.previousPassword) {
			errors.previousPassword = "Este campo es obligatorio";
		}
		if (!formData.password) {
			errors.password = "Este campo es obligatorio";
		}
		if (!formData.password2) {
			errors.password2 = "Este campo es obligatorio";
		}

		if (formData.password !== formData.password2) {
			errors.password = "Las contraseñas no coinciden";
			errors.password2 = "Las contraseñas no coinciden";
		}

		if (formData.password.length < 4) {
			errors.password = "La contraseña debe tener al menos 4 caracteres";
		}

		setErrors(errors);
		if (Object.values(errors).some((error) => error !== "")) {
			return false;
		}
		return true;
	};

	const submitPassword = () => {
		if (!validatePassword()) {
			return;
		}

		setLoading(true);
		axiosInstance
			.patch(`${URLS.USERS}${id}/`, {
				previous_password: formData.previousPassword,
				password: formData.password,
			})
			.then((_response) => {
				addMessage("Contraseña cambiada correctamente", "success");
				setFormData({
					previousPassword: "",
					password: "",
					password2: "",
				});
			})
			.catch((_error) => {
				if (_error.response.previous_password) {
					setErrors((prevState) => ({
						...prevState,
						previousPassword: _error.response.previous_password,
					}));
				} else {
					addMessage("Error al cambiar la contraseña", "error");
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
			<Stack
				direction="column"
				mt={2}
				py={2}
				px={3}
				spacing={2}
				justifyContent="center"
				alignItems="center"
				width="100%"
				maxWidth={500}
			>
				<Typography variant="h6" color="text.primary">
					Cambiar contraseña
				</Typography>

				<TextField
					id="previousPassword"
					label="Contraseña anterior"
					variant="outlined"
					type={showPassword.previous ? "text" : "password"}
					value={formData.previousPassword}
					required
					fullWidth
					onChange={(e) => {
						setFormData((prevState) => ({
							...prevState,
							previousPassword: e.target.value,
						}));
						setErrors((prevState) => ({
							...prevState,
							previousPassword: "",
						}));
					}}
					error={Boolean(errors.previousPassword)}
					helperText={errors.previousPassword}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle previous password visibility"
									onClick={() =>
										setShowPassword((prevState) => ({
											...prevState,
											previous: !prevState.previous,
										}))
									}
									onMouseDown={(e) => e.preventDefault()}
									edge="end"
									tabIndex="-1"
								>
									{showPassword.previous ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<TextField
					id="password"
					label="Contraseña nueva"
					variant="outlined"
					type={showPassword.password ? "text" : "password"}
					value={formData.password}
					required
					fullWidth
					onChange={(e) => {
						setFormData((prevState) => ({
							...prevState,
							password: e.target.value,
						}));
						setErrors((prevState) => ({
							...prevState,
							password: "",
						}));
					}}
					error={Boolean(errors.password)}
					helperText={errors.password}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() =>
										setShowPassword((prevState) => ({
											...prevState,
											password: !prevState.password,
										}))
									}
									onMouseDown={(e) => e.preventDefault()}
									edge="end"
									tabIndex="-1"
								>
									{showPassword.password ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<TextField
					id="password2"
					label="Repetir contraseña"
					variant="outlined"
					type={showPassword.password2 ? "text" : "password"}
					value={formData.password2}
					fullWidth
					onChange={(e) => {
						setFormData((prevState) => ({
							...prevState,
							password2: e.target.value,
						}));
						setErrors((prevState) => ({
							...prevState,
							password2: "",
						}));
					}}
					error={Boolean(errors.password2)}
					helperText={errors.password2}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password2 visibility"
									onClick={() =>
										setShowPassword((prevState) => ({
											...prevState,
											password2: !prevState.password2,
										}))
									}
									onMouseDown={(e) => e.preventDefault()}
									edge="end"
									tabIndex="-1"
								>
									{showPassword.password2 ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<Button variant="contained" color="primary" onClick={submitPassword} disabled={loading}>
					Cambiar contraseña
				</Button>
			</Stack>
		</Box>
	);
};

const ProfilePage = () => {
	const { userData, USER_TYPE } = useAuthContext();

	return (
		<Box sx={{ height: "100%", width: "100%", p: 2 }}>
			<Stack direction="column" my={2} spacing={2}>
				<Typography variant="body1" color="text.secondary">
					Aquí puedes ver tus datos, para cambiarlos debes contactar con un administrador.
				</Typography>
				<UserDetail id={userData.id} />
				{USER_TYPE === "Operador ROV" && (
					<>
						<Typography variant="h5" color="text.primary">
							Mis permisos
						</Typography>
						<PermissionsList />
					</>
				)}

				{/* cambio de contraseña */}
				<PasswordForm id={userData.id} />
			</Stack>
		</Box>
	);
};

export default ProfilePage;

const permiso_types = [
	{
		id: 1,
		name: "canViewHistory",
		description: "Ver historial",
	},
	{
		id: 2,
		name: "canUploadReport",
		description: "Subir reportes",
	},
];

/**
 * Componente para mostrar un permiso de un ROVOperator
 * @param {object} props
 * @param {object} props.permiso Información del permiso
 */
const PermisoItem = ({ permiso }) => {
	return (
		<ListItem key={permiso.id} divider sx={{ display: "flex", justifyContent: "space-between" }}>
			<ListItemText primary={`${permiso.cultivationCenter.code} | ${permiso.cultivationCenter.name}`} />

			<Box
				sx={{
					display: "flex",
					gap: "1rem",
					alignItems: "center",
					justifyContent: "flex-start",
				}}
			>
				{permiso_types.map((permiso_type) => (
					<Fragment key={permiso_type.id}>
						<Box
							key={permiso_type.id}
							sx={{
								display: "flex",
								gap: "1rem",
								alignItems: "center",
							}}
						>
							<Typography
								component="div"
								variant="body1"
								sx={{
									display: "flex",
									gap: "1rem",
									alignItems: "center",
								}}
							>
								{permiso_type.icon} {permiso_type.description}
							</Typography>
							<Switch
								checked={permiso[permiso_type.name] || false}
								inputProps={{ "aria-label": "controlled" }}
								disabled
								name={permiso_type.name}
							/>
						</Box>
						<Divider orientation="vertical" flexItem />
					</Fragment>
				))}
			</Box>
		</ListItem>
	);
};

/**
 * Componente que muestra la lista de permisos de un ROVOperator
 */
const PermissionsList = () => {
	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();
	const [myPermissions, setMyPermissions] = useState([]);

	const requestData = useCallback(() => {
		axiosInstance
			.get(`${URLS.OPERATORS}my_permissions/`)
			.then((response) => {
				setMyPermissions(response.data.permissions);
			})
			.catch((_error) => {
				addMessage("Error al cargar los permisos del usuario", "error");
			})
			.finally(() => {});
	}, [axiosInstance, addMessage]);

	useEffect(() => {
		requestData();
	}, [requestData]);

	return (
		<List
			component="div"
			disablePadding
			sx={{
				marginY: 1,
				paddingLeft: 1,
				border: "2px solid #F5F5F5",
				borderRadius: 1,
			}}
		>
			{myPermissions
				.sort((a, b) => b.id - a.id)
				.map((permiso) => (
					<PermisoItem permiso={permiso} key={permiso.id} />
				))}
		</List>
	);
};
