import Plot from "react-plotly.js";
import GeneralButton from "../../../GeneralButton";
import { GeneralInfo, middleScreenMedia, TabContent } from "../Common";
import {
	CloseButton,
	PlotContainer,
	PlotStats,
	PlotTab,
	RotateInstruction,
	See,
	Shadow,
	StatTitle,
	SwitchContainer,
	TabContentWrapper,
	Table,
	TableHead,
	Td,
	Th,
} from "./ConsolidatedCommon";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import RotateIcon from "../../../Icons/RotateIcon";
import CloseIcon from "@mui/icons-material/Close";

const Stats = ({ reportData }) => {
	const [statsSwitch, setStatsSwitch] = useState(0);
	const [byQFPOpen, setByQFPOpen] = useState(false);
	const [byQSPOpen, setByQSPOpen] = useState(false);
	const [byMFPOpen, setByMFPOpen] = useState(false);
	const [byMSPOpen, setByMSPOpen] = useState(false);
	const [rotate, setRotate] = useState(false);
	const theme = useTheme();

	const layout = {
		responsive: true,
		autosize: true,
		font: {
			family: "Roboto, sans-serif",
			size: 14,
		},
		yaxis: {
			automargin: true,
			showgrid: true,
		},
		xaxis: {
			automargin: true,
			showgrid: false,
		},
		padding: 30,
		margin: "auto",
		title: {
			font: {
				size: 16,
				family: "Roboto, sans-serif",
				color: theme.palette.brandColors.primary,
			},
		},
	};

	const onByQFPOpen = () => {
		setRotate(true);

		setTimeout(() => {
			setRotate(false);
			setByQFPOpen(!byQFPOpen);
		}, 1000);
	};

	const onByQSPOpen = () => {
		setRotate(true);

		setTimeout(() => {
			setRotate(false);
			setByQSPOpen(!byQSPOpen);
		}, 1000);
	};

	const onByMFPOpen = () => {
		setRotate(true);

		setTimeout(() => {
			setRotate(false);
			setByMFPOpen(!byMFPOpen);
		}, 1000);
	};

	const onByMSPOpen = () => {
		setRotate(true);

		setTimeout(() => {
			setRotate(false);
			setByMSPOpen(!byMSPOpen);
		}, 1000);
	};

	return (
		<>
			<TabContentWrapper>
				{rotate && (
					<Shadow>
						<RotateInstruction>
							<RotateIcon />
						</RotateInstruction>
					</Shadow>
				)}
				<SwitchContainer style={{ marginBottom: 0 }}>
					<GeneralButton
						variant={statsSwitch === 0 ? "contained" : "empty"}
						onClick={() => setStatsSwitch(0)}
						text="Objetos cuantificables"
						sx={{
							textTransform: "none",
							[middleScreenMedia]: {
								fontSize: "12px",
								textWrap: "nowrap",
							},
						}}
					/>
					<GeneralButton
						variant={statsSwitch === 1 ? "contained" : "empty"}
						onClick={() => setStatsSwitch(1)}
						text="Objetos medibles"
						sx={{
							textTransform: "none",
							[middleScreenMedia]: {
								fontSize: "12px",
								textWrap: "nowrap",
							},
						}}
					/>
				</SwitchContainer>
				<TabContent
					value={statsSwitch}
					index={0}
					style={{
						height: "100%",
						padding: "0 20px",
						margin: 0,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<PlotStats>
						<Table notModal>
							<TableHead>
								<tr>
									<Th>Objeto</Th>
									<Th>Cantidad</Th>
								</tr>
							</TableHead>
							<tbody>
								{reportData?.byQuantity?.map((obj) => (
									<tr key={obj.type__name}>
										<Td>{obj.type__name}</Td>
										<Td>{obj.ratio}</Td>
									</tr>
								))}
							</tbody>
						</Table>

						<PlotTab>
							<StatTitle>
								Cantidad de objetos cuantificables
								<See onClick={onByQFPOpen}>Ver</See>
							</StatTitle>
							<PlotContainer open={byQFPOpen}>
								{byQFPOpen && (
									<CloseButton onClick={() => setByQFPOpen(false)}>
										<CloseIcon />
									</CloseButton>
								)}

								<Plot
									config={{ responsive: true, displayModeBar: false }}
									data={[
										{
											y: reportData.byQuantity.map((item) => item.type__name),
											x: reportData.byQuantity.map((item) => item.ratio),
											texttemplate: `%{value}`,
											type: "bar",
											orientation: "h",
											marker: {
												color: theme.palette.brandColors.primary,
											},
											textfont: {
												color: "#FFFFFF",
											},
											hoverlabel: {
												bgcolor: "rgba(34, 172, 164, 0.23)",
												font: {
													color: "black",
												},
											},
										},
									]}
									layout={{
										...layout,
									}}
									style={{ width: "100%", height: "100%" }}
								/>
							</PlotContainer>
						</PlotTab>

						<PlotTab>
							<StatTitle>
								Peso (kg) de objetos cuantificables
								<See onClick={onByQSPOpen}>Ver</See>
							</StatTitle>
							<PlotContainer open={byQSPOpen}>
								{byQSPOpen && (
									<CloseButton onClick={() => setByQSPOpen(false)}>
										<CloseIcon />
									</CloseButton>
								)}

								<Plot
									config={{ responsive: true, displayModeBar: false }}
									data={[
										{
											y: reportData.byQuantity.map((item) => item.type__name),
											x: reportData.byQuantity.map((item) => item.count),
											texttemplate: `%{value}`,
											type: "bar",
											orientation: "h",
											marker: {
												color: theme.palette.brandColors.primary,
											},
											textfont: {
												color: "#FFFFFF",
											},
											hoverlabel: {
												bgcolor: "rgba(34, 172, 164, 0.23)",
												font: {
													color: "black",
												},
											},
										},
									]}
									layout={{
										...layout,
									}}
									style={{ width: "100%", height: "100%" }}
								/>
							</PlotContainer>
						</PlotTab>
					</PlotStats>
				</TabContent>

				<TabContent
					value={statsSwitch}
					index={1}
					style={{
						height: "100%",
						padding: "0 20px",
						margin: 0,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<PlotStats>
						<Table notModal>
							<TableHead>
								<tr>
									<Th>Objeto</Th>
									<Th>Cantidad (m)</Th>
								</tr>
							</TableHead>
							<tbody>
								{reportData?.byMeasurable?.map((obj) => (
									<tr key={obj.type__name}>
										<Td>{obj.type__name}</Td>
										<Td>{obj.ratio}</Td>
									</tr>
								))}
							</tbody>
						</Table>

						<PlotTab>
							<StatTitle>
								Cantidad (m) de objetos medibles
								<See onClick={onByMFPOpen}>Ver</See>
							</StatTitle>
							<PlotContainer open={byMFPOpen}>
								{byMFPOpen && (
									<CloseButton onClick={() => setByMFPOpen(false)}>
										<CloseIcon />
									</CloseButton>
								)}
								<Plot
									config={{ responsive: true, displayModeBar: false }}
									data={[
										{
											y: reportData.byMeasurable.map((item) => item.type__name),
											x: reportData.byMeasurable.map((item) => item.ratio),
											texttemplate: `%{value}`,
											type: "bar",
											orientation: "h",
											marker: {
												color: theme.palette.brandColors.primary,
											},
											textfont: {
												color: "#FFFFFF",
											},
											hoverlabel: {
												bgcolor: "rgba(34, 172, 164, 0.23)",
												font: {
													color: "black",
												},
											},
										},
									]}
									layout={{
										...layout,
									}}
									style={{ width: "100%", height: "100%" }}
								/>
							</PlotContainer>
						</PlotTab>

						<PlotTab>
							<StatTitle>
								Peso (kg) de objetos medibles
								<See onClick={onByMSPOpen}>Ver</See>
							</StatTitle>
							<PlotContainer open={byMSPOpen}>
								{byMSPOpen && (
									<CloseButton onClick={() => setByMSPOpen(false)}>
										<CloseIcon />
									</CloseButton>
								)}
								<Plot
									config={{ responsive: true, displayModeBar: false }}
									data={[
										{
											y: reportData.byMeasurable.map((item) => item.type__name),
											x: reportData.byMeasurable.map((item) => item.count),
											texttemplate: `%{value}`,
											type: "bar",
											orientation: "h",
											marker: {
												color: theme.palette.brandColors.primary,
											},
											textfont: {
												color: "#FFFFFF",
											},
											hoverlabel: {
												bgcolor: "rgba(34, 172, 164, 0.23)",
												font: {
													color: "black",
												},
											},
										},
									]}
									layout={{
										...layout,
									}}
									style={{ width: "100%", height: "100%" }}
								/>
							</PlotContainer>
						</PlotTab>
					</PlotStats>
				</TabContent>
			</TabContentWrapper>
		</>
	);
};

export default Stats;
