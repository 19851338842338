// mui components
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

// mui icons
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
// components
import { ClientForm } from "./";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";
import IconButtons from "../../Icons/IconButtons";
import EditIcon from "../../Icons/EditIcon";
import { useEffect, useState } from "react";

/**
 * Componente para mostrar el detalle de una jaula. Se utiliza el componente ClientForm para mostrar los datos y permitir su edicion.
 * @param {object} props
 * @param {object} props.data Datos del usuario
 * @param {function} props.onClose Funcion para cerrar el detalle
 * @param {number} props.companyId ID de la empresa productora a la que pertenece el usuario
 */
export const ClientDetail = ({ data, onClose, companyId }) => {
	const theme = useTheme();
	return (
		<Box sx={{ height: "100%", width: "100%", borderRadius: "10px" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
					padding: "15px 25px",
				}}
			>
				<div
					style={{
						color: "#212121",
						fontSize: "15px",
						fontWeight: 500,
					}}
				>
					{data?.id ? "Editar Usuario" : "Agregar Usuario"}
				</div>
				<div
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						fontWeight: 700,
						color: theme.palette.brandColors.primary,
						fontSize: "13px",
					}}
					onClick={onClose}
				>
					Volver al listado
				</div>
			</Box>

			{data && <ClientForm data={data} onFinished={onClose} companyId={companyId} />}
		</Box>
	);
};

/**
 * Componente para listar usuarios (clientes/encargados) de una empresa productora
 * @param {Object} props
 * @param {Number} props.companyId ID de la empresa productora a la que pertenecen los usuarios
 * @param {Function} props.openDetail Función para abrir el dialogo de detalle de un usuario
 * @param {Boolean} props.dataReload Indica si los datos deben ser recargados
 * @param {Function} props.setDataReload Función para indicar que los datos deben ser recargados
 */
export const ClientList = ({ companyId, openDetail, dataReload, setDataReload }) => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	const columns = [
		{
			field: "first_name",
			headerName: "Nombre",
			renderCell: (row) => row.user.first_name,
			valueGetter: (row) => row.user.first_name,
			sortable: true,
		},
		{
			field: "last_name",
			headerName: "Apellido",
			renderCell: (row) => row.user.last_name,
			valueGetter: (row) => row.user.last_name,
			sortable: true,
		},
		{
			field: "email",
			headerName: "Correo",
			renderCell: (row) => row.user.email,
			valueGetter: (row) => row.user.email,
			sortable: true,
		},
		{
			field: "is_active",
			headerName: "Activo",
			renderCell: (row) => {
				return row.user.is_active ? <CheckIcon /> : <CloseIcon />;
			},
			valueGetter: (row) => {
				return row.user.is_active;
			},
			sortable: true,
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<IconButtons icon={<EditIcon />} onClick={() => openDetail(row.id, row)} />
				</div>
			),
		},
	];

	useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(`${URLS.SALMON_COMPANY}${companyId}/managers/`)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al cargar los datos", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [companyId, axiosInstance, addMessage, dataReload, setDataReload]);

	return <Table rows={rows} columns={columns} loading={loading} />;
};

/**
 * Componente para listar y agregar/modificar usuarios de una empresa productora
 * @param {Object} props
 * @param {Number} props.companyId ID de la empresa productora a la que pertenecen los usuarios
 */
const Clients = ({ companyId, setHide }) => {
	const [dataReload, setDataReload] = useState(true);
	const [detailData, setDetailData] = useState({
		open: false,
		data: null,
	});

	const openDetail = (_id, data = null) => {
		setDetailData({
			open: true,
			data: {
				...data,
			},
		});
	};

	const closeDetail = () => {
		setDetailData({ open: false, data: null });
		setDataReload(true);
	};

	useEffect(() => {
		setHide(detailData.open);
	}, [detailData.open, setHide]);

	return (
		<Box sx={{ width: "100%" }}>
			{detailData.open && <ClientDetail data={detailData.data} onClose={closeDetail} companyId={companyId} />}

			{!detailData.open && (
				<div style={{ padding: "35px 0" }}>
					<div style={{ padding: "0 34px 20px" }}>
						<GeneralButton onClick={openDetail} text="Agregar Usuario" />
					</div>

					<ClientList
						companyId={companyId}
						openDetail={openDetail}
						dataReload={dataReload}
						setDataReload={setDataReload}
					/>
				</div>
			)}
		</Box>
	);
};

export default Clients;
