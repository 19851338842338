// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";

// mui icons
import LockIcon from "@mui/icons-material/Lock";
import LockResetIcon from "@mui/icons-material/LockReset";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
import TextInput from "../../Inputs/TextInput";
import GeneralButton from "../../GeneralButton";
import { BoxStyle } from "../../Reports/ReportConstants";
import { useCallback, useEffect, useState } from "react";

/**
 * Dialogo para crear o editar un administrador de una empresa
 * @param {object} props
 * @param {boolean} props.open Indica si el dialogo esta abierto
 * @param {function} props.onClose Funcion a ejecutar al cerrar el dialogo
 * @param {object} props.data Datos del usuario a editar
 * @param {number} props.companyId Id de la empresa a la que pertenece el usuario
 */
export const AdminDialogForm = ({ open, onClose, ...props }) => {
    const theme = useTheme();
	const handleClose = (_event, _reason) => {
		if (_reason === "backdropClick") return;
		onClose();
	};

	const onFinished = () => {
		onClose();
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<div style={{ ...BoxStyle, height: "auto" }}>
				<div
					style={{
						position: "relative",
						borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
						padding: "15px 25px",
					}}
				>
					<div
						style={{
							color: "#212121",
							fontSize: "15px",
							fontWeight: 500,
						}}
					>
						{props?.data?.id ? "Editar" : "Crear"} administrador
					</div>
					<IconButton onClick={handleClose} sx={{ position: "absolute", right: 0, top: 0 }}>
						<CloseIcon />
					</IconButton>
				</div>

				<div style={{ padding: "25px 35px" }}>
					<AdminForm {...props} onFinished={onFinished} />
				</div>
			</div>
		</Modal>
	);
};

/**
 * Formulario para crear o editar un administrador de una empresa
 * @param {object} props
 * @param {function} props.onFinished Funcion a ejecutar al terminar de editar o crear
 * @param {object} props.data Datos del usuario a editar
 * @param {number} props.companyId Id de la empresa a la que pertenece el usuario
 */
export const AdminForm = ({ onFinished = () => {}, data = undefined, companyId = undefined }) => {
	const [currentData, setCurrentData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);

	const [showPassword, setShowPassword] = useState(false);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const requestData = useCallback(async () => {
		setLoading(true);
		axiosInstance
			.get(`${URLS.ADMINS}${data.id}/`)
			.then((response) => {
				setCurrentData({
					user_id: response.data.user.id,
					username: response.data.user.username,
					first_name: response.data.user.first_name,
					last_name: response.data.user.last_name,
					email: response.data.user.email,
					rut: response.data.user.rut,
					phone: response.data.user.phone,
					other_contact_info: response.data.user.other_contact_info,
					permissions: response.data.user.permissions,
					is_active: response.data.user.is_active,
					canDelete: response.data.canDelete,
				});
			})
			.catch((error) => {
				console.log(error);
				addMessage("Error al obtener los datos del administrador de la empresa", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [axiosInstance, addMessage, data?.id]);

	const onCreation = useCallback(() => {
		setCurrentData({
			username: "",
			password: "",
			first_name: "",
			last_name: "",
			email: "",
			rut: "",
			phone: "",
			other_contact_info: "",
			is_active: true,
			permissions: "Admin",
			canDelete: false,
		});
	}, []);

	useEffect(() => {
		// si se esta editando, se obtienen los datos
		if (data?.id) requestData();
		// si se esta creando, se inicializan los datos
		else {
			onCreation();
			setLoading(false);
		}
	}, [data?.id, requestData, onCreation]);

	const handleCancel = () => {
		onFinished();
	};

	const handleSave = (e) => {
		e.preventDefault();

		let new_data = { ...currentData };
		if (new_data.password === "") delete new_data.password;
		if (new_data.username === data?.user?.username) delete new_data.username;

		// Creacion de objeto por defecto
		// crear configuracion de request
		let config = {
			method: "POST",
			url: URLS.ADMINS,
			data: {
				wreckRescueCompany: companyId,
				canDelete: new_data.canDelete,
				user: {
					...new_data,
				},
			},
		};
		let message = "Administrador creado exitosamente";
		let error_message = "Error al crear el administrador";

		if (data?.id) {
			// Editar
			config.method = "PATCH";
			config.url += `${data.id}/`;
			config.data = { ...config.data, id: data.id };
			message = "Administrador editado exitosamente";
			error_message = "Error al editar el administrador";
		} else {
			config.data = {
				...config.data,
				user: {
					...config.data.user,
					username: new_data.username,
					password: new_data.password,
				},
			};
		}

		// enviar request
		setLoading(true);
		axiosInstance(config)
			.then((_res) => {
				addMessage(message, "success");
				onFinished();
			})
			.catch((_err) => {
				addMessage(error_message, "error");
				setErrors({ ..._err.response.data, ..._err.response.data?.user });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const alternateActive = (active) => {
		setLoading(true);
		axiosInstance
			.patch(`${URLS.ADMINS}${data.id}/`, {
				user: {
					is_active: active,
				},
			})
			.then((_res) => {
				addMessage(`Administrador ${active ? "reactivado" : "desactivado"} exitosamente`, "success");
				onFinished();
			})
			.catch((_err) => {
				addMessage(`Error al ${active ? "reactivar" : "desactivar"} el administrador`, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleActivate = () => {
		if (!window.confirm("¿Está seguro de reactivar esta cuenta?")) return;

		alternateActive(true);
	};

	const handleDeactivate = () => {
		if (!window.confirm("¿Está seguro de desactivar esta cuenta?")) return;

		alternateActive(false);
	};

	const handleDelete = () => {
		if (
			!window.confirm(
				"¿Está seguro de eliminar este administrador?\n" +
					"Esta acción no se puede deshacer y eliminará todos los informes y objetos asociados."
			)
		)
			return;

		setLoading(true);
		axiosInstance
			.delete(`${URLS.ADMINS}${data.id}/`)
			.then((_res) => {
				addMessage("Administrador eliminado exitosamente", "success");
				onFinished();
			})
			.catch((err) => {
				addMessage("Error al eliminar el administrador", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (e) => {
		if (e.target.name === "canDelete") {
			setCurrentData({ ...currentData, [e.target.name]: e.target.checked });
			return;
		}
		setCurrentData({ ...currentData, [e.target.name]: e.target.value });
	};

	return (
		<form
			onSubmit={handleSave}
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "10px",
			}}
		>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<Stack direction="row" spacing={2}>
				<TextInput
					label={"Nombre"}
					InputLabelProps={{ shrink: true }}
					name={"first_name"}
					value={currentData["first_name"] || ""}
					onChange={handleChange}
					fullWidth
					required
					error={errors["first_name"]}
					helperText={errors["first_name"]}
					disabled={loading}
				/>

				<TextInput
					label={"Apellido"}
					InputLabelProps={{ shrink: true }}
					name={"last_name"}
					value={currentData["last_name"] || ""}
					onChange={handleChange}
					fullWidth
					required
					error={errors["last_name"]}
					helperText={errors["last_name"]}
					disabled={loading}
				/>

				<TextInput
					label={"Correo electrónico"}
					InputLabelProps={{ shrink: true }}
					name={"email"}
					value={currentData["email"] || ""}
					onChange={handleChange}
					fullWidth
					required
					error={errors["email"]}
					helperText={errors["email"]}
					disabled={loading}
				/>
			</Stack>

			<Stack direction="row" spacing={2}>
				<TextInput
					label={"Rut"}
					InputLabelProps={{ shrink: true }}
					name={"rut"}
					value={currentData["rut"] || ""}
					onChange={handleChange}
					fullWidth
					error={errors["rut"]}
					helperText={errors["rut"]}
					disabled={loading}
				/>

				<TextInput
					label={"Telefono"}
					InputLabelProps={{ shrink: true }}
					name={"phone"}
					value={currentData["phone"] || ""}
					onChange={handleChange}
					fullWidth
					error={errors["phone"]}
					helperText={errors["phone"]}
					disabled={loading}
				/>
				<TextInput
					label={"Otro contacto"}
					InputLabelProps={{ shrink: true }}
					name={"other_contact_info"}
					value={currentData["other_contact_info"] || ""}
					onChange={handleChange}
					fullWidth
					error={errors["other_contact_info"]}
					helperText={errors["other_contact_info"]}
					disabled={loading}
				/>
			</Stack>

			<Stack direction="row" spacing={2} alignItems="center">
				<TextInput
					label={"Nombre de usuario"}
					InputLabelProps={{ shrink: true }}
					name={"username"}
					value={currentData["username"] || ""}
					onChange={handleChange}
					fullWidth
					required
					error={errors["username"]}
					helperText={errors["username"]}
					disabled={loading}
				/>

				<TextInput
					label={"Contraseña"}
					InputLabelProps={{ shrink: true }}
					name={"password"}
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={(e) => e.preventDefault()}
									tabIndex={-1}
									edge="end"
									disabled={loading || data?.id !== undefined}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					value={currentData["password"] || ""}
					onChange={handleChange}
					fullWidth
					error={errors["password"]}
					helperText={
						errors["password"] ||
						(data?.id ? (
							<>No se puede editar</>
						) : (
							<>Dejar en blanco genera una contraseña aleatoria que se enviará al correo del usuario</>
						))
					}
					disabled={loading || data?.id}
				/>
				<Box sx={{ width: "100%" }}>
					<Checkbox
						label={"Puede eliminar"}
						InputLabelProps={{ shrink: true }}
						name={"canDelete"}
						checked={currentData["canDelete"] || ""}
						onChange={handleChange}
						disabled={loading}
					/>
					Puede eliminar informes
				</Box>
			</Stack>

			<Box sx={{ display: "flex", justifyContent: "space-between" }} mt={2}>
				<Stack direction="row" spacing={2}>
					{data?.id && !currentData.is_active && (
						<Stack direction="row" spacing={2}>
							<Button variant="outlined" onClick={handleDelete} color="error" disabled={loading}>
								Eliminar
							</Button>
						</Stack>
					)}

					{data?.id && currentData.is_active && (
						<Stack direction="row" spacing={2}>
							<Button
								variant="outlined"
								onClick={handleDeactivate}
								color="warning"
								disabled={loading}
								startIcon={<LockIcon />}
							>
								Desactivar cuenta
							</Button>
						</Stack>
					)}

					{data?.id && !currentData.is_active && (
						<Stack direction="row" spacing={2}>
							<Button
								variant="outlined"
								onClick={handleActivate}
								color="success"
								disabled={loading}
								startIcon={<LockResetIcon />}
							>
								Reactivar cuenta
							</Button>
						</Stack>
					)}
				</Stack>

				<Stack direction="row" spacing={2} justifyContent="end" flexGrow={1}>
					<GeneralButton text="Cancelar" variant="empty" onClick={handleCancel} disabled={loading} />

					<GeneralButton text="Guardar" type="submit" disabled={loading} onClick={handleSave} />
				</Stack>
			</Box>
		</form>
	);
};
