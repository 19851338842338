// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

// mui icons
import LockIcon from "@mui/icons-material/Lock";
import LockResetIcon from "@mui/icons-material/LockReset";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
import TextInput from "../../Inputs/TextInput";
import GeneralButton from "../../GeneralButton";
import { useCallback, useEffect, useState } from "react";

const checkList = [
	{
		name: "canReceiveRRNNormalDay",
		label: "Día Normal",
	},
	{
		name: "canReceiveRRNProspection",
		label: "Prospección",
	},
	{
		name: "canReceiveRRNClosedPort",
		label: "Puerto Cerrado",
	},
	{
		name: "canReceiveRRNBadWeather",
		label: "Mal clima",
	},
	{
		name: "canReceiveRRNFueling",
		label: "Carga de combustible",
	},
	{
		name: "canReceiveRRNROVFlaw",
		label: "Falla de equipo ROV",
	},
	{
		name: "canReceiveRRNBoatFlaw",
		label: "Falla Embarcación",
	},
	{
		name: "canReceiveRRNTakeover",
		label: "Relevo",
	},
	{
		name: "canReceiveRRNMaintenance",
		label: "Mantenimiento de embarcación",
	},
	{
		name: "canReceiveRRNBoatReview",
		label: "Revisión Embarcación",
	},
	{
		name: "canReceiveRRNCastawayRestDelivery",
		label: "Entrega de restos náufragos",
	},
	{
		name: "canReceiveRRNSailing",
		label: "Navegación",
	},
	{
		name: "canReceiveRRNFeedingAndBacterium",
		label: "Alimentación y Bacterias",
	},
];

/**
 * Formulario para crear o editar un usuario (cliente/encargado) de una empresa productora
 * @param {object} props
 * @param {function} props.onFinished Funcion a ejecutar al terminar de editar o crear
 * @param {object} props.data Datos del usuario a editar
 * @param {number} props.companyId Id de la empresa productora a la que pertenece el usuario
 */
export const ClientForm = ({ onFinished = () => {}, data = undefined, companyId = undefined }) => {
	const [currentData, setCurrentData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);
	const theme = useTheme();

	const [showPassword, setShowPassword] = useState(false);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const requestData = useCallback(async () => {
		setLoading(true);
		axiosInstance
			.get(`${URLS.CLIENTS}${data.id}/`)
			.then((response) => {
				setCurrentData({
					user_id: response.data.user.id,
					username: response.data.user.username,
					first_name: response.data.user.first_name,
					last_name: response.data.user.last_name,
					email: response.data.user.email,
					rut: response.data.user.rut,
					phone: response.data.user.phone,
					other_contact_info: response.data.user.other_contact_info,
					permissions: response.data.user.permissions,
					is_active: response.data.user.is_active,
					canReceiveRRNNormalDay: response.data.canReceiveRRNNormalDay,
					canReceiveRRNProspection: response.data.canReceiveRRNProspection,
					canReceiveRRNClosedPort: response.data.canReceiveRRNClosedPort,
					canReceiveRRNBadWeather: response.data.canReceiveRRNBadWeather,
					canReceiveRRNFueling: response.data.canReceiveRRNFueling,
					canReceiveRRNROVFlaw: response.data.canReceiveRRNROVFlaw,
					canReceiveRRNBoatFlaw: response.data.canReceiveRRNBoatFlaw,
					canReceiveRRNTakeover: response.data.canReceiveRRNTakeover,
					canReceiveRRNMaintenance: response.data.canReceiveRRNMaintenance,
					canReceiveRRNBoatReview: response.data.canReceiveRRNBoatReview,
					canReceiveRRNCastawayRestDelivery: response.data.canReceiveRRNCastawayRestDelivery,
					canReceiveRRNSailing: response.data.canReceiveRRNSailing,
					canReceiveRRNFeedingAndBacterium: response.data.canReceiveRRNFeedingAndBacterium,
					canReceiveRRNConsolidated: response.data.canReceiveRRNConsolidated,
					canReceiveSBProspection: response.data.canReceiveSBProspection,
					canReceiveReceptionNote: response.data.canReceiveReceptionNote,
				});
			})
			.catch((error) => {
				console.log(error);
				addMessage("Error al obtener los datos del usuario de empresa productora", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [axiosInstance, addMessage, data?.id]);

	const onCreation = useCallback(() => {
		setCurrentData({
			username: "",
			password: "",
			first_name: "",
			last_name: "",
			email: "",
			rut: "",
			phone: "",
			other_contact_info: "",
			is_active: true,
			permissions: "Cliente",
			canReceiveRRNNormalDay: true,
			canReceiveRRNProspection: true,
			canReceiveRRNClosedPort: true,
			canReceiveRRNBadWeather: true,
			canReceiveRRNFueling: true,
			canReceiveRRNROVFlaw: true,
			canReceiveRRNBoatFlaw: true,
			canReceiveRRNTakeover: true,
			canReceiveRRNMaintenance: true,
			canReceiveRRNBoatReview: true,
			canReceiveRRNCastawayRestDelivery: true,
			canReceiveRRNSailing: true,
			canReceiveRRNFeedingAndBacterium: true,
			canReceiveRRNConsolidated: true,
			canReceiveSBProspection: true,
			canReceiveReceptionNote: true,
		});
	}, []);

	useEffect(() => {
		// si se esta editando, se obtienen los datos
		if (data?.id) requestData();
		// si se esta creando, se inicializan los datos
		else {
			onCreation();
			setLoading(false);
		}
	}, [data?.id, requestData, onCreation]);

	const handleCancel = () => {
		onFinished();
	};

	const handleSave = (e) => {
		e.preventDefault();

		let new_data = { ...currentData };
		if (new_data.password === "") delete new_data.password;
		if (new_data.username === data?.user?.username) delete new_data.username;

		// Creacion de objeto por defecto
		// crear configuracion de request
		let config = {
			method: "POST",
			url: URLS.CLIENTS,
			data: {
				salmonCompany: companyId,
				...currentData,
				user: {
					...new_data,
				},
			},
		};
		let message = "Usuario creado exitosamente";
		let error_message = "Error al crear el usuario";

		if (data?.id) {
			// Editar
			config.method = "PATCH";
			config.url += `${data.id}/`;
			config.data = { ...config.data, id: data.id };
			message = "Usuario editado exitosamente";
			error_message = "Error al editar el usuario";
		} else {
			config.data = {
				...config.data,
				user: {
					...config.data.user,
					username: new_data.username,
					password: new_data.password,
				},
			};
		}

		// enviar request
		setLoading(true);
		axiosInstance(config)
			.then((_res) => {
				addMessage(message, "success");
				onFinished();
			})
			.catch((_err) => {
				addMessage(error_message, "error");
				setErrors({ ..._err.response.data, ..._err.response.data?.user });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const alternateActive = (active) => {
		setLoading(true);
		axiosInstance
			.patch(`${URLS.CLIENTS}${data.id}/`, {
				user: {
					is_active: active,
				},
			})
			.then((_res) => {
				addMessage(`Usuario ${active ? "reactivado" : "desactivado"} exitosamente`, "success");
				onFinished();
			})
			.catch((_err) => {
				addMessage(`Error al ${active ? "reactivar" : "desactivar"} el usuario`, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleActivate = () => {
		if (!window.confirm("¿Está seguro de reactivar esta cuenta?")) return;

		alternateActive(true);
	};

	const handleDeactivate = () => {
		if (!window.confirm("¿Está seguro de desactivar esta cuenta?")) return;

		alternateActive(false);
	};

	const handleDelete = () => {
		if (!window.confirm("¿Está seguro de eliminar este usuario?\n" + "Esta acción no se puede deshacer.")) return;

		setLoading(true);
		axiosInstance
			.delete(`${URLS.CLIENTS}${data.id}/`)
			.then((_res) => {
				addMessage("Usuario eliminado exitosamente", "success");
				onFinished();
			})
			.catch((err) => {
				addMessage("Error al eliminar el usuario", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (e) => {
		setCurrentData({ ...currentData, [e.target.name]: e.target.value });
	};

	const onCheckChange = (e) => {
		setCurrentData({
			...currentData,
			[e.target.name]: e.target.checked,
		});
	};

	return (
		<form
			onSubmit={handleSave}
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "10px",
				padding: "20px 30px",
			}}
		>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<Stack direction="row" spacing={2}>
				<TextInput
					label={"Nombre"}
					InputLabelProps={{ shrink: true }}
					name={"first_name"}
					value={currentData["first_name"] || ""}
					onChange={handleChange}
					fullWidth
					required
					error={errors["first_name"]}
					helperText={errors["first_name"]}
					disabled={loading}
				/>

				<TextInput
					label={"Apellido"}
					InputLabelProps={{ shrink: true }}
					name={"last_name"}
					value={currentData["last_name"] || ""}
					onChange={handleChange}
					fullWidth
					required
					error={errors["last_name"]}
					helperText={errors["last_name"]}
					disabled={loading}
				/>

				<TextInput
					label={"Correo electrónico"}
					InputLabelProps={{ shrink: true }}
					name={"email"}
					value={currentData["email"] || ""}
					onChange={handleChange}
					fullWidth
					required
					error={errors["email"]}
					helperText={errors["email"]}
					disabled={loading}
				/>
			</Stack>

			<Stack direction="row" spacing={2}>
				<TextInput
					label={"Rut"}
					InputLabelProps={{ shrink: true }}
					name={"rut"}
					value={currentData["rut"] || ""}
					onChange={handleChange}
					fullWidth
					error={errors["rut"]}
					helperText={errors["rut"]}
					disabled={loading}
				/>

				<TextInput
					label={"Telefono"}
					InputLabelProps={{ shrink: true }}
					name={"phone"}
					value={currentData["phone"] || ""}
					onChange={handleChange}
					fullWidth
					error={errors["phone"]}
					helperText={errors["phone"]}
					disabled={loading}
				/>
				<TextInput
					label={"Otro contacto"}
					InputLabelProps={{ shrink: true }}
					name={"other_contact_info"}
					value={currentData["other_contact_info"] || ""}
					onChange={handleChange}
					fullWidth
					error={errors["other_contact_info"]}
					helperText={errors["other_contact_info"]}
					disabled={loading}
				/>
			</Stack>

			<Stack direction="row" spacing={2}>
				<TextInput
					label={"Nombre de usuario"}
					InputLabelProps={{ shrink: true }}
					name={"username"}
					value={currentData["username"] || ""}
					onChange={handleChange}
					fullWidth
					required
					error={errors["username"]}
					helperText={errors["username"]}
					disabled={loading}
				/>

				<TextInput
					label={"Contraseña"}
					InputLabelProps={{ shrink: true }}
					name={"password"}
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={(e) => e.preventDefault()}
									tabIndex={-1}
									edge="end"
									disabled={loading || data?.id !== undefined}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					value={currentData["password"] || ""}
					onChange={handleChange}
					fullWidth
					error={errors["password"]}
					disabled={loading || data?.id}
				/>
			</Stack>

			<Accordion
				sx={{
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Informes Diarios</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						{checkList.map((check, index) => (
							<Grid item xs={4} key={index}>
								<FormControlLabel
									control={
										<Checkbox
											name={check.name}
											checked={currentData[check.name] || false}
											onChange={onCheckChange}
										/>
									}
									label={check.label}
								/>
							</Grid>
						))}
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion
				sx={{
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Notas de Recepción</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										name="canReceiveReceptionNote"
										checked={currentData.canReceiveReceptionNote || false}
										onChange={onCheckChange}
									/>
								}
								label="Notas de Recepción"
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion
				sx={{
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Consolidados</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						<Grid item xs={6}>
							<FormControlLabel
								control={
									<Checkbox
										name="canReceiveRRNConsolidated"
										checked={currentData.canReceiveRRNConsolidated || false}
										onChange={onCheckChange}
									/>
								}
								label="General"
							/>
						</Grid>

						<Grid item xs={6}>
							<FormControlLabel
								control={
									<Checkbox
										name="canReceiveSBProspection"
										checked={currentData.canReceiveSBProspection || false}
										onChange={onCheckChange}
									/>
								}
								label="Prospección"
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Box sx={{ display: "flex", justifyContent: "space-between" }} mt={2}>
				<Stack direction="row" spacing={2}>
					{data?.id && !currentData.is_active && (
						<Stack direction="row" spacing={2}>
							<Button variant="outlined" onClick={handleDelete} color="error" disabled={loading}>
								Eliminar
							</Button>
						</Stack>
					)}

					{data?.id && currentData.is_active && (
						<Stack direction="row" spacing={2}>
							<Button
								variant="outlined"
								onClick={handleDeactivate}
								color="warning"
								disabled={loading}
								startIcon={<LockIcon />}
								sx={{
									borderRadius: "12px",
								}}
							>
								Desactivar cuenta
							</Button>
						</Stack>
					)}

					{data?.id && !currentData.is_active && (
						<Stack direction="row" spacing={2}>
							<Button
								variant="outlined"
								onClick={handleActivate}
								color="success"
								disabled={loading}
								startIcon={<LockResetIcon />}
								sx={{
									borderRadius: "12px",
								}}
							>
								Reactivar cuenta
							</Button>
						</Stack>
					)}
				</Stack>

				<Stack direction="row" spacing={2} justifyContent="end" flexGrow={1}>
					<GeneralButton
						text="Cancelar"
						variant="empty"
						onClick={handleCancel}
						disabled={loading}
					/>

					<GeneralButton
						text="Guardar"
						type="submit"
						disabled={loading}
						onClick={handleSave}
					/>
				</Stack>
			</Box>
		</form>
	);
};

export default ClientForm;
