import { useContext, useEffect, useState } from "react";
import { URLS } from "../../../global";
import { AuthContext } from "../../../context/AuthContext";
import { MapContainer, TileLayer, ScaleControl, Polygon, useMap, useMapEvents, Tooltip } from "react-leaflet";
import { findCentralPoint, parse_coordinates } from "../../../utils/map_helper";
import { LatLonInput } from "../../CoordInput";
import { TextField, Box, Checkbox, Button, useTheme } from "@mui/material";

const MapController = ({ center, zoom }) => {
	const map = useMap();

	useEffect(() => {
		map.setView(center, zoom);
	}, [center, zoom, map]);
};

function MapEvents({ handleMapClick }) {
	useMapEvents({
		click: (e) => {
			handleMapClick(e.latlng);
		},
	});

	return null;
}

const MultipleCageForm = ({ setDataReload, centerId, onClose }) => {
	const { axiosInstance } = useContext(AuthContext);
	const theme = useTheme();
	const [centerData, setCenterData] = useState(null);
	const [center, setCenter] = useState({
		lat: null,
		lon: null,
	});
	const [centerCoords, setCenterCoords] = useState([]);
	const [zoom, setZoom] = useState(12);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!centerId) return;
		axiosInstance.get(`${URLS.CENTERS}${centerId}/`).then((response) => {
			setCenterData({
				...response.data,
				cages: [],
				polygons: [],
			});
			const coords = findCentralPoint(response.data.coordinates);
			setCenterCoords(parse_coordinates(response.data.coordinates).map((coord) => [coord[1], coord[0]]));
			setCenter({
				lat: coords[0],
				lon: coords[1],
			});
		});
	}, [centerId, axiosInstance]);

	const [upperLeft, setUpperLeft] = useState({
		latitude: null,
		longitude: null,
	});
	const [bottomRight, setBottomRight] = useState({
		latitude: null,
		longitude: null,
	});
	const [bottomLeft, setBottomLeft] = useState({
		latitude: null,
		longitude: null,
	});

	const [latDivider, setLatDivider] = useState(1);
	const [lonDivider, setLonDivider] = useState(1);
	const handlePointChange = (latlon, type) => {
		if (type === "upperLeft") {
			setUpperLeft({
				latitude: latlon.latitude,
				longitude: latlon.longitude,
			});
		} else if (type === "bottomRight") {
			setBottomRight({
				latitude: latlon.latitude,
				longitude: latlon.longitude,
			});
		} else if (type === "bottomLeft") {
			setBottomLeft({
				latitude: latlon.latitude,
				longitude: latlon.longitude,
			});
		}
	};
	const [extraPoligons, setExtraPoligons] = useState([]);

	useEffect(() => {
		if (!centerData) return;
		if (!upperLeft.latitude || !bottomRight.latitude || !bottomLeft.latitude) return;
		if (!upperLeft.longitude || !bottomRight.longitude || !bottomLeft.longitude) return;

		const leftLatDiff = upperLeft.latitude - bottomLeft.latitude;
		const lefLonDiff = upperLeft.longitude - bottomLeft.longitude;

		const rightLatDiff = bottomRight.latitude - bottomLeft.latitude;
		const rightLonDiff = bottomRight.longitude - bottomLeft.longitude;

		const newPoligons = [];
		for (let i = 0; i < latDivider; i++) {
			for (let j = 0; j < lonDivider; j++) {
				const punto1 = [
					upperLeft.latitude - (leftLatDiff / latDivider) * i + (rightLatDiff / lonDivider) * j,
					upperLeft.longitude - (lefLonDiff / latDivider) * i + (rightLonDiff / lonDivider) * j,
				];
				const punto2 = [
					upperLeft.latitude - (leftLatDiff / latDivider) * (i + 1) + (rightLatDiff / lonDivider) * j,
					upperLeft.longitude - (lefLonDiff / latDivider) * (i + 1) + (rightLonDiff / lonDivider) * j,
				];
				const punto4 = [
					upperLeft.latitude - (leftLatDiff / latDivider) * i + (rightLatDiff / lonDivider) * (j + 1),
					upperLeft.longitude - (lefLonDiff / latDivider) * i + (rightLonDiff / lonDivider) * (j + 1),
				];
				const punto3 = [
					upperLeft.latitude - (leftLatDiff / latDivider) * (i + 1) + (rightLatDiff / lonDivider) * (j + 1),
					upperLeft.longitude - (lefLonDiff / latDivider) * (i + 1) + (rightLonDiff / lonDivider) * (j + 1),
				];
				newPoligons.push([punto1, punto2, punto3, punto4]);
			}
		}
		setExtraPoligons(newPoligons);
	}, [upperLeft, bottomRight, bottomLeft, latDivider, lonDivider, centerData]);
	const [fromMap, setFromMap] = useState({
		upperLeft: false,
		bottomLeft: false,
		bottomRight: false,
	});

	const handleMapClick = (latlon) => {
		if (fromMap.upperLeft) {
			setUpperLeft({
				latitude: latlon.lat.toFixed(6),
				longitude: latlon.lng.toFixed(6),
			});
		} else if (fromMap.bottomRight) {
			setBottomRight({
				latitude: latlon.lat.toFixed(6),
				longitude: latlon.lng.toFixed(6),
			});
		} else if (fromMap.bottomLeft) {
			setBottomLeft({
				latitude: latlon.lat.toFixed(6),
				longitude: latlon.lng.toFixed(6),
			});
		}
		setFromMap({ bottomRight: false, upperLeft: false, bottomLeft: false });
	};

	const [cageNumbers, setCageNumbers] = useState([]);

	useEffect(() => {
		if (!centerData) return;
		const newCageNumbers = [];
		for (let i = 0; i < latDivider; i++) {
			for (let j = 0; j < lonDivider; j++) {
				newCageNumbers.push(i * Number(lonDivider) + j + 1);
			}
		}
		setCageNumbers(newCageNumbers);
	}, [latDivider, lonDivider, centerData]);

	const saveCage = async (cage) => {
		await axiosInstance
			.post(`${URLS.CAGES}`, cage)
			.then((response) => {
				return Promise.resolve();
			})
			.catch((error) => {
				return Promise.reject();
			});
	};
	const handleSave = async () => {
		setLoading(true);
		const cages = extraPoligons.map((poligon, index) => {
			return {
				cultivationCenter: centerId,
				coordinates: poligon.map((coord) => coord.join(",")).join(";"),
				name: cageNumbers[index],
			};
		});

		return Promise.all(cages.map((cage) => saveCage(cage)))
			.then(() => {
				setDataReload(true);
				setLoading(false);
				onClose();
			})
			.catch(() => {
				alert("Error al guardar las jaulas");
				setLoading(false);
			});
	};
	return (
		<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
					padding: "15px 25px",
				}}
			>
				<div
					style={{
						color: "#212121",
						fontSize: "15px",
						fontWeight: 500,
					}}
				>
					Agregar Jaulas
				</div>
				<div
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						fontWeight: 700,
						color: theme.palette.brandColors.primary,
						fontSize: "13px",
					}}
					onClick={onClose}
				>
					Volver al listado
				</div>
			</Box>
			<div
				style={{
					padding: "20px 35px",
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div style={{ display: "flex", gap: "20px", height: "100%" }}>
					<Box sx={{ width: "50%" }}>
						<Box sx={{ margin: "10px 0" }}>
							Esquina Superior Izquierda
							<Box sx={{ display: "flex", marginTop: "10px" }}>
								<LatLonInput
									inputValues={upperLeft}
									setInputValues={(latlon) => handlePointChange(latlon, "upperLeft")}
								/>
								<Box sx={{ textAlign: "center", width: "65%" }}>
									Agregar Desde Mapa
									<Checkbox
										checked={fromMap.upperLeft}
										onChange={(e) =>
											setFromMap({
												bottomLeft: false,
												bottomRight: false,
												upperLeft: e.target.checked,
											})
										}
									/>
								</Box>
							</Box>
						</Box>

						<Box sx={{ margin: "10px 0" }}>
							Esquina Inferior Izquierda
							<Box sx={{ display: "flex", marginTop: "10px" }}>
								<LatLonInput
									inputValues={bottomLeft}
									setInputValues={(latlon) => handlePointChange(latlon, "bottomLeft")}
								/>

								<Box sx={{ textAlign: "center", width: "65%" }}>
									Agregar Desde Mapa
									<Checkbox
										checked={fromMap.bottomLeft}
										onChange={(e) =>
											setFromMap({
												bottomLeft: e.target.checked,
												upperLeft: false,
												bottomRight: false,
											})
										}
									/>
								</Box>
							</Box>
						</Box>

						<Box sx={{ margin: "10px 0" }}>
							Esquina Inferior Derecha
							<Box sx={{ display: "flex", marginTop: "10px" }}>
								<LatLonInput
									inputValues={bottomRight}
									setInputValues={(latlon) => handlePointChange(latlon, "bottomRight")}
								/>
								<Box sx={{ textAlign: "center", width: "65%" }}>
									Agregar Desde Mapa
									<Checkbox
										checked={fromMap.bottomRight}
										onChange={(e) =>
											setFromMap({
												bottomLeft: false,
												upperLeft: false,
												bottomRight: e.target.checked,
											})
										}
									/>
								</Box>
							</Box>
						</Box>

						<Box sx={{ display: "flex", marginTop: "20px", gap: "20px" }}>
							<TextField
								label="Divisiones en Latitud"
								value={latDivider}
								onChange={(e) => setLatDivider(e.target.value)}
							/>
							<TextField
								label="Divisiones en Longitud"
								value={lonDivider}
								onChange={(e) => setLonDivider(e.target.value)}
							/>
						</Box>

						<Box sx={{ paddingBottom: "20px" }}>
							{Array(Number(latDivider))
								.fill(0)
								.map((_, index) => {
									return (
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												gap: "20px",
												margin: "20px 0",
											}}
										>
											{Array(Number(lonDivider))
												.fill(0)
												.map((_, index2) => {
													return (
														<TextField
															label={`Jaula ${index + 1}-${index2 + 1}`}
															value={cageNumbers[index * Number(lonDivider) + index2]}
															onChange={(e) => {
																const newCageNumbers = [...cageNumbers];
																newCageNumbers[index * Number(lonDivider) + index2] =
																	e.target.value;
																setCageNumbers(newCageNumbers);
															}}
														/>
													);
												})}
										</Box>
									);
								})}
						</Box>
					</Box>

					<Box sx={{ width: "50%", height: "500px" }}>
						<MapContainer
							zoomControl={false}
							center={center}
							zoom={zoom}
							style={{ height: "100%", width: "100%" }}
							key={centerCoords}
							attributionControl={false}
						>
							<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
							<ScaleControl position="bottomleft" />
							<MapController center={center} zoom={zoom} setZoom={setZoom} />

							<Polygon positions={centerCoords} pathOptions={{ color: "white" }} />
							{extraPoligons.map((poligon, index) => (
								<Polygon positions={poligon} pathOptions={{ color: "grey" }}>
									<Tooltip direction="top">{cageNumbers[index]}</Tooltip>
								</Polygon>
							))}
							<MapEvents handleMapClick={handleMapClick} />
						</MapContainer>
					</Box>
				</div>
				<Box>
					<Button
						onClick={handleSave}
						disabled={extraPoligons.length === 0 || loading}
						variant="contained"
						sx={{ marginTop: "20px" }}
					>
						Guardar
					</Button>
				</Box>
			</div>
		</Box>
	);
};

export default MultipleCageForm;
