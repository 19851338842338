import Plot from "react-plotly.js";
import GeneralButton from "../../../GeneralButton";
import { useTheme } from "@mui/material/styles";
import {
	CloseButton,
	PlotContainer,
	PlotStats,
	PlotTab,
	RotateInstruction,
	See,
	Shadow,
	StatTitle,
	SwitchContainer,
	Table,
	TableHead,
	Td,
	Th,
} from "./ConsolidatedCommon";
import RotateIcon from "../../../Icons/RotateIcon";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const WorkSummary = ({ reportData }) => {
	const [rotate, setRotate] = useState(false);
	const [plotOpen, setPlotOpen] = useState(false);
	const theme = useTheme();

	const layout = {
		responsive: true,
		autosize: true,
		font: {
			family: "Roboto, sans-serif",
			size: 14,
		},
		yaxis: {
			automargin: true,
			showgrid: true,
		},
		xaxis: {
			automargin: true,
			showgrid: false,
		},
		padding: 30,
		margin: "auto",
		title: {
			font: {
				size: 16,
				family: "Roboto, sans-serif",
				color: theme.palette.brandColors.primary,
			},
		},
	};

	const onOpenPlot = () => {
		setRotate(true);

		setTimeout(() => {
			setRotate(false);
			setPlotOpen(true);
		}, 1000);
	};

	return (
		<>
			{rotate && (
				<Shadow>
					<RotateInstruction>
						<RotateIcon />
					</RotateInstruction>
				</Shadow>
			)}
			<SwitchContainer style={{ marginBottom: 0 }}>
				<GeneralButton
					variant={"contained"}
					text="Tabla y gráfico"
					sx={{
						textTransform: "none",
					}}
				/>
			</SwitchContainer>

			<PlotStats>
				<Table notModal={true}>
					<TableHead>
						<tr>
							<Th>Motivo</Th>
							<Th>Días</Th>
						</tr>
					</TableHead>
					<tbody>
						{reportData.reports.map((object, index) => (
							<tr key={index}>
								<Td>{object.type}</Td>
								<Td>{object.total}</Td>
							</tr>
						))}

						{reportData.additionalRows.map((row, index) => (
							<tr key={index}>
								<Td>{row[0]}</Td>
								<Td>{row[1]}</Td>
							</tr>
						))}

						<tr style={{ backgroundColor: "rgba(168, 168, 168, 0.39)" }}>
							<Td>TOTAL</Td>
							<Td>
								{reportData.reports.reduce((acc, item) => acc + item.total, 0) +
									reportData.additionalRows.reduce((acc, item) => acc + item[1], 0)}
							</Td>
						</tr>
					</tbody>
				</Table>

				<PlotTab>
					<StatTitle mt>
						Gráficos resumen días trabajados
						<See onClick={onOpenPlot}>Ver</See>
					</StatTitle>
					<PlotContainer open={plotOpen}>
						{plotOpen && (
							<CloseButton onClick={() => setPlotOpen(false)}>
								<CloseIcon />
							</CloseButton>
						)}
						<Plot
							config={{ responsive: true, displayModeBar: false }}
							data={[
								{
									labels: reportData.reports
										.map((item) => item.type)
										.concat(reportData.additionalRows.map((item) => item[0])),
									values: reportData.reports
										.map((item) => item.total)
										.concat(reportData.additionalRows.map((item) => item[1])),
									texttemplate: "%{value} días | %{percent}",
									type: "pie",
									marker: {
										colors: Object.keys(theme.palette.brandColors)
											.filter((item) => item !== "white")
											.map((item) => theme.palette.brandColors[item]),
									},
								},
							]}
							layout={{
								...layout,
							}}
							style={{ width: "100%", height: "100%" }}
						/>
					</PlotContainer>
				</PlotTab>
			</PlotStats>
		</>
	);
};

export default WorkSummary;
