import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";

const CreateButton = (props) => {
    const theme = useTheme();
    return (
        <Button
            size="medium"
            variant="contained"
            disabled={props.disabled || false}
            sx={{
                backgroundColor: theme.palette.brandColors.primary,
                "&:hover": { backgroundColor: theme.palette.brandColors.primary },
                borderRadius: "10px",
                ...props.sx,
            }}
            startIcon={props.icon || <AddIcon />}
            onClick={props.onClick}
        >
            {props.text}
        </Button>
    );
}

export default CreateButton;