import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { URLS } from '../global';
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tooltip, IconButton } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import WarningDialog from '../components/WarningDialog';


const DeletePetition = () => {
    const [petition, setPetition] = useState({});
    const [loading, setLoading] = useState(true);
    const { axiosInstance } = useContext(AuthContext);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [reload, setReload] = useState(true);
    console.log(reload);

    useEffect(() => {
        if (!reload) return;
        axiosInstance.get(URLS.DELETE_PETITION)
            .then((response) => {
                const data = response.data.map((item) => (
                    {
                        ...item,
                        date: item.date.split('T')[0],
                        adminUser: item.adminUser.username,
                    }
                ));

                setPetition(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setReload(false);
            });

    }, [axiosInstance, reload]);

    const handleDelete = (id) => {
        setConfirmDelete(true);
        setIdToDelete(id);
    };

    const handleReject = (id) => {
        axiosInstance.put(URLS.DELETE_PETITION + id + '/', { state: 'Rechazada' }).then((response) => {
            setReload(true);
        });
    }

    const columns = [
		{ field: "id", headerName: "ID", flex: 1, align: "center" },
		{ field: "type", headerName: "Tipo de Objeto", flex: 1, align: "center" },
		{ field: "object_id", headerName: "ID del Objeto", flex: 1, align: "center" },
		{ field: "date", headerName: "Fecha", flex: 1, align: "center" },
		{ field: "adminUser", headerName: "Usuario", flex: 1, align: "center" },
		{ field: "state", headerName: "Estado", flex: 1, align: "center" },
		{
			field: "eliminar",
			headerName: "Confirmar Eliminación",
			flex: 1,
			align: "center",
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<Tooltip title={"Confirmar Eliminación de " + params.row.type} arrow>
					<div>
						<IconButton
							onClick={() => handleDelete(params.row.id)}
							aria-label="Confirmar Eliminación"
							disabled={params.row.state !== "Pendiente"}
							// color={theme.palette.brandColors.primary}
							color={params.row.state === "Pendiente" ? "error" : "rgba(0, 0, 0, 0.87)"}
						>
							<DeleteIcon />
						</IconButton>
					</div>
				</Tooltip>
			),
		},
		{
			field: "rechazar",
			headerName: "Rechazar Eliminación",
			flex: 1,
			align: "center",
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<Tooltip title={"Rechazar petición de eliminación de " + params.row.type} arrow>
					<div>
						<IconButton
							onClick={() => handleReject(params.row.id)}
							aria-label="Confirmar Rechazo"
							disabled={params.row.state !== "Pendiente"}
							// color={theme.palette.brandColors.primary}
							color={params.row.state === "Pendiente" ? "primary" : "rgba(0, 0, 0, 0.87)"}
						>
							<ThumbDownAltIcon />
						</IconButton>
					</div>
				</Tooltip>
			),
		},
	];

    const handleConfirmExit = () => {
        axiosInstance.delete(URLS.DELETE_PETITION + idToDelete + '/')
            .then((response) => {
                console.log(response);
                setConfirmDelete(false);
                setReload(true);
            })
            .catch((error) => {
                console.log(error);
            })
    };


    return (
        <Box sx={
            {
                height: "100%",
                p: 3,
                width: "100%"
            }
        }>
            <WarningDialog
                open={confirmDelete}
                onClose={() => setConfirmDelete(false)}
                title={"Confirmar eliminación del objeto"}
                text={"Al eliminar el objeto se eliminarán todos los datos asociados a él. ¿Desea continuar?"}
                handleButton1={() => setConfirmDelete(false)}
                Button1Text={"Cancelar"}
                handleButton2={handleConfirmExit}
                Button2Text={"Eliminar"}
            />
            <DataGrid
                rows={petition}
                columns={columns}
                loading={loading}
            />
        </Box>

    )
};

export default DeletePetition;