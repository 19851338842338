import { useEffect, useState } from "react";
import {
	AppName,
	Container,
	Content,
	Data,
	GeneralInfo,
	GeneralInfoData,
	Logo,
	LogosContainer,
	ReportTitle,
	ReportType,
	Subtitle,
	Tab,
	TabContent,
	Tabs,
} from "../Common";
import "../common.css";
import { generateImagePoints, generateObjectsPoints } from "../../../../utils/map_helper";
import MapAddorns from "../../../Map/MapAddorns";
import GeneralButton from "../../../GeneralButton";
import Plot from "react-plotly.js";
import { useTheme } from "@mui/material/styles";
import {
	PlotContainer,
	PlotTab,
	StatTitle,
	SwitchContainer,
	TabContentWrapper,
	Table,
	TableHead,
	Td,
	Th,
} from "./ConsolidatedCommon";
import Objects from "../Objects";
import GeoImages from "../GeoImages";
import GeneralData from "./GeneralData";
import MapContent from "./MapContent";
import GeoImagesContent from "./GeoImagesContent";

const TABS = {
	DATA: 0,
	MAP: 1,
	GEO_IMAGES: 2,
};

const Prospection = ({ reportData }) => {
	const [tab, setTab] = useState(TABS.DATA);

	return (
		<Container>
			<ReportTitle>
				<ReportType>Informe consolidado</ReportType>

				<AppName>Rastrum</AppName>
			</ReportTitle>
			<Content nt={TABS.MAP === tab}>
				<Tabs centered value={tab} onChange={(e, v) => setTab(v)}>
					<Tab label="Datos" small />
					<Tab label="Mapa" small />
					<Tab label="Prospección" small />
				</Tabs>

				<TabContent value={tab} index={TABS.DATA}>
					<GeneralData reportData={reportData} />
				</TabContent>

				<TabContent value={tab} index={TABS.MAP} style={{ height: "100%", padding: 0, margin: 0 }}>
					<MapContent reportData={reportData} includeSwitch={false} open={tab === TABS.MAP} />
				</TabContent>

				<TabContent value={tab} index={TABS.GEO_IMAGES} style={{ margin: 0 }}>
					<GeoImagesContent reportData={reportData} />
				</TabContent>

			</Content>
		</Container>
	);
};

export default Prospection;
