// SingleDropZone.jsx

/**
 * SingleDropZone - Componente para subir sólamente una imágen. Usuarios pueden droppear o seleccionar el archivo.
 */

import { React, useState, useContext } from "react";
import { IconButton, Typography, FormHelperText } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { MessagesContext } from "../context";

export default function SingleDropZone({ handleInputChange, inputChangeName, errorText = "", sx = {}, initialFile = null }) {
	const [file, setFile] = useState(initialFile);
	const { addMessage } = useContext(MessagesContext);
	const maxSize = 20 * 1024 * 1024; // 20 MB
	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".jpg", ".png"],
		},
		onDrop: (acceptedFiles) => {
			if (acceptedFiles.length > 1) {
				addMessage(`Selecciona solo 1 imagen`, "error");
				return;
			}

			if (acceptedFiles[0].size > maxSize) {
				addMessage(`La imagen excede el tamaño máximo permitido de ${maxSize / 1024 / 1024}MB`, "error");
				return;
			} else {
				if (file) URL.revokeObjectURL(file.preview);
				acceptedFiles[0].preview = URL.createObjectURL(acceptedFiles[0]);
				setFile(acceptedFiles[0]);
				handleInputChange(inputChangeName, acceptedFiles[0]);
			}
		},
	});

	return (
		<>
			<div
				{...getRootProps({ className: "dropzone" })}
				style={{
					border: "2px dashed grey",
					borderRadius: "10px",
					backgroundColor: "#f5f5f5",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "220px",
					position: "relative",
					...sx,
				}}
			>
				{!file ? (
					<>
						<input {...getInputProps()} />

						<CloudUploadOutlinedIcon style={{ color: "grey", fontSize: "3rem" }} />
						<p style={{ color: "grey", textAlign: "center" }}>Sube o arrastra la imagen</p>
					</>
				) : typeof file === "object" ? (
					<>
						<div key={file.preview} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
							<div style={{ border: "1px solid grey", maxWidth: "80%", maxHeight: "170px", position: "relative" }}>
								<IconButton
									style={{ position: "absolute", top: 0, right: 0, backgroundColor: "white", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
									onClick={(event) => {
										event.stopPropagation(); // prevent to open file explorer
										setFile(null);
										handleInputChange(inputChangeName, null);
									}}
								>
									<CloseIcon />
								</IconButton>
								<img
									src={file.preview}
									// Revoke data uri after image is loaded
									onLoad={() => {
										URL.revokeObjectURL(file.preview);
									}}
									style={{ maxWidth: "100%", maxHeight: "100%" }}
									alt="Imagen no disponible"
								/>
							</div>
							<Typography variant="caption" display="block" gutterBottom>
								{file.name.length > 25 ? `${file.name.slice(0, 25)}...` : file.name}
							</Typography>
						</div>
					</>
				) : (
					<>
						<div key={file} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
							<div style={{ border: "1px solid grey", maxWidth: "80%", maxHeight: "170px", position: "relative" }}>
								<IconButton
									style={{ position: "absolute", top: 0, right: 0, backgroundColor: "white", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
									onClick={(event) => {
										event.stopPropagation(); // prevent to open file explorer
										setFile(null);
										handleInputChange(inputChangeName, null);
									}}
								>
									<CloseIcon />
								</IconButton>
								<img
									src={file}
									// Revoke data uri after image is loaded
									onLoad={() => {
										URL.revokeObjectURL(file.preview);
									}}
									style={{ maxWidth: "100%", maxHeight: "100%" }}
									alt="Imagen no disponible"
								/>
							</div>
							<Typography variant="caption" display="block" gutterBottom>
								{file.name}
							</Typography>
						</div>
					</>
				)}
			</div>
			<FormHelperText sx={{ color: "#d32f2f" }}>{errorText}</FormHelperText>
		</>
	);
}
