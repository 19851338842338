import React, { useContext } from "react";
import { Typography, Box, Divider, IconButton, Grid, Button, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { ReportDataContext } from "../../../context";
import CircularProgress from "@mui/material/CircularProgress";
import RenderFormContent from "./TypeRenderer";

// global
import { STATES } from "./StatesConstants";
import Stepper from "../../Stepper/Stepper";

// Estilos del modal
const BoxStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: "75vw",
	maxWidth: "75vw",
	width: "75vw",
	height: "90vh",
	maxHeight: "90%",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "auto",
	borderRadius: "10px",
	display: "flex",
	flexDirection: "column",
};

/**
 * Componente que renderiza el componente de creación de informes y maneja el estado del mismo, para cambiar entre cada paso de la creación
 * @param {Function} handleClose Función a ejecutar al cerrar el modal
 */
const CreateReport = ({ handleClose }) => {
	const {
		reportData,
		setReportType,
		showBottomButtons,
		handleEdit,
		handleSubmit,
		loading,
		handleBack,
		handleNext,
		isLastStep,
		activeStep,
		handleMapUpload,
	} = useContext(ReportDataContext);
	const edit = reportData.isEdit;
	const theme = useTheme();

	return (
		<>
			<Box sx={BoxStyle}>
				{loading && (
					<Box
						sx={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 1,
							bgcolor: "rgba(255,255,255,0.5)",
						}}
					>
						<CircularProgress />
					</Box>
				)}
				{reportData.reportType ? (
					<>
						<Box sx={{ position: "relative", width: "100%" }}>
							<div
								style={{
									position: "absolute",
									top: 0,
									right: 0,
								}}
							>
								<IconButton onClick={handleClose}>
									<CloseIcon />
								</IconButton>
							</div>
							<Divider />
							<Stepper
								activeStep={activeStep}
								steps={reportData.reportType.steps.map((label, index) => ({
									label,
								}))}
							/>
						</Box>
						<Box sx={{ height: "100%", overflowY: "auto" }}>
							<RenderFormContent activeStep={activeStep} edit={edit} />
						</Box>

						<div style={{ padding: "20px 25px" }}>
							{showBottomButtons ? (
								<Box sx={{ position: "relative", width: "100%" }}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											gap: "20px",
										}}
									>
										{activeStep === 0 &&
										reportData.reportType.id !== 10 &&
										reportData.reportType.id !== 11 ? (
											<Button
												variant="outlined"
												onClick={() => {
													setReportType(undefined);
												}}
												sx={{
													color: theme.palette.brandColors.primary,
													borderRadius: "10px",
													border: `1px solid ${theme.palette.brandColors.primary}`,
												}}
												startIcon={
													<ArrowBackIcon sx={{ color: theme.palette.brandColors.primary }} />
												}
											>
												Cambiar tipo de informe
											</Button>
										) : (
											<Button
												variant="outlined"
												disabled={loading}
												onClick={handleBack}
												startIcon={
													<ArrowBackIcon sx={{ color: theme.palette.brandColors.primary }} />
												}
												sx={{
													color: theme.palette.brandColors.primary,
													borderRadius: "10px",
													border: `1px solid ${theme.palette.brandColors.primary}`,
													"&:hover": {
														color: theme.palette.brandColors.primary,
														backgroundColor: "white",
														border: `1px solid ${theme.palette.brandColors.primary}`,
													},
												}}
											>
												Anterior
											</Button>
										)}
										<Box />
										{isLastStep() ||
										(reportData.reportType.id === STATES.NORMAL_DAY.id && activeStep === 5) ||
										(reportData.reportType.id === STATES.SEA_BOTTOM.id && activeStep === 4) ||
										(reportData.reportType.id === STATES.FEEDING_AND_BACTERIUM.id &&
											activeStep === 4) ? (
											edit ? (
												<Button
													variant="contained"
													color="primary"
													endIcon={<AssignmentTurnedInIcon sx={{ color: "white" }} />}
													onClick={
														(reportData.reportType.id === STATES.NORMAL_DAY.id &&
															activeStep === 6) ||
														(reportData.reportType.id === STATES.SEA_BOTTOM.id &&
															activeStep === 5) ||
														(reportData.reportType.id === STATES.FEEDING_AND_BACTERIUM.id &&
															activeStep === 5)
															? handleMapUpload
															: handleEdit
													}
													disabled={loading}
													style={{
														backgroundColor: theme.palette.brandColors.primary,
														color: "white",
														borderRadius: "10px",
														"&:hover": {
															backgroundColor: theme.palette.brandColors.primary,
														},
													}}
												>
													Guardar Cambios
												</Button>
											) : (
												<Button
													variant="contained"
													color="success"
													endIcon={<AssignmentTurnedInIcon sx={{ color: "white" }} />}
													onClick={handleSubmit}
													disabled={loading}
													style={{
														backgroundColor: theme.palette.brandColors.primary,
														color: "white",
														borderRadius: "10px",
														"&:hover": {
															backgroundColor: theme.palette.brandColors.primary,
														},
													}}
												>
													Terminar Informe
												</Button>
											)
										) : (
											<Button
												onClick={handleNext}
												sx={{
													backgroundColor: theme.palette.brandColors.primary,
													borderRadius: "10px",
													"&:hover": {
														backgroundColor: theme.palette.brandColors.primary,
													},
												}}
												variant="contained"
												endIcon={<ArrowForwardIcon />}
											>
												Continuar
											</Button>
										)}
									</Box>
								</Box>
							) : (
								<></>
							)}
						</div>
					</>
				) : (
					<div style={{ padding: "20px 25px" }}>
						{/* Si no se ha seleccionado el tipo de informe, se muestra el selector de tipo de informe */}
						<Typography variant="h6" align="center" mt="20px" gutterBottom>
							Selecciona el tipo de informe
						</Typography>
						<IconButton sx={{ position: "absolute", top: 0, right: 0, m: 2 }} onClick={handleClose}>
							<CloseIcon />
						</IconButton>
						<Grid container spacing={2} justifyContent="center" marginTop="10px">
							{Object.keys(STATES).map((element) => {
								if (STATES[element].hidden) {
									return null;
								}

								return (
									<Grid
										item
										xs={12}
										sm={STATES[element].label === "Día normal" ? 12 : 4}
										key={STATES[element].label}
									>
										<Button
											variant="outlined"
											onClick={() => {
												setReportType(STATES[element]);
											}}
											sx={{ width: "100%", borderRadius: "15px" }}
										>
											<Box
												sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
											>
												<Box sx={{ display: "flex" }}>
													{STATES[element].icon}
													{STATES[element].extraIcons && STATES[element].extraIcons}
												</Box>
												<Typography sx={{ mt: 1, color: theme.palette.brandColors.primary }}>
													{STATES[element].label}
												</Typography>
											</Box>
										</Button>
									</Grid>
								);
							})}
						</Grid>
					</div>
				)}
			</Box>
		</>
	);
};

export default CreateReport;
