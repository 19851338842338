import { useState } from "react";
import { AppName, Container, Content, ReportTitle, ReportType, Tab, TabContent, Tabs } from "./Common";
import "./common.css";
import MapContent from "./MapContent";
import GeneralData from "./GeneralData";
import ImagesContent from "./ImagesContent";
import FeedingBacteriumImages from "./FeedingBacteriumImages";
import InfoIcon from "../../Icons/InfoIcon";
import CameraIcon from "../../Icons/CameraIcon";
import ImageIcon from "../../Icons/ImageIcon";
import MapIcon from "../../Icons/MapIcon";
import { useTheme } from "@mui/material/styles";

const TABS = {
	DATA: 0,
	IMAGES: 1,
	GEO_IMAGES: 2,
	MAP: 3,
};

const FeedingBacterium = ({ reportData }) => {
	const [tab, setTab] = useState(TABS.DATA);
	const theme = useTheme();

	return (
		<Container>
			<ReportTitle>
				<ReportType>Alimentación y bacterias</ReportType>

				<AppName>Rastrum</AppName>
			</ReportTitle>
			<Content nt={TABS.MAP === tab}>
				<Tabs centered value={tab} onChange={(e, v) => setTab(v)} tabs={4}>
					<Tab
						label={<div className="text">Datos</div>}
						icon={<InfoIcon color={tab === TABS.DATA ? theme.palette.brandColors.primary : "#848591"} />}
					/>
					<Tab
						label={<div className="text">Imágenes</div>}
						icon={
							<CameraIcon color={tab === TABS.IMAGES ? theme.palette.brandColors.primary : "#848591"} />
						}
					/>
					<Tab
						label={<div className="text">Imágenes georreferenciadas</div>}
						icon={
							<ImageIcon
								color={tab === TABS.GEO_IMAGES ? theme.palette.brandColors.primary : "#848591"}
							/>
						}
					/>

					<Tab
						label={<div className="text">Mapa</div>}
						icon={<MapIcon color={tab === TABS.MAP ? theme.palette.brandColors.primary : "#848591"} />}
					/>
				</Tabs>

				<TabContent value={tab} index={TABS.DATA}>
					<GeneralData reportData={reportData} />
				</TabContent>

				<TabContent value={tab} index={TABS.IMAGES}>
					<ImagesContent images={reportData?.images} />
				</TabContent>

				<TabContent value={tab} index={TABS.GEO_IMAGES} style={{ margin: "0", overflow: "unset" }}>
					<FeedingBacteriumImages
						images={reportData?.feedingAndBacteriumImages}
						cages={reportData?.cultivationCenter?.cages}
					/>
				</TabContent>

				<TabContent value={tab} index={TABS.MAP} style={{ height: "100%", padding: 0, margin: 0 }}>
					<MapContent open={TABS.MAP === tab} reportData={reportData} />
				</TabContent>
			</Content>
		</Container>
	);
};

export default FeedingBacterium;
