import { createContext, useEffect, useState, useCallback, useContext } from "react";
import { URLS } from "../global";
import { AuthContext, MessagesContext, ReportErrorContext } from "./index";
import { STATES } from "../components/Reports/Create/StatesConstants";

export const ReportDataContext = createContext({});

const ReportDataProvider = ({ children, initialData, handleClose }) => {
	const [reportData, setReportData] = useState(initialData);
	useEffect(() => {
		setReportData(initialData);
	}, [initialData]);
	const setReportType = (newReportType) => setReportData((prev) => ({ ...prev, reportType: newReportType }));
	const [showBottomButtons, setShowBottomButtons] = useState(true);
	const [databaseIds, setDatabaseIds] = useState({});
	const [imageDatabaseIds, setImageDatabaseIds] = useState([]);
	const [loading, setLoading] = useState(false);
	const { axiosInstance } = useContext(AuthContext);
	const { addMessage } = useContext(MessagesContext);
	const [activeStep, setActiveStep] = useState(0);
	const { checkErrors } = useContext(ReportErrorContext);

	const totalSteps = () => {
		return !reportData.reportType ? 0 : reportData.reportType.steps.length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const handleNext = () => {
		if (checkErrors() === false) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else {
			addMessage("Por favor, completa todos los campos obligatorios", "error");
		}
	};

	const handleBack = () => {
		if (reportData.hasBeenSend) return;
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const uploadObjectRescue = useCallback(
		async (row, informe_id) => {
			if (row.id) {
				return Promise.resolve(row.id);
			}
			let form_data = new FormData();
			form_data.append("castawayRest", row.castawayRest.id);
			form_data.append("report", informe_id);

			let config = {
				method: "POST",
				url: URLS.RESCUED_OBJECTS,
				data: form_data,
			};

			await axiosInstance(config)
				.then((response) => {
					setDatabaseIds((prev) => ({
						...prev,
						[row.id]: response.data.id,
					}));
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					return Promise.reject(error);
				});
		},
		[axiosInstance]
	);

	const uploadObjectRescues = useCallback(
		async (informe_id) => {
			console.log(reportData.beaconObjects);
			if (reportData.beaconObjects.length === 0) {
				return Promise.resolve();
			}

			return Promise.all(
				reportData.beaconObjects.map(async (row) => {
					return uploadObjectRescue(row, informe_id)
						.then((response) => {
							return Promise.resolve(response);
						})
						.catch((error) => {
							console.log(error);
							addMessage("Ocurrió un error desbalizar un objeto", "error");
							return Promise.reject(error);
						});
				})
			);
		},
		[reportData.beaconObjects, uploadObjectRescue, addMessage]
	);

	const deleteObjectRescue = useCallback(
		async (row) => {
			let config = {
				method: "DELETE",
				url: `${URLS.RESCUED_OBJECTS}${row.id}/`,
			};

			// enviar datos
			await axiosInstance(config)
				.then((response) => {
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					return Promise.reject(error);
				});
		},
		[axiosInstance]
	);

	const deleteObjectRescues = useCallback(async () => {
		if (reportData.deletedBeaconObjects.length === 0) {
			return Promise.resolve();
		}

		return Promise.all(
			reportData.deletedBeaconObjects.map(async (row) => {
				return deleteObjectRescue(row)
					.then((response) => {
						return Promise.resolve(response);
					})
					.catch((error) => {
						console.log(error);
						addMessage("Ocurrió un error al desbalizar un objeto", "error");
						return Promise.reject(error);
					});
			})
		);
	}, [reportData.deletedBeaconObjects, deleteObjectRescue, addMessage]);

	/**
	 * Función que crea o edita un objeto en la base de datos
	 * @param {object} row objeto a crear o editar
	 * @param {number} informe_id id del informe al que pertenece el objeto
	 */
	const uploadObject = useCallback(
		async (row, informe_id) => {
			// preparar datos
			let form_data = new FormData();
			console.log(row);
			if (row.submarineImage && row.submarineImage instanceof File)
				form_data.append("submarineImage", row.submarineImage, row.submarineImage.name);
			if (row.inBoatImage && row.inBoatImage instanceof File)
				form_data.append("inBoatImage", row.inBoatImage, row.inBoatImage.name);
			if (row.video && row.video?.video instanceof File) form_data.append("video", row.video.video);
			form_data.append("type", row.type.id);
			form_data.append("state", row.beacon ? "Balizado" : "En Embarcación");
			form_data.append("latitude", row.latitude);
			form_data.append("longitude", row.longitude);
			form_data.append("volume", row.volume);
			form_data.append("report", informe_id);
			form_data.append("comments", row.comments);
			form_data.append("notify", row.notify ? "True" : "False");
			form_data.append("ammount", row.amount);

			let config = {
				method: "POST",
				url: URLS.OBJECTS,
				headers: { "Content-Type": "multipart/form-data" },
				data: form_data,
			};
			if (row.database_id !== -1) {
				config.method = "PATCH";
				config.url = `${URLS.OBJECTS}${row.database_id}/`;
			}

			// enviar datos
			await axiosInstance(config)
				.then((response) => {
					setDatabaseIds((prev) => ({
						...prev,
						[row.id]: response.data.id,
					}));
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					return Promise.reject(error);
				});
		},
		[axiosInstance]
	);

	const uploadObjects = useCallback(
		async (informe_id, errorObjectList) => {
			if (reportData.objectsList.length === 0) {
				return Promise.resolve();
			}

			return Promise.all(
				reportData.objectsList.map(async (row, index) => {
					return uploadObject({ ...row, inner_id: index }, informe_id)
						.then((response) => {
							return Promise.resolve(response);
						})
						.catch((error) => {
							console.log(error);
							errorObjectList.push(row);
							addMessage(
								`Ocurrió un error al ${row.database_id ? "editar" : "crear"} el objeto Nº ${row.id}`,
								"error"
							);
							return Promise.reject(error);
						});
				})
			);
		},
		[reportData.objectsList, uploadObject, addMessage]
	);

	const deleteObject = useCallback(
		async (row) => {
			let config = {
				method: "DELETE",
				url: `${URLS.OBJECTS}${row.database_id}/`,
			};

			// enviar datos
			await axiosInstance(config)
				.then((response) => {
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					return Promise.reject(error);
				});
		},
		[axiosInstance]
	);

	const deleteObjects = useCallback(async () => {
		if (reportData.objectsDeleted.length === 0) {
			return Promise.resolve();
		}

		return Promise.all(
			reportData.objectsDeleted.map(async (row) => {
				return deleteObject(row)
					.then((response) => {
						return Promise.resolve(response);
					})
					.catch((error) => {
						console.log(error);
						addMessage(`Ocurrió un error al eliminar el objeto Nº ${row.id}`, "error");
						return Promise.reject(error);
					});
			})
		);
	}, [reportData.objectsDeleted, deleteObject, addMessage]);

	const uploadVideo = useCallback(
		async (item, informe_id) => {
			// preparar datos
			let form_data = new FormData();
			if (item.video && item.video instanceof File) form_data.append("video", item.video);
			form_data.append("description", item.description);
			if (item.latitude) form_data.append("latitude", item.latitude);
			if (item.longitude) form_data.append("longitude", item.longitude);
			form_data.append("type", item.type);
			form_data.append("report", informe_id);

			let config = {
				method: "POST",
				url: URLS.GEO_VIDEO,
				headers: { "Content-Type": "multipart/form-data" },
				data: form_data,
			};
			if (reportData.isEdit && item.id) {
				config.method = "PATCH";
				config.url = `${URLS.GEO_VIDEO}${item.id}/`;
			}

			// enviar datos
			await axiosInstance(config)
				.then((response) => {
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					console.log(error);
					return Promise.reject(error);
				});
		},
		[axiosInstance, reportData.isEdit]
	);

	const uploadVideos = useCallback(
		async (informe_id, errorVideoList) => {
			if (reportData.videos.length === 0) {
				return Promise.resolve();
			}

			return Promise.all(
				reportData.videos.map(async (item) => {
					return uploadVideo(item, informe_id)
						.then((response) => {
							return Promise.resolve(response);
						})
						.catch((error) => {
							console.log(error);
							errorVideoList.push(item);
							addMessage(
								`Ocurrió un error al ${item.id ? "editar" : "crear"} el video Nº ${item.id}`,
								"error"
							);
							return Promise.reject(error);
						});
				})
			);
		},
		[reportData.videos, uploadVideo, addMessage]
	);

	const deleteVideo = useCallback(
		async (item) => {
			let config = {
				method: "DELETE",
				url: `${URLS.GEO_VIDEO}${item.id}/`,
			};

			// enviar datos
			await axiosInstance(config)
				.then((response) => {
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					console.log(error);
					return Promise.reject(error);
				});
		},
		[axiosInstance]
	);

	const deleteVideos = useCallback(async () => {
		if (reportData.videosDeleted.length === 0) {
			return Promise.resolve();
		}

		return Promise.all(
			reportData.videosDeleted.map(async (item) => {
				return deleteVideo(item)
					.then((response) => {
						return Promise.resolve(response);
					})
					.catch((error) => {
						console.log(error);
						addMessage(`Ocurrió un error al eliminar el video Nº ${item.id}`, "error");
						return Promise.reject(error);
					});
			})
		);
	}, [reportData.videosDeleted, deleteVideo, addMessage]);

	const IMAGES_TYPE = {
		"GEO_IMAGES": 0, 
		"IMAGES": 1, 
		"FEEDING_AND_BACTERIUM_IMAGES": 2
	}
	const getImageURL = (imageType) => {
		if (imageType === IMAGES_TYPE.GEO_IMAGES) {
			return URLS.GEO_IMAGES;
		} else if (imageType === IMAGES_TYPE.IMAGES) {
			return URLS.IMAGES;
		} else if (imageType === IMAGES_TYPE.FEEDING_AND_BACTERIUM_IMAGES) {
			return URLS.FEEDING_AND_BACTERIUM_IMAGES;
		}
	}

	const uploadImage = useCallback(
		async (item, informe_id, imageType) => {
			// preparar datos
			let form_data = new FormData();
			let url = getImageURL(imageType);

			if (item.image && item.image instanceof File) form_data.append("image", item.image, item.image.name);
			form_data.append("description", item.description);

			if (imageType === IMAGES_TYPE.GEO_IMAGES) {
				form_data.append("latitude", item.latitude);
				form_data.append("longitude", item.longitude);
			} 
			if (imageType === IMAGES_TYPE.FEEDING_AND_BACTERIUM_IMAGES) {
				form_data.append("latitude", item.latitude);
				form_data.append("longitude", item.longitude);
				form_data.append("hasFeed", item.hasFeed ? "True" : "False");
				form_data.append("hasBacterium", item.hasBacterium ? "True" : "False");
				form_data.append("hasFeces", item.hasFeces ? "True" : "False");

				form_data.append("feedRate", item.feedRate);
				form_data.append("bacteriumRate", item.bacteriumRate);
				form_data.append("fecesRate", item.fecesRate);
			}
			form_data.append("report", informe_id);


			let config = {
				method: "POST",
				url: url,
				headers: { "Content-Type": "multipart/form-data" },
				data: form_data,
			};
			if (reportData.isEdit && item.id) {
				config.method = "PATCH";
				config.url = `${url}${item.id}/`;
			}

			// enviar datos
			await axiosInstance(config)
				.then((response) => {
					if (imageType !== IMAGES_TYPE.IMAGES) {
						setImageDatabaseIds((prev) => [...prev, response.data]);
					}
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					console.log(error);
					return Promise.reject(error);
				});
		},
		[axiosInstance, reportData.isEdit]
	);

	const uploadImages = useCallback(
		async (informe_id, errorImageList, imageType) => {
			let list = [];
			if (imageType === IMAGES_TYPE.GEO_IMAGES) {
				list = reportData.geoImagesList;
			} else if (imageType === IMAGES_TYPE.IMAGES) {
				list = reportData.imagesList;
			} else if (imageType === IMAGES_TYPE.FEEDING_AND_BACTERIUM_IMAGES) {
				list = reportData.feedingBacteriumImages;
			}
			if (list.length === 0) {
				return Promise.resolve();
			}

			return Promise.all(
				list.map(async (item, index) => {
					return uploadImage({ ...item, index: index }, informe_id, imageType)
						.then((response) => {
							return Promise.resolve(response);
						})
						.catch((error) => {
							console.log(error);
							errorImageList.push(item);
							addMessage(
								`Ocurrió un error al ${item.id ? "editar" : "crear"} la imagen Nº ${item.id}`,
								"error"
							);
							return Promise.reject(error);
						});
				})
			);
		},
		[reportData.imagesList, reportData.geoImagesList, reportData.feedingBacteriumImages, uploadImage, addMessage]
	);

	const deleteImage = useCallback(
		async (item, imagesType) => {
			const url = getImageURL(imagesType);
			let config = {
				method: "DELETE",
				url: `${url}${item.id}/`,
			};

			// enviar datos
			await axiosInstance(config)
				.then((response) => {
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					console.log(error);
					return Promise.reject(error);
				});
		},
		[axiosInstance]
	);

	const deleteImages = useCallback(
		async (imagesType) => {
			let list = [];
			if (imagesType === IMAGES_TYPE.GEO_IMAGES) {
				list = reportData.geoImagesDeleted;
			} else if (imagesType === IMAGES_TYPE.IMAGES) {
				list = reportData.imagesDeleted;
			} else if (imagesType === IMAGES_TYPE.FEEDING_AND_BACTERIUM_IMAGES) {
				list = reportData.feedingBacteriumImagesDeleted;
			}
			if (list.length === 0) {
				return Promise.resolve();
			}

			return Promise.all(
				list.map(async (item) => {
					return deleteImage(item, imagesType)
						.then((response) => {
							return Promise.resolve(response);
						})
						.catch((error) => {
							console.log(error);
							addMessage(`Ocurrió un error al eliminar la imagen Nº ${item.id}`, "error");
							return Promise.reject(error);
						});
				})
			);
		},
		[
			reportData.imagesDeleted,
			reportData.geoImagesDeleted,
			reportData.feedingBacteriumImagesDeleted,
			deleteImage,
			addMessage,
		]
	);

	const uploadMap = (informe_id) => {
		let form_data = new FormData();
		if (reportData.map && reportData.map instanceof Blob)
			form_data.append("map", reportData.map, reportData.map.name);

		let config = {
			url: `${URLS.REPORTS}${informe_id}/editdata/`,
			headers: { "Content-Type": "multipart/form-data" },
			data: form_data,
			method: "PATCH",
		};
		axiosInstance(config)
			.then((response) => {
				setLoading(false);
				handleClose(null, "success");
			})
			.catch((error) => {
				console.log(error);
				addMessage("Ocurrió un error al agregar el mapa al informe", "error");
			});
	};

	const handleSubmitErrors = (errorObjectList, errorImageList, informe_id) => {
		if (errorObjectList.length === 0 && errorImageList.length === 0 && informe_id !== -1 && isLastStep()) {
			handleClose(null, "success");
		} else if (errorObjectList.length === 0 && errorImageList.length === 0 && informe_id !== -1 && !isLastStep()) {
			setActiveStep(activeStep + 1);
			setLoading(false);
			setReportData((prev) => ({
				...prev,
				reportId: informe_id,
				id: informe_id,
				initialCounter: prev.objectsList.length,
				objectsDeleted: [],
				imagesDeleted: [],
				isEdit: true,
				hasBeenSend: true,
			}));
		} else {
			if (errorObjectList.length !== 0) {
				setReportData({
					...reportData,
					objectsList: errorObjectList,
					imagesList: errorImageList,
					reportId: informe_id,
					reportType: STATES.OBJECTS_UPLOAD_ERROR,
				});
			} else if (informe_id !== -1) {
				setReportData({
					...reportData,
					objectsList: errorObjectList,
					imagesList: errorImageList,
					reportId: informe_id,
					reportType: STATES.IMAGES_UPLOAD_ERROR,
				});
			}
			setActiveStep(0);
		}
	};
	/**
	 * Función que crea o edita un informe en la base de datos
	 * @param {number} informe_id id del informe a editar, si es -1 se crea un nuevo informe
	 */
	const uploadReport = useCallback(
		async (informe_id = -1, edit = false) => {
			const currentDate = new Date(); // Get the current date and time
			const submissionDateTime = currentDate.toISOString(); // Convert the date to a string in ISO format
			let form_data = new FormData();
			form_data.append("cultivationCenterID", reportData.cultivationCenter.id);
			form_data.append("cultivationCenter_id", reportData.cultivationCenter.id);
			form_data.append("date", reportData.date);
			form_data.append("fecha_submit", submissionDateTime);
			form_data.append("boat_id", reportData.boat.id);
			form_data.append("reportType", reportData.reportType.label);
			if (reportData.endDate) form_data.append("endDate", reportData.endDate);
			form_data.append("comments", reportData.comments);

			let config = {
				method: "POST",
				url: URLS.SAVE_REPORT,
				headers: { "Content-Type": "multipart/form-data" },
				data: form_data,
			};
			if (informe_id !== -1) {
				config.method = "PATCH";
				config.url = `${URLS.REPORTS}${informe_id}/editdata/`;
			}

			return axiosInstance(config)
				.then((response) => {
					informe_id = response.data.id;
					return Promise.resolve(response.data.id);
				})
				.catch((error) => {
					console.log(error);
					if (error.response?.data?.error_type === "sin_permisos") {
						addMessage(error.response.data.error, "error");
					} else {
						addMessage("Ocurrió un error al crear el informe", "error");
					}
					return Promise.reject(error);
				});
		},
		[axiosInstance, addMessage, reportData]
	);

	const handleSubmit = async () => {
		setLoading(true);
		let report = uploadReport();
		await report
			.then(async (informe_id) => {
				let errorObjectList = [];
				let errorImageList = [];
				let errorGeoImageList = [];

				let objectsPromise = uploadObjects(informe_id, errorObjectList);
				let imagesPromise = uploadImages(informe_id, errorImageList, IMAGES_TYPE.IMAGES);
				let geoImagesPromise = uploadImages(informe_id, errorGeoImageList, IMAGES_TYPE.GEO_IMAGES);
				let feedingBacteriumImagesPromise = uploadImages(
					informe_id,
					errorGeoImageList,
					IMAGES_TYPE.FEEDING_AND_BACTERIUM_IMAGES
				);
				let objectRescuesPromise = uploadObjectRescues(informe_id);
				let videosPromise = uploadVideos(informe_id, errorImageList);

				await Promise.all([
					report,
					objectsPromise,
					imagesPromise,
					geoImagesPromise,
					objectRescuesPromise,
					videosPromise,
					feedingBacteriumImagesPromise,
				])
					.then((response) => {
						addMessage(`Se creó el informe Nº ${informe_id}`, "success");
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						handleSubmitErrors(errorObjectList, errorImageList, informe_id);
					});
			})
			.catch((error) => {
				console.log(error);
				addMessage("Ocurrió un error al crear el informe", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleEdit = async () => {
		const informeID = reportData.id;
		setLoading(true);
		let report = uploadReport(informeID, true);
		await report.then(async (informe_id) => {
			let errorObjectList = [];
			let errorImageList = [];
			let errorGeoImageList = [];

			let objectsPromise = uploadObjects(informe_id, errorObjectList);
			let imagesPromise = uploadImages(informe_id, errorImageList, IMAGES_TYPE.IMAGES);
			let geoImagesPromise = uploadImages(informe_id, errorGeoImageList, IMAGES_TYPE.GEO_IMAGES);
			let feedingBacteriumImagesPromise = uploadImages(
				informe_id,
				errorGeoImageList,
				IMAGES_TYPE.FEEDING_AND_BACTERIUM_IMAGES
			);
			let objectRescuesPromise = uploadObjectRescues(informe_id);
			let videosPromise = uploadVideos(informe_id, errorImageList);
			let deleteObjectsPromise = deleteObjects();
			let deleteImagesPromise = deleteImages(IMAGES_TYPE.IMAGES);
			let deleteGeoImagesPromise = deleteImages(IMAGES_TYPE.GEO_IMAGES);
			let deleteFeedingBacteriumImagesPromise = deleteImages(IMAGES_TYPE.FEEDING_AND_BACTERIUM_IMAGES);
			let deleteObjectRescuesPromise = deleteObjectRescues(informe_id);
			let deleteVideosPromise = deleteVideos();

			await Promise.all([
				report,
				objectsPromise,
				imagesPromise,
				deleteObjectsPromise,
				deleteImagesPromise,
				geoImagesPromise,
				deleteGeoImagesPromise,
				deleteObjectRescuesPromise,
				objectRescuesPromise,
				videosPromise,
				deleteVideosPromise,
				feedingBacteriumImagesPromise,
				deleteFeedingBacteriumImagesPromise,
			])
				.then((response) => {
					addMessage(`Se editó el informe Nº ${informeID}`, "success");
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					handleSubmitErrors(errorObjectList, errorImageList, informe_id);
				});
		});
	};

	const handleMapUpload = () => {
		setLoading(true);
		uploadMap(reportData.reportId);
	};

	return (
		<ReportDataContext.Provider
			value={{
				reportData,
				setReportData,
				setReportType,
				initialData,
				showBottomButtons,
				setShowBottomButtons,
				handleEdit,
				handleSubmit,
				databaseIds,
				imageDatabaseIds,
				loading,
				setLoading,
				isLastStep,
				handleNext,
				handleBack,
				handleMapUpload,
				activeStep,
			}}
		>
			{children}
		</ReportDataContext.Provider>
	);
};

export default ReportDataProvider;
