import { createContext, useState } from "react";

export const ReportErrorContext = createContext({});

const ReportErrorProvider = ({ children }) => {
    const [checkErrors, setCheckErrors] = useState(() => {});
    return (
          <ReportErrorContext.Provider value={{checkErrors, setCheckErrors}}>
            {children}
          </ReportErrorContext.Provider>
      );
};

export default ReportErrorProvider;