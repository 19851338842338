
import { useEffect, useState, useCallback } from "react";

// mui components
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

// mui icons
import ExploreIcon from "@mui/icons-material/Explore";

// context
import { useAuthContext, useMessagesContext } from "../../context";
// global
import { URLS } from "../../global";

// components
import WarningDialog from "../WarningDialog";
import { estado_color_map } from "../../utils/map_helper";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`& .${stepConnectorClasses.line}`]: {
		// position: "absolute",
		position: "relative",
		top: "-4px",
		left: "-12px",
		width: "calc(100% + 24px)",
		backgroundColor: theme.palette.brandColors.primary,
		// marginLeft: -4,
		border: `2px solid ${theme.palette.brandColors.primary}`,
		// borderRadius: "10px",
	},
}));

const ColorlibStepIcon = styled("div")(({ theme }) => ({
	zIndex: 1,
	color: "#fff",
	width: 20,
	height: 20,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	backgroundColor: theme.palette.brandColors.primary,
}));

const StepContainer = styled(Step)(({ theme }) => ({
	position: "relative",
}));

const Historial = ({ objectDetail }) => {
	const today = new Date().toLocaleDateString();
	const objectDate = new Date(objectDetail.report.date).toLocaleDateString();
	const theme = useTheme();

	const subtitleStyle = { fontSize: 14, fontWeight: 400, color: theme.palette.brandColors.text };
	const titleStyle = { fontSize: 16, fontWeight: 600, color: theme.palette.brandColors.text };

	return (
		<Stepper
			connector={<ColorlibConnector />}
			alternativeLabel
			sx={{ position: "relative", width: "100%" }}
		>
			{objectDetail.state === "En Embarcación" && objectDetail.rescued.length > 0 ? (
				<StepContainer key={"Balizado"}>
					<StepLabel
						optional={
							<>
								<Typography sx={subtitleStyle} component="div">
									{objectDate}
								</Typography>
								<Typography sx={subtitleStyle} component="div">
									{objectDetail.ROVOperatorName}
								</Typography>
							</>
						}
						sx={{ p: 0 }}
						StepIconComponent={ColorlibStepIcon}
					>
						<Typography sx={titleStyle} component="div">
							Balizado
						</Typography>
					</StepLabel>
				</StepContainer>
			) : null}

			{objectDetail.state === "En Embarcación" && objectDetail.rescued.length > 0 && (
				<StepContainer key={"Recuperado"}>
					<StepLabel
						optional={
							<>
								<Typography sx={subtitleStyle} component="div">
									{objectDetail.rescued[0].report.date}
								</Typography>
								<Typography sx={subtitleStyle} component="div">
									{objectDetail.rescued[0].ROVOperatorName}
								</Typography>
							</>
						}
						sx={{ p: 0 }}
						StepIconComponent={ColorlibStepIcon}
					>
						<Typography sx={titleStyle} component="div">
							Recuperado
						</Typography>
					</StepLabel>
				</StepContainer>
			)}

			{objectDetail.state === "En Embarcación" && objectDetail.rescued.length === 0 ? (
				<StepContainer key={"Recuperado"}>
					<StepLabel
						optional={
							<>
								<Typography sx={subtitleStyle} component="div">
									{objectDate}
								</Typography>
								<Typography sx={subtitleStyle} component="div">
									{objectDetail.ROVOperatorName}
								</Typography>
							</>
						}
						sx={{ p: 0 }}
						StepIconComponent={ColorlibStepIcon}
					>
						<Typography sx={titleStyle} component="div">
							Recuperado del fondo
						</Typography>
					</StepLabel>
				</StepContainer>
			) : null}
			{objectDetail.state === "En Embarcación" && !objectDetail.receptionNote ? (
				<StepContainer key={"en_embarcacion"}>
					<StepLabel
						optional={
							<>
								<Typography sx={subtitleStyle} component="div">
									{today}
								</Typography>
								<Typography sx={subtitleStyle} component="div">
									{objectDetail.ROVOperatorName}
								</Typography>
							</>
						}
						sx={{ p: 0 }}
						StepIconComponent={ColorlibStepIcon}
					>
						<Typography sx={titleStyle} component="div">
							En embarcación
						</Typography>
					</StepLabel>
				</StepContainer>
			) : null}

			{objectDetail.state === "En Embarcación" && objectDetail.receptionNote ? (
				<StepContainer key={"entregado"}>
					<StepLabel
						optional={
							<>
								<Typography sx={subtitleStyle} component="div">
									{objectDetail.receptionNote.date}
								</Typography>
								<Typography sx={subtitleStyle} component="div">
									Por {objectDetail.ROVOperatorName} a {objectDetail.receptionNote.receptor}
								</Typography>
							</>
						}
						sx={{ p: 0 }}
						StepIconComponent={ColorlibStepIcon}
					>
						<Typography sx={titleStyle} component="div">
							Entregado
						</Typography>
					</StepLabel>
				</StepContainer>
			) : null}

			{objectDetail.state === "Balizado" ? (
				<StepContainer key={"Balizado"}>
					<StepLabel
						optional={
							<>
								<Typography sx={subtitleStyle} component="div">
									{objectDate}
								</Typography>
								<Typography sx={subtitleStyle} component="div">
									{objectDetail.ROVOperatorName}
								</Typography>
							</>
						}
						sx={{ p: 0 }}
						StepIconComponent={ColorlibStepIcon}
					>
						<Typography sx={titleStyle} component="div">
							Balizado
						</Typography>
					</StepLabel>
				</StepContainer>
			) : null}
		</Stepper>
	);
};

const Pair = ({ title, value, style }) => {
	return (
		<div style={{ display: "flex", alignItems: "center", ...style }}>
			<div style={{ fontSize: "14px", fontWeight: 700, color: "#212121" }}>{title}</div>
			<div
				style={{
					marginRight: "5px",
				}}
			>
				:
			</div>
			<div style={{ fontSize: "14px", fontWeight: 400, color: "#212121" }}>{value}</div>
		</div>
	);
};

const ObjectDetail = ({ objectDetail, operatorDetail = undefined }) => {
	const theme = useTheme();
	return (
		<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
			<div style={{ display: "flex", gap: "20px", width: "100%", alignItems: "center" }}>
				<div
					style={{
						width: "100%",
						height: "1px",
						backgroundColor: "#8E9499",
					}}
				/>
				<div
					style={{
						textWrap: "nowrap",
						backgroundColor: estado_color_map[objectDetail.state],
						borderRadius: "12px",
						color: "white",
						padding: "3px 10px",
						fontSize: "14px",
						fontWeight: 700,
					}}
				>
					ID: {objectDetail.id}
				</div>
				<div
					style={{
						width: "100%",
						height: "1px",
						backgroundColor: "#8E9499",
					}}
				/>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					gap: "40px",
					margin: "20px 0",
				}}
			>
				<Pair
					title="Latitud"
					value={objectDetail.latitude}
					style={{
						width: "100%",

						justifyContent: "end",
					}}
				/>
				<div>|</div>
				<Pair
					title="Longitud"
					value={objectDetail.longitude}
					style={{
						width: "100%",
						justifyContent: "start",
					}}
				/>
			</div>

			<div style={{ display: "flex", gap: "80px", width: "100%" }}>
				<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<div
						style={{
							color: "#00629E",
							fontSize: "14px",
							fontWeight: 700,
						}}
					>
						Imagen fondo
					</div>
					<img
						src={objectDetail.submarineImage}
						style={{
							width: "100%",
							height: "auto",
							borderRadius: "10px",
						}}
						alt="Problema al cargar la imagen"
					/>
				</div>
				<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<div
						style={{
							color: "#00629E",
							fontSize: "14px",
							fontWeight: 700,
						}}
					>
						Imagen en embarcación
					</div>
					<img
						src={objectDetail.inBoatImage}
						style={{
							width: "100%",
							height: "auto",
							borderRadius: "10px",
						}}
						alt="Sin imagen"
					/>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					gap: "10px",
					justifyContent: "center",
					margin: "20px 0 0",
				}}
			>
				<Pair title="Tipo de Objeto" value={objectDetail.type.name} />
				<div>|</div>
				<Pair title="Volumen" value={objectDetail.volume} />
				<div>|</div>
				<Pair title="Importante" value={objectDetail.notify ? "Si" : "No"} />
				<div>|</div>
				<Pair title="Balizado" value={objectDetail.state === "Balizado" ? "Si" : "No"} />
			</div>

			{objectDetail.comments && (
				<div style={{ display: "flex", gap: "10px", justifyContent: "center", margin: "20px 0" }}>
					<Pair title="Comentario" value={objectDetail.comments} />
				</div>
			)}

			{objectDetail.video && (
				<div>
					<div
						style={{
							color: "#00629E",
							fontSize: "14px",
							fontWeight: 700,
							textAlign: "center",
						}}
					>
						Video
					</div>
					<video controls width="95%" height="auto" src={objectDetail.video} />
				</div>
			)}

			{objectDetail.receptionNote && (
				<>
					<div style={{ display: "flex", gap: "20px", width: "100%", alignItems: "center" }}>
						<div
							style={{
								width: "100%",
								height: "1px",
								backgroundColor: "#8E9499",
							}}
						/>
						<div
							style={{
								textWrap: "nowrap",
								backgroundColor: "#00629E",
								borderRadius: "12px",
								color: "white",
								padding: "3px 10px",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							Nota de Salida {objectDetail.receptionNote.id}
						</div>
						<div
							style={{
								width: "100%",
								height: "1px",
								backgroundColor: "#8E9499",
							}}
						/>
					</div>

					<div
						style={{
							display: "flex",
							gap: "20px",
							width: "100%",
							justifyContent: "center",
							margin: "20px 0",
						}}
					>
						<Pair
							title="Operador"
							value={operatorDetail ? operatorDetail.first_name + " " + operatorDetail.last_name : ""}
							style={{
								width: "100%",
								justifyContent: "end",
							}}
						/>
						<div>|</div>
						<Pair
							title="Fecha"
							value={objectDetail.receptionNote.date}
							style={{ width: "100%", justifyContent: "start" }}
						/>
					</div>

					<div>
						<div
							style={{
								color: "#00629E",
								fontSize: "14px",
								fontWeight: 700,
								textAlign: "center",
							}}
						>
							Imagen de la recepción
						</div>
						<div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
							<img
								src={objectDetail.receptionNote.receptionImage}
								style={{
									width: "50%",
									height: "auto",
									borderRadius: "10px",
								}}
								alt="Problema al cargar la imagen"
							/>
						</div>
					</div>

					<div style={{ display: "flex", gap: "10px", justifyContent: "center", margin: "20px 0" }}>
						<Pair title="Persona que recibe" value={objectDetail.receptionNote.receptor} />
						<div>|</div>
						<Pair title="RUT de quien recibe" value={objectDetail.receptionNote.rutReceptor} />
						<div>|</div>
						<Pair title="Cargo de quien recibe" value={objectDetail.receptionNote.cargoReceptor} />
						<div>|</div>
						<Pair title="Transporte" value={objectDetail.receptionNote.boat} />
					</div>

					{objectDetail.receptionNote.comments && (
						<div style={{ display: "flex", gap: "10px", justifyContent: "center", margin: "0 20px" }}>
							<Pair title="Comentario" value={objectDetail.receptionNote.comments} />
						</div>
					)}
				</>
			)}
		</div>
	);
};

const ObjectInfo = ({ handleBackToObjects, selectedObject, steps, setStep, focusObject }) => {
	const { axiosInstance, USER_TYPE } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const theme = useTheme();
	const [objectDetail, setObjectDetail] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const [operatorDetail, setOperatorDetail] = useState(null);

	const request_operator_name = useCallback(
		async (id) => {
			setLoading(true);
			await axiosInstance
				.get(`${URLS.OPERATORS}${id}/get_name/`)
				.then((res) => {
					setOperatorDetail(res.data);
				})
				.catch((err) => {
					console.log("error: ", err.response.status);
					// por ahora no hago nada
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[axiosInstance, setOperatorDetail]
	);

	const request_object_detail = useCallback(
		async (id) => {
			setLoading(true);
			await axiosInstance
				.get(`${URLS.GET_OBJECT.replace("<int:objeto_id>", id)}info/`)
				.then((res) => {
					setObjectDetail(res.data);
					if (res.data.receptionNote) {
						request_operator_name(res.data.receptionNote.ROVOperator);
					}
				})
				.catch((err) => {
					console.log("error: ", err.response.status);
					// por ahora no hago nada
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[axiosInstance, setObjectDetail, request_operator_name]
	);

	const handleObjectExitClick = () => {
		setStep(steps.NOTA_SALIDA);
	};

	useEffect(() => {
		request_object_detail(selectedObject.id);
	}, [selectedObject, request_object_detail]);

	const handleDelete = async () => {
		setConfirmOpen(true);
	};

	const handleDeleteConfirmed = async () => {
		setConfirmOpen(false); // Cerrar el diálogo
		await axiosInstance
			.delete(`api/delete_reception_note/${selectedObject.id}/`)
			.then((res) => {
				console.log("res: ", res);
				addMessage("Nota de salida eliminada con éxito", "success");
				setStep(steps.LISTA_OBJETOS);
			})
			.catch((err) => {
				console.log("error: ", err.response.status);
				addMessage("No se pudo eliminar la nota de salida", "error");
			});
	};

	return (
		<>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
					padding: "0",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					Objeto {selectedObject.id}
					<IconButton aria-label="delete" onClick={() => focusObject(selectedObject.id)}>
						<ExploreIcon fontSize="inherit" />
					</IconButton>
				</div>
				<div
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						fontWeight: 700,
						color: theme.palette.brandColors.primary,
						fontSize: "13px",
					}}
					onClick={handleBackToObjects}
				>
					Volver al listado
				</div>
			</div>

			<div
				style={{
					margin: "0 0 20px",
					overflowY: "auto",
					overflowX: "hidden",
					height: "100%",
					paddingTop: "20px",
				}}
			>
				{objectDetail ? (
					<>
						<Historial objectDetail={objectDetail} />
						<ObjectDetail objectDetail={objectDetail} operatorDetail={operatorDetail} />
					</>
				) : null}
			</div>
			<WarningDialog
				open={confirmOpen}
				setOpen={setConfirmOpen}
				title="¿Está seguro que desea eliminar esta nota de salida?"
				onConfirm={handleDelete}
				handleButton1={() => setConfirmOpen(false)}
				Button1Text="Cancelar"
				Button2Text="Eliminar"
				handleButton2={handleDeleteConfirmed}
			/>
		</>
	);
};

export default ObjectInfo;
