import { useState, useContext, useRef } from "react";
import Plot from "react-plotly.js";
import { Box, Checkbox, FormControlLabel, Slider, Switch } from "@mui/material";
import { generateObjectsPoints } from "../../utils/map_helper";
import { AuthContext } from "../../context";
import { useTheme } from "@mui/material/styles";
import MapAddorns from "../Map/MapAddorns";
import html2canvas from "html2canvas";

// Constante para guardar el estado del tipo de estadística
const aggregations = {
	GENERAL: { label: "Estadísticas Generales", type: "GENERAL", objectName: "Área" },
	AREA: { label: "Estadísticas por Área", type: "AREA", objectName: "Centro" },
	CENTER: { label: "Estadísticas por Centro", type: "CENTER", objectName: "Objeto" },
};

const Graphs = ({ data, type, setType, setSelectedArea, setSelectedCenter, mapStructures }) => {
	const [heapMapSelected, setHeapMapSelected] = useState(true);
	const [heatRadius, setHeatRadius] = useState(10);
	const { USER_TYPE } = useContext(AuthContext);
	const theme = useTheme();
	const graphStyle = { boxShadow: "0 0 10px 5px #e8e8e8" };

	const areaData = type.type === "GENERAL" ? data?.byPlace : data.byCultivationCenter;
	const areaLabels =
		type.type === "GENERAL"
			? areaData?.map((item) => item.report__cultivationCenter__place__name)
			: areaData?.map((item) => item.report__cultivationCenter__name);
	const areaIDs =
		type.type === "GENERAL"
			? areaData?.map((item) => item.report__cultivationCenter__place__id)
			: areaData?.map((item) => item.report__cultivationCenter__id);

	const layout = {
		responsive: true,
		autosize: true,
		font: {
			family: "Roboto, sans-serif",
			size: 12,
		},
		yaxis: {
			automargin: true,
		},
		xaxis: {
			automargin: true,
		},
		title: {
			font: {
				size: 16,
				family: "Roboto, sans-serif",
				color: theme.palette.brandColors.primary,
			},
		},
		modebar: { remove: ["pan", "zoom", "zoomIn2d", "zoomOut2d", "autoScale2d", "resetScale2d", "select", "lasso"] },
	};

	const handlePlotClick = (e) => {
		const value = areaIDs[e.points[0].i];
		if (type.type === "GENERAL") {
			setType(aggregations.AREA);
			setSelectedArea({
				id: value,
				name: areaLabels[e.points[0].i],
			});
		} else if (type.type === "AREA") {
			setType(aggregations.CENTER);
			setSelectedCenter({
				id: value,
				name: areaLabels[e.points[0].i],
			});
		}
	};

	let longitudes = mapStructures.heatmap.map((row) => row.map((point) => point[0]));
	let latitudes = mapStructures.heatmap.map((row) => row.map((point) => point[1]));
	longitudes = longitudes.reduce((a, b) => a.concat([...b, b[0], null]), []);
	latitudes = latitudes.reduce((a, b) => a.concat([...b, b[0], null]), []);

	const [mapState, setMapState] = useState({
		zoom: 8, // Initialize zoom state with default value
		center: { lat: -42.3, lon: -73 }, // Initialize center state with default value
	});

	const handleRelayout = (event) => {
		const newZoom = event?.["mapbox.zoom"];
		const newCenter = event?.["mapbox.center"];

		if (newZoom !== undefined || newCenter !== undefined) {
			setMapState({
				zoom: newZoom !== undefined ? newZoom : mapState.zoom,
				center: newCenter !== undefined ? newCenter : mapState.center,
			});
		}
	};

	const [byAmmountByDay, setByAmmountByDay] = useState(false);
	const [byAmmountByAggregation, setByAmmountByAggregation] = useState(false);
	const [byObjectType, setByObjectType] = useState(false);

	return (
		<>
			<Box sx={{ display: "flex", gap: "20px", padding: "0 20px" }}>
				<Box sx={{ width: "100%", height: "40vh", ...graphStyle, position: "relative" }}>
					<Box
						sx={{
							display: "flex",
							position: "absolute",
							top: "0",
							left: "10px",
							zIndex: "1000",
							alignItems: "center",
							fontSize: "10px",
						}}
					>
						Por peso
						<Switch sx={{}} checked={byAmmountByDay} onChange={() => setByAmmountByDay(!byAmmountByDay)} />
						Por Cantidad
					</Box>
					<Plot
						config={{ responsive: true, displaylogo: false }}
						data={[
							{
								x: data.byDate.map((item) => item.report__date),
								y: data.byDate.map((item) => (!byAmmountByDay ? item.total : item.count)),
								type: "scatter",
								marker: {
									color: theme.palette.brandColors.primary,
									size: 12, // You can also specify the size of markers
								},
							},
						]}
						layout={{
							...layout,

							yaxis: {
								...layout.yaxis,
								title: "Cantidad de objetos" + (!byAmmountByDay ? " (kg)" : ""),
							},
							title: {
								...layout.title,
								text: "Cantidad de objetos recolectada por día",
							},
						}}
						style={{ width: "100%", height: "100%" }}
					/>
				</Box>

				{type.type !== "CENTER" && (
					<Box sx={{ width: "100%", height: "40vh", ...graphStyle, position: "relative" }}>
						<Box
							sx={{
								display: "flex",
								position: "absolute",
								top: "0",
								left: "10px",
								zIndex: "1000",
								alignItems: "center",
								fontSize: "10px",
							}}
						>
							Por peso
							<Switch
								sx={{}}
								checked={byAmmountByAggregation}
								onChange={() => setByAmmountByAggregation(!byAmmountByAggregation)}
							/>
							Por Cantidad
						</Box>
						<Plot
							config={{ responsive: true }}
							onClick={(e) => handlePlotClick(e)}
							data={[
								{
									labels: areaLabels,
									objectIDs: areaIDs,
									values: areaData?.map((item) =>
										!byAmmountByAggregation ? item.total : item.count
									),
									type: "pie",
									marker: {
										colors: Object.keys(theme.palette.brandColors).map(
											(item) => theme.palette.brandColors[item]
										),
									},
								},
							]}
							layout={{
								...layout,
								title: {
									...layout.title,
									text:
										"Cantidad de objetos recolectada por " +
										type.objectName.toLowerCase() +
										(byAmmountByAggregation ? "" : " (kg)"),
								},
							}}
							style={{ width: "100%", height: "100%" }}
						/>
					</Box>
				)}

				<Box sx={{ width: "100%", height: "40vh", ...graphStyle, position: "relative" }}>
					<Box
						sx={{
							display: "flex",
							position: "absolute",
							top: "0",
							left: "10px",
							zIndex: "1000",
							alignItems: "center",
							fontSize: "10px",
						}}
					>
						Por peso
						<Switch sx={{}} checked={byObjectType} onChange={() => setByObjectType(!byObjectType)} />
						Por Cantidad
					</Box>
					<Plot
						config={{ responsive: true }}
						data={[
							{
								// y: data.byObjects.map((item) => item.type),// Ver que este bien con el tema pesos y obj predeterminados
								y: data.byObjects.map((item) => item.type__name),
								x: data.byObjects.map((item) => (byObjectType ? item.count : item.total)),
								type: "bar",
								orientation: "h",
								marker: {
									color: theme.palette.brandColors.primary,
									// color: Object.keys(theme.palette.brandColors).map((item) => theme.palette.brandColors[item])
								},
							},
						]}
						layout={{
							...layout,
							xaxis: {
								...layout.xaxis,
								automargin: true,
								title: "Cantidad de objetos" + (byObjectType ? "" : " (kg)"),
							},
							margin: {
								...layout.margin,
								b: 90,
							},
							title: {
								...layout.title,
								text: "Cantidad de objetos recolectada por objeto",
							},
						}}
						style={{ width: "100%", height: "100%" }}
					/>
				</Box>
			</Box>
			<Box sx={{ padding: "20px" }}>
				<Box sx={{ width: "100%", height: "90vh", ...graphStyle }}>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							height: "10%",
							justifyContent: "end",
							alignItems: "center",
							gap: "50px",
							padding: "10px 0",
						}}
					>
						<Box sx={{ width: "450px", display: "flex" }}>
							<Box sx={{ width: "150px" }}>Radio de calor</Box>
							<Slider
								value={heatRadius}
								step={1}
								min={1}
								max={50}
								onChange={(e, value) => setHeatRadius(value)}
							/>
						</Box>
						<FormControlLabel
							control={
								<Checkbox
									checked={heapMapSelected}
									onChange={() => setHeapMapSelected(!heapMapSelected)}
								/>
							}
							label="Mapa de Calor"
						/>
					</Box>
					<Box sx={{ padding: "0 20px 20px", width: "100%", height: "90%" }}>
						{!heapMapSelected ? (
							<>
								<MapAddorns
									mapKey={""}
									data={{
										objects: generateObjectsPoints(data.allObjects),
										structures: mapStructures.normal,
										images: [],
										boats: [],
									}}
									centros={[]}
									loading={false}
									sx={{ width: "100%" }}
									hideImages={true}
								/>
							</>
						) : (
							<Plot
								config={{ responsive: true }}
								data={[
									{
										type: "densitymapbox",
										text: data.allObjects.map((item) => 2),
										lon: data.allObjects.map((item) => item.longitude),
										lat: data.allObjects.map((item) => item.latitude),
										z: data.allObjects.map((item) => 0.1),
										radius: heatRadius,
										hoverinfo: "skip",
									},
									{
										type: "scattermapbox",
										mode: "lines",
										fill: "none",
										lon: longitudes,
										lat: latitudes,
										marker: { size: 10, color: "rgba(0,0,0, 0.1)" },
										line: {
											color: "rgb(255, 255, 255)",
											opacity: 1,
											width: 2,
										},
										showlegend: false,
										hoverinfo: "skip",
									},
								]}
								layout={{
									dragmode: "zoom",
									mapbox: {
										style: "open-street-map",
										center: mapState.center,
										zoom: mapState.zoom,
									},
									margin: {
										l: 0,
										r: 0,
										b: 0,
										t: 0,
									},
								}}
								style={{ width: "100%", height: "100%" }}
								onRelayout={handleRelayout}
							/>
						)}
					</Box>
				</Box>
			</Box>

			{USER_TYPE === "Admin" && (
				<Box sx={{ width: "33%", height: "40vh", margin: "0 20px 20px", ...graphStyle }}>
					<Plot
						config={{ responsive: true }}
						data={[
							{
								x: data.byROVOperator.map((item) => item.total),
								y: data.byROVOperator.map(
									(item) =>
										item.report__ROVOperator__user__first_name +
										" " +
										item.report__ROVOperator__user__last_name
								),
								type: "bar",
								orientation: "h",
							},
						]}
						layout={{
							...layout,
							xaxis: {
								...layout.xaxis,
								title: "Cantidad de objetos (kg)",
							},
							margin: {
								...layout.margin,
								b: 90,
							},
							title: {
								...layout.title,
								text: "Cantidad de objetos recolectada por operador",
							},
						}}
						style={{ width: "100%", height: "100%" }}
					/>
				</Box>
			)}
		</>
	);
};

export default Graphs;
