import { styled, Tabs as MUITabs, Tab as MUITab } from "@mui/material";

export const CSS = {
	primary: "#22ACA4",
	secondary: "#00629E",
	gray: "#848591",
	gray2: "#8E9499",
	lightGray: "#D7D7D7",
	black: "#212121",
	shadow: "0px 0px 10px 0px rgba(50, 55, 59, 0.15)",
	borderRadius: "12px",
};

const smallScreenMedia = "@media (max-width: 500px)";
export const middleScreenMedia = "@media (max-width: 800px)";
export const middleScreenMedia2 = "@media (max-width: 1000px)";
export const largeScreenMedia = "@media (max-width: 1200px)";
export const extraLargeScreenMedia = "@media (max-width: 1600px)";

export const Container = styled("div")(() => ({
	height: "100%",
	display: "flex",
	flexDirection: "column",
}));

export const ReportTitle = styled("div")(() => ({
	height: "75px",
	backgroundColor: CSS.primary,
	display: "flex",
	padding: "0 35px",
	alignItems: "center",
	justifyContent: "space-between",
	[middleScreenMedia]: {
		padding: "0 20px",
	},
}));

export const ReportType = styled("div")(() => ({
	fontSize: "20px",
	fontWeight: 700,
	color: "white",
	textTransform: "uppercase",
	[middleScreenMedia]: {
		fontSize: "13px",
	},
}));

export const AppName = styled("div")(() => ({
	fontSize: "38px",
	fontWeight: 800,
	color: "white",
	textTransform: "uppercase",
	[middleScreenMedia]: {
		fontSize: "25px",
	},
}));

export const Content = styled("div")(({ nt }) => ({
	padding: nt ? "0" : "0 200px",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	overflow: "auto",
	[extraLargeScreenMedia]: {
		padding: nt ? "0" : "0 50px",
	},
	[largeScreenMedia]: {
		padding: nt ? "0" : "0 25px",
	},
	[middleScreenMedia]: {
		padding: 0,
	},
}));

export const Tabs = styled(MUITabs)(({ tabs, perRow = 1 }) => ({
	marginTop: "10px",
	minHeight: "unset",
	overflow: "unset",
	"& .MuiTabs-flexContainer": {
		height: "fit-content",
	},
	"& .MuiTabs-scroller": {
		height: "fit-content",
	},

	[largeScreenMedia]: {
		marginTop: "0",
		minHeight: "unset",
		overflow: "unset",
		"& .MuiTabs-flexContainer": {
			flexWrap: "wrap",
		},
		"& .MuiTab-root": {
			width: `${100 / (tabs / perRow)}%`,
		},
	},
}));

export const Tab = styled(MUITab)(({ small }) => ({
	borderBottom: `1px solid ${CSS.lightGray}`,
	fontWeight: 600,
	fontSize: "15px",
	color: CSS.gray,
	width: "fit-content",
	padding: small ? "15px 50px" : "15px 50px",
	minHeight: "unset",
	"> svg": {
		display: "none",
	},
	[middleScreenMedia]: {
		padding: "10px 20px",
		minWidth: "unset",
		"> svg": {
			display: "block",
		},
	},
	"> .text": {
		textWrap: "nowrap",
		[middleScreenMedia]: {
			display: "none",
		},
	},
}));

const TabContainer = styled("div")(() => ({
	overflow: "auto",
	maxHeight: "100%",
	padding: "0 25px",
	marginTop: "10px",
	[largeScreenMedia]: {
		overflow: "unset",
	},
}));

export const TabContent = ({ children, value, index, style, sx }) => {
	return value === index && <TabContainer sx={sx} style={style}>{children}</TabContainer>;
};

export const Pair = ({ title, value, start, end }) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				flexWrap: "wrap",
				...(start && {
					justifyContent: "start",
					width: "100%",
				}),
				...(end && {
					justifyContent: "end",
					width: "100%",
				}),
			}}
		>
			<div style={{ fontSize: "14px", fontWeight: 700, color: "#212121", textWrap: "nowrap" }}>{title}</div>
			<div
				style={{
					marginRight: "5px",
				}}
			>
				:
			</div>
			<div
				style={{
					fontSize: "14px",
					fontWeight: 400,
					color: "#212121",
					textWrap: "nowrap",
					whiteSpace: "normal",
				}}
			>
				{value}
			</div>
		</div>
	);
};

export const LogosContainer = styled("div")(() => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
}));

export const Logo = styled("img")(() => ({
	width: "200px",
	height: "auto",
	[middleScreenMedia]: {
		width: "100px",
	},
}));

export const GeneralInfo = styled("div")(({ justPhoto, smMaxPerRow = 1 }) => ({
	borderRadius: CSS.borderRadius,
	boxShadow: CSS.shadow,
	margin: justPhoto ? "0" : "0 0 25px",
	display: "flex",
	boxSizing: "unset",
	[largeScreenMedia]: {
		margin: "10px 0 20px",
		display: "grid",
		gridTemplateColumns: `repeat(${smMaxPerRow}, 1fr)`,
	},
}));

export const GeneralInfoData = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	padding: "25px",
	gap: "7px",
	justifyContent: "center",
	flexGrow: 1,
	borderRight: `1px solid ${CSS.lightGray}`,
	[largeScreenMedia]: {
		borderBottom: `1px solid ${CSS.lightGray}`,
		":nth-child(even)": {
			borderRight: "none",
		},
		":nth-last-child(2):nth-child(odd)": {
			borderBottom: "none",
		},
	},

	":nth-last-child(1)": {
		borderRight: "none",
		borderBottom: "none",
	},
}));

export const ImagesContainer = styled("div")(() => ({
	display: "grid",
	gridTemplateColumns: "1fr 1fr",
	width: "100%",
	[middleScreenMedia]: {
		gridTemplateColumns: "1fr",
	},
}));

export const Subtitle = styled("div")(({ md }) => ({
	fontSize: md ? "14px" : "13px",
	fontWeight: 400,
	color: CSS.black,
}));

export const Data = styled("div")(({ md }) => ({
	fontSize: md ? "14px" : "15px",
	fontWeight: 700,
}));

export const Line = styled("div")(() => ({
	height: "1px",
	width: "100%",
	backgroundColor: CSS.gray2,
	margin: "20px 0",
}));

export const ObjectIDContainer = styled("div")(() => ({
	display: "flex",
	gap: "20px",
	width: "100%",
	alignItems: "center",
	marginTop: "20px",
	[smallScreenMedia]: {
		gap: "10px",
	},
}));

export const ObjectID = styled("div")(({ color }) => ({
	fontSize: "14px",
	fontWeight: 700,
	backgroundColor: color,
	borderRadius: "12px",
	padding: "5px 10px",
	color: "white",
	textWrap: "nowrap",
	height: "fit-content",
	width: "max-content",
}));

export const LatLonContainer = styled("div")(() => ({
	display: "flex",
	justifyContent: "center",
	gap: "40px",
	margin: "20px 0",
	[smallScreenMedia]: {
		gap: "10px",
	},
}));

export const LatLon = ({ obj }) => {
	return (
		<LatLonContainer>
			<Pair title="Latitud" value={obj.latitude} end />
			<div>|</div>
			<Pair title="Longitud" value={obj.longitude} start />
		</LatLonContainer>
	);
};

export const ObjectDescription = styled("div")(() => ({
	fontSize: "14px",
	fontWeight: 700,
	color: CSS.secondary,
	margin: "10px 0",
}));

export const ObjectsImagesContainer = styled("div")(() => ({
	display: "flex",
	gap: "20px",
	[smallScreenMedia]: {
		flexDirection: "column",
	},
}));

export const ObjectImage = styled("img")(() => ({
	width: "75%",
	height: "auto",
	objectFit: "contain",
	margin: "10px 0",
}));

export const ObjectInfo = styled("div")(({ comments }) => ({
	display: "flex",
	gap: "10px",
	justifyContent: "center",
	margin: comments ? "20px 0 0" : "20px 0",
	flexWrap: "wrap",
}));

export const ImageContainer = styled("div")(() => ({
	padding: "30px",
	display: "flex",
	gap: "30px",
	borderRight: "1px solid #8E9499",
	borderBottom: "1px solid #8E9499",
	":nth-child(even)": { borderRight: "none" },
	":nth-last-child(2):nth-child(odd)": { borderBottom: "none" },
	":nth-last-child(1):nth-child(even)": { borderBottom: "none" },
	":nth-last-child(1):nth-child(odd)": { borderBottom: "none" },
	[middleScreenMedia]: {
		padding: "20px",
		flexDirection: "column",
		borderRight: "none",
		":nth-last-child(2)": {
			borderBottom: "1px solid #8E9499 !important",
		},
	},
}));

export const ContextImage = styled("img")(() => ({
	width: "50%",
	height: "auto",
	objectFit: "contain",
	[middleScreenMedia]: {
		width: "60%",
		margin: "0 auto",
	},
	[smallScreenMedia]: {
		width: "100%",
	},
}));

export const commentDummy = `El presente informe describe trabajos de extracción y localización de restos náufragos en el fondo marino, correspondiente al Centro de Cultivos Chelín, perteneciente a la empresa Multi-X, la recuperación y localización de los objetos es realizada con equipos R.O.V, modelo DEEP TREKKER y ROVEEE. Se trabaja desde barcaza VORTEX 1, PMO 7704 CA8198. Los materiales utilizados fueron cabo de 10mm, 12mm, 18mm, gancho mosquetón y gancho araña. También se grafican datos recopilados respecto a la posición de la concesión con el fin de enfocar los esfuerzos de limpieza en las zonas marcadas. Los datos obtenidos fueron a través de imágenes satelitales históricas. Los mapas y la localización de los elementos son realizados con Software Rastrum (trazabilidad de restos náufragos). Se realizan trabajos de extracción y prospección de fondo marino con equipos R.O.V. • Hora inicio faena: 08:30 horas • Hora termino faena: 16:40 horas. Se trabaja en paras de marea. Se realizaron trabajos de búsqueda y localización de orrentómetro que se encuentra extraviado en cabecera del módulo lado oeste sin resultados positivos el día de hoy. Se realizan 7 enganches los cueles quedan en cubierta de B/Z Vortex I.`;

export const dummyData = {
	id: 53,
	salmonCompany: {
		logo: "https://picsum.photos/200/151",
		name: "AcuaMarinos S.A.",
	},
	cultivationCenter: {
		coordinates: "-42.242281,-73.260814;-42.242236,-73.257783;-42.233233,-73.258033;-42.233278,-73.261061",
		code: "104110",
		name: "Bajo Chauques",
		cages: [
			{
				id: 13,
				coordinates: "-42.237750,-73.260409;-42.239697,-73.260409;-42.239586,-73.259314;-42.237639,-73.259314",
				name: "5",
				depth: 12.0,
				cultivationCenter: 1,
			},
			{
				id: 9,
				coordinates: "-42.233856,-73.260409;-42.235803,-73.260409;-42.235692,-73.259314;-42.233745,-73.259314",
				name: "1",
				depth: 42.0,
				cultivationCenter: 1,
			},
			{
				id: 12,
				coordinates:
					"-42.239697,-73.260409;-42.241644,-73.260409;-42.241533000000004,-73.25931449999999;-42.239586,-73.25931449999999",
				name: "7",
				depth: 12.5,
				cultivationCenter: 1,
			},
			{
				id: 7,
				coordinates: "-42.235803,-73.260409;-42.237750,-73.260409;-42.237639,-73.259314;-42.235692,-73.259314",
				name: "3",
				depth: 35.4,
				cultivationCenter: 1,
			},
			{
				id: 8,
				coordinates: "-42.235692,-73.259314;-42.237639,-73.259314;-42.237528,-73.258220;-42.235581,-73.258220",
				name: "4",
				depth: 3.5,
				cultivationCenter: 1,
			},
			{
				id: 10,
				coordinates: "-42.239586,-73.259314;-42.241533,-73.259314;-42.241422,-73.258220;-42.239475,-73.258220",
				name: "8",
				depth: 5.0,
				cultivationCenter: 1,
			},
			{
				id: 6,
				coordinates: "-42.233745,-73.259314;-42.235692,-73.259314;-42.235581,-73.258220;-42.233634,-73.258220",
				name: "2",
				depth: 66.2,
				cultivationCenter: 1,
			},
			{
				id: 11,
				coordinates:
					"-42.237639,-73.25931449999999;-42.239586,-73.25931449999999;-42.239475,-73.25822;-42.237528000000005,-73.25822",
				name: "J 11",
				depth: 20.0,
				cultivationCenter: 1,
			},
		],
		polygons: [
			{
				id: 1,
				name: "asddsa",
				description: "dsasdadas",
				coordinates: "1.000000,2.000000;3.000000,4.000000;3.000000,6.000000",
				cultivationCenter: 1,
			},
		],
	},
	place: "Canal Hudson, al norte de Punta Pindo, Isla Chelin",
	wreckRescueCompany: {
		id: 1,
		name: "Vortex",
		logo: "https://picsum.photos/200/150",
	},
	date: "2024-08-21",
	boat: "Vortex 1",
	comments: commentDummy,
	type: "Día normal",
	// type: "Prospección de fondo",
	// type: "Alimentación y bacterias",
	// type: "Relevo",
	objects: [
		{
			id: 2572,
			date: "2024-08-21",
			rescued: [],
			ammount: 1.0,
			state: "En Embarcación",
			latitude: -42.23432,
			longitude: -73.258581,
			volume: 90.012313312,
			submarineImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			inBoatImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			comments: "asdasd adsak djklsajkjdkaskjljdsjkkdaslkjdsal jklasdkjl dsakl; jkldsajk jkdsajkjkdasjkldsakjl;dklj;sajkdjklasjlkdsajlk;kjdjklsa jkdl;sa jkldjsajkjdksajdsa jdsjka kjldsakljdklsajkldjsaljdklsjakljjkjj  kldsja kld ksaljkj ldsakl jkj ksja kldj sakl;j dkslajklasj klsja kjl sajdslak;jkld;sajkl;d jsaklj dklsaj kl;jdsakljdklsajlkjl;kajsdkljsdakl;j klsdajkl;djsa l;kdjsa lk;jsdalkj",
			notify: false,
			type: {
				id: 1,
				name: "Contrapeso 90kg",
				description: "",
				weight: 90.0,
				type: "Cuantificable",
			},
		},
		{
			id: 258,
			date: "2024-08-21",
			rescued: [],
			ammount: 1.0,
			state: "Balizado",
			latitude: -42.24,
			longitude: -73.258581,
			volume: 90.0,
			submarineImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			inBoatImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			comments: "asdasd",
			notify: false,
			type: {
				id: 1,
				name: "Contrapeso 90kg",
				description: "",
				weight: 90.0,
				type: "Cuantificable",
			},
		},
	],
	images: [
		{
			id: 9,
			image: "https://testrastrum.s3.amazonaws.com/media/images/DescribedImage_0cinyh0jwbbqsn.png",
			description: "sdfdsdsf",
		},
		{
			id: 12,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			description: "sdfdsdsf",
		},
		{
			id: 13,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			description: "sdfdsdsf",
		},
		{
			id: 9,
			image: "https://testrastrum.s3.amazonaws.com/media/images/DescribedImage_0cinyh0jwbbqsn.png",
			description: "sdfdsdsf",
		},
		{
			id: 12,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			description: "sdfdsdsf",
		},
		{
			id: 13,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			description: "sdfdsdsf",
		},
	],
	ROVOperator: "ROVOperator",
	map: "https://testrastrum.s3.amazonaws.com/media/images/Report_oq97ws865bs6bf.png",
	geoImages: [
		{
			id: 9,
			image: "https://testrastrum.s3.amazonaws.com/media/images/DescribedImage_0cinyh0jwbbqsn.png",
			description: "sdfdsdsf",
			latitude: -42.238501,
			longitude: -73.260055,
		},
		{
			id: 12,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			description: "sdfdsdsf",
			latitude: -42.238701,
			longitude: -73.262055,
		},
		{
			id: 13,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			description: "sdfdsdsf",
			latitude: -42.238901,
			longitude: -73.264055,
		},
	],
	rescuedObjects: [
		{
			id: 257,
			date: "2024-08-21",
			rescued: [],
			ammount: 1.0,
			state: "En Embarcación",
			latitude: -42.23432,
			longitude: -73.258581,
			volume: 90.0,
			submarineImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			inBoatImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			comments: "asdasd",
			notify: false,
			date: "2024-08-21",
			type: {
				id: 1,
				name: "Contrapeso 90kg",
				description: "",
				weight: 90.0,
				type: "Cuantificable",
			},
		},
		{
			id: 258,
			date: "2024-08-21",
			rescued: [],
			ammount: 1.0,
			state: "Balizado",
			latitude: -42.24,
			longitude: -73.258581,
			volume: 90.0,
			submarineImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			inBoatImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			comments: "asdasd",
			date: "2024-08-21",
			notify: false,
			type: {
				id: 1,
				name: "Contrapeso 90kg",
				description: "",
				weight: 90.0,
				type: "Cuantificable",
			},
		},
	],
	videos: [
		{
			id: 11,
			date: "2024-08-21",
			video: "https://testrastrum.s3.amazonaws.com/media/videos/GeographicVideo_6sv18z3odchqhf.mp4",
			description: "asddsadasdadsa",
			latitude: -42.238501,
			longitude: -73.260055,
			report: 53,
		},
	],
	feedingAndBacteriumImages: [
		{
			id: 9,
			image: "https://testrastrum.s3.amazonaws.com/media/images/DescribedImage_0cinyh0jwbbqsn.png",
			description: "sdfdsdsf",
			latitude: -42.238501,
			longitude: -73.260055,
			hasFeed: true,
			feedRate: 0,
			hasBacterium: true,
			bacteriumRate: 2,
			hasFeces: true,
			fecesRate: 1,
		},
		{
			id: 12,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			description: "sdfdsdsf",
			latitude: -42.238701,
			longitude: -73.262055,
			hasFeed: false,
			feedRate: 0,
			hasBacterium: false,
			bacteriumRate: 0,
			hasFeces: false,
			fecesRate: 0,
		},
		{
			id: 13,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			description: "sdfdsdsf",
			latitude: -42.238901,
			longitude: -73.264055,
			hasFeed: true,
			feedRate: 0,
			hasBacterium: true,
			bacteriumRate: 2,
			hasFeces: false,
			fecesRate: 1,
		},
	],
	endDate: null,
};
