import { Box } from "@mui/material";
import { useState, useCallback, useMemo, Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const times = [
	{
		label: "Nuevo",
		color: "white",
	},
	{
		label: "+1 sem",
		color: "#c0c0c0",
	},
	{
		label: "+2 sem",
		color: "#a0a0a0",
	},
	{
		label: "+1 mes",
		color: "#777",
	},
];

const Legend = ({ small }) => {
	const [open, setOpen] = useState(small);
	const [hover, setHover] = useState(false);
	const [timesVideoROVOpen, setTimesVideoROVOpen] = useState(false);
	const [timesVideoDroneOpen, setTimesVideoDroneOpen] = useState(false);

	const handleToggleVideoROV = useCallback(() => {
		setTimesVideoROVOpen((prev) => !prev);
	}, []);

	const handleToggleVideoDrone = useCallback(() => {
		setTimesVideoDroneOpen((prev) => !prev);
	}, []);

	const objects = useMemo(
		() => ({
			Concesión: {
				backgroundColor: "rgba(255, 255, 255, 0.2)",
				borderRadius: 0,
				border: "3px solid white",
				width: "30px",
				height: "20px",
			},
			Módulo: {
				backgroundColor: "rgba(128, 128, 128, 0.2)",
				borderRadius: 0,
				border: "3px solid gray",
				width: "30px",
				height: "20px",
			},
			"Estructuras Flotantes": {
				borderRadius: 0,
				backgroundColor: "rgba(127, 255, 212, 0.2)",
				border: "3px solid #7fffd4",
				width: "30px",
				height: "20px",
			},
			"Objeto En Embarcación": {
				borderRadius: "50%",
				backgroundColor: "rgb(4, 208, 0)",
				border: "3px solid white",
				width: "20px",
				height: "20px",
				margin: "0 5px",
			},
			"Objeto Balizado": {
				backgroundColor: "red",
				borderRadius: "50%",
				border: "3px solid white",
				width: "20px",
				height: "20px",
				margin: "0 5px",
			},
			"Objeto Entregado": {
				backgroundColor: "#ffc176",
				borderRadius: "50%",
				border: "3px solid white",
				width: "20px",
				height: "20px",
				hide: small,
				margin: "0 5px",
			},
			"Imagen Prospección": {
				backgroundColor: "#48beff",
				borderRadius: "50%",
				border: "3px solid white",
				width: "20px",
				height: "20px",
				margin: "0 5px",
			},
			"Alimentación y Bacterias": {
				backgroundColor: "#4f5989",
				borderRadius: "50%",
				border: "3px solid white",
				width: "20px",
				height: "20px",
				margin: "0 5px",
			},
			"Video ROV": {
				backgroundColor: "#C14CE2",
				borderRadius: "50%",
				border: "3px solid white",
				width: "20px",
				height: "20px",
				hide: small,
				margin: "0 5px",
			},
			"Video Drone": {
				backgroundColor: "#FFBDE1",
				borderRadius: "50%",
				border: "3px solid white",
				width: "20px",
				height: "20px",
				hide: small,
				margin: "0 5px",
			},
		}),
		[small]
	);

	return (
		<Box
			sx={{
				backgroundColor: open || hover ? "#E8E8E8" : "white",
				borderRadius: "10px",
				position: "relative",
				"@media (maxWidth: 600px)": {
					backgroundColor: open ? "#E8E8E8" : "white",
				},
			}}
		>
			{open && !small && (
				<CloseIcon
					style={{
						position: "absolute",
						top: "5px",
						right: "5px",
						cursor: "pointer",
						zIndex: 3000,
					}}
					onClick={() => setOpen(false)}
				/>
			)}
			<Box
				sx={{
					flexDirection: "column",
					position: "relative",
					cursor: "default",
					padding: "10px 0",
					display: open || hover ? "flex" : "none",
					zIndex: 2000,
					"@media (maxWidth: 600px)": {
						display: open ? "flex" : "none",
					},
				}}
			>
				{Object.keys(objects).map(
					(key, index) =>
						objects[key].hide !== true && (
							<Fragment key={index}>
								<Box
									sx={{
										display: "flex",
										gap: "10px",
										margin: "5px 12px",
										alignItems: "center",
										position: "relative",
									}}
									key={index}
								>
									<Box sx={objects[key]}></Box>
									<Box
										sx={{
											fontSize: "12px",
											lineHeight: "10px",
											fontWeight: 400,
											fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
										}}
									>
										{key}
									</Box>
									{key === "Video ROV" && (
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												cursor: "pointer",
												position: "absolute",
												right: "0",
											}}
											onClick={handleToggleVideoROV}
										>
											{!timesVideoROVOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
										</Box>
									)}
									{key === "Video Drone" && (
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												cursor: "pointer",
												position: "absolute",
												right: "0",
											}}
											onClick={handleToggleVideoDrone}
										>
											{!timesVideoDroneOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
										</Box>
									)}
								</Box>
								{key === "Video ROV" && timesVideoROVOpen && (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											padding: "10px",
											gap: "5px",
											backgroundColor: "#E3E3E3",
										}}
									>
										{times.map((time, index) => (
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													gap: "10px",
													alignItems: "center",
													fontSize: "12px",
													lineHeight: "10px",
													fontWeight: 400,
													fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
													marginRight: index === times.length - 1 ? "0" : "10px",
												}}
												key={index}
											>
												<Box
													sx={{
														backgroundColor: "#C14CE2",
														borderRadius: "50%",
														border: `3px solid ${time.color}`,
														width: "20px",
														height: "20px",
													}}
												></Box>
												{time.label}
											</Box>
										))}
									</div>
								)}
								{key === "Video Drone" && timesVideoDroneOpen && (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											padding: "10px",
											gap: "5px",
											backgroundColor: "#E3E3E3",
										}}
									>
										{times.map((time, index) => (
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													gap: "10px",
													alignItems: "center",
													fontSize: "12px",
													lineHeight: "10px",
													fontWeight: 400,
													fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
													marginRight: index === times.length - 1 ? "0" : "10px",
												}}
												key={index}
											>
												<Box
													sx={{
														backgroundColor: "#FFBDE1",
														borderRadius: "50%",
														border: `3px solid ${time.color}`,
														width: "20px",
														height: "20px",
													}}
												></Box>
												{time.label}
											</Box>
										))}
									</div>
								)}
							</Fragment>
						)
				)}
			</Box>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					fontSize: "15px",
					fontWeight: "400",
					gap: "5px",
					cursor: "pointer",
					padding: "10px 16px",
					backgroundColor: "white",
					borderRadius: open || hover ? "0 0 10px 10px" : "10px",
					display: open ? "none" : "flex",
					"@media (maxWidth: 600px)": {
						borderRadius: open ? "0 0 10px 10px" : "10px",
					},
				}}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				onClick={() => setOpen((prev) => !prev)}
			>
				Referencia de Objetos
				{open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
			</div>
		</Box>
	);
};

export default Legend;
