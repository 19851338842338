import { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../../App";
import GeneralConsolidated from "./GeneralConsolidated";
import Prospection from "./Prospection";

const dummyData = {
	startDate: "2021-10-10",
	endDate: "2021-10-11",
	objectMap: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
	imageMap: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
	cultivationCenter: {
		coordinates: "-42.242281,-73.260814;-42.242236,-73.257783;-42.233233,-73.258033;-42.233278,-73.261061",
		code: "104110",
		name: "Bajo Chauques",
		cages: [
			{
				id: 13,
				coordinates: "-42.237750,-73.260409;-42.239697,-73.260409;-42.239586,-73.259314;-42.237639,-73.259314",
				name: "5",
				depth: 12.0,
				cultivationCenter: 1,
			},
			{
				id: 9,
				coordinates: "-42.233856,-73.260409;-42.235803,-73.260409;-42.235692,-73.259314;-42.233745,-73.259314",
				name: "1",
				depth: 42.0,
				cultivationCenter: 1,
			},
			{
				id: 12,
				coordinates:
					"-42.239697,-73.260409;-42.241644,-73.260409;-42.241533000000004,-73.25931449999999;-42.239586,-73.25931449999999",
				name: "7",
				depth: 12.5,
				cultivationCenter: 1,
			},
			{
				id: 7,
				coordinates: "-42.235803,-73.260409;-42.237750,-73.260409;-42.237639,-73.259314;-42.235692,-73.259314",
				name: "3",
				depth: 35.4,
				cultivationCenter: 1,
			},
			{
				id: 8,
				coordinates: "-42.235692,-73.259314;-42.237639,-73.259314;-42.237528,-73.258220;-42.235581,-73.258220",
				name: "4",
				depth: 3.5,
				cultivationCenter: 1,
			},
			{
				id: 10,
				coordinates: "-42.239586,-73.259314;-42.241533,-73.259314;-42.241422,-73.258220;-42.239475,-73.258220",
				name: "8",
				depth: 5.0,
				cultivationCenter: 1,
			},
			{
				id: 6,
				coordinates: "-42.233745,-73.259314;-42.235692,-73.259314;-42.235581,-73.258220;-42.233634,-73.258220",
				name: "2",
				depth: 66.2,
				cultivationCenter: 1,
			},
			{
				id: 11,
				coordinates:
					"-42.237639,-73.25931449999999;-42.239586,-73.25931449999999;-42.239475,-73.25822;-42.237528000000005,-73.25822",
				name: "J 11",
				depth: 20.0,
				cultivationCenter: 1,
			},
		],
		polygons: [
			{
				id: 1,
				name: "asddsa",
				description: "dsasdadas",
				coordinates: "1.000000,2.000000;3.000000,4.000000;3.000000,6.000000",
				cultivationCenter: 1,
			},
		],
	},
	place: "Canal Hudson, al norte de Punta Pindo, Isla Chelin",
	wreckRescueCompany: {
		id: 1,
		name: "Vortex",
		logo: "https://picsum.photos/200/150",
	},
	salmonCompany: {
		logo: "https://picsum.photos/200/151",
		name: "AcuaMarinos S.A.",
	},
	type: "RRN",
	// type: "PROSPECCION",
	comments: "Comentarios",
	objects: [
		{
			id: 2572,
			date: "2024-08-21",
			rescued: [],
			ammount: 1.0,
			state: "En Embarcación",
			latitude: -42.23432,
			longitude: -73.258581,
			volume: 90.0,
			submarineImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			inBoatImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			comments:
				"asdasd adsak djklsajkjdkaskjljdsjkkdaslkjdsal jklasdkjl dsakl; jkldsajk jkdsajkjkdasjkldsakjl;dklj;sajkdjklasjlkdsajlk;kjdjklsa jkdl;sa jkldjsajkjdksajdsa jdsjka kjldsakljdklsajkldjsaljdklsjakljjkjj  kldsja kld ksaljkj ldsakl jkj ksja kldj sakl;j dkslajklasj klsja kjl sajdslak;jkld;sajkl;d jsaklj dklsaj kl;jdsakljdklsajlkjl;kajsdkljsdakl;j klsdajkl;djsa l;kdjsa lk;jsdalkj",
			notify: false,
			type: {
				id: 1,
				name: "Contrapeso 90kg",
				description: "",
				weight: 90.0,
				type: "Cuantificable",
			},
		},
		{
			id: 258,
			date: "2024-08-21",
			rescued: [],
			ammount: 1.0,
			state: "Balizado",
			latitude: -42.24,
			longitude: -73.258581,
			volume: 90.0,
			submarineImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			inBoatImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			comments: "asdasd",
			notify: false,
			type: {
				id: 1,
				name: "Contrapeso 90kg",
				description: "",
				weight: 90.0,
				type: "Cuantificable",
			},
		},
	],
	geoImages: [
		{
			id: 9,
			image: "https://testrastrum.s3.amazonaws.com/media/images/DescribedImage_0cinyh0jwbbqsn.png",
			description: "sdfdsdsf",
			latitude: -42.238501,
			longitude: -73.260055,
		},
		{
			id: 12,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			description: "sdfdsdsf",
			latitude: -42.238701,
			longitude: -73.262055,
		},
		{
			id: 13,
			image: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			description: "sdfdsdsf",
			latitude: -42.238901,
			longitude: -73.264055,
		},
	],
	byQuantity: [
		{
			type__name: "Contrapeso 90kg",
			type__weight: 90.0,
			ratio: 2,
			count: 180,
		},
		{
			type__name: "Contrapeso 100kg",
			type__weight: 100.0,
			ratio: 1,
			count: 100,
		},
		{
			type__name: "Contrapeso 120kg",
			type__weight: 120.0,
			ratio: 3,
			count: 360,
		},
	],
	byMeasurable: [
		{
			type__name: "Contrapeso 90kg",
			type__weight: 90.0,
			ratio: 2,
			count: 180,
		},
		{
			type__name: "Contrapeso 100kg",
			type__weight: 100.0,
			ratio: 1,
			count: 100,
		},
		{
			type__name: "Contrapeso 120kg",
			type__weight: 120.0,
			ratio: 3,
			count: 360,
		},
	],
	reports: [
		{
			type: "Día normal",
			total: 12,
		},
		{
			type: "Prospección de fondo",
			total: 2,
		},
		{
			type: "Alimentación y bacterias",
			total: 1,
		},
	],
	additionalRows: [
		["Motivo 1", 1],
		["Motivo 2", 2],
		["Motivo 3", 3],
	],
};

const ConsolidatedReport = ({ data }) => {
	// const [reportData, setReportData] = useState(dummyData);
	const [reportData] = useState(data || JSON.parse(document.getElementById("reportData")?.textContent || "{}"));
	return (
		<ThemeProvider theme={theme}>
			{reportData?.type === "RRN" && <GeneralConsolidated reportData={reportData} />}
			{reportData?.type === "PROSPECCION" && <Prospection reportData={reportData} />}
		</ThemeProvider>
	);
};

export default ConsolidatedReport;
