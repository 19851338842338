import { Stepper as MUIStepper, Step, StepLabel } from "@mui/material";

import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { stepLabelClasses } from "@mui/material/StepLabel";
import { useStepContext } from "@mui/material/Step";
import { useRef, useState, useLayoutEffect } from "react";

const QontoConnector = styled(({ activeStep, ...props }) => <StepConnector {...props} />)(({ theme, activeStep }) => {
	const ctx = useStepContext();
	const sxNextToCurrent =
		ctx.index === activeStep + 1
			? { left: "calc(-50% + 10px)" }
			: ctx.index > activeStep
			? { left: "calc(-50% + 4px)" }
			: {};
	return {
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 10,
			left: "calc(-50% + 6px)",
			right: "calc(50% + 3px)",
			zIndex: 1,
			...sxNextToCurrent,
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				borderColor: theme.palette.brandColors.primary,
			},
			left: "calc(-50% + 8px)",
			right: "calc(50% + 9px)",
		},
		[`& .${stepConnectorClasses.line}`]: {
			borderTopWidth: 3,
			borderRadius: 1,
			borderColor: ctx.index <= activeStep ? theme.palette.brandColors.primary : "#B8B9BE",
		},
	};
});

const QontoStepIconRoot = styled("div")(({ theme }) => ({
	display: "flex",
	height: 22,
	alignItems: "center",
	color: "#848591",
	zIndex: 2,
	"& .QontoStepIcon-completedIcon": {
		color: "white",
		fontSize: 18,
		backgroundColor: theme.palette.brandColors.primary,
		borderRadius: "50%",
		width: 20,
		height: 20,
		padding: 2,
		marginTop: 1,
		border: `1px solid ${theme.palette.brandColors.primary}`,
	},
	"& .QontoStepIcon-circle": {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
		marginTop: 2,
	},
	"& .QontoStepIcon-circle-active": {
		color: theme.palette.brandColors.primary,
		border: `1px solid ${theme.palette.brandColors.primary}`,
		padding: "5px",
		borderRadius: "50%",
		marginTop: 2,
		["& .QontoStepIcon-circle"]: {
			marginTop: 0,
		},
	},
}));

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
	[`& .${stepLabelClasses.label}`]: {
		color: theme.palette.brandColors.primary,
		transition: "color 0.3s",
		marginTop: "10px",
		position: "relative",
		backgroundColor: "white",

		[`&.${stepLabelClasses.active}`]: {
			color: theme.palette.brandColors.primary,
		},
		[`&.${stepLabelClasses.completed}`]: {
			color: theme.palette.brandColors.primary,
		},
	},
	[`& .${stepLabelClasses.labelContainer}`]: {
		display: "flex",
		justifyContent: "center",
		position: "relative",
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;

	return (
		<QontoStepIconRoot className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className={active ? " QontoStepIcon-circle-active" : ""}>
					<div className={"QontoStepIcon-circle"} />
				</div>
			)}
		</QontoStepIconRoot>
	);
}

const StepperLabel = styled("div", {
	shouldForwardProp: (prop) => prop !== "leftPosition",
})(({ theme, leftPosition }) => ({
	position: "absolute",
	top: "35px",
	display: "flex",
	width: "max-content",
	left: `${leftPosition}px`,
	color: theme.palette.brandColors.primary,
	zIndex: 1,
	backgroundColor: "white",
	userSelect: "none",
	padding: "4px 8px",
	borderRadius: "15px",
	fontWeight: 700,
}));

const Stepper = ({ activeStep, steps }) => {
	const parentRef = useRef(null);
	const childRef = useRef(null);
	const labelRef = useRef(null);

	const [leftPosition, setLeftPosition] = useState(0);

	useLayoutEffect(() => {
		if (parentRef.current && childRef.current && labelRef.current) {
			const parentRect = parentRef.current.getBoundingClientRect();
			const childRect = childRef.current.getBoundingClientRect();
			const labelRect = labelRef.current.getBoundingClientRect();

			let left = childRect.left - parentRect.left + childRect.width / 2 - labelRect.width / 2;

			// Check if the child exceeds the parent's right boundary
			if (left + labelRect.width > parentRect.width) {
				left = parentRect.width - labelRect.width;
			}
			// Ensure the child does not move outside the left boundary
			// If it does, place it as far to the right as it can fit
			if (left < 0) {
				left = 0;
			}

			setLeftPosition(left);
		}
	}, [steps, activeStep, parentRef, childRef, labelRef.current]);

	return (
		<div style={{ position: "relative", minHeight: "70px" }} ref={parentRef}>
			{parentRef.current && childRef.current && (
				<StepperLabel leftPosition={leftPosition} ref={labelRef}>
					{steps[activeStep].label}
				</StepperLabel>
			)}
			<MUIStepper
				alternativeLabel
				activeStep={activeStep}
				sx={{ mt: "20px" }}
				//@ts-ignore
				connector={<QontoConnector activeStep={activeStep} />}
			>
				{steps.map((s, i) => (
					<Step key={i}>
						<CustomStepLabel
							StepIconComponent={QontoStepIcon}
							ref={i === activeStep ? childRef : null}
						></CustomStepLabel>
					</Step>
				))}
			</MUIStepper>
		</div>
	);
};

export default Stepper;
