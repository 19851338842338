import React, { useEffect } from "react";
import ReportGrid from "../components/Reports/ReportGrid";
import { ReportErrorProvider } from "../context";

const ReportPage = () => {
	useEffect(() => {
		document.title = "Rastrum | Reportes";
	}, []);

	return (
		<ReportErrorProvider>
			<ReportGrid />
		</ReportErrorProvider>
	);
};

export default ReportPage;
