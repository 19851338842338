import { Map } from "../../Map";
import { useContext, useEffect, useState } from "react";
import { ReportErrorContext } from "../../../context";
import { ReportDataContext } from "../../../context";
import { Box, Typography } from "@mui/material";
import { findCentralPoint, generateStructurePointsFromCentros } from "../../../utils/map_helper";
import { MapContainerStyle } from "./StatesConstants";

/**
 * Componente que muestra el mapa con los objetos y el centro de cultivo, para tomar una captura de pantalla y agregarla al informe
 */
const MapVisualizer = () => {
	const { setCheckErrors } = useContext(ReportErrorContext);
	const { reportData, setReportData, databaseIds } = useContext(ReportDataContext);
	const [parsedObjects, setParsedObjects] = useState([]); // Lista de objetos parseados para el mapa

	useEffect(() => {
		// Al cargar el componente se asume que ya se creó/editó el resto del informe, por lo que ahora solo se editarán los objetos (si vuelve hacia atrás)
		// y el mapa
		setReportData((prev) => ({ ...prev, isEdit: true, isMap: true }));
	}, [setReportData]);

	useEffect(() => {
		setParsedObjects(
			reportData.objectsList.map((object, index) => {
				return {
					type: "Feature",
					properties: {
						id: object.database_id !== -1 ? object.database_id : object.id,
						name: object.type.name,
						comments: object.comments,
						volume: object.volume,
						type: object.type,
						state: object.beacon ? "Balizado" : "En embarcación",
						data_type: "Objeto",
						color: object.beacon ? "red" : "rgba(4, 208, 0)",
					},
					geometry: {
						type: "Point",
						coordinates: { lat: parseFloat(object.latitude), lon: parseFloat(object.longitude) },
					},
				};
			})
		);
	}, [reportData.objectsList, setReportData]);

	const centro = generateStructurePointsFromCentros([reportData.cultivationCenter]);
	const centroString = reportData.cultivationCenter.coordinates;
	const CentralPoint = findCentralPoint(centroString);

	const data = {
		objects: {
			type: "FeatureCollection",
			features: parsedObjects,
		},
		structures: centro,
	};

	useEffect(() => {
		setCheckErrors(() => {
			return () => reportData.map === null;
		});
	}, [reportData.map, setCheckErrors]);

	useEffect(() => {
		setReportData((prev) => ({
			...prev,
			objectsList: prev.objectsList.map((object) => {
				if (databaseIds[object.id]) {
					object.database_id = databaseIds[object.id];
				}
				return object;
			}),
		}));
	}, [databaseIds, setReportData]);

	return (
		<>
			<div style={{ fontWeight: 500, marginBottom: "20px", display: "flex", justifyContent: "center" }}>
				Ajusta el mapa a tu gusto y captura la pantalla para agregar un mapa con los objectos al informe
			</div>
			<Box
				sx={{
					height: "auto",
					width: "100%",
					marginBottom: "10px",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Box
					sx={{
						...MapContainerStyle,
						border: "1px solid black",
					}}
				>
					<Map
						data={data}
						key={reportData.objectsList.map((object) => object.database_id).join("")}
						initial_state={{
							initial_is_clustered: false,
							initial_cluster_radius: 0,
							initial_zoom: 14,
							initial_center: CentralPoint,
						}}
						loading={false}
						title={reportData.cultivationCenter.name}
						setMapImage={(image) => {
							setReportData((prev) => {
								prev.map && URL.revokeObjectURL(prev.map);
								return { ...prev, map: new File([image], "mapa.png") };
							});
						}}
					/>
				</Box>
			</Box>

			{reportData.map && (
				<>
					<div style={{ fontWeight: 500, margin: "20px", display: "flex", justifyContent: "center" }}>
						Imagen del mapa
					</div>
					<Box
						sx={{
							justifyContent: "center",
							display: "flex",
							width: "100%",
						}}
					>
						<img
							src={
								typeof reportData.map === "object"
									? URL.createObjectURL(reportData.map)
									: reportData.map
							}
							alt="mapa"
							style={{
								...MapContainerStyle,
								border: "1px solid black",
							}}
						/>
					</Box>
				</>
			)}
		</>
	);
};

export default MapVisualizer;
