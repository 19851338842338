// mui components
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

// mui icons
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";

// components
import { ROVOperatorDetail, ROVOperatorDialogForm } from ".";
import GeneralButton from "../../GeneralButton";
import UserIcon from "../../Icons/UserIcon";
import { Table } from "../../Table";
import { useEffect, useState } from "react";

/**
 * Componente que muestra la lista de operadores
 * @param {Object} props
 * @param {Function} props.openDetail Función para abrir el detalle del operador
 * @param {Boolean} props.dataReload Indica si los datos deben ser recargados
 * @param {Function} props.setDataReload Función para indicar que los datos deben ser recargados
 */
export const ROVOperatorList = ({ openDetail, dataReload, setDataReload }) => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const columns = [
		{
			field: "first_name",
			headerName: "Nombre",
			renderCell: (row) => row.user.first_name,
			valueGetter: (row) => row.user.first_name,
			sortable: true,
		},
		{
			field: "last_name",
			headerName: "Apellido",
			renderCell: (row) => row.user.last_name,
			valueGetter: (row) => row.user.last_name,
			sortable: true,
		},
		{
			field: "email",
			headerName: "Correo",
			renderCell: (row) => row.user.email,
			valueGetter: (row) => row.user.email,
			sortable: true,
		},
		{
			field: "is_active",
			headerName: "Activo",
			renderCell: (row) => {
				return row.user.is_active === true ? <CheckIcon /> : <CloseIcon />;
			},
			valueGetter: (row) => {
				return row.user.is_active;
			},
			sortable: true,
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<GeneralButton
						variant="small"
						text="Ver operador"
						onClick={() => openDetail({ id: row.id, data: row })}
					/>
				</div>
			),
			sortable: true,
		},
	];

	useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(URLS.OPERATORS)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al cargar los operadores", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [axiosInstance, addMessage, dataReload, setDataReload]);

	return <Table rows={rows} columns={columns} loading={loading} />;
};

/**
 * Componente que enlista los operadores, permite agregar nuevos operadores y ver su detalle
 */
const ROVOperators = ({ setHide }) => {
	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const [wreckRescueCompany, setWreckRescueCompany] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axiosInstance
			.get(URLS.WRECK_RESCUE_COMPANY)
			.then((response) => {
				setWreckRescueCompany(response.data[0]);
			})
			.catch((error) => {
				addMessage("Error al cargar la compañía del usuario actual", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [axiosInstance, addMessage]);

	const [detailDisplay, setDetailDisplay] = useState({
		open: false,
		id: null,
		data: null,
	});

	const [dialog, setDialog] = useState({
		open: false,
		id: null,
		data: null,
	});

	const [dataReload, setDataReload] = useState(true);

	const openDetail = ({ id, data }) => {
		setDetailDisplay({
			open: true,
			id: id,
			data: data,
		});
	};

	const exitDetailDisplay = () => {
		setDetailDisplay({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};

	const openDialog = () => {
		setDialog({
			open: true,
			id: null,
			data: null,
		});
	};

	const exitDialog = () => {
		setDialog({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};

	useEffect(() => {
		setHide(detailDisplay.open || dialog.open);
	}, [detailDisplay.open, dialog.open, setHide]);

	return (
		<>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 1,
						bgcolor: "rgba(255, 255, 255, 0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			{detailDisplay.open ? (
				<>
					<ROVOperatorDetail onClose={exitDetailDisplay} data={detailDisplay.data} />
				</>
			) : (
				<>
					<div style={{ width: "100%", position: "relative" }}>
						<div style={{ position: "absolute", top: "-60px", left: "20px" }}>
							<GeneralButton
								onClick={openDialog}
								text="Agregar operador"
								icon={<UserIcon color="white" />}
							/>
						</div>
					</div>

					<ROVOperatorList openDetail={openDetail} dataReload={dataReload} setDataReload={setDataReload} />

					{wreckRescueCompany?.id && (
						<ROVOperatorDialogForm
							open={dialog.open}
							onClose={exitDialog}
							data={dialog.data}
							companyId={wreckRescueCompany.id}
						/>
					)}
				</>
			)}
		</>
	);
};

export default ROVOperators;
