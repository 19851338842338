// mui components
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";

// components
import { ROVOperatorDialogForm, PermissionsList } from ".";
import UserIcon from "../../Icons/UserIcon";
import IconButtons from "../../Icons/IconButtons";
import EditIcon from "../../Icons/EditIcon";
import { useCallback, useEffect, useState } from "react";

const StackDivider = () => {
	const theme = useTheme();
	return (
		<Box
			component="hr"
			sx={{
				borderColor: theme.palette.brandColors.lightGray,
				borderStyle: "dashed",
				borderWidth: "thin",
				borderRadius: 1,
			}}
		/>
	);
};

/**
 * Componente para mostrar el detalle de un operador ROV y editar sus datos
 * @param {Object} props
 * @param {Function} props.onClose Función para cerrar el componente
 * @param {Object} props.data Datos del operador
 */
const ROVOperatorDetail = ({ onClose, data }) => {
	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();
	const theme = useTheme();

	const [objectDetails, setObjectDetails] = useState({
		id: data?.id,
		user: {
			username: data?.user?.username,
			first_name: data?.user?.first_name,
			last_name: data?.user?.last_name,
			rut: data?.user?.rut,
			email: data?.user?.email,
			phone: data?.user?.phone,
			other_contact_info: data?.user?.other_contact_info,
			is_active: data?.user?.is_active,
			permissions: data?.user?.permissions,
		},
		wreckRescueCompany: data?.wreckRescueCompany,
		permissions: [],
		canUseMapTools: data?.canUseMapTools,
	});

	const [loading, setLoading] = useState(true);

	const requestData = useCallback(() => {
		axiosInstance
			.get(`${URLS.OPERATORS}${data.id}/permissions/`)
			.then((response) => {
				setObjectDetails(response.data);
			})
			.catch((error) => {
				addMessage("Error al cargar los datos del operador", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [axiosInstance, addMessage, data?.id]);

	useEffect(() => {
		if (data?.id) {
			requestData();
		}
	}, [data?.id, requestData]);

	const [dialogData, setDialogData] = useState({
		open: false,
		id: undefined,
		data: {},
	});

	const editOperator = () => {
		setDialogData({
			open: true,
			id: data.id,
			data: {
				id: data.id,
				user: { ...objectDetails.user },
				wreckRescueCompany: objectDetails.wreckRescueCompany,
			},
		});
	};

	const closeDialog = () => {
		setDialogData({
			open: false,
			id: undefined,
			data: {},
		});

		requestData();
	};

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			<ROVOperatorDialogForm
				open={dialogData.open}
				onClose={closeDialog}
				data={dialogData.data}
				companyId={objectDetails.wreckRescueCompany?.id || objectDetails.wreckRescueCompany}
			/>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					padding: "0 34px 10px",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<UserIcon />
					<div
						style={{
							display: "inline-block",
							marginLeft: 10,
							color: theme.palette.brandColors.primary,
							fontSize: "16px",
							fontWeight: 700,
						}}
					>
						{objectDetails.user.first_name} {objectDetails.user.last_name}
					</div>
				</div>

				<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
					<IconButtons icon={<EditIcon />} onClick={editOperator} />
					<div
						style={{
							cursor: "pointer",
							textDecoration: "underline",
							fontWeight: 700,
							color: theme.palette.brandColors.primary,
							fontSize: "13px",
						}}
						onClick={onClose}
					>
						Volver al listado
					</div>
				</div>
			</Box>

			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 1,
						bgcolor: "rgba(255, 255, 255, 0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<div
				style={{
					display: "flex",
					alignItems: "center",
					padding: "15px 34px",
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<div style={{ color: "#212121", fontSize: "14px", fontWeight: 400, width: "100%" }}>
					Nombre completo: {objectDetails.user.first_name} {objectDetails.user.last_name}
				</div>
				<div style={{ color: "#212121", fontSize: "14px", fontWeight: 400, width: "100%" }}>
					RUT: {objectDetails.user.rut || "No especificado"}
				</div>
				<div style={{ color: "#212121", fontSize: "14px", fontWeight: 400, width: "100%" }}>
					Activo: {objectDetails.user.is_active ? "Sí" : "No"}
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					padding: "15px 34px",
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
					borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<div style={{ color: "#212121", fontSize: "14px", fontWeight: 400, width: "100%" }}>
					Nombre de usuario: {objectDetails.user.username}
				</div>
				<div style={{ color: "#212121", fontSize: "14px", fontWeight: 400, width: "100%" }}>
					Correo: {objectDetails.user.email || "No especificado"}
				</div>
				<div style={{ color: "#212121", fontSize: "14px", fontWeight: 400, width: "100%" }}>
					Teléfono: {objectDetails.user.phone || "No especificado"}
				</div>
			</div>

			<div style={{ padding: "15px 34px", fontWeight: 700, fontSize: "15px" }}>Permisos</div>
			<PermissionsList reloadData={requestData} data={objectDetails} loading={loading} setLoading={setLoading} />
		</Box>
	);
};

export default ROVOperatorDetail;
