import { ContextImage, GeneralInfo, ImageContainer, ImagesContainer, ObjectID, Pair } from "./Common";

const GeoImages = ({ images }) => {
	return (
		<GeneralInfo style={{ marginTop: "20px" }}>
			<ImagesContainer>
				{images?.map((img) => (
					<ImageContainer>
						<ContextImage
							src={typeof img.image === "string" ? img.image : URL.createObjectURL(img.image)}
							onLoad={(e) => URL.revokeObjectURL(e.target.src)}
						/>
						<div style={{ width: "50%" }}>
							<ObjectID color={"#48beff"}>ID: {img.id}</ObjectID>
							<div style={{ display: "flex", flexDirection: "column", gap: "2px", marginTop: "20px" }}>
								<Pair title="Latitud" value={img.latitude} />
								<Pair title="Longitud" value={img.longitude} />
								<Pair title="Descripción" value={img.description} />
							</div>
						</div>
					</ImageContainer>
				))}
			</ImagesContainer>

			{images?.length === 0 && (
				<div
					style={{
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						fontSize: "20px",
						width: "100%",
					}}
				>
					No se han subido imágenes para este informe
				</div>
			)}
		</GeneralInfo>
	);
};

export default GeoImages;
