import React, { Fragment, useMemo } from "react";
import { ReportDataContext, AuthContext } from "../../context";
import { useContext, useState, useEffect } from "react";
import OfflineReport from "./Offline/OfflineReport";


/**
 *
 * @param {boolean} grid es true cuando se esta moostrando el preview en ReportGrid
 * @returns
 */
export default function ReportPreview({ grid }) {
	const { reportData } = useContext(ReportDataContext);

	return (
		<div style={{ width: "100%", height: "100%", overflow: "auto", display: "flex", flexDirection: "column" }}>
			<OfflineReport
				data={{
					salmonCompany: reportData.cultivationCenter.salmonCompany,
					cultivationCenter: { ...reportData.cultivationCenter, name: reportData.cultivationCenter.label },
					place: reportData.cultivationCenter.place?.name || reportData.cultivationCenter.place,
					wreckRescueCompany: null,
					date: reportData.date,
					boat: reportData.boat.name,
					comments: reportData.comments,
					type: reportData.reportType.label,
					objects: reportData.objectsList,
					geoImages: reportData.geoImagesList,
					images: reportData.imagesList,
					ROVOperator: reportData.creator || reportData.ROVOperator,
					feedingAndBacteriumImages: reportData.feedingBacteriumImages,
					endDate: reportData.endDate,
					rescuedObjects: reportData.beaconObjects.map((obj) => ({
						...obj.castawayRest,
						date: obj.castawayRest?.report?.date,
					})),
				}}
			/>
		</div>
	);
}
