import { estado_color_map } from "../../../utils/map_helper";
import {
	GeneralInfo,
	LatLon,
	Line,
	ObjectDescription,
	ObjectID,
	ObjectIDContainer,
	ObjectImage,
	ObjectInfo,
	ObjectsImagesContainer,
	Pair,
} from "./Common";

const Objects = ({ objects, summary, addDescription, justPhoto }) => {
	return (
		<>
			{objects?.length > 0 && (
				<>
					{summary}
					<GeneralInfo>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							{objects?.map((obj) => (
								<div style={{ padding: "0 25px", width: "100%", boxSizing: "border-box" }}>
									<ObjectIDContainer>
										<Line />
										<ObjectID color={estado_color_map[obj.state]}>ID: {obj.id}</ObjectID>
										<Line />
									</ObjectIDContainer>
									{!justPhoto && <LatLon obj={obj} />}

									<ObjectsImagesContainer>
										<div style={{ width: "100%" }}>
											<ObjectDescription>Imagen fondo</ObjectDescription>
											<ObjectImage
												src={
													typeof obj.submarineImage === "string"
														? obj.submarineImage
														: URL.createObjectURL(obj.submarineImage)
												}
												onLoad={(e) => URL.revokeObjectURL(e.target.src)}
											/>
										</div>
										<div style={{ textAlign: "right", width: "100%" }}>
											<ObjectDescription>Imagen superficie</ObjectDescription>
											<ObjectImage
												src={
													(typeof obj.inBoatImage === "string" || !obj.inBoatImage)
														? obj.inBoatImage
														: URL.createObjectURL(obj.inBoatImage)
												}
												align="right"
												onLoad={(e) => URL.revokeObjectURL(e.target.src)}
											/>
										</div>
									</ObjectsImagesContainer>

									{!justPhoto && (
										<>
											<ObjectInfo comments={obj.comments}>
												<Pair title="Tipo de Objeto" value={obj.type.name} />
												<div>|</div>
												<Pair title="Volumen" value={obj.volume} />
												<div>|</div>
												<Pair title="Importante" value={obj.notify ? "Si" : "No"} />
												<div>|</div>
												<Pair title="Balizado" value={obj.state === "Balizado" ? "Si" : "No"} />
												{addDescription && addDescription(obj)}
											</ObjectInfo>

											{obj.comments && (
												<div
													style={{
														display: "flex",
														gap: "10px",
														justifyContent: "center",
														margin: "20px 0",
													}}
												>
													<Pair title="Comentario" value={obj.comments} />
												</div>
											)}
										</>
									)}
								</div>
							))}
						</div>
					</GeneralInfo>
				</>
			)}
		</>
	);
};

export default Objects;
