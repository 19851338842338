/**
 * @fileoverview Componente para abstraer las páginas que necesitan de un usuario
 * autenticado para ser accedidas. Este componente se encarga de verificar si el
 * usuario está autenticado y si no lo está, redirecciona a la página de login.
 */

import Typography from "@mui/material/Typography";

import { useAuthContext } from "../context";

/**
 * Se debe asignar una key a cada PrivateRoute que se renderiza. Utilizando optimizaciones de React,
 * esta verificación no se realiza cada vez que se renderiza el componente, sino que se realiza
 * solamente cuando la key cambia.
 */
function PrivateRoute({ component, userTypes = ["all"] }) {
	const { isAuthenticated, USER_TYPE } = useAuthContext();

	if (isAuthenticated) {
		if (userTypes.includes("all") || userTypes.includes(USER_TYPE)) {
			return <>{component}</>;
		}

		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
					gap: "10px",
				}}
			>
				<Typography variant="h5" color="textSecondary">
					No tiene permisos para acceder a esta página
				</Typography>
			</div>
		);
	}
}

export default PrivateRoute;
