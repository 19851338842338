import React, { useContext, useEffect } from "react";
import { Box, TextField, useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { ReportDataContext, ReportErrorContext, MessagesContext } from "../../../context";
import IconButtons from "../../Icons/IconButtons";
import DeleteIcon from "../../Icons/DeleteIcon";

/**
 * Componente para la subida de imágenes con descripciones
 * @param atLeastOneImage: booleano que indica si se requiere al menos una imagen
 */
export default function MultipleDropZone({ atLeastOneImage = false }) {
	const { reportData, setReportData } = useContext(ReportDataContext); // files = [{file: File, comment: String}]
	const { setCheckErrors } = useContext(ReportErrorContext);
	const { addMessage } = useContext(MessagesContext);
    const theme = useTheme();

	useEffect(() => {
		// si se requiere al menos una imagen, se agrega la validación
		if (atLeastOneImage) {
			setCheckErrors(() => () => reportData.imagesList.length === 0);
		}
	}, [reportData, setCheckErrors, atLeastOneImage]);

	const maxImages = 100;
	const maxSize = 100 * 1024 * 1024; // 100 MB

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".jpg", ".png"],
		},
		maxFiles: maxImages,
		onDrop: (acceptedFiles) => {
			const updatedFiles = acceptedFiles.map((file) => {
				if (file.size > maxSize) {
					addMessage(
						`La imagen ${file.name} excede el tamaño máximo permitido de ${maxSize / 1024 / 1024}MB`,
						"error"
					);
					return null;
				} else {
					return {
						image: Object.assign(file, { preview: URL.createObjectURL(file) }),
						description: "",
					};
				}
			});

			//no se toman los que son nulos (los que excedieron el tamaño)
			const validFiles = updatedFiles.filter((file) => file !== null);
			setReportData({ ...reportData, imagesList: [...reportData.imagesList, ...validFiles] });
		},
	});

	const handleCommentChange = (index, comment) => {
		const updatedFiles = [...reportData.imagesList];
		updatedFiles[index].description = comment;
		setReportData({ ...reportData, imagesList: [...updatedFiles] });
	};

	return (
		<div style={{ padding: "20px 30px" }}>
			<div
				{...getRootProps({ className: "dropzone" })}
				style={{
					border: `4px dashed ${theme.palette.brandColors.gray}`,
					borderRadius: "10px",
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "200px",
					position: "relative",
				}}
			>
				<input {...getInputProps()} />
				<CloudUploadOutlinedIcon style={{ color: "grey", fontSize: "3rem" }} />
				<p style={{ color: "grey", textAlign: "center", margin: "0px 0" }}>
					<strong>Arrastra las imágenes aquí, o click para seleccionar</strong>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>{reportData.imagesList.length} agregada(s)</em>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>máximo {maxImages}</em>
				</p>
			</div>

			<div>
				{reportData.imagesList.map((item, index) => (
					<Box
						key={index}
						sx={{
							height: "auto",
							display: "flex",
							padding: "20px 0",
							gap: "20px",
						}}
					>
						{/* <IconButton
							style={{
								margin: "10px",
								position: "absolute",
								top: 0,
								right: 0,
								backgroundColor: "white",
								boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
							}}
							onClick={() => {
								// elimina la imagen de la lista
								// si la imagen ya estaba en la db, tambien se agrega a la lista de eliminados
								if (item.id) {
									setReportData({
										...reportData,
										imagesDeleted: [...reportData.imagesDeleted, item],
										imagesList: reportData.imagesList.filter((_, i) => i !== index),
									});
								} else {
									setReportData({
										...reportData,
										imagesList: reportData.imagesList.filter((_, i) => i !== index),
									});
								}
							}}
						>
							<CloseIcon />
						</IconButton> */}
						<img
							src={typeof item.image === "object" ? item.image.preview : item.image}
							alt="Error con la imagen"
							// Data uri is revoked when submited/exit
							style={{
								display: "block",
								height: "100%",
								width: "auto",
								maxHeight: "150px",
								maxWidth: "100%",
								borderRadius: "10px",
							}}
						/>
						<div style={{ width: "100%", display: "flex", gap: "15px" }}>
							<TextField
								label="Añadir un comentario (opcional)..."
								variant="outlined"
								multiline
								rows={5}
								fullWidth
								style={{ flex: 1 }}
								value={item.description}
								onChange={(e) => handleCommentChange(index, e.target.value)}
							/>
							<IconButtons
								icon={<DeleteIcon />}
								variant="danger"
								onClick={() => {
									// elimina la imagen de la lista
									// si la imagen ya estaba en la db, tambien se agrega a la lista de eliminados
									if (item.id) {
										setReportData({
											...reportData,
											imagesDeleted: [...reportData.imagesDeleted, item],
											imagesList: reportData.imagesList.filter((_, i) => i !== index),
										});
									} else {
										setReportData({
											...reportData,
											imagesList: reportData.imagesList.filter((_, i) => i !== index),
										});
									}
								}}
							/>
						</div>
					</Box>
				))}
			</div>
		</div>
	);
}
