import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const GeneralButton = ({ text, icon, onClick, sx, variant, disabled = false, type}) => {
	const theme = useTheme();

	return (
		<Button
			type={type}
			sx={{
				backgroundColor: theme.palette.brandColors.primary,
				color: "white",
				borderColor: "white",
				padding: "10px 20px",
				borderRadius: "10px",
				boxSizing: "border-box",
				lineHeight: "unset",
				fontSize: "15px",
				display: "flex",
				alignItems: "center",
				gap: "7px",
				"&:hover": {
					backgroundColor: theme.palette.brandColors.primary,
					color: "white",
				},
				"&:disabled": {
					backgroundColor: theme.palette.brandColors.primary,
					color: "white",
					opacity: 0.5,
				},
				...(variant === "small" && {
					padding: "7px 15px",
					minWidth: "unset",
					fontSize: "12px",
					fontSize: "12px",
					borderRadius: "15px",
					textTransform: "unset",
				}),
				...(variant === "empty" && {
					backgroundColor: "transparent",
					color: theme.palette.brandColors.primary,
					border: `1px solid ${theme.palette.brandColors.primary}`,
					padding: "8px 20px",
					"&:hover": {
						backgroundColor: "transparent",
						color: theme.palette.brandColors.primary,
					},
				}),
				...sx,
			}}
			onClick={onClick}
			disabled={disabled}
		>
			{icon}
			<div>{text}</div>
		</Button>
	);
};

export default GeneralButton;
