import { useState, useEffect } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	TextField,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Checkbox,
	FormControlLabel,
	Grid,
} from "@mui/material";
import { useAuthContext, useMessagesContext } from "../../../context";
import { URLS } from "../../../global";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";
import IconButtons from "../../Icons/IconButtons";
import EditIcon from "../../Icons/EditIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import TextInput from "../../Inputs/TextInput";

const EmailRecipientForm = ({ onFinished = () => {}, data = undefined }) => {
	const [currentData, setCurrentData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);
	const theme = useTheme();

	const { axiosInstance, USER_TYPE } = useAuthContext();
	const { addMessage } = useMessagesContext();

	useEffect(() => {
		if (data) {
			setCurrentData(data);
		} else {
			setCurrentData({
				email: "",
				companyId: data?.companyId,
				canReceiveRRNNormalDay: false,
				canReceiveRRNProspection: false,
				canReceiveRRNClosedPort: false,
				canReceiveRRNBadWeather: false,
				canReceiveRRNFueling: false,
				canReceiveRRNROVFlaw: false,
				canReceiveRRNBoatFlaw: false,
				canReceiveRRNTakeover: false,
				canReceiveRRNMaintenance: false,
				canReceiveRRNBoatReview: false,
				canReceiveRRNCastawayRestDelivery: false,
				canReceiveRRNSailing: false,
				canReceiveRRNFeedingAndBacterium: false,
				canReceiveRRNConsolidated: false,
				canReceiveSBProspection: false,
				canReceiveReceptionNote: false,
			});
		}

		setLoading(false);
	}, [data]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		currentData.salmonCompany = currentData.companyId;

		let config = {
			method: "POST",
			url: URLS.EMAIL_RECIPIENTS,
			data: currentData,
		};
		let message = "Correo destinatario creado exitosamente";
		let error_message = "Error al crear el correo destinatario";

		if (data?.id) {
			// Editar
			config.method = "PATCH";
			config.url += `${data.id}/`;
			config.data.id = data.id;
			// config.data.append("id", data.id);
			message = "Correo destinatario editado exitosamente";
			error_message = "Correo destinatario no pudo ser editado";
		}

		// enviar request
		setLoading(true);
		axiosInstance(config)
			.then((_res) => {
				addMessage(message, "success");
				onFinished();
			})
			.catch((_err) => {
				addMessage(error_message, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onChange = (e) => {
		setCurrentData({
			...currentData,
			[e.target.name]: e.target.value,
		});
	};

	const handleDelete = () => {
		axiosInstance
			.delete(`${URLS.EMAIL_RECIPIENTS}${data.id}/`)
			.then((res) => {
				addMessage("Destinatario de Correo eliminado exitosamente", "success");
				onFinished();
			})
			.catch((err) => {
				addMessage("Error al elimnar destinatario de correo", "error");
			});
	};

	const onCheckChange = (e) => {
		setCurrentData({
			...currentData,
			[e.target.name]: e.target.checked,
		});
	};

	const checkList = [
		{
			name: "canReceiveRRNNormalDay",
			label: "Día Normal",
		},
		{
			name: "canReceiveRRNProspection",
			label: "Prospección",
		},
		{
			name: "canReceiveRRNClosedPort",
			label: "Puerto Cerrado",
		},
		{
			name: "canReceiveRRNBadWeather",
			label: "Mal clima",
		},
		{
			name: "canReceiveRRNFueling",
			label: "Carga de combustible",
		},
		{
			name: "canReceiveRRNROVFlaw",
			label: "Falla de equipo ROV",
		},
		{
			name: "canReceiveRRNBoatFlaw",
			label: "Falla Embarcación",
		},
		{
			name: "canReceiveRRNTakeover",
			label: "Relevo",
		},
		{
			name: "canReceiveRRNMaintenance",
			label: "Mantenimiento de embarcación",
		},
		{
			name: "canReceiveRRNBoatReview",
			label: "Revisión Embarcación",
		},
		{
			name: "canReceiveRRNCastawayRestDelivery",
			label: "Entrega de restos náufragos",
		},
		{
			name: "canReceiveRRNSailing",
			label: "Navegación",
		},
		{
			name: "canReceiveRRNFeedingAndBacterium",
			label: "Alimentación y Bacterias",
		},
	];

	return (
		<form
			onSubmit={handleSubmit}
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "10px",
				padding: "20px 30px",
			}}
		>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}
			<Stack direction="row" spacing={2}>
				<TextInput
					fullWidth
					label="Correo"
					InputLabelProps={{ shrink: true }}
					variant="outlined"
					name="email"
					value={currentData.email || ""}
					onChange={onChange}
					error={errors.email}
					helperText={errors.email}
					required
				/>
			</Stack>

			<Accordion
				sx={{
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Informes Diarios</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						{checkList.map((check, index) => (
							<Grid item xs={4} key={index}>
								<FormControlLabel
									control={
										<Checkbox
											name={check.name}
											checked={currentData[check.name] || false}
											onChange={onCheckChange}
										/>
									}
									label={check.label}
								/>
							</Grid>
						))}
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion
				sx={{
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Notas de Recepción</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										name="canReceiveReceptionNote"
										checked={currentData.canReceiveReceptionNote || false}
										onChange={onCheckChange}
									/>
								}
								label="Notas de Recepción"
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion
				sx={{
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Consolidados</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						<Grid item xs={6}>
							<FormControlLabel
								control={
									<Checkbox
										name="canReceiveRRNConsolidated"
										checked={currentData.canReceiveRRNConsolidated || false}
										onChange={onCheckChange}
									/>
								}
								label="General"
							/>
						</Grid>

						<Grid item xs={6}>
							<FormControlLabel
								control={
									<Checkbox
										name="canReceiveSBProspection"
										checked={currentData.canReceiveSBProspection || false}
										onChange={onCheckChange}
									/>
								}
								label="Prospección"
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Box sx={{ display: "flex", justifyContent: "space-between" }} mt={2}>
				<Button variant="outlined" onClick={handleDelete} color="error" disabled={loading}>
					Eliminar
				</Button>
				<Stack direction="row" spacing={2} justifyContent="end" flexGrow={1}>
					<GeneralButton text="Cancelar" variant="empty" onClick={onFinished} disabled={loading} />

					<GeneralButton text="Guardar" onClick={handleSubmit} disabled={loading} />
				</Stack>
			</Box>
		</form>
	);
};

const EmailRecipientFormDetail = ({ data, onClose, companyId }) => {
	const theme = useTheme();
	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
					padding: "15px 25px",
				}}
			>
				<div
					style={{
						color: "#212121",
						fontSize: "15px",
						fontWeight: 500,
					}}
				>
					{data?.id ? "Editar correo" : "Agregar correo"}
				</div>

				<div
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						fontWeight: 700,
						color: theme.palette.brandColors.primary,
						fontSize: "13px",
					}}
					onClick={onClose}
				>
					Volver al listado
				</div>
			</Box>

			{data && <EmailRecipientForm data={data} onFinished={onClose} companyId={companyId} />}
		</Box>
	);
};

const EmailRecipientList = ({ companyId, openDetail, dataReload, setDataReload }) => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(`${URLS.SALMON_COMPANY}${companyId}/email_recipients/`)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al cargar los datos", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [companyId, axiosInstance, addMessage, dataReload, setDataReload]);

	const deleteEmailRecipient = (id) => {
		axiosInstance
			.delete(`${URLS.EMAIL_RECIPIENTS}${id}/`)
			.then((res) => {
				addMessage("Destinatario de Correo eliminado exitosamente", "success");
				setDataReload(true);
			})
			.catch((err) => {
				addMessage("Error al elimnar destinatario de correo", "error");
			});
	};

	const columns = [
		{
			field: "id",
			headerName: "ID",
		},
		{
			field: "email",
			headerName: "Correo",
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
					<IconButtons icon={<EditIcon />} onClick={() => openDetail(row.id, row)} />
					<IconButtons variant="danger" icon={<DeleteIcon />} onClick={() => deleteEmailRecipient(row.id)} />
				</div>
			),
		},
	];

	return (
		<Box sx={{ height: 400, width: "100%" }}>
			<Table rows={rows} columns={columns} loading={loading} />
		</Box>
	);
};

const EmailRecipients = ({ companyId, setHide }) => {
	const [dataReload, setDataReload] = useState(true);
	const [detailData, setDetailData] = useState({
		open: false,
		data: null,
	});

	const openDetail = (_id, data = null) => {
		setDetailData({
			open: true,
			data: {
				companyId: companyId,
				...data,
			},
		});
	};

	const closeDetail = () => {
		setDetailData({ open: false, data: null });
		setDataReload(true);
	};

	useEffect(() => {
		setHide(detailData.open);
	}, [detailData.open, setHide]);

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			{detailData.open && (
				<EmailRecipientFormDetail data={detailData.data} onClose={closeDetail} companyId={companyId} />
			)}

			{!detailData.open && (
				<div style={{ padding: "35px 0" }}>
					<div style={{ padding: "0 34px 20px" }}>
						<GeneralButton onClick={openDetail} text="Agregar Destinatario" />
					</div>

					<EmailRecipientList
						companyId={companyId}
						openDetail={openDetail}
						dataReload={dataReload}
						setDataReload={setDataReload}
					/>
				</div>
			)}
		</Box>
	);
};

export default EmailRecipients;
