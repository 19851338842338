import { IconButton, Box, Grid, Switch, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";

const MultipleImages = ({ setImages, images, AddDeletedImage }) => {
	const maxImages = 100;
	const maxSize = 100 * 1024 * 1024; // 100 MB

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".jpg", ".png"],
		},
		maxFiles: maxImages,
		onDrop: (acceptedFiles) => {
			const updatedFiles = acceptedFiles.map((file) => {
				if (file.size > maxSize) {
					return null;
				} else {
					return {
						image: Object.assign(file, { preview: URL.createObjectURL(file) }),
						isHorizontal: false,
					};
				}
			});

			//no se toman los que son nulos (los que excedieron el tamaño)
			const validFiles = updatedFiles.filter((file) => file !== null);
			setImages([...images, ...validFiles]);
		},
	});
	return (
		<>
			<div
				{...getRootProps({ className: "dropzone" })}
				style={{
					border: "2px dashed grey",
					borderRadius: "10px",
					backgroundColor: "#f5f5f5",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "200px",
					position: "relative",
				}}
			>
				<input {...getInputProps()} />
				<CloudUploadOutlinedIcon style={{ color: "grey", fontSize: "3rem" }} />
				<p style={{ color: "grey", textAlign: "center", margin: "0px 0" }}>
					<strong>Arrastra las imágenes aquí, o click para seleccionar</strong>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>{images.length} agregada(s)</em>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>máximo {maxImages}</em>
				</p>
			</div>

			<div sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "20px", paddingBottom: "20px" }}>
				{images?.map((item, index) => (
					<Box
						key={index}
						sx={{
							border: "2px solid grey",
							maxWidth: "100%",
							height: "auto",
							// maxHeight: '250px',
							position: "relative",
							display: "flex",
							justifyContent: "center",
							padding: "10px",
							margin: "10px",
						}}
					>
						<Grid container spacing={2} alignItems="center">
							<IconButton
								style={{
									margin: "10px",
									position: "absolute",
									top: 0,
									right: 0,
									backgroundColor: "white",
									boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
								}}
								onClick={() => {
									if (item.id) {
										AddDeletedImage(item.id);
									}
									setImages(images.filter((_, i) => i !== index));
								}}
							>
								<CloseIcon />
							</IconButton>
							<Grid item xs={12} md={6} style={{ textAlign: "center" }}>
								<img
									src={typeof item.image === "object" ? item.image.preview : item.map}
									alt="Error con la imagen"
									// Data uri is revoked when submited/exit
									style={{ display: "block", margin: "0 auto", width: "auto", maxHeight: "180px", maxWidth: "100%" }}
								/>
								<Typography variant="caption" display="block" gutterBottom>
									{item?.image?.name}
								</Typography>
							</Grid>
							<Grid item xs={12} md={6} style={{ textAlign: "center" }}>
								<Typography variant="caption" display="block" gutterBottom>
									{item.isHorizontal ? "Horizontal" : "Vertical"}
								</Typography>
								<Switch
									checked={item.isHorizontal}
									onChange={() => {
										const newImages = images.map((img, i) => {
											if (i === index) {
												return { ...img, isHorizontal: !img.isHorizontal };
											}
											return img;
										});
										setImages(newImages);
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				))}
			</div>
		</>
	);
};

export default MultipleImages;
