import React, { useCallback, useMemo, useState } from "react";
import {
  useMap,
  useMapEvent,
  MapContainer,
  TileLayer,
  Rectangle,
} from "react-leaflet";
import { useEventHandlers } from "@react-leaflet/core";

// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

const BOUNDS_STYLE = { weight: 1 };

function MinimapBounds({ parentMap, zoomLevelOffset = -5 }) {
  const minimap = useMap();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), parentMap.getZoom() + zoomLevelOffset);
  }, [minimap, parentMap, zoomLevelOffset]);

  // Listen to events on the parent map
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);
  useEventHandlers({ instance: parentMap }, handlers);

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} preferCanvas={true} />;
}

function MinimapControl({ position, zoomLevelOffset = -5 }) {
  const parentMap = useMap();

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
		() => (
			<MapContainer
				style={{ height: 80, width: 80 }}
				center={parentMap.getCenter()}
				dragging={false}
				zoom={parentMap.getZoom() + zoomLevelOffset}
				doubleClickZoom={false}
				scrollWheelZoom={false}
				attributionControl={false}
				zoomControl={false}
				preferCanvas={true}
			>
				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" preferCanvas={true} />
				<MinimapBounds parentMap={parentMap} zoomLevelOffset={zoomLevelOffset} preferCanvas={true} />
			</MapContainer>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
  );

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  );
}

export default MinimapControl;
