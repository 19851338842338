import { styled } from "@mui/material/styles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Popup } from "react-leaflet";
import { memo, useEffect, useState } from "react";
import L, { popup } from "leaflet";

// leaflet components
import { MapContainer, TileLayer, GeoJSON, useMapEvents, ScaleControl, ZoomControl, Marker } from "react-leaflet";

export const TopFilters = styled("div")(() => ({
	position: "absolute",
	top: "10px",
	right: "100px",
	zIndex: 1000,
	fontSize: "15px",
	display: "flex",
	gap: "10px",
}));

export const BottomFilters = styled("div")(() => ({
	position: "absolute",
	top: "80px",
	right: "100px",
	zIndex: 990,
	fontSize: "15px",
	display: "flex",
	gap: "10px",
}));

export const DateFilterContainer = styled("div")(() => ({
	display: "flex",
	backgroundColor: "white",
	alignItems: "center",
	borderRadius: "10px",
	gap: "10px",
	padding: "5px 10px",
	boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
	"& input[type='date']::-webkit-calendar-picker-indicator": {
		filter: "invert(1)",
	},
}));

export const DateInput = styled("input")(({ theme }) => ({
	border: "none",
	backgroundColor: "transparent",
	fontFamily: "inherit",
	outline: "none",
	backgroundColor: theme.palette.brandColors.primary,
	color: "white",
	borderRadius: "12px",
	padding: "5px 10px",
}));

export const CenterFilter = styled("div")(() => ({
	display: "flex",
	gap: "10px",
	backgroundColor: "white",
	borderRadius: "12px",
	boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
}));

export const ObjectsFilter = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	gap: "10px",
	borderRadius: "12px",
	position: "relative",
	boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
	zIndex: 1001,
}));

export const ObjectsFilterTitle = styled("div")(({ isOpen }) => ({
	backgroundColor: "white",
	padding: "10px 16px",
	display: "flex",
	alignContent: "center",
	cursor: "pointer",
	zIndex: 1000,
	borderRadius: isOpen ? "12px 12px 0 0" : "12px",
	position: "relative",
}));

export const ObjectsFilterTitleText = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
}));

export const ObjectsFilterTitleIcon = styled("div")(() => ({
	position: "relative",
	top: "2px",
	right: "-4px",
}));

export const ObjectsFilterContent = styled("div")(({ isOpen, small }) => ({
	backgroundColor: small ? "#E3E3E3" : "#E8E8E8",
	position: small ? "static" : "absolute",
	top: "51px",
	zIndex: 1001,
	display: isOpen ? "flex" : "none",
	width: "100%",
	left: 0,
	flexDirection: "column",
	borderRadius: small ? "0" : "0 0 12px 12px",
	boxShadow: !small && "0px 0px 4px 0px rgba(33, 33, 33, 0.08)",
}));

export const FilterRow = styled("div")(({ small }) => ({
	padding: small ? "5px 16px" : "10px 16px",
	display: "flex",
	alignItems: "center",
	gap: "10px",
	justifyContent: "space-between",
	fontSize: small ? "12px" : "13px",
}));

export const FilterName = styled("div")(({ small }) => ({
	display: "flex",
	alignItems: "center",
	paddingRight: small ? "23px" : "0",
}));

export const FilterCheckbox = styled("input")(() => ({
	margin: 0,
}));

export const OpenIcon = ({ isOpen, setIsOpen }) => {
	return (
		<div onClick={() => setIsOpen()} style={{ cursor: "pointer", position: "relative", top: "2px", right: "-5px" }}>
			{isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
		</div>
	);
};

const Row = styled("div")(({ bb, theme }) => ({
	display: "flex",
	alignItems: "center",
	borderBottom: bb ? `1px solid ${theme.palette.brandColors.lightGray}` : "none",
}));

const Column = styled("div")(({ br, theme, isID, isDiv }) => ({
	width: isDiv ? "10px" : "100%",
	padding: isID ? "10px 15px" : isDiv ? "0" : "15px 15px",
	borderRight: br ? `1px solid ${theme.palette.brandColors.lightGray}` : "none",
	textAlign: isDiv ? "center" : "left",
	fontSize: "15px",
	display: "flex",
	flexDirection: "column",
	height: "100%",
}));

const Image = styled("img")(() => ({
	minWidth: "100%",
	width: "100%",
	borderRadius: "12px",
}));

const PopupContainer = styled("div")(() => ({
	fontSize: "15px",
	borderRadius: "12px",
	width: "100%",
	boxShadow: "0px 0px 4px 0px rgba(33, 33, 33, 0.08)",
	display: "flex",
	flexDirection: "column",
	height: "100%",
}));

const ObjectName = styled("div")(() => ({
	fontWeight: 700,
	padding: "15px 20px",
	borderRadius: "12px 12px 0 0",
	backgroundColor: "white",
}));

const ObjectID = styled("div")(({ color }) => ({
	backgroundColor: color,
	display: "inline-block",
	borderRadius: "12px",
	padding: "3px 10px",
	color: "white",
	fontWeight: 700,
	textWrap: "nowrap",
	width: "fit-content",
}));

const Content = styled("div")(({ theme, maxHeight }) => ({
	backgroundColor: theme.palette.brandColors.gray,
	borderRadius: "0 0 12px 12px",
	overflowY: "auto",
	maxHeight: maxHeight ? `calc(${maxHeight}px - 125px)` : "100%",
}));

const Triangle = styled("div")(({ theme }) => ({
	width: 0,
	height: 0,
	borderLeft: "15px solid transparent",
	borderRight: "15px solid transparent",
	borderTop: `15px solid ${theme.palette.brandColors.gray}`,
	position: "relative",
	top: "-1px",
	left: "calc(50% - 15px)",
}));

/**
 * createDivIcon: Funcion para crear un divIcon de leaflet
 * @param {object} feature: objeto con las propiedades del icono
 * @returns {object} divIcon de leaflet
 */
const createDivIcon = (feature, defIconSize) => {
	let text = feature.properties.id;

	let opacity = feature.properties.opacity || 1;
	let fillOpacity = feature.properties.fillOpacity || 1;
	// let textColor = feature.properties.textColor || "black";
	let textColor = "white";
	let bgColor = feature.properties.color || `rgba(89, 169, 255, ${fillOpacity})`;

	// si el color es rojo, se cambia el texto a blanco por legibilidad
	if (bgColor === "red") {
		textColor = "white";
		bgColor = `rgba(255, 0, 0, ${fillOpacity})`;
	} else if (bgColor === "green") {
		bgColor = `rgba(4, 208, 0, ${fillOpacity})`;
	}

	let borderColor = feature.properties.borderColor || `rgba(255, 255, 255, 1)`;
	let borderWidth = feature.properties.borderWidth || "0.4em";
	let borderStyle = feature.properties.borderStyle || "solid";

	let iconSize = feature.properties.iconSize || L.point(defIconSize, defIconSize, true);
	let fontByDefIconSize = defIconSize / 4 + 2;
	let fontSize = feature.properties.fontSize || fontByDefIconSize + "px";

	// si esta seleccionado, se cambia el estilo del icono
	if (feature.properties.selected) {
		borderColor = "black";
		iconSize = L.point(defIconSize, defIconSize, true);
		fontSize = "1.2em";
	}

	const style = {
		"background-color": bgColor,
		color: textColor,
		"border-radius": "50%",
		"border-width": borderWidth,
		"border-style": borderStyle,
		"border-color": borderColor,
		width: "100%",
		height: "100%",
		display: "flex",
		"justify-content": "center",
		"align-items": "center",
		"font-weight": "bold",
		"font-size": fontSize,
		"text-align": "center",
		"z-index": 10 + feature.properties.id,
		position: "relative",
		opacity: opacity,
		"--state": feature.properties.state || "Balizado",
	};
	return L.divIcon({
		html: `<div style="${Object.entries(style)
			.map(([key, value]) => `${key}:${value}`)
			.join(";")}"><span>${text}</span></div>`,
		iconSize: iconSize,
		className: `transparent-no-border ${
			feature.properties.selected ? "custom-selected-marker" : "custom-selected-marker"
		}`,
		state: feature.properties.state || "Balizado",
	});
};

const ProspectionImageMarker = memo(
	({ feature, clusterRadius, maxHeight }) => {
		const [open, setOpen] = useState(false);
		return (
			<Marker
				icon={createDivIcon(feature, clusterRadius)}
				position={feature.geometry.coordinates}
				title={`ID: ${feature.properties.id}`}
				eventHandlers={{
					click: () => {
						setOpen((prev) => !prev);
					},
					popupclose: () => {
						console.log("close");
						setOpen(false);
					},
				}}
			>
				<Popup ref={(popupEl) => this?.assignPopupProperties(popupEl)}>
					{open && (
						<PopupContainer>
							<ObjectName>Prospección de fondo</ObjectName>
							<Content maxHeight={maxHeight}>
								<Row bb>
									<Column br isID>
										<ObjectID color={feature.properties.color}>
											ID: {feature.properties.id}
										</ObjectID>
									</Column>
									<Column>Fecha: {feature.properties.date}</Column>
								</Row>
								<Row bb>
									<Column>Lat: {feature.properties.latitude}</Column>
									<Column isDiv>|</Column>
									<Column>Lon: {feature.properties.longitude}</Column>
								</Row>
								<Row bb>
									<Column>
										<div>Descripción:</div>
										<div
											style={{
												fontSize: "14px",
												fontWeight: 300,
											}}
										>
											{feature.properties.description}
										</div>
									</Column>
								</Row>
								<Row>
									<Column>
										<div>Imagen</div>
										<Image src={feature.properties.image} alt="Sin imagen" />
									</Column>
								</Row>
							</Content>
							<Triangle />
						</PopupContainer>
					)}
				</Popup>
			</Marker>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.feature.properties.id === nextProps.feature.properties.id &&
			prevProps.clusterRadius === nextProps.clusterRadius &&
			prevProps.maxHeight === nextProps.maxHeight
		);
	}
);

export const ProspectionImages = memo(
	({ images, clusterRadius, maxHeight}) => {
		return images?.map((feature) => <ProspectionImageMarker feature={feature} clusterRadius={clusterRadius} maxHeight={maxHeight} />);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.images?.length === nextProps.images?.length &&
			prevProps.clusterRadius === nextProps.clusterRadius &&
			prevProps.maxHeight === nextProps.maxHeight
		);
	}
);

const BacteriumImageMarker = memo(
	({ feature, clusterRadius, maxHeight }) => {
		const [open, setOpen] = useState(false);

		return (
			<Marker
				icon={createDivIcon(feature, clusterRadius)}
				position={feature.geometry.coordinates}
				title={`ID: ${feature.properties.id}`}
				eventHandlers={{
					click: () => {
						setOpen((prev) => !prev);
					},
					popupclose: () => {
						console.log("close");
						setOpen(false);
					},
				}}
			>
				<Popup ref={(popupEl) => this?.assignPopupProperties(popupEl)}>
					{open && (
						<PopupContainer>
							<ObjectName>Alimentación y bacterias</ObjectName>
							<Content maxHeight={maxHeight}>
								<Row bb>
									<Column br isID>
										<ObjectID color={feature.properties.color}>
											ID: {feature.properties.id}
										</ObjectID>
									</Column>
									<Column>Fecha: {feature.properties.date}</Column>
								</Row>
								<Row bb>
									<Column>Lat: {feature.properties.latitude}</Column>
									<Column isDiv>|</Column>
									<Column>Lon: {feature.properties.longitude}</Column>
								</Row>
								<Row bb>
									{feature.properties.hasFeed && (
										<Column br>Alimento grado {feature.properties.feedRate}</Column>
									)}
									{feature.properties.hasBacterium && (
										<Column br>Bacteria grado {feature.properties.bacteriumRate}</Column>
									)}
									{feature.properties.hasFeces && (
										<Column>Heces grado {feature.properties.fecesRate}</Column>
									)}
								</Row>
								<Row bb>
									<Column>
										<div>Descripción:</div>
										<div
											style={{
												fontSize: "14px",
												fontWeight: 300,
											}}
										>
											{feature.properties.description}
										</div>
									</Column>
								</Row>
								<Row>
									<Column>
										<div>Imagen</div>
										<Image src={feature.properties.image} alt="Sin imagen" />
									</Column>
								</Row>
							</Content>
							<Triangle />
						</PopupContainer>
					)}
				</Popup>
			</Marker>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.feature.properties.id === nextProps.feature.properties.id &&
			prevProps.clusterRadius === nextProps.clusterRadius &&
			prevProps.maxHeight === nextProps.maxHeight
		);
	}
);

export const FeedingBacteriumImages = memo(
	({ images, clusterRadius, maxHeight}) => {
		return images?.map((feature) => <BacteriumImageMarker feature={feature} clusterRadius={clusterRadius} maxHeight={maxHeight} />);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.images?.length === nextProps.images?.length &&
			prevProps.clusterRadius === nextProps.clusterRadius &&
			prevProps.maxHeight === nextProps.maxHeight
		);
	}
);

const VideoMarker = memo(
	({ feature, clusterRadius }) => {
		const [open, setOpen] = useState(false);
		return (
			<Marker
				icon={createDivIcon(feature, clusterRadius)}
				position={feature.geometry.coordinates}
				title={`ID: ${feature.properties.id}`}
				eventHandlers={{
					click: () => {
						setOpen((prev) => !prev);
					},
					popupclose: () => {
						setOpen(false);
					},
				}}
			>
				<Popup>
					{open && (
						<PopupContainer>
							<ObjectName>Video {feature.properties.type}</ObjectName>
							<Content>
								<Row bb>
									<Column br isID>
										<ObjectID color={feature.properties.color}>
											ID: {feature.properties.id}
										</ObjectID>
									</Column>
									<Column>Fecha: {feature.properties.date}</Column>
								</Row>
								<Row bb>
									<Column>Lat: {feature.properties.latitude}</Column>
									<Column isDiv>|</Column>
									<Column>Lon: {feature.properties.longitude}</Column>
								</Row>
								<Row bb>
									<Column>
										<div>Descripción:</div>
										<div
											style={{
												fontSize: "14px",
												fontWeight: 300,
											}}
										>
											{feature.properties.description}
										</div>
									</Column>
								</Row>
								<Row>
									<Column>
										<div>Video</div>
										<video controls width="100%" height="auto" src={feature.properties.video} />
									</Column>
								</Row>
							</Content>
							<Triangle />
						</PopupContainer>
					)}
				</Popup>
			</Marker>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.feature.properties.id === nextProps.feature.properties.id &&
			prevProps.clusterRadius === nextProps.clusterRadius
		);
	}
);

export const VideoPopups = memo(
	({ images, clusterRadius }) => {
		return images?.map((feature) => (
			<VideoMarker key={feature.properties.id} feature={feature} clusterRadius={clusterRadius} />
		));
	},
	(prevProps, nextProps) => {
		return (
			prevProps.images?.length === nextProps.images?.length && prevProps.clusterRadius === nextProps.clusterRadius
		);
	}
);

export const ObjectMarker = memo(
	({ feature, clusterRadius, maxHeight }) => {
		const [open, setOpen] = useState(false);
		console.log(maxHeight);
		return (
			<Marker
				icon={createDivIcon(feature, clusterRadius)}
				position={feature.geometry.coordinates}
				title={`ID: ${feature.properties.id}`}
				eventHandlers={{
					click: () => {
						setOpen((prev) => !prev);
					},
					popupclose: (a) => {
						setOpen(false);
					},
				}}
			>
				<Popup ref={(popupEl) => this?.assignPopupProperties(popupEl)}>
					{open && (
						<PopupContainer>
							<ObjectName>{feature.properties.type.name}</ObjectName>
							<Content maxHeight={maxHeight}>
								<Row bb>
									<Column br isID>
										<ObjectID color={feature.properties.color}>
											ID: {feature.properties.id}
										</ObjectID>
									</Column>
									<Column
										br
										style={{
											fontWeight: 700,
										}}
									>
										{feature.properties.state}
									</Column>
									<Column>Vol: {feature.properties.volume.toFixed(2)} kg</Column>
								</Row>

								<Row bb>
									<Column>Lat: {feature.properties.latitude}</Column>
									<Column isDiv>|</Column>
									<Column>Lon: {feature.properties.longitude}</Column>
								</Row>

								<Row bb>
									<Column>
										<div>Comentario:</div>
										<div
											style={{
												fontSize: "14px",
												fontWeight: 300,
											}}
										>
											{feature.properties.comments}
										</div>
									</Column>
								</Row>

								<Row>
									<Column>
										<div>Fondo</div>
										<Image src={feature.properties.submarineImage || ""} alt="Sin imagen" />
									</Column>
									<Column>
										<div>En Embarcación</div>
										<Image src={feature.properties.inBoatImage || ""} alt="Sin imagen" />
									</Column>
								</Row>
							</Content>
							<Triangle />
						</PopupContainer>
					)}
				</Popup>
			</Marker>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.feature.properties.id === nextProps.feature.properties.id &&
			prevProps.clusterRadius === nextProps.clusterRadius &&
			prevProps.maxHeight === nextProps.maxHeight
		);
	}
);

export const ObjectImages = memo(
	({ images, clusterRadius, maxHeight }) => {
		console.log(maxHeight);
		return images?.map((feature) => (
			<ObjectMarker
				key={feature.properties.id}
				feature={feature}
				clusterRadius={clusterRadius}
				maxHeight={maxHeight}
			/>
		));
	},
	(prevProps, nextProps) => {
		return (
			prevProps.images?.length === nextProps.images?.length &&
			prevProps.clusterRadius === nextProps.clusterRadius &&
			prevProps.maxHeight === nextProps.maxHeight
		);
	}
);
