/**
 * @fileoverview Este componente renderiza la lista de objetos agregados al form para agregar objetos a un informe.
 */

import { useState, useContext, useEffect } from "react";
import { Typography } from "@mui/material";
import CreateButton from "../../CreateButton";
import { ObjectFormModal } from "./ObjectFormModal";
import { ReportDataContext, ReportErrorContext } from "../../../context";
import BoxIcon from "../../Icons/BoxIcon";
import IconButtons from "../../Icons/IconButtons";
import DeleteIcon from "../../Icons/DeleteIcon";
import EditIcon from "../../Icons/EditIcon";
import { Table } from "../../Table";

// Información inicial
const empty_object = {
	type: null,
	amount: "",
	volume: "",
	latitude: "",
	longitude: "",
	beacon: false,
	notify: false,
	comments: "",
	submarineImage: null,
	inBoatImage: null,
	database_id: -1,
	video: null,
};

const initialErrors = {
	type: { error: false, message: "" },
	amount: { error: false, message: "" },
	volume: { error: false, message: "" },
	latitude: { error: false, message: "" },
	longitude: { error: false, message: "" },
	// in_boat_image: { error: false, message: ""},
	submarineImage: { error: false, message: "" },
};

// Posibles estados del componente
const STATES = {
	LIST_OBJECTS: 0, // Listando objetos agregados
	ADD_OBJECT: 1, // Agregando un objeto
};

const CustomNoRowsOverlay = () => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			height: "100%",
		}}
	>
		<Typography
			variant="h8"
			component="div"
			style={{
				color: "gray",
				padding: "16px",
			}}
		>
			Todavía no añades objetos
		</Typography>
	</div>
);

/**
 * Componente donde se listan los objetos agregados mientras se crea/edita un informe, y se pueden agregar nuevos objetos.
 *
 * @param {boolean} edit: true si reporte está siendo editado, false si se está creando
 */
const ReportObjectGrid = ({ edit }) => {
	const [currentObjectData, setCurrentObjectData] = useState(empty_object); // Info del objeto que está siendo creado
	const [compState, setCompState] = useState(STATES.LIST_OBJECTS); // Estado del componente
	const [errors, setErrors] = useState(initialErrors); // Posibles errores en el form de un objeto
	const { reportData, setReportData, setShowBottomButtons } = useContext(ReportDataContext);
	const [objectEditMode, setObjectEditMode] = useState(false); // Si se está editando un objeto, cambian los botones
	const { setCheckErrors } = useContext(ReportErrorContext); // Función para revisar si hay errores en el form
	const [counter, setCounter] = useState(reportData.initialCounter); // contador para asignar id a los objetos

	useEffect(() => {
		switch (compState) {
			case STATES.LIST_OBJECTS:
				setShowBottomButtons(true);
				setCheckErrors(() => () => false);
				break;
			case STATES.ADD_OBJECT:
				setShowBottomButtons(false);
				setCheckErrors(() => () => true);
				break;
			default:
				break;
		}
	}, [compState, setCheckErrors, setShowBottomButtons]);

	// Función utilizada al presionar el botón de agregar objeto
	const handleAddObject = () => {
		// Revisamos si hay errores en el form
		const newErrors = {};
		let hasErrors = false;
		for (const key in initialErrors) {
			if (!currentObjectData[key] || currentObjectData[key] == null || currentObjectData[key] === "") {
				newErrors[key] = { error: true, message: "Este campo es requerido" };
				hasErrors = true;
			} else {
				newErrors[key] = { error: false, message: "" };
			}
		}
		setErrors(newErrors);
		if (!hasErrors) {
			let newData = currentObjectData;
			if (newData?.id !== undefined) {
				//se esta editando un objeto ya creado
				setReportData((prev) => {
					const newObjects = prev.objectsList;
					newObjects[newObjects.findIndex((object) => object.id === newData.id)] = newData;
					return { ...prev, objectsList: newObjects };
				});
			} else {
				//se esta creando
				newData.id = counter;
				setCounter((prevValue) => prevValue + 1);
				setReportData((prev) => {
					const newObjects = prev.objectsList;
					newObjects.push(newData);
					return { ...prev, objectsList: newObjects };
				});
			}

			setCompState(STATES.LIST_OBJECTS);
			setObjectEditMode(false);
		}
	};

	// Handler para eliminar un objeto de la lista
	const handleDeleteRow = (id) => {
		if (edit === true) {
			// puede ser que el objeto ya esté en la db
			const rowDel = reportData.objectsList.find((row) => row.id === id);
			if (rowDel.database_id !== -1) {
				// si el objeto ya está en la db, lo agregamos a la lista de objetos eliminados
				setReportData((prev) => {
					const newDeleted = [...prev.objectsDeleted, rowDel];
					const newObjects = prev.objectsList.filter((row) => row.id !== id);
					return { ...prev, objectsDeleted: newDeleted, objectsList: newObjects };
				});
			} else {
				// si el objeto no está en la db, solo lo eliminamos de la lista de objetos
				setReportData((prev) => {
					const newObjects = prev.objectsList.filter((row) => row.id !== id);
					return { ...prev, objectsList: newObjects };
				});
			}
		}

		setReportData((prev) => {
			const updatedRows = prev.objectsList.filter((row) => row.id !== id);
			return { ...prev, objectsList: updatedRows };
		});
	};

	const handleEditRow = (id) => {
		const rowToEdit = reportData.objectsList.find((row) => row.id === id);
		if (rowToEdit.submarineImage && typeof rowToEdit.submarineImage === "object") {
			rowToEdit.submarineImage.preview = URL.createObjectURL(rowToEdit.submarineImage);
		}
		if (rowToEdit.inBoatImage && typeof rowToEdit.inBoatImage === "object")
			rowToEdit.inBoatImage.preview = URL.createObjectURL(rowToEdit.inBoatImage);
		setCurrentObjectData(rowToEdit); // Seteamos la info del objeto que se está editando
		setObjectEditMode(true);

		setCompState(STATES.ADD_OBJECT);
	};

	const columns = [
		{ field: "type", headerName: "Tipo" },
		{ field: "amount", headerName: "Cantidad" },
		{ field: "volume", headerName: "Volumen" },
		{ field: "state", headerName: "Estado" },
		{ field: "notify", headerName: "Notificar" },
		{ field: "latitude", headerName: "Latitud" },
		{ field: "longitude", headerName: "Longitud" },
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", gap: "10px" }}>
					<IconButtons onClick={() => handleEditRow(row.id)} icon={<EditIcon />} />
					<IconButtons onClick={() => handleDeleteRow(row.id)} icon={<DeleteIcon />} variant="danger" />
				</div>
			),
		},
	];

	return (
		<div style={{ width: "100%", height: "100%" }}>
			{compState === STATES.LIST_OBJECTS && (
				<>
					<div style={{ padding: "10px 25px" }}>
						<CreateButton
							text="Agregar objeto"
							onClick={() => {
								setCurrentObjectData(empty_object);
								setCompState(STATES.ADD_OBJECT);
							}}
							icon={<BoxIcon />}
						/>
					</div>

					<div style={{ height: "auto", width: "100%" }}>
						<Table
							columns={columns}
							rows={reportData.objectsList.map((row) => ({
								type: row.type.label,
								amount: row.amount,
								volume: row.volume,
								id: row.id,
								state: row.beacon ? "Balizado" : "En embarcación",
								notify: row.notify ? "Sí" : "No",
								latitude: row.latitude,
								longitude: row.longitude,
								submarineImage: row.submarineImage,
								inBoatImage: row.inBoatImage,
								comments: row.comments,
							}))}
						/>
					</div>
				</>
			)}

			{compState === STATES.ADD_OBJECT && (
				<ObjectFormModal
					object={currentObjectData}
					otherObjects={reportData.objectsList.filter((obj) => obj.id !== currentObjectData.id)}
					setObject={setCurrentObjectData}
					handleAddObject={handleAddObject}
					handleClose={() => {
						setCompState(STATES.LIST_OBJECTS);
						setCurrentObjectData(empty_object);
						setErrors(initialErrors);
					}}
					errors={errors}
					setErrors={setErrors}
					objectEditMode={objectEditMode} // Si se está editando un objeto, cambian los botones
				/>
			)}
		</div>
	);
};

export default ReportObjectGrid;
