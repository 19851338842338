import { useEffect, useState, useCallback } from "react";

// mui components
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// mui icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// context
import { useAuthContext } from "../context";

// global
import { URLS } from "../global";

// utils
import {
	generateObjectsPoints,
	generateStructurePointsFromCentros,
	generateImagePoints,
	generateVideoPoints,
	generateFeedingBacteriumPoints,
} from "../utils/map_helper";

// components
import ObjectController from "../components/Objects/ObjectController";
import MapAddorns from "../components/Map/MapAddorns";

const HomePage = () => {
	const [rows, setRows] = useState([]);
	const [mapStructures, setMapStructures] = useState([]); // estructuras que se muestran en el mapa
	const [mapObjects, setMapObjects] = useState([]); // objetos que se muestran en el mapa (sincronizados con filteredRows)
	const [loading, setLoading] = useState({
		objects: true,
		structures: true,
		images: true,
		boats: true,
		videos: true,
		feedingBacteriumImages: true,
	});
	const [centros, setCentros] = useState([{}]);
	const [showTable, setShowTable] = useState(false);
	const [selectedObject, setSelectedObject] = useState(null); // selectedObject es el objeto que se elige
	const [imagePoints, setImagePoints] = useState([]);
	const [videoPoints, setVideoPoints] = useState([]);
	const [filteredImagePoints, setFilteredImagePoints] = useState([]);
	const [boats, setBoats] = useState([]);
	const [bacteriumPoints, setBacteriumPoints] = useState([]);
	const [filteredBacteriumPoints, setFilteredBacteriumPoints] = useState([]);
	const [mapCenter, setMapCenter] = useState(null);
	const [initialDate, setInitialDate] = useState(
		new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())
	);
	const [endDate, setEndDate] = useState(new Date());

	const { axiosInstance } = useAuthContext();
	const theme = useTheme();

	useEffect(() => {
		document.title = "Rastrum | Home";
	}, []);

	const handleShowTable = () => {
		setShowTable(!showTable);
	};

	const focusObject = (ob) => {
		const obj = rows.filter((obj) => obj.id === ob)[0];
		if (!obj || !obj.latitude || !obj.longitude) {
			return;
		}
		if (isNaN(obj.latitude) || isNaN(obj.longitude)) {
			return;
		}
		setMapCenter([obj.latitude, obj.longitude]);
	};

	// controla la seleccion de un objeto por click
	const handleSelectObject = useCallback(
		(object) => {
			setSelectedObject(object);

			rows.forEach((row) => {
				if (row.id === object?.id) {
					row.selected = true;
				} else {
					row.selected = false;
				}
			});

			mapObjects.features.forEach((feature) => {
				if (feature.properties.id === object?.id) {
					feature.properties.selected = true;
				} else {
					feature.properties.selected = false;
				}
			});
		},
		[rows, mapObjects]
	);

	// controla la seleccion de objetos multiples por checkbox
	const selectMultipleObjects = useCallback(
		(objects) => {
			rows.forEach((row) => {
				if (objects.includes(row.id)) {
					row.selected = true;
				} else {
					row.selected = false;
				}
			});

			mapObjects.features.forEach((feature) => {
				if (objects.includes(feature.properties.id)) {
					feature.properties.selected = true;
				} else {
					feature.properties.selected = false;
				}
			});
		},
		[rows, mapObjects]
	);

	useEffect(() => {
		setLoading({
			objects: true,
			structures: true,
			images: true,
			boats: true,
			videos: true,
			feedingBacteriumImages: true,
		});

		const getData = async () => {
			axiosInstance
				.get(URLS.GET_OBJECTS, {
					params: {
						initialDate: initialDate.toISOString().slice(0, 10),
						endDate: endDate.toISOString().slice(0, 10),
					},
				})
				.then((response) => {
					if (response.status === 200) {
						// guardamos los objetos para la tabla, y los objetos para el mapa
						const rows = response.data
							.map((obj) => {
								return {
									...obj,
									state: obj.receptionNote ? "Entregado" : obj.state,
									volume: +obj.volume.toFixed(3),
								};
							})
							.sort((a, b) => (a.id > b.id ? 1 : -1));
						setMapObjects(generateObjectsPoints(rows));
						setRows(
							rows.map((obj) => {
								return { ...obj, creationDate: obj.creationDate.slice(0, 10) };
							})
						);
						setLoading((prev) => ({ ...prev, objects: false }));
					}
				});

			axiosInstance.get(URLS.CENTERS).then((response) => {
				if (response.status === 200) {
					// guardamos las estructuras para el mapa
					setMapStructures(generateStructurePointsFromCentros(response.data));
					setCentros(response.data);
					setLoading((prev) => ({ ...prev, structures: false }));
				}
			});

			axiosInstance
				.get(URLS.GEO_IMAGES, {
					params: {
						initialDate: initialDate.toISOString().slice(0, 10),
						endDate: endDate.toISOString().slice(0, 10),
					},
				})
				.then((response) => {
					const imagePoints = generateImagePoints(response.data);
					setImagePoints(imagePoints);
					setFilteredImagePoints(imagePoints);
					setLoading((prev) => ({ ...prev, images: false }));
				});

			axiosInstance.get(URLS.BOAT_LOGS + "last_boats_centers/").then((response) => {
				const data = response.data;
				const boatData = data.map((boat) => {
					return {
						id: boat.id,
						name: boat.boat.name,
						cultivationCenter: boat.cultivationCenter.id,
					};
				});
				setBoats(boatData);
				setLoading((prev) => ({ ...prev, boats: false }));
			});

			axiosInstance
				.get(URLS.GEO_VIDEO, {
					params: {
						initialDate: initialDate.toISOString().slice(0, 10),
						endDate: endDate.toISOString().slice(0, 10),
					},
				})
				.then((response) => {
					setVideoPoints(generateVideoPoints(response.data));
					setLoading((prev) => ({ ...prev, videos: false }));
				});

			axiosInstance
				.get(URLS.FEEDING_AND_BACTERIUM_IMAGES, {
					params: {
						initialDate: initialDate.toISOString().slice(0, 10),
						endDate: endDate.toISOString().slice(0, 10),
					},
				})
				.then((response) => {
					const bacteriumPoints = generateFeedingBacteriumPoints(response.data);
					setBacteriumPoints(bacteriumPoints);
					setFilteredBacteriumPoints(bacteriumPoints);
					setLoading((prev) => ({ ...prev, feedingBacteriumImages: false }));
				});
		};

		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [axiosInstance, initialDate, endDate]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				height: "100%",
				margin: "auto",
			}}
		>
			<Box
				sx={{
					height: "100%",
					padding: 3,
					width: "50%",
					display: showTable ? "block" : "none",
				}}
			>
				<ObjectController
					rows={rows}
					focusObject={focusObject}
					setRows={setRows}
					loading={
						loading.objects ||
						loading.structures ||
						loading.images ||
						loading.boats ||
						loading.videos ||
						loading.feedingBacteriumImages
					}
					setMapObjects={setMapObjects}
					selectedObject={selectedObject}
					setSelectedObject={handleSelectObject}
					selectMultipleObjects={selectMultipleObjects}
					images={imagePoints}
					setFilteredImagePoints={setFilteredImagePoints}
					bacteriumPoints={bacteriumPoints}
					setFilteredBacteriumPoints={setFilteredBacteriumPoints}
					setMapCenter={setMapCenter}
				/>
			</Box>

			<Box
				sx={{
					position: "relative",
					height: "100%",
					margin: "auto",
					display: "flex",
					width: showTable ? "50%" : "100%",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<IconButton
					onClick={handleShowTable}
					sx={{
						position: "absolute",
						top: "25px",
						left: "0px",
						zIndex: 1000,
						backgroundColor: theme.palette.brandColors.white,
						width: "35px",
						height: "50px",
						borderRadius: "0 15px 15px 0",
						color: "#101828",
					}}
				>
					{showTable ? <ChevronLeftIcon /> : <ChevronRightIcon />}
				</IconButton>

				<MapAddorns
					mapKey={String(showTable)}
					data={{
						objects: mapObjects,
						structures: mapStructures,
						images: filteredImagePoints,
						boats: boats,
						videos: videoPoints,
						feedingBacteriumImages: filteredBacteriumPoints,
					}}
					centros={centros}
					loading={loading.objects || loading.structures || loading.images || loading.boats || loading.videos}
					sx={{ width: "100%" }}
					eMapCenter={mapCenter}
					showTable={showTable}
					initialDate={initialDate}
					endDate={endDate}
					setInitialDate={setInitialDate}
					setEndDate={setEndDate}
				/>
			</Box>
		</Box>
	);
};

export default HomePage;
