import { findCentralPoint, generateStructurePointsFromCentros, generateImagePoints } from "../../../utils/map_helper";
import { useEffect, useState } from "react";
import { Map } from "../../Map";
import { MapContainerStyle } from "../Create/StatesConstants";
import { Box, Switch } from "@mui/material";
import MultipleImages from "./MultipleImages";
import FileUpload from "./FileUpload";

const ObjectsMap = ({ generalData, setGeneralData, setCheckErrors, geoImages = false }) => {
	const [images] = useState(generateImagePoints(geoImages ? generalData.geoImages : generalData.images));
	const center = generalData.centers.find((center) => center.id === generalData.center);
	const [structures] = useState(generateStructurePointsFromCentros([center]));

	useEffect(() => {
		setCheckErrors(() => () => false);
	}, [generalData.imageMap, setCheckErrors]);

	const ContainerStyle = {
		...MapContainerStyle,
		border: "1px solid black",
		display: "flex",
		justifyContent: "center",
		width: generalData.isImageMapHorizontal ? "1200px" : "900px",
		height: generalData.isImageMapHorizontal ? "900px" : "1200px",
	};
	const mapCenter = findCentralPoint(center.coordinates);

	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				padding: "20px 30px",
				flexDirection: "column",
				gap: "20px",
			}}
		>
			<Box>
				Vertical
				<Switch
					checked={generalData.isImageMapHorizontal}
					onChange={() =>
						setGeneralData((prev) => ({ ...prev, isImageMapHorizontal: !prev.isImageMapHorizontal }))
					}
				/>
				Horizontal
			</Box>
			<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
				<Box sx={ContainerStyle}>
					<Map
						key={generalData.isImageMapHorizontal}
						data={{
							structures: structures,
							images: images,
						}}
						initial_state={{
							initial_is_clustered: false,
							initial_cluster_radius: 0,
							initial_zoom: 14,
							initial_center: mapCenter,
						}}
						smallLegends={true}
					/>
				</Box>
			</div>
			<Box>Imagen Principal</Box>

			<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
				<FileUpload
					image={generalData.imageMap}
					sx={{ ...ContainerStyle }}
					setImage={(image) => {
						setGeneralData((prev) => {
							prev.imageMap && URL.revokeObjectURL(prev.imageMap);
							return { ...prev, imageMap: image };
						});
					}}
				/>
			</div>
			<div>Imágenes Adicionales</div>
			<MultipleImages
				images={generalData.addImagesMap}
				setImages={(images) => setGeneralData((prev) => ({ ...prev, addImagesMap: images }))}
				AddDeletedImage={(id) =>
					setGeneralData((prev) => ({ ...prev, deletedImagesMap: [...prev.deletedImagesMap, id] }))
				}
			/>
		</Box>
	);
};

export default ObjectsMap;
