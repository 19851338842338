import { useState } from "react";
import {
	AppName,
	Container,
	Content,
	Data,
	GeneralInfo,
	GeneralInfoData,
	Logo,
	LogosContainer,
	middleScreenMedia,
	ReportTitle,
	ReportType,
	Subtitle,
	Tab,
	TabContent,
	Tabs,
} from "./Common";
import "./common.css";
import Objects from "./Objects";
import MapContent from "./MapContent";
import { ThemeProvider, styled } from "@mui/material/styles";
import { theme } from "../../../App";
import InfoIcon from "../../Icons/InfoIcon";
import CameraIcon from "../../Icons/CameraIcon";
import MapIcon from "../../Icons/MapIcon";
import BoxIcon from "../../Icons/BoxIcon";

const TABS = {
	DATA: 0,
	IMAGE: 1,
	OBJECTS: 2,
	MAP: 3,
};

const dummyData = {
	ROVOperator: "John Doe",
	date: "2021-10-10",
	receptor: "Jane Doe",
	rutReceptor: "123456789-0",
	cargoReceptor: "Cargo",
	boat: "Vortex 2",
	receptionImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
	comments: "Comentarios",
	salmonCompany: {
		logo: "https://picsum.photos/200/151",
		name: "AcuaMarinos S.A.",
	},
	wreckRescueCompany: {
		name: "Wreck Rescue Company",
		logo: "https://picsum.photos/200/150",
	},
	objects: [
		{
			id: 257,
			date: "2024-08-21",
			rescued: [],
			ammount: 1.0,
			state: "En Embarcación",
			latitude: -42.23432,
			longitude: -73.258581,
			volume: 90.0,
			submarineImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			inBoatImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			comments: "asdasd",
			notify: false,
			type: {
				id: 1,
				name: "Contrapeso 90kg",
				description: "",
				weight: 90.0,
				type: "Cuantificable",
			},
			receptionNote: true,
		},
		{
			id: 258,
			date: "2024-08-21",
			rescued: [],
			ammount: 1.0,
			state: "Balizado",
			latitude: -42.24,
			longitude: -73.258581,
			volume: 90.0,
			submarineImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_mh3qmwbkctc6ya.png",
			inBoatImage: "https://testrastrum.s3.amazonaws.com/media/images/CastawayRest_8jvqnwqfjhjjyd.png",
			comments: "asdasd",
			notify: false,
			type: {
				id: 1,
				name: "Contrapeso 90kg",
				description: "",
				weight: 90.0,
				type: "Cuantificable",
			},
			receptionNote: true,
		},
	],
};

const CommentsContainer = styled("div")(() => ({
	display: "flex",
	gap: "20px",
	width: "100%",
	[middleScreenMedia]: {
		flexDirection: "column",
		gap: "0",
	},
}));

const ReceptionNote = () => {
	// const [reportData, setReportData] = useState({
	// 	...dummyData,
	// 	objects: dummyData.objects.map((obj) => ({ ...obj, state: "Entregado" })),
	// });
	const data = JSON.parse(document.getElementById("reportData")?.textContent || "{}");
	const [reportData] = useState({ ...data, objects: data.objects.map((obj) => ({ ...obj, state: "Entregado" })) });
	const [tab, setTab] = useState(TABS.DATA);

	return (
		<ThemeProvider theme={theme}>
			<Container>
				<ReportTitle>
					<ReportType>Nota de salida</ReportType>

					<AppName>Rastrum</AppName>
				</ReportTitle>
				<Content nt={TABS.MAP === tab}>
					<Tabs centered value={tab} onChange={(e, v) => setTab(v)} tabs={4}>
						<Tab
							label={<div className="text">Datos</div>}
							icon={
								<InfoIcon color={tab === TABS.DATA ? theme.palette.brandColors.primary : "#848591"} />
							}
						/>
						<Tab
							label={<div className="text">Imagen recepción</div>}
							icon={
								<CameraIcon
									color={tab === TABS.IMAGE ? theme.palette.brandColors.primary : "#848591"}
								/>
							}
						/>
						<Tab
							label={<div className="text">Objetos</div>}
							icon={
								<BoxIcon color={tab === TABS.OBJECTS ? theme.palette.brandColors.primary : "#848591"} />
							}
						/>
						<Tab
							label={<div className="text">Mapa</div>}
							icon={<MapIcon color={tab === TABS.MAP ? theme.palette.brandColors.primary : "#848591"} />}
						/>
					</Tabs>

					<TabContent value={tab} index={TABS.DATA}>
						<LogosContainer>
							<Logo src={reportData.wreckRescueCompany.logo} alt={reportData.wreckRescueCompany.name} />
							<Logo src={reportData.salmonCompany.logo} alt={reportData.salmonCompany.name} />
						</LogosContainer>

						<GeneralInfo smMaxPerRow={2}>
							<GeneralInfoData>
								<Subtitle>Nombre operador ROV</Subtitle>
								<Data>{reportData?.ROVOperator}</Data>
							</GeneralInfoData>

							<GeneralInfoData>
								<Subtitle>Fecha</Subtitle>
								<Data>{reportData?.date}</Data>
							</GeneralInfoData>

							<GeneralInfoData>
								<Subtitle>Identificación transporte</Subtitle>
								<Data>{reportData?.boat}</Data>
							</GeneralInfoData>

							<GeneralInfoData>
								<Subtitle>Nombre receptor</Subtitle>
								<Data>{reportData?.receptor}</Data>
							</GeneralInfoData>

							<GeneralInfoData>
								<Subtitle>RUT receptor</Subtitle>
								<Data>{reportData?.rutReceptor}</Data>
							</GeneralInfoData>

							<GeneralInfoData>
								<Subtitle>Cargo receptor</Subtitle>
								<Data>{reportData?.cargoReceptor}</Data>
							</GeneralInfoData>
						</GeneralInfo>
						<CommentsContainer>
							<GeneralInfo style={{ width: "100%" }}>
								<GeneralInfoData>
									<div>Se ha realizado la entrega de los siguientes objetos:</div>
									<div
										style={{
											fontSize: "14px",
											fontWeight: 700,
											borderRadius: "12px",
											color: "white",
											backgroundColor: "#22ACA4",
											width: "fit-content",
											padding: "5px 10px",
										}}
									>
										{reportData.objects.map((object, i) => object.id).join(", ")}
									</div>
								</GeneralInfoData>
							</GeneralInfo>
							<GeneralInfo style={{ width: "100%" }}>
								<GeneralInfoData>
									<Data>Comentario:</Data>
									<Subtitle
										style={{
											fontSize: "14px",
										}}
									>
										{reportData?.comments}
									</Subtitle>
								</GeneralInfoData>
							</GeneralInfo>
						</CommentsContainer>
					</TabContent>

					<TabContent value={tab} index={TABS.IMAGE} style={{ display: "flex" }}>
						<GeneralInfo style={{ justifyContent: "center", padding: "30px", width: "100%" }}>
							<img src={reportData.receptionImage} style={{ width: "100%", objectFit: "contain" }} />
						</GeneralInfo>
					</TabContent>

					<TabContent value={tab} index={TABS.OBJECTS}>
						<Objects
							objects={reportData?.objects}
							summary={
								<div
									style={{ display: "flex", justifyContent: "center", margin: "20px 0", gap: "10px" }}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										<Data md>Cantidad de objetos</Data>
										<Subtitle md>: {reportData?.objects?.length}</Subtitle>
									</div>
								</div>
							}
						/>
					</TabContent>

					<TabContent value={tab} index={TABS.MAP} style={{ height: "100%", padding: 0, margin: 0 }}>
						<MapContent open={TABS.MAP === tab} reportData={reportData} />
					</TabContent>
				</Content>
			</Container>
		</ThemeProvider>
	);
};

export default ReceptionNote;
