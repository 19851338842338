import { generateFeedingBacteriumPoints, generateImagePoints, generateObjectsPoints, generateStructurePointsFromCentros } from "../../../utils/map_helper";
import MapAddorns from "../../Map/MapAddorns";
import { useEffect, useState } from "react";

const MapContent = ({ open, reportData }) => {
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [objects, setObjects] = useState([]);
	const [images, setImages] = useState([]);
	const [bacteriumPoints, setBacteriumPoints] = useState([]);
	const [structures, setStructures] = useState([]);

	useEffect(() => {
		if (open) {
			setIsFullScreen(true);
		}
	}, [open]);

	useEffect(() => {
		if (reportData) {
			setObjects(generateObjectsPoints(reportData.objects || []));
			setImages(generateImagePoints(reportData.geoImages || []));
			setBacteriumPoints(generateFeedingBacteriumPoints(reportData.feedingAndBacteriumImages || []));
			if (reportData.cultivationCenter) {
				setStructures(generateStructurePointsFromCentros([reportData.cultivationCenter]));
			}
		}
	}, [reportData]);

	return (
		<div style={{ width: "100%", height: "100%", position: "relative" }}>
			<MapAddorns
				mapKey={String(isFullScreen)}
				data={{
					objects: objects,
					structures: structures,
					images: images,
					boats: [],
					videos: [],
					feedingBacteriumImages: bacteriumPoints,
				}}
				centros={[]}
				sx={{ width: "100%" }}
				showTable={true}
				hideDates
				hideSelect
				hideShowInMap
			/>
		</div>
	);
};

export default MapContent;
