import { Box, Menu, TextField, Tooltip } from "@mui/material";
import { useAuthContext } from "../../context";
import { useEffect, useRef, useState } from "react";
import GeneralButton from "../GeneralButton";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EventEdit from "./EventEdit";
import AddIcon from "@mui/icons-material/Add";

const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

const dayNames = ["lunes", "martes", "miercoles", "jueves", "viernes", "sábado", "domingo"];



function getMonthsBetweenDates(startDate, endDate) {
	var months = [];
	const startDateCopy = new Date(startDate);

	while (startDateCopy <= endDate) {
		var year = startDateCopy.getFullYear();
		var month = startDateCopy.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index

		months.push({ year: year, month: month });
		startDateCopy.setDate(1);
		startDateCopy.setMonth(month);
	}

	return months;
}

function daysUntilEndOfMonth(date) {
	var currentMonth = date.getMonth();
	var currentYear = date.getFullYear();
	var totalDaysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
	var remainingDays = totalDaysInMonth - date.getDate();
	return remainingDays + 1;
}

function getDaysInMonth(year, month) {
	var lastDayOfMonth = new Date(year, month, 0);
	return lastDayOfMonth.getDate();
}

function formatDay(date) {
	var dayOfWeek = date.getDay() - 1 < 0 ? 6 : date.getDay() - 1;
	var dayOfMonth = date.getDate();
	return {
		num: (dayOfMonth < 10 ? "0" : "") + dayOfMonth,
		name: dayNames[dayOfWeek],
	};
}

function getDaysInRange(startDate, endDate) {
	var currentDate = new Date(startDate);
	var endDateTime = new Date(endDate).getTime();
	const days = [];
	while (currentDate <= endDateTime) {
		days.push(formatDay(currentDate));
		currentDate.setDate(currentDate.getDate() + 1);
	}
	return days;
}

function daysFromStartOfMonth(date) {
	var dayOfMonth = date.getDate();
	return dayOfMonth;
}

function daysBetweenDates(date1, date2) {
	var utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
	var utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

	var diff = Math.abs(utc2 - utc1);
	var days = Math.floor(diff / (1000 * 60 * 60 * 24));
	return days;
}

const GanttCalendar = ({ tasks, onBoatLogClick, boatLog, setBoatLog, open, setOpen, onClose, canEdit }) => {
	const minimalDates = tasks.map((task) => {
		const dates1 = task.logs.map((log) => new Date(new Date(log.initialDate).toISOString().slice(0, -1)));
		const dates2 = task.additionalDays.map(
			(additionalDay) => new Date(new Date(additionalDay.initialDate).toISOString().slice(0, -1))
		);
		const dates3 = task.turns.map((turn) => new Date(new Date(turn.initialDate).toISOString().slice(0, -1)));
		return Math.min.apply(null, [...dates1, ...dates2, ...dates3]);
	});
	const minDate = new Date(Math.min.apply(null, minimalDates));
	const maxDates = tasks.map((task) => {
		const dates1 = task.logs.map((log) => new Date(new Date(log.endDate).toISOString().slice(0, -1)));
		const dates2 = task.additionalDays.map(
			(additionalDay) => new Date(new Date(additionalDay.endDate).toISOString().slice(0, -1))
		);
		const dates3 = task.turns.map((turn) => new Date(new Date(turn.endDate).toISOString().slice(0, -1)));
		return Math.max.apply(null, [...dates1, ...dates2, ...dates3]);
	});
	const maxDate = new Date(Math.max.apply(null, maxDates));

	const months = getMonthsBetweenDates(minDate, maxDate);
	const pixelDay = 80;
	const days = getDaysInRange(minDate, maxDate);
	const height = 70;

	const { USER_TYPE } = useAuthContext();

	const todayRef = useRef(null);
	const today = new Date();
	const distance = daysBetweenDates(today, minDate);
	

	useEffect(() => {
		if (tasks.length > 0 && todayRef.current) {
			todayRef.current.scrollIntoView({ behavior: "smooth", inline: "center", block: "center"});
		}
	}, [tasks, todayRef]);

	const [anchorEl, setAnchorEl] = useState(null);


	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ display: "flex" }}>
				<Box sx={{ width: "20%" }}>
					{canEdit && <GeneralButton text="Añadir Ciclo" icon={<AddIcon />} onClick={() => setOpen(!open)} />}
					<EventEdit
						boatLog={boatLog}
						setBoatLog={setBoatLog}
						open={open}
						setOpen={setOpen}
						onClose={onClose}
					/>
				</Box>
				<Box sx={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
					<GeneralButton
						text="Ir al principio"
						onClick={() =>
							document
								.getElementById("day0")
								.scrollIntoView({ behavior: "smooth", inline: "start", block: "start" })
						}
					/>

					<GeneralButton
						text="Hoy"
						onClick={() =>
							document
								.getElementById("day" + (distance - 1))
								.scrollIntoView({ behavior: "smooth", inline: "center" })
						}
					/>

					<Box>
						<GeneralButton
							text={"Ir al día ..."}
							onClick={(e) => {
								setAnchorEl(e.currentTarget);
							}}
						/>

						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								horizontal: "left",
								vertical: "bottom",
							}}
							MenuListProps={{
								sx: {
									padding: 0,
								},
							}}
						>
							<TextField
								variant="outlined"
								type="date"
								inputProps={{
									min: tasks.length > 0 && minDate.toISOString().slice(0, 10),
									max: tasks.length > 0 && maxDate.toISOString().slice(0, 10),
								}}
								onChange={(e) => {
									const date = new Date(e.target.value);
									const distance = daysBetweenDates(date, minDate);
									document.getElementById("day" + distance).scrollIntoView({
										behavior: "smooth",
										inline: "center",
									});
								}}
							/>
						</Menu>
					</Box>

					<GeneralButton
						text="Ir al final"
						onClick={() =>
							document
								.getElementById("day" + (days.length - 1))
								.scrollIntoView({ behavior: "smooth", inline: "end" })
						}
					/>
				</Box>
			</Box>
			<Box sx={{ display: "flex" }} id={"ganttCalendar"}>
				<Box sx={{ marginTop: "110px", width: "20%", paddingRight: "3px" }} id="lGanttRows">
					{tasks.map((task, index) => {
						return (
							<Box
								key={index}
								sx={{
									width: "100%",
									minWidth: "100%",
									maxWidth: "100%",
									height: (3 * height) / 2 + 25 + "px",
									borderBottom: "3px dotted #22ACA4",
								}}
							>
								<Box
									sx={{ alignItems: "end", height: "50%", display: "flex", justifyContent: "center" }}
								>
									{task.boat.name}
								</Box>
								<Box
									sx={{
										alignItems: "start",
										height: "50%",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{task.cultivationCenter.name}
								</Box>
							</Box>
						);
					})}
				</Box>
				<Box
					sx={{
						width: "100%",
						overflowX: "scroll",
						overflowY: "hidden",
						paddingBottom: "50px",
					}}
					onScroll={() => {
						const turns = document.querySelectorAll("[id^='turn']");
						const additionalDays = document.querySelectorAll("[id^='additionalDay']");
						const left = document.getElementById("lGanttRows").getBoundingClientRect().right;
						const ganttCalendar = document.getElementById("ganttCalendar");

						turns.forEach((turn) => {
							turn.children[0].children[0].children[0].style.display =
								turn.getBoundingClientRect().left - left > 0 ? "none" : "block";

							const width = ganttCalendar.getBoundingClientRect().right - left;
							const textWidth = turn.children[0].children[0].children[0].getBoundingClientRect().width;

							turn.children[1].style.display =
								turn.getBoundingClientRect().right - left < width ? "none" : "block";
						});

						additionalDays.forEach((additionalDay) => {
							additionalDay.children[0].children[0].children[0].style.display =
								additionalDay.getBoundingClientRect().left - left > 0 ? "none" : "block";

							const width = ganttCalendar.getBoundingClientRect().right - left;
							const textWidth = additionalDay.children[0].children[0].getBoundingClientRect().width;

							additionalDay.children[1].style.display =
								additionalDay.getBoundingClientRect().right - left < width ? "none" : "block";
						});
					}}
				>
					<Box sx={{ display: "flex" }}>
						{months.map((date, index) => {
							const width =
								index === 0 && index !== months.length - 1
									? daysUntilEndOfMonth(minDate)
									: index === months.length - 1
									? daysFromStartOfMonth(maxDate)
									: getDaysInMonth(date.year, date.month);

							return (
								<Box
									key={index}
									sx={{
										padding: "5px 20px",
										backgroundColor: "#22ACA4",
										alignItems: "center",
										display: "flex",
										color: "white",
										minWidth: width * pixelDay + "px",
										maxWidth: width * pixelDay + "px",
										maxHeight: "50px",
										minHeight: "50px",
										border: "1px solid #D7D7D7",
										whiteSpace: "nowrap",
										position: "relative",
										borderRadius: "10px 10px 0 0",
									}}
								>
									<Box sx={{ position: "sticky", left: "20px" }}>
										{monthNames[date.month - 1] + " " + date.year}
									</Box>
								</Box>
							);
						})}
					</Box>
					<Box sx={{ display: "flex" }}>
						{days.map((day, index) => (
							<Box
								key={index}
								sx={{
									width: pixelDay + "px",
									maxWidth: pixelDay + "px",
									minWidth: pixelDay + "px",
									border: "1px solid #D7D7D7",
									textAlign: "center",
									padding: "5px 1px",
									maxHeight: "60px",
									minHeight: "60px",
									position: "relative",
								}}
								ref={index === distance ? todayRef : null}
								id={"day" + index}
							>
								{index === distance && (
									<Box
										sx={{
											position: "absolute",
											top: "-22px",
											width: "100%",
											padding: "2px 5px",
											backgroundColor: "#22ACA4",
											borderRadius: "15px",
											border: "2px solid orange",
											color: "white",
										}}
									>
										Hoy
									</Box>
								)}
								<Box>{day.num}</Box>
								<Box>{day.name}</Box>
							</Box>
						))}
					</Box>
					{tasks.map((task, index) => (
						<Box
							key={index}
							sx={{
								display: "flex",
								margin: "5px 0 0",
								height: (3 * height) / 2 + 20 + "px",
							}}
						>
							<Box key={index} sx={{ display: "flex", position: "relative" }}>
								{task.logs.map((log, index) => (
									<Box
										key={index}
										sx={{
											position: "absolute",
											left:
												daysBetweenDates(
													new Date(new Date(log.initialDate).toISOString().slice(0, -1)),
													minDate
												) *
													pixelDay +
												"px",
											minWidth:
												(daysBetweenDates(
													new Date(new Date(log.initialDate).toISOString().slice(0, -1)),
													new Date(new Date(log.endDate).toISOString().slice(0, -1))
												) +
													1) *
													pixelDay +
												"px",
											border: "2px solid #22ACA4",
											height: height + "px",
											backgroundColor: "#DEF3F1",
											padding: "10px 20px",
											borderRadius: "25px",
											cursor: USER_TYPE === "Admin" ? "pointer" : "default",
										}}
										onClick={() => {
											USER_TYPE === "Admin" && onBoatLogClick(log.boatLog);
										}}
									>
										<Box sx={{ fontSize: "12px" }}>{task.cultivationCenter.name}</Box>
										<Box>{task.boat.name}</Box>
									</Box>
								))}
							</Box>

							{task.turns.map((turn, index) => (
								<Box
									key={index}
									sx={{
										display: "flex",
										height: height + "px",
										position: "relative",
									}}
								>
									<Tooltip
										title={
											"Fecha de inicio: " +
											turn.initialDate +
											"\nFecha de Término: " +
											turn.endDate
										}
										key={index}
									>
										<Box
											sx={{
												left:
													daysBetweenDates(
														new Date(new Date(turn.initialDate).toISOString().slice(0, -1)),
														minDate
													) *
														pixelDay +
													"px",
												minWidth:
													(daysBetweenDates(
														new Date(new Date(turn.initialDate).toISOString().slice(0, -1)),
														new Date(new Date(turn.endDate).toISOString().slice(0, -1))
													) +
														1) *
														pixelDay +
													"px",
												height: height / 2 + "px",
												top: height + 5 + "px",
												backgroundColor: "#F5F5F5",
												padding: "5px 15px",
												border: "1px solid #D7D7D7",
												borderRadius: "10px",
												position: "absolute",
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												whiteSpace: "nowrap",
											}}
											id={"turn" + turn.id}
										>
											<Box
												sx={{
													position: "sticky",
													left: "0px",
													display: "flex",
												}}
											>
												<Box sx={{ display: "flex" }}>
													<ArrowLeftIcon sx={{ display: "none" }} />
													{turn.ROVOperator.user.first_name +
														" " +
														turn.ROVOperator.user.last_name}
												</Box>
											</Box>
											<ArrowRightIcon
												sx={{ position: "sticky", right: "0", backgroundColor: "inherit" }}
											/>
										</Box>
									</Tooltip>
								</Box>
							))}

							{task.additionalDays.map((additionalDay, index) => (
								<Box
									key={index}
									sx={{
										display: "flex",
										height: height + "px",
										position: "relative",
										margin: "15px 0 0",
									}}
								>
									<Tooltip
										title={
											"Fecha de inicio: " +
											additionalDay.initialDate +
											"\nFecha de Término: " +
											additionalDay.endDate
										}
										key={index}
									>
										<Box
											sx={{
												position: "relative",
												left:
													daysBetweenDates(
														new Date(
															new Date(additionalDay.initialDate)
																.toISOString()
																.slice(0, -1)
														),
														minDate
													) *
														pixelDay +
													"px",
												minWidth:
													(daysBetweenDates(
														new Date(
															new Date(additionalDay.initialDate)
																.toISOString()
																.slice(0, -1)
														),
														new Date(
															new Date(additionalDay.endDate).toISOString().slice(0, -1)
														)
													) +
														1) *
														pixelDay +
													"px",
												height: height / 2 + "px",
												backgroundColor: "orange",
												padding: "5px 15px",
												borderRadius: "10px",
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												whiteSpace: "nowrap",
											}}
											id={"additionalDay" + additionalDay.id}
										>
											<Box
												sx={{
													position: "sticky",
													left: "0px",
													display: "flex",
												}}
											>
												<Box sx={{ display: "flex" }}>
													<ArrowLeftIcon sx={{ display: "none" }} />
													{additionalDay.description}
												</Box>
											</Box>
											<ArrowRightIcon
												sx={{ position: "sticky", right: "0", backgroundColor: "inherit" }}
											/>
										</Box>
									</Tooltip>
								</Box>
							))}
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
};

export default GanttCalendar;
