import React, { useContext, useEffect, useState } from "react";
import { Button, IconButton, Box, Grid, TextField, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { ReportDataContext, ReportErrorContext, MessagesContext, AuthContext } from "../../../context";
import { CoordinatesInput } from "../../CoordInput";
import ImageMapModal from "./ImageMapModal";
import { useTheme } from "@mui/material/styles";
import IconButtons from "../../Icons/IconButtons";
import DeleteIcon from "../../Icons/DeleteIcon";

const GeographicImages = () => {
	const { reportData, setReportData } = useContext(ReportDataContext); // files = [{file: File, comment: String}]
	const { setCheckErrors } = useContext(ReportErrorContext);
	const { addMessage } = useContext(MessagesContext);
	const [errors, setErrors] = React.useState([]);
	const theme = useTheme();

	useEffect(() => {
		setCheckErrors(() => () => {
			let hasErrors = false;
			const newErrors = [];
			reportData.geoImagesList.forEach((item, index) => {
				// console.log(item)
				if (!item.latitude || !item.longitude) {
					newErrors.push({ error: true, message: "Coordenadas inválidas" });
				} else {
					newErrors.push({ error: false, message: "" });
				}
			});
			setErrors(newErrors);
			newErrors.forEach((item) => {
				if (item.error) {
					hasErrors = true;
				}
			});
			return hasErrors;
		});
	}, [setCheckErrors, reportData.geoImagesList]);

	// console.log(errors);
	const maxImages = 100;
	const maxSize = 100 * 1024 * 1024; // 100 MB

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".jpg", ".png"],
		},
		maxFiles: maxImages,
		onDrop: (acceptedFiles) => {
			const updatedFiles = acceptedFiles.map((file) => {
				if (file.size > maxSize) {
					addMessage(
						`La imagen ${file.name} excede el tamaño máximo permitido de ${maxSize / 1024 / 1024}MB`,
						"error"
					);
					return null;
				} else {
					return {
						image: Object.assign(file, { preview: URL.createObjectURL(file) }),
						description: "",
						latitude: "",
						longitude: "",
					};
				}
			});

			//no se toman los que son nulos (los que excedieron el tamaño)
			const validFiles = updatedFiles.filter((file) => file !== null);
			setReportData({ ...reportData, geoImagesList: [...reportData.geoImagesList, ...validFiles] });
		},
	});

	const handleCommentChange = (index, comment) => {
		const updatedFiles = [...reportData.geoImagesList];
		updatedFiles[index].description = comment;
		setReportData({ ...reportData, geoImagesList: [...updatedFiles] });
	};
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const { canUseMapTools } = useContext(AuthContext);
	const [zoom, setZoom] = useState(16); // [zoom, setZoom
	const onRowClick = (index) => {
		setIsModalOpen(true);
		setSelectedImage(index);
	};
	const onModalClose = () => {
		setIsModalOpen(false);
		setSelectedImage(null);
	};

	return (
		<div style={{ padding: "20px 30px" }}>
			<ImageMapModal
				object={reportData.geoImagesList[selectedImage]}
				setObject={(obj) => {
					setReportData((prev) => {
						prev.geoImagesList[selectedImage].latitude = obj.latitude;
						prev.geoImagesList[selectedImage].longitude = obj.longitude;
						return { ...prev };
					});
				}}
				otherObjects={reportData.geoImagesList
					.filter((_, i) => i !== selectedImage)
					.filter((item) => item.latitude && item.longitude)}
				reportData={reportData}
				mapZoom={zoom}
				setMapZoom={setZoom}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				onClose={onModalClose}
			/>
			<div
				{...getRootProps({ className: "dropzone" })}
				style={{
					border: `4px dashed ${theme.palette.brandColors.gray}`,
					borderRadius: "10px",
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "200px",
					position: "relative",
				}}
			>
				<input {...getInputProps()} />
				<CloudUploadOutlinedIcon style={{ color: "grey", fontSize: "3rem" }} />
				<p style={{ color: "grey", textAlign: "center", margin: "0px 0" }}>
					<strong>Arrastra las imágenes aquí, o click para seleccionar</strong>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>{reportData.geoImagesList.length} agregada(s)</em>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>máximo {maxImages}</em>
				</p>
			</div>

			<div>
				{reportData.geoImagesList.map((item, index) => (
					<Box
						key={index}
						sx={{
							height: "auto",
							display: "flex",
							padding: "20px 0",
							gap: "20px",
						}}
					>
						
						<img
							src={typeof item.image === "object" ? item.image.preview : item.image}
							alt="Error con la imagen"
							// Data uri is revoked when submited/exit
							style={{
								display: "block",
								height: "100%",
								width: "auto",
								maxHeight: "150px",
								maxWidth: "100%",
								borderRadius: "10px",
							}}
						/>
						<div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
							<div>
								<CoordinatesInput
									setter={(latlon) => {
										setReportData((prev) => {
											prev.geoImagesList[index].latitude = latlon.latitude;
											prev.geoImagesList[index].longitude = latlon.longitude;
											return { ...prev };
										});
									}}
									coordinates={{ latitude: item.latitude, longitude: item.longitude }}
									error={{
										latitude: errors[index]?.error,
										longitude: errors[index]?.error,
									}}
									helperText={{
										latitude: errors[index]?.message,
										longitude: errors[index]?.message,
									}}
									key={index}
									column
								>
									{canUseMapTools && (
										<div style={{ display: "flex", alignItems: "center" }}>
											<Button
												sx={{
													backgroundColor: theme.palette.brandColors.primary,
													color: "white",
													padding: "5px 20px",
													borderRadius: "10px",
													boxSizing: "border-box",
													textWrap: "nowrap",
													"&:hover": {
														backgroundColor: theme.palette.brandColors.primary,
														color: "white",
													},
												}}
												onClick={() => onRowClick(index)}
											>
												Ver ubicación en mapa
											</Button>
										</div>
									)}
								</CoordinatesInput>
							</div>
							<TextField
								label="Añadir un comentario (opcional)..."
								variant="outlined"
								multiline
								rows={5}
								fullWidth
								style={{ flex: 1 }}
								value={item.description}
								onChange={(e) => handleCommentChange(index, e.target.value)}
							/>
						</div>
						<IconButtons
							icon={<DeleteIcon />}
							variant="danger"
							onClick={() => {
								// elimina la imagen de la lista
								// si la imagen ya estaba en la db, tambien se agrega a la lista de eliminados
								if (item.id) {
									setReportData({
										...reportData,
										geoImagesDeleted: [...reportData.geoImagesDeleted, item],
										geoImagesList: reportData.geoImagesList.filter((_, i) => i !== index),
									});
								} else {
									setReportData({
										...reportData,
										geoImagesList: reportData.geoImagesList.filter((_, i) => i !== index),
									});
								}
							}}
						/>
					</Box>
				))}
			</div>
		</div>
	);
};

export default GeographicImages;
