import { useState, useEffect, useContext } from "react";
import { Summary, Graphs } from "../components/Stats";
import { URLS } from "../global";
import { AuthContext } from "../context";
import { FormControl, InputLabel, Select, MenuItem, Box, CircularProgress, TextField, Breadcrumbs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { generateStructurePointsFromCentros } from "../utils/map_helper";

// Constante para guardar el estado del tipo de estadística
export const aggregations = {
	GENERAL: { label: "Estadísticas Generales", type: "GENERAL", objectName: "Área" },
	AREA: { label: "Estadísticas por Área", type: "AREA", objectName: "Centro de Cultivo" },
	CENTER: { label: "Estadísticas por Centro", type: "CENTER", objectName: "Objeto" },
};

// Componente para mostrar el dashboard
const DashBoard = () => {
	const theme = useTheme();

	const currentDate = new Date();
	const oneYearAgo = new Date(currentDate);
	oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
	const formattedDate = oneYearAgo.toISOString().split("T")[0];

	const { axiosInstance, USER_TYPE } = useContext(AuthContext);
	const [generalStats, setGeneralStats] = useState({});
	const [loading, setLoading] = useState(true);

	const [salmonCompany, setSalmonCompany] = useState(undefined); // Salmonera seleccionada
	const [salmonCompanyData, setSalmonCompanyData] = useState(undefined); // Datos de todas las salmoneras

	// Fechas seleccionadas en el rango
	const [initialDate, setInitialDate] = useState(formattedDate);
	const [finalDate, setFinalDate] = useState(currentDate.toISOString().split("T")[0]);

	// Tipo de estadística seleccionada (General, Área o Centro)
	const [type, setType] = useState(aggregations.GENERAL);

	// Área o centro seleccionado según el tipo de estadística
	const [selectedArea, setSelectedArea] = useState(undefined);
	const [selectedCenter, setSelectedCenter] = useState(undefined);

	const [mapStructures, setMapStructures] = useState({
		heatmap: [],
		normal: [],
	}); // estructuras que se muestran en el mapa

	useEffect(() => {
		document.title = "Rastrum | Dashboard";
		axiosInstance
			.get(URLS.CENTERS)
			.then((response) => {
				if (response.status === 200) {
					// guardamos las estructuras para el mapa
					const normalData = generateStructurePointsFromCentros(response.data);
					const coords = response.data.map((center) => center.coordinates);
					const parsedCoords = coords.map((coord) => coord.split(";").map((c) => c.split(",").map((c) => parseFloat(c))));
					const heatmapData = parsedCoords.map((coord) => coord.map((c) => [c[1], c[0]]));
					setMapStructures({
						heatmap: heatmapData,
						normal: normalData,
					});
				}
			})
			.catch((error) => {
				// por ahora no hago nada
			});
	}, [axiosInstance]);

	useEffect(() => {
		if (USER_TYPE === "Cliente") {
		} else {
			// Si es un usuario de tipo administrador, se obtienen las salmoneras para mostrarlas en el select
			axiosInstance.get(URLS.SALMON_COMPANY).then((res) => {
				setSalmonCompanyData(res.data);
				res.data.length > 0 && setSalmonCompany(res.data[0].id);
			});
		}
	}, [axiosInstance, USER_TYPE]);

	const handleSamoneraChange = (e) => {
		setType(aggregations.GENERAL);
		setSalmonCompany(e.target.value);
		setLoading(true);
	};

	// Fetch de las estadísticas según el tipo de estadística, la salmonera y el rango de fechas
	useEffect(() => {
		setLoading(true);
		const selectedObject = type.type === aggregations.AREA.type ? selectedArea.id : type.type === aggregations.CENTER.type ? selectedCenter.id : "";
		if (USER_TYPE === "Cliente") {
			axiosInstance.get(URLS.STATS + type.type + "/" + 0 + "/" + initialDate + "/" + finalDate + "/?object=" + selectedObject).then((res) => {
				setGeneralStats(res.data);
				setLoading(false);
			});
		} else if (salmonCompany) {
			axiosInstance.get(URLS.STATS + type.type + "/" + salmonCompany + "/" + initialDate + "/" + finalDate + "/?object=" + selectedObject).then((res) => {
				setGeneralStats(res.data);
				setLoading(false);
			});
		}
	}, [salmonCompany, initialDate, finalDate, type, selectedCenter, selectedArea, axiosInstance, USER_TYPE]);

	const linkStyle = {
		cursor: "pointer",
		color: theme.palette.brandColors.primary,
		fontSize: "20px",
		"&:hover": {
			textDecoration: "underline",
		},
	};

	return (
		<>
			{loading ? (
				<Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
					<CircularProgress />
				</Box>
			) : (
				<Box sx={{ backgroundColor: theme.palette.brandColors.white, width: "100%" }}>
					<Box sx={{ display: "flex", marginBottom: "20px", justifyContent: "space-between", padding: "20px 20px 0" }}>
						{/** Box para mostrar el tipo de estadística, la salmonera, el área y el centro seleccionados */}
						<Breadcrumbs
							separator={<NavigateNextIcon />}
							sx={{
								display: "flex",
								alignItems: "center",
								boxShadow: "0 0 10px 5px #e8e8e8",
								padding: "0 20px",
								width: "100%",
								marginRight: "20px",
							}}
						>
							<div onClick={() => setType(aggregations.GENERAL)} style={linkStyle}>
								{USER_TYPE !== "Cliente" ? salmonCompanyData.filter((salmonera) => salmonera.id === salmonCompany)[0].name : "General"}
							</div>
							{type.type !== aggregations.GENERAL.type && (
								<div onClick={() => setType(aggregations.AREA)} style={linkStyle}>
									{selectedArea.name}
								</div>
							)}
							{type.type === aggregations.CENTER.type && (
								<div onClick={() => setType(aggregations.CENTER)} style={linkStyle}>
									{selectedCenter.name}
								</div>
							)}
						</Breadcrumbs>
						<Box sx={{ display: "flex", gap: "20px" }}>
							{USER_TYPE !== "Cliente" && (
								<FormControl sx={{ minWidth: "200px", width: "auto" }}>
									<InputLabel>Empresa productora</InputLabel>
									<Select value={salmonCompany} label="Salmonera" onChange={handleSamoneraChange} sx={{ boxShadow: "0 0 10px 5px #e8e8e8" }}>
										{salmonCompanyData &&
											salmonCompanyData.map((salmonera) => (
												<MenuItem key={salmonera.id} value={salmonera.id}>
													{salmonera.name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							)}
							<Box>
								<FormControl sx={{ width: "100%" }}>
									<TextField
										variant="outlined"
										type="date"
										label="Fecha Inicial"
										value={initialDate}
										onChange={(e) => setInitialDate(e.target.value)}
										sx={{ boxShadow: "0 0 10px 5px #e8e8e8" }}
									/>
								</FormControl>
							</Box>
							<Box>
								<FormControl sx={{ width: "100%" }}>
									<TextField
										variant="outlined"
										type="date"
										label="Fecha Final"
										value={finalDate}
										onChange={(e) => setFinalDate(e.target.value)}
										sx={{ boxShadow: "0 0 10px 5px #e8e8e8" }}
									/>
								</FormControl>
							</Box>
						</Box>
					</Box>

					{type.type !== aggregations.CENTER.type && <Summary data={generalStats} type={type} />}
					<Graphs
						data={generalStats}
						type={type}
						setType={setType}
						setSelectedArea={setSelectedArea}
						setSelectedCenter={setSelectedCenter}
						mapStructures={mapStructures}
					/>
				</Box>
			)}
		</>
	);
};

export default DashBoard;
