import { useEffect, useState } from "react";

// mui components
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";

// components
import {
	WreckRescueCompanies,
	SalmonCompanies,
	ROVOperators,
	ObjectTypes,
	CsvImporter,
	Boats,
	CultivationCenterCagesList,
} from "../components/AdminPanel";

// context
import { useAuthContext } from "../context";
import FishIcon from "../components/Icons/FishIcon";
import UserIcon from "../components/Icons/UserIcon";
import BoatIcon from "../components/Icons/BoatIcon";
import BoxIcon from "../components/Icons/BoxIcon";

const USER_TYPES = {
	ADMIN: "ADMIN",
	SUPER_ADMIN: "SUPER ADMIN",
	ROV_OPERATOR: "OPERADOR ROV",
};

const AdminContent = ["Empresa Productora", "Operadores", "Embarcaciones", "Tipos de Objeto", "Importar CSV"];
const SuperAdminContent = ["Empresas", "Tipos de Objeto"];

const ROVOperatorContent = ["Centros de Cultivo"];

const ContentRenderer = ({ content, setHide }) => {
	switch (content) {
		case "Empresa Productora":
			return <SalmonCompanies setHide={setHide} />;
		case "Operadores":
			return <ROVOperators setHide={setHide} />;
		case "Embarcaciones":
			return <Boats />;
		case "Empresas":
			return <WreckRescueCompanies setHide={setHide} />;
		case "Tipos de Objeto":
			return <ObjectTypes />;
		case "Importar CSV":
			return <CsvImporter />;
		case "Centros de Cultivo":
			return <CultivationCenterCagesList />;
		default:
			return <div>Content not found</div>;
	}
};

const ContentIcon = ({ content, contentIndex }) => {
	switch (content) {
		case "Empresa Productora":
			return <FishIcon color={contentIndex === 0 ? null : "#848591"} />;
		case "Operadores":
			return <UserIcon color={contentIndex === 1 ? null : "#848591"} />;
		case "Embarcaciones":
			return <BoatIcon color={contentIndex === 2 ? null : "#848591"} />;
		case "Tipos de Objeto":
			return <BoxIcon color={contentIndex === 3 ? null : "#848591"} />;
		default:
			return null;
	}
};

const getContents = (USER_TYPE) => {
	switch (USER_TYPE?.toUpperCase()) {
		case USER_TYPES.ADMIN:
			return AdminContent;
		case USER_TYPES.SUPER_ADMIN:
			return SuperAdminContent;
		case USER_TYPES.ROV_OPERATOR:
			return ROVOperatorContent;
		default:
			return [];
	}
};

const AdminPanelPage = () => {
	const { USER_TYPE } = useAuthContext();
	const [contentIndex, setContentIndex] = useState(0);
	const [hide, setHide] = useState(false);
	const [contents] = useState(getContents(USER_TYPE));
	const theme = useTheme();

	const handleChange = (_event, newValue) => {
		setContentIndex(newValue);
	};

	useEffect(() => {
		document.title = "Rastrum | Administración";
	}, []);

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				padding: "20px 0",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					mb: "10px",
					width: "100%", // this width controls the width of the grid
					display: "flex",
					justifyContent: "center",
				}}
			>
				{!hide && (
					<Tabs
						value={contentIndex}
						onChange={handleChange}
						centered
						sx={{
							marginLeft: "auto",
							marginRight: "auto",
							display: "flex",
							justifyContent: "center",
							width: "100%",
						}}
					>
						{USER_TYPE &&
							contents.map((c, index) => (
								<Tab
									key={c}
									label={c}
									value={index}
									iconPosition="start"
									sx={{
										borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
										gap: "7px",
									}}
									icon={<ContentIcon content={c} contentIndex={contentIndex} />}
								/>
							))}
					</Tabs>
				)}
			</Box>
			<ContentRenderer content={contents[contentIndex]} setHide={setHide} />
		</Box>
	);
};

export default AdminPanelPage;
