import { useEffect, useState, useCallback } from "react";

// mui components
import { useTheme, styled } from "@mui/material/styles";
import { Box, CircularProgress, Modal, Button, Tabs, Tab, Menu, MenuItem } from "@mui/material";

// mui icons

import { useSearchParams } from "react-router-dom";

// context
import { useAuthContext, useMessagesContext } from "../../context";

// components
import CreateReport from "./Create/CreateReport";
import { STATES } from "./Create/StatesConstants";
import WarningDialog from "../WarningDialog";
import ConsolidatedReport from "./ConsolidatedReports/ConsolidatedReport";
import { initialData } from "./ReportConstants";
import RenderEstado from "./StateRender";

// global
import { URLS } from "../../global";
import CreateButton from "../CreateButton";
import { ReportDataProvider } from "../../context";
import UploadFromExcel from "./UploadFromExcel/UploadFromExcel";
import Reports from "./ConsolidatedReports/Reports";
import ReportPDFViewer from "./ReportPDFViewer";
import ReceptionNote from "./ReceptionNote/ReceptionNote";
import { Search, Table } from "../Table";
import IconButtons from "../Icons/IconButtons";
import EyeIcon from "../Icons/EyeIcon";
import EditIcon from "../Icons/EditIcon";
import DeleteIcon from "../Icons/DeleteIcon";
import DownloadItem from "./DownloadItem";

const ContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	height: "100%",
	maxHeight: "100%",
	overflowY: "auto",
	display: "flex",
	flexDirection: "column",
}));

export default function ReportGrid() {
	const { axiosInstance, USER_TYPE } = useAuthContext();
	const { addMessage } = useMessagesContext();
	const [reportData, setReportData] = useState(JSON.parse(JSON.stringify(initialData)));

	const theme = useTheme();
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [openModal, setOpenModal] = useState(false);
	const [openModalEdit, setOpenModalEdit] = useState(false);
	const [optionsLoading, setOptionsLoading] = useState(false);
	const [search, setSearch] = useState("");

	const get_reports = useCallback(async () => {
		setLoading(true);
		axiosInstance
			.get(URLS.REPORTS)
			.then((response) => {
				setRows(response.data);
			})
			.catch((error) => {
				console.log(error.response.status);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [axiosInstance]);

	useEffect(() => {
		get_reports();
	}, [get_reports]);

	async function downloadPDF(ID, onClose) {
		setOptionsLoading(true);
		// funcion que despues va a descargar el archivo
		axiosInstance
			.get(`${URLS.REPORTS}${ID}/get_pdf/`, { responseType: "blob" })
			.then((response) => {
				const data = response.data;
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement("a");

				const contentDisposition = response.headers["content-disposition"];
				let fileName = "Informe"; // Default name if header is missing
				if (contentDisposition) {
					const fileNameMatch = contentDisposition.match(/filename='(.+)'/);
					if (fileNameMatch.length === 2) {
						fileName = fileNameMatch[1];
					}
				}

				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => {
				console.log(error);
				addMessage("Error al descargar el informe", "error");
			})
			.finally(() => {
				setOptionsLoading(false);
				onClose();
			});
	}

	async function downloadHTML(ID, onClose) {
		setOptionsLoading(true);
		axiosInstance
			.get(`${URLS.REPORTS}${ID}/get_html/`)
			.then((response) => {
				const data = response.data;
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement("a");

				const contentDisposition = response.headers["content-disposition"];
				let fileName = "Informe"; // Default name if header is missing
				if (contentDisposition) {
					const fileNameMatch = contentDisposition.match(/filename='(.+)'/);
					if (fileNameMatch.length === 2) {
						fileName = fileNameMatch[1];
					}
				}

				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => {
				console.log(error);
				addMessage("Error al descargar el informe", "error");
			})
			.finally(() => {
				setOptionsLoading(false);
				onClose();
			});
	}

	const handleOpenModal = () => setOpenModal(true);
	function handleCloseModal() {
		setReportData(JSON.parse(JSON.stringify(initialData)));
		setOpenModal(false);
	}

	function handleOpenModalEdit() {
		setOpenModal(false);
		setOpenModalEdit(true);
		setReportData((prev) => ({ ...prev, isEdit: true }));
	}

	const handleCloseModalEdit = (e, reason) => {
		if (reason === "backdropClick") return;
		if (reason === "success") {
			handleConfirmExitCreate();
			return;
		}
		setConfirmOpen(true);
	};

	/**
	 * parsea la info del informe seleccionado
	 * para poder ser usada por el componente ReportPreview
	 *
	 * @param row info de la fila seleccionada
	 * @param modalFunction funcion que abre el modal correspondiente
	 */
	async function dataPreview(row, modalFunction) {
		let selectedData = {};
		await axiosInstance.get(`${URLS.REPORTS}${row.id}/get_editdata/`).then((response) => {
			selectedData = response.data;
			selectedData.estado_report = row.estadoText;
			selectedData.boat.label = response.data.boat?.name;
			selectedData.cultivationCenter = {
				...selectedData.cultivationCenter,
				label: response.data.cultivationCenter.name,
			};
			selectedData.reportType =
				STATES[Object.keys(STATES).filter((type) => STATES[type].label === response.data.type)[0]];
			selectedData.beaconObjects = response.data.rescuedObjects;
			selectedData.creator = response.data.ROVOperator;
			selectedData.feedingBacteriumImages = response.data.feedingAndBacteriumImages;
			for (let i = 0; i < selectedData.objectsList.length; i++) {
				selectedData.objectsList[i].type.label = selectedData.objectsList[i].type.name;
				selectedData.objectsList[i].type.metrics = selectedData.objectsList[i].type.unit || "Unidad";
				selectedData.objectsList[i].type.conversionRate = selectedData.objectsList[i].type.weight || 1; // Peso/densidad por unidad
				selectedData.objectsList[i].amount =
					selectedData.objectsList[i].volume / selectedData.objectsList[i].type.weight;
				selectedData.objectsList[i].database_id = selectedData.objectsList[i].id; // id !== -1 -> objeto se está editando
				selectedData.objectsList[i].id = i; // se asigna id local del informe
				selectedData.objectsList[i].beacon = selectedData.objectsList[i].state === "Balizado" ? true : false;
			}
			selectedData.initialCounter = selectedData.objectsList.length;
		});

		setReportData({
			...JSON.parse(JSON.stringify(initialData)),
			...selectedData,
		});
		modalFunction();
	}

	const columns = [
		{ field: "id", headerName: "ID", sortable: true },
		{ field: "type", headerName: "Tipo", sortable: true },
		{ field: "cultivationCenter", headerName: "Centro", sortable: true },
		{ field: "date", headerName: "Fecha", sortable: true, type: "date" },
		{ field: "objectsCount", headerName: "Cantidad de Objetos", sortable: true },
		{ field: "ROVOperator", headerName: "Realizado Por", sortable: true },
	]
		.concat(
			USER_TYPE !== "Cliente"
				? [
						{
							field: "state",
							headerName: "Estado",
							sortable: true,
							renderCell: (row) => (
								<RenderEstado row={row} url={URLS.REPORTS} setLoading={setOptionsLoading} setRows={setRows} loading={optionsLoading} />
							),
						},
				  ]
				: []
		)
		.concat({
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", gap: "10px", justifyContent: "end" }}>
					<IconButtons icon={<EyeIcon />} onClick={() => dataPreview(row, handleOpenModal)} disabled={optionsLoading} />
					{USER_TYPE !== "Cliente" && (
						<IconButtons
							disabled={row.state === "Revisado" || optionsLoading}
							icon={<EditIcon />}
							onClick={() => dataPreview(row, handleOpenModalEdit)}
						/>
					)}
					<DownloadItem
						id={row.id}
						downloadHTML={downloadHTML}
						downloadPDF={downloadPDF}
						disabled={optionsLoading}
					/>
					{USER_TYPE === "Admin" && (
						<IconButtons variant="danger" icon={<DeleteIcon />} onClick={() => handleDelete(row.id)} disabled={optionsLoading} />
					)}
				</div>
			),
		});

	const [confirmDelete, setConfirmDelete] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const handleDelete = async (id) => {
		setDeleteId(id);
		setConfirmDelete(true);
	};
	const handleConfirmExit = () => {
		setOptionsLoading(true);
		axiosInstance
			.delete(`${URLS.REPORTS}${deleteId}/`)
			.then((res) => {
				addMessage("Peticion de eliminación enviada", "success");
				setOptionsLoading(false);
			})
			.catch((err) => {
				if (err.response.status === 400) {
					console.log(err);
					addMessage(err.response.data.error, "error");
				}
				addMessage("Error al eliminar el informe", "error");
			});
		setConfirmDelete(false);
	};

	const [searchParams] = useSearchParams();
	const [consolidatedReportId, setConsolidatedReportId] = useState(null);

	useEffect(() => {
		if (searchParams.get("id") !== null) {
			if (searchParams.get("consolidados") !== null) {
				setWindowTab(2);
				setConsolidatedReportId(searchParams.get("id"));
			} else {
				axiosInstance
					.get(`${URLS.REPORTS}${searchParams.get("id")}/get_editdata/`)
					.then((response) => {
						dataPreview(response.data, handleOpenModal);
					})
					.catch((error) => {
						console.log(error.response.status);
					});
			}
		}
	}, [searchParams.get("id")]);

	const [isConsolidatedModalOpen, setIsConsolidatedModalOpen] = useState(false);
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [isCreating, setIsCreating] = useState(false);

	useEffect(() => {
		setReportData((prev) => ({
			...prev,
			isEdit: openModalEdit,
		}));
	}, [openModalEdit]);

	const [confirmOpen, setConfirmOpen] = useState(false);

	const handleCancelExitCreate = () => {
		setConfirmOpen(false);
	};

	const handleCloseCreating = (e, reason) => {
		if (reason === "backdropClick") return;
		if (reason === "success") {
			setReportData(JSON.parse(JSON.stringify(initialData)));
			handleConfirmExitCreate();
			return;
		}
		setConfirmOpen(true);
	};

	const handleConfirmExitCreate = () => {
		reportData.imagesList.forEach((item) => URL.revokeObjectURL(item.image.preview));
		reportData.objectsList.forEach((item) => {
			if (item.submarineImage) URL.revokeObjectURL(item.submarineImage.preview);
			if (item.inBoatImage) URL.revokeObjectURL(item.inBoatImage.preview);
		});
		setReportData(JSON.parse(JSON.stringify(initialData)));
		setConfirmOpen(false);
		setIsCreating(false);
		get_reports();
		setOpenModalEdit(false);
	};

	const [windowTab, setWindowTab] = useState(0);
	const [reloadConsolidated, setReloadConsolidated] = useState(false);

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				position: "relative",
				padding: "20px 0",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<div
				style={{
					textAlign: "center",
					marginBottom: "20px",
					width: "100%",
				}}
			>
				<Modal
					open={isConsolidatedModalOpen}
					onClose={() => {
						setIsConsolidatedModalOpen(false);
					}}
				>
					<Box>
						<ConsolidatedReport
							onClose={() => {
								setIsConsolidatedModalOpen(false);
								setReloadConsolidated(true);
							}}
						/>
					</Box>
				</Modal>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						position: "relative",
						zIndex: 1,
					}}
				>
					<Tabs
						value={windowTab}
						onChange={(e, newValue) => setWindowTab(newValue)}
						centered
						sx={{
							borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
						}}
					>
						<Tab
							label="Diario"
							sx={{
								backgroundColor: "none !important",
								fontSize: "15px",
								fontWeight: 600,
							}}
						/>
						<Tab label="Notas de Salida" />
						<Tab label="Consolidado" />
					</Tabs>
				</div>
			</div>
			{windowTab === 1 && <ReceptionNote />}
			{windowTab === 2 && (
				<>
					{(USER_TYPE === "Admin" || USER_TYPE === "Operador ROV") && (
						<div
							style={{
								position: "absolute",
								top: "30px",
								left: "20px",
								zIndex: 1,
							}}
						>
							<CreateButton onClick={() => setIsConsolidatedModalOpen(true)} text="Crear Consolidado" />
						</div>
					)}
					<Reports
						initialId={consolidatedReportId}
						setInitialId={(id) => {
							setConsolidatedReportId(id);
						}}
						reloadData={reloadConsolidated}
					/>
				</>
			)}
			{windowTab === 0 && (
				<>
					<WarningDialog
						open={confirmDelete}
						onClose={() => setConfirmDelete(false)}
						title={"Confirmar eliminación del informe"}
						text={
							"Al eliminar el informe, se creará una petición de eliminación del informe para confirmar esta acción. ¿Desea continuar?"
						}
						handleButton1={() => setConfirmDelete(false)}
						Button1Text={"Cancelar"}
						handleButton2={handleConfirmExit}
						Button2Text={"Eliminar"}
					/>
					<>
						{USER_TYPE === "Operador ROV" && (
							<>
								<Modal open={isCreating} onClose={handleCloseCreating} disableEscapeKeyDown>
									<Box>
										<ReportDataProvider initialData={reportData} handleClose={handleCloseCreating}>
											<CreateReport handleClose={handleCloseCreating} />
										</ReportDataProvider>
									</Box>
								</Modal>
								<div
									style={{
										position: "absolute",
										top: "30px",
										left: "20px",
										zIndex: 1,
									}}
								>
									<CreateButton text="Crear Informe" onClick={() => setIsCreating(true)} />
								</div>

								<div
									style={{
										position: "absolute",
										top: "30px",
										right: "20px",
										zIndex: 1,
									}}
								>
									<Button
										style={{
											backgroundColor: "white",
											color: theme.palette.brandColors.primary,
											marginBottom: "20px",
											borderRadius: "10px",
											border: `1px solid ${theme.palette.brandColors.primary}`,
											padding: "6px 20px",
										}}
										onClick={() => setIsUploadModalOpen(true)}
									>
										Importar Desde Excel
									</Button>
								</div>
							</>
						)}
						<ContentContainer>
							{!loading ? (
								<>
									<div style={{ padding: "0 20px 20px", width: "300px" }}>
										<Search search={search} setSearch={setSearch} />
									</div>
									<Table
										columns={columns}
										filter={search}
										rows={rows.map((row) => ({
											...row,
											estadoCircle: row.state,
											estadoText: row.state,
										}))}
										sorting={{
											field: "date",
											sort: "desc",
										}}
									/>
								</>
							) : (
								<Box
									sx={{
										width: "100%",
										height: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<CircularProgress />
								</Box>
							)}
						</ContentContainer>
					</>

					<Modal open={openModal} onClose={handleCloseModal}>
						<ReportPDFViewer report={reportData} />
					</Modal>

					<Modal open={openModalEdit} onClose={handleCloseModalEdit}>
						<ReportDataProvider initialData={reportData} handleClose={handleCloseModalEdit}>
							<CreateReport handleClose={handleCloseModalEdit} />
						</ReportDataProvider>
					</Modal>

					<Modal
						open={isUploadModalOpen}
						onClose={() => {
							setIsUploadModalOpen(false);
						}}
					>
						<Box>
							<UploadFromExcel
								onUpload={(reportData) => {
									setReportData(reportData);
									setIsCreating(true);
									setIsUploadModalOpen(false);
								}}
							/>
						</Box>
					</Modal>
					<WarningDialog
						open={confirmOpen}
						onClose={() => setConfirmOpen(false)}
						title={"No se han guardado tus cambios"}
						text={"Si sales perderás todos los cambios realizados en el informe. ¿Desea continuar?"}
						handleButton1={handleCancelExitCreate}
						Button1Text={"Cancelar"}
						handleButton2={handleConfirmExitCreate}
						Button2Text={"Salir"}
					/>
				</>
			)}
		</div>
	);
}
