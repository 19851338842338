import React, { useEffect } from "react";

// mui components
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";


// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";

// components
import { CageForm } from "./";
import MultipleCageForm from "./MultipleCageForm";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";
import IconButtons from "../../Icons/IconButtons";
import EditIcon from "../../Icons/EditIcon";
import DeleteIcon from "../../Icons/DeleteIcon";

/**
 * Componente para mostrar el detalle de una jaula. Debido a que las jaulas solamente tienen
 * coordenadas, se utiliza el componente CageForm para mostrar los datos y permitir su edicion.
 * @param {object} props
 * @param {object} props.data Datos de la jaula
 * @param {function} props.onClose Funcion para cerrar el detalle
 * @param {number} props.centerId ID del centro al que pertenece la jaula
 */
export const CageDetail = ({ data, onClose, centerId }) => {
    const theme = useTheme();
	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
					padding: "15px 25px",
				}}
			>
				<div
					style={{
						color: "#212121",
						fontSize: "15px",
						fontWeight: 500,
					}}
				>
					{data?.id ? "Editar jaula" : "Agregar jaula"}
				</div>
				<div
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						fontWeight: 700,
						color: theme.palette.brandColors.primary,
						fontSize: "13px",
					}}
					onClick={onClose}
				>
					Volver al listado
				</div>
			</Box>

			{data && <CageForm data={data} onFinished={onClose} centerId={centerId} />}
		</Box>
	);
};

/**
 * Componente para mostrar una lista de jaulas
 * @param {object} props
 * @param {number} props.centerId ID del centro al que pertenecen las jaulas
 * @param {function} props.openDetail Funcion para abrir el detalle de una jaula
 * @param {boolean} props.dataReload Bandera para recargar los datos de la tabla
 * @param {function} props.setDataReload Funcion para cambiar la bandera de recarga de datos
 */
export const CagesList = ({ centerId, openDetail = () => {}, dataReload = false, setDataReload = () => {} }) => {
	const [rows, setRows] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	React.useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(`${URLS.CENTERS}${centerId}/cages/`)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al cargar las jaulas del centro", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [centerId, axiosInstance, addMessage, dataReload, setDataReload]);

	const handleDelete = (id) => {
		if (!window.confirm("¿Está seguro de eliminar esta jaula?")) return;

		setLoading(true);
		axiosInstance
			.delete(`${URLS.CAGES}${id}/`)
			.then((_res) => {
				addMessage("Jaula eliminada exitosamente", "success");
				setDataReload(true);
			})
			.catch((err) => {
				addMessage("Error al eliminar la jaula", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	let columns = [
		{ field: "name", headerName: "Nombre" },
		{ field: "depth", headerName: "Profundidad (m)" },
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
					<IconButtons icon={<EditIcon />} onClick={() => openDetail(row.id, row)} />
					<IconButtons icon={<DeleteIcon />} onClick={() => handleDelete(row.id)} variant="danger" />
				</div>
			),
		},
	];

	return <Table rows={rows} columns={columns} loading={loading} />;
};

/**
 * Componente para enlistar, agregar y editar jaulas de un centro
 * @param {object} props
 * @param {number} props.centerId ID del centro al que pertenecen las jaulas
 */
const Cages = ({ centerId, setHide }) => {
	const [dataReload, setDataReload] = React.useState(true);
	const [centerPolygon, setCenterPolygon] = React.useState([]);
	const [detailData, setDetailData] = React.useState({
		open: false,
		data: null,
	});

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	React.useEffect(() => {
		const getCenterPolygon = async () => {
			if (!centerId) return;

			axiosInstance
				.get(`${URLS.CENTERS}${centerId}/`)
				.then((response) => {
					let coordinates = response.data.coordinates;
					let points = [];
					coordinates.split(";").forEach((point) => {
						let [lat, lon] = point.split(",");
						lat = parseFloat(lat);
						lon = parseFloat(lon);
						points.push([lat, lon]);
					});
					setCenterPolygon(points);
				})
				.catch((error) => {
					addMessage("Error al cargar las coordenadas del centro", "error");
				});
		};

		getCenterPolygon();
	}, [axiosInstance, addMessage, centerId]);

	const openDetail = (_id, data = null) => {
		setDetailData({
			open: true,
			data: {
				...data,
				centerPolygon: centerPolygon,
			},
		});
	};

	const closeDetail = () => {
		setDetailData({ open: false, data: null });
		setDataReload(true);
	};
	const [multipleCagesModal, setMultipleCagesModal] = React.useState(false);

	useEffect(() => {
		setHide(detailData.open || multipleCagesModal);
	}, [detailData.open, multipleCagesModal, setHide]);

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			{detailData.open && !multipleCagesModal && (
				<CageDetail data={detailData.data} onClose={closeDetail} centerId={centerId} />
			)}
			{multipleCagesModal && (
				<MultipleCageForm
					data={detailData.data}
					dataReload={dataReload}
					setDataReload={setDataReload}
					centerId={centerId}
					onClose={() => setMultipleCagesModal(false)}
				/>
			)}

			{!detailData.open && !multipleCagesModal && (
				<div style={{ padding: "35px 0", height: "100%" }}>
					<div style={{ padding: "0 34px 20px", display: "flex", justifyContent: "space-between" }}>
						<GeneralButton text="Agregar Jaula" onClick={() => openDetail()} />
						<GeneralButton text="Agregar Múltiples Jaulas" onClick={() => setMultipleCagesModal(true)} />
					</div>

					<CagesList
						centerId={centerId}
						openDetail={openDetail}
						dataReload={dataReload}
						setDataReload={setDataReload}
					/>
				</div>
			)}
		</Box>
	);
};

export default Cages;
