
import React from 'react';
import { Box, Typography } from '@mui/material';

const NotFoundPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Typography variant="h1" component="h1">
        404 - Page Not Found
      </Typography>
    </Box>
  );
};

export default NotFoundPage;
