import { useState, useEffect } from "react";
import { useAuthContext, useMessagesContext } from "../../../context";
import { URLS } from "../../../global";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Modal, Typography, TextField, useTheme } from "@mui/material";
import { MapContainer, TileLayer, GeoJSON, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
	findCentralPoint,
	generateStructurePointsFromCentros,
	feature_type_map,
	pointInPolygon,
} from "../../../utils/map_helper";
import GeneralButton from "../../GeneralButton";
import Table from "../../Table/Table";
import IconButtons from "../../Icons/IconButtons";
import EditIcon from "../../Icons/EditIcon";

export const BoxStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "60vw",
	height: "60vh",
	bgcolor: "background.paper",
	borderRadius: "10px",
};

const MapEvents = ({ onMapClick }) => {
	useMapEvents({
		click: (e) => {
			onMapClick && onMapClick(e);
		},
	});
	return null;
};

const CagesModal = ({ selectedCenter, setReload }) => {
	const [cages, setCages] = useState([]);
	const [centro, setCentro] = useState(null);
	const [changed, setChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();
	const theme = useTheme();

	useEffect(() => {
		if (!selectedCenter) return;
		setCages(selectedCenter.cages);
		setCentro(generateStructurePointsFromCentros([selectedCenter]));
	}, [selectedCenter]);

	const onEachFeature = (feature, layer, object) => {
		if (object.properties.data_type === feature_type_map.Jaula && object.properties.name) {
			layer.bindTooltip("<div class='tooltip-jail'>" + String(object.properties.name) + "</div>", {
				permanent: true,
				direction: "center",
				className: "leaflet-tooltip-own",
			});
		} else if (object.properties.data_type === feature_type_map.Poligono && object.properties.name) {
			layer.bindTooltip("<div class='tooltip-jail'>" + String(object.properties.name) + "</div>", {
				permanent: true,
				direction: "center",
				className: "leaflet-tooltip-own",
			});
		}
	};

	const onMapClick = (e) => {
		const { lat, lng } = e.latlng;
		let cage = null;

		if (selectedCenter) {
			for (let i = 0; i < selectedCenter.cages.length; i++) {
				const cageCoordinates = selectedCenter.cages[i].coordinates.split(";");
				const cagePolygon = cageCoordinates.map((coord) => coord.split(","));
				const cagePolygonLatLng = cagePolygon.map((coord) => [parseFloat(coord[0]), parseFloat(coord[1])]);

				if (pointInPolygon([lat, lng], cagePolygonLatLng)) {
					cage = selectedCenter.cages[i];
					setCentro((prev) => {
						const newCentro = { ...prev };
						newCentro.features = newCentro.features.map((feature) => {
							if (
								feature.properties.id === cage.id &&
								feature.properties.data_type === feature_type_map.Jaula
							) {
								feature.properties.isSelected = true;
							} else {
								feature.properties.isSelected = false;
							}
							return feature;
						});
						return newCentro;
					});
					break;
				}
			}
		}
	};

	const patchCage = async (cage) => {
		setLoading(true);

		const data = {
			depth: cage.depth,
		};

		if (cage.depth === null) {
			return Promise.resolve();
		}

		await axiosInstance.patch(`${URLS.CAGES}${cage.id}/`, data).then((response) => {
			setLoading(false);
		});

		return Promise.resolve();
	};

	const uploadChanges = () => {
		setLoading(true);
		const cages = centro.features.filter((feat) => feat.properties.data_type === feature_type_map.Jaula);
		const cagesData = cages.map((cage) => ({
			id: cage.properties.id,
			depth: cage.properties.depth,
		}));

		Promise.all(cagesData.map((cage) => patchCage(cage))).then(() => {
			setLoading(false);
			setChanged(false);
			addMessage("Cambios guardados correctamente", "success");
			setReload(true);
		});
	};

	return (
		<Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
			<Box
				sx={{
					width: "100%",
					padding: "20px 30px",
					borderBottom: `1px solid ${theme.palette.brandColors.gray}`,
				}}
			>
				Editar Jaulas
			</Box>
			<div style={{ height: "100%", width: "100%", padding: "20px 30px", display: "flex" }}>
				<Box sx={{ width: "50%", height: "100%" }}>
					<MapContainer
						key={1}
						center={selectedCenter ? findCentralPoint(selectedCenter.coordinates) : [0, 0]}
						zoom={15}
						attributionControl={false}
					>
						<MapEvents onMapClick={onMapClick} />
						<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={25} />
						{/* Centro */}
						{centro?.features.map((feature) => (
							<GeoJSON
								data={feature.geometry}
								style={{
									color: feature.properties.isSelected ? "orange" : feature.properties.color,
									fillOpacity: 0.2,
								}}
								onEachFeature={(feat, layer) => onEachFeature(feat, layer, feature)}
							/>
						))}
					</MapContainer>
				</Box>
				<Box
					sx={{
						width: "50%",
						height: "100%",
						justifyContent: "space-between",
						display: "flex",
						flexDirection: "column",
						padding: "0 20px",
					}}
				>
					<Box>
						<div>Haz click en una jaula para editar su profundidad</div>

						{centro?.features.map((feature) => {
							if (feature.properties.isSelected) {
								return (
									<Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: "20px" }}>
										<div>Jaula {feature.properties.name}</div>
										<TextField
											label="Profundidad"
											type="number"
											InputLabelProps={{
												shrink: true,
											}}
											variant="outlined"
											value={feature.properties.depth}
											onChange={(e) => {
												setCentro((prev) => {
													const newCentro = { ...prev };
													newCentro.features = newCentro.features.map((feat) => {
														if (feat.properties.id === feature.properties.id) {
															feat.properties.depth = e.target.value;
														}
														return feat;
													});
													return newCentro;
												});
												setChanged(true);
											}}
										/>
									</Box>
								);
							}
						})}
					</Box>

					<GeneralButton onClick={uploadChanges} disabled={!changed || loading} text="Guardar cambios" />
				</Box>
			</div>
		</Box>
	);
};

const ROVOperatorsTabs = () => {
	const [needsReload, setNeedsReload] = useState(true);
	const { axiosInstance } = useAuthContext();
	const [centros, setCentros] = useState([]);
	const [selectedCenter, setSelectedCenter] = useState(null);

	const columns = [
		{ field: "id", headerName: "ID", sortable: true },
		{ field: "name", headerName: "Nombre", sortable: true },
		{ field: "code", headerName: "Código", sortable: true },
		{ field: "place", headerName: "Área", sortable: true },
		{ field: "salmonCompany", headerName: "Empresa Productora", sortable: true },
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<IconButtons icon={<EditIcon />} onClick={() => setSelectedCenter(row)} />
				</div>
			),
		},
	];

	useEffect(() => {
		if (!needsReload) return;
		axiosInstance.get(`${URLS.CENTERS}uploadPermissionsCenter/`).then((response) => {
			setCentros(
				response.data.map((row) => ({
					name: row.name,
					id: row.id,
					code: row.code,
					place: row.place?.name,
					coordinates: row.coordinates,
					salmonCompany: row.salmonCompany.name,
					cages: row.cages,
					polygons: row?.polygons,
				}))
			);
			setNeedsReload(false);
			setSelectedCenter(null);
		});
	}, [axiosInstance, needsReload]);

	return (
		<Box>
			<Table rows={centros} columns={columns} />
			<Modal open={selectedCenter !== null} onClose={() => setSelectedCenter(null)}>
				<Box sx={BoxStyle}>
					<CagesModal selectedCenter={selectedCenter} setReload={setNeedsReload} />
				</Box>
			</Modal>
		</Box>
	);
};

export default ROVOperatorsTabs;
