
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AnonymousRoute, PrivateRoute } from "./utils";
import { AuthProvider, MessagesProvider } from "./context/";

import {
    HomePage,
    ReportPage,
    Login,
    AdminPanelPage,
    NotFoundPage,
    Dashboard,
    ResetPassword,
    ProfilePage,
    DeletePetition,
    Calendar
} from "./pages";
import MiniDrawer from "./components/NavBar/MiniDrawer";
import "./App.css";

// Define your primary color
export const theme = createTheme({
	components: {
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: "#848591",
					"&.Mui-checked": {
						color: "var(--primary)",
					},
					'&:hover': {
						backgroundColor: 'transparent',
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontWeight: 700,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					borderRadius: "15px",
				},
				input: {
					borderRadius: "15px",
					fontSize: "14px",
					"&::placeholder": {
						color: "var(--color-gray)",
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					border: "1px solid #d9d9d9",
					borderRadius: "15px",
					fontSize: "14px",
					"& .MuiOutlinedInput-notchedOutline": {
						border: "inherit",
					},
				},
				input: {
					borderRadius: "15px",
					padding: "13px 16px",
				},
				notchedOutline: {
					border: "none !important",
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					borderRadius: "15px",
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				flexContainer: {
					height: "100%",
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					border: "none",
					boxShadow: "none",
					position: "static",
					borderBottom: "1px solid var(--color-light-gray)",
				},
			},
		},
	},
	palette: {
		primary: {
			light: "#82d3f2",
			main: "#0475B1",
			dark: "#005690",
			contrastText: "#fff",
		},
		secondary: {
			light: "#ff7961",
			main: "#f44336",
			dark: "#ba000d",
			contrastText: "#000",
		},
		brandColors: {
			primary: "#22ACA4",
			secondary: "#F5F5F5",
			text: "#5A5A5A",
			textSecondary: "#A8A8A8",
			white: "#FFFFFF",
			background: "#DEF3F1",
			red: "#FF4B4B",
			green: "#57FF54",
			gray: "#E8E8E8",
			lightGray: "#E2E2E2",
			danger: "#FF4040",
		},
	},
});

/* 
    Cada Route representa un página dentro de aplicación
    AnonymousRoute es para páginas que no necesitan de un usuario como el login u otras que puedan surgir.
    PrivateRoute es para páginas que si necesitan de un usuario.
    Hay que crear estas 2 para modelar este comportamiento.
    También hay que separar las vistar por el nivel de permisos del usuario
*/
function App({show404 = false}) {
  return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <MessagesProvider>
                <AuthProvider>
                    <Routes>
                        <Route element={<AnonymousRoute component={<Login />} />} path="/login" />

                        <Route element={<AnonymousRoute component={<ResetPassword />} />} path="/password-reset/:pk/:token/" />
                        <Route element={<AnonymousRoute component={<ResetPassword />} />} path="/password-reset" />

                        {/* Ruta base con MiniDrawer-Outlet (renderiza sus rutas descendientes) */}
                        <Route element={<MiniDrawer><Outlet /></MiniDrawer>} path="/">
                            <Route element={<PrivateRoute component={<HomePage />} />} path="" />
                            <Route element={<PrivateRoute component={<ReportPage />} />} path="reports" />
                            <Route element={<PrivateRoute component={<Calendar />} />} path="calendario" />
                            <Route element={<PrivateRoute component={<Dashboard />} userTypes={['Cliente', 'Admin']} />} path="/dashboard" />
                            <Route element={<PrivateRoute component={<AdminPanelPage />} userTypes={['Admin','Super Admin' , 'Operador ROV']} />} path="admin" />
                            <Route element={<PrivateRoute component={<ProfilePage />} />} path="profile" />
                            <Route element={<PrivateRoute component={<DeletePetition />} />} path="delete-requests" />
                        </Route>
                            
                        {/* Ruta para páginas no encontradas, muestra 404 o redirecciona a / */}
                        <Route 
                            element={show404 ? <PrivateRoute component={<NotFoundPage />} /> : <Navigate to="/" replace />}
                            path="*"
                        />
                        
                    </Routes>                
                </AuthProvider>
                </MessagesProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
