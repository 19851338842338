import { styled } from "@mui/material/styles";
import { CSS, largeScreenMedia, middleScreenMedia, middleScreenMedia2 } from "../Common";

export const StatTitle = styled("div")(({ mt }) => ({
	backgroundColor: "rgba(34, 172, 164, 0.23)",
	padding: "10px",
	textAlign: "center",
	fontSize: "12px",
	display: "flex",
	justifyContent: "space-between",
	[middleScreenMedia2]: {
		marginTop: mt ? "20px" : 0,
	},
}));

export const TableTab = styled("div")(() => ({
	borderRadius: CSS.borderRadius,
	boxShadow: CSS.shadow,
	width: "100%",
	[middleScreenMedia]: {
		padding: "10px",
	},
	[largeScreenMedia]: {
		padding: "20px",
	},
}));

export const TableTitle = styled("div")(() => ({
	backgroundColor: "rgba(34, 172, 164, 0.23)",
	padding: "10px",
	display: "flex",
	justifyContent: "space-between",
	display: "none",
	[middleScreenMedia2]: {
		display: "flex",
	},
}));

export const TableContainer = styled("div")(() => ({
	display: "flex",
	padding: "25px",
	gap: "10px",
	width: "100%",
	[middleScreenMedia2]: {
		padding: 0,
	},
}));

export const Table = styled("table")(({ open, notModal }) => ({
	width: "100%",
	borderCollapse: "collapse",
	height: "fit-content",
	[middleScreenMedia2]: {
		...(!notModal && {
			display: open ? "inline-block" : "none",
			position: "absolute",
			zIndex: 100,
			top: "20px",
			left: "20px",
			padding: "40px 20px 20px",
			backgroundColor: "white",
			width: "calc(100% - 40px)",
			height: "calc(100% - 40px)",
			display: open ? "inline-block" : "none",
			borderRadius: "12px",
			boxShadow: "0px 0px 100px 0px rgba(50, 55, 59, 1)",
			overflow: "auto",
		}),
	},
}));

export const TableHead = styled("thead")(() => ({
	backgroundColor: "rgba(34, 172, 164, 0.23)",
}));

export const Th = styled("th")(() => ({
	padding: "10px",
	fontWeight: 400,
	fontSize: "12px",
	textAlign: "left",
}));

export const Td = styled("td")(() => ({
	padding: "10px",
	fontSize: "12px",
	border: "1px solid #D7D7D7",
	[middleScreenMedia2]: {
		width: "100%",
	},
}));

export const PlotTab = styled("div")(() => ({
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	[middleScreenMedia2]: {
		height: "auto",
	},
}));

export const PlotContainer = styled("div")(({ open }) => ({
	borderLeft: "1px solid #D7D7D7",
	borderRight: "1px solid #D7D7D7",
	borderBottom: "1px solid #D7D7D7",
	[middleScreenMedia2]: {
		position: "absolute",
		zIndex: 100,
		top: "20px",
		left: "20px",
		padding: "20px",
		backgroundColor: "white",
		width: "calc(100% - 40px)",
		height: "calc(100% - 40px)",
		display: open ? "inline-block" : "none",
		borderRadius: "12px",
		boxShadow: "0px 0px 100px 0px rgba(50, 55, 59, 1)",
	},
}));

export const TabContentWrapper = styled("div")(() => ({
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	maxHeight: "100%",
	overflow: "auto",
	[middleScreenMedia2]: {
		overflow: "unset",
	}
}));

export const SwitchContainer = styled("div")(() => ({
	display: "flex",
	justifyContent: "center",
	gap: "10px",
	margin: "20px 0",
	[middleScreenMedia2]: {
		margin: "10px 0",
	},
}));

export const See = styled("span")(() => ({
	fontSize: "13px",
	cursor: "pointer",
	textDecoration: "underline",
	display: "none",
	[middleScreenMedia2]: {
		display: "block",
	},
}));

export const PlotStats = styled("div")(() => ({
	display: "flex",
	padding: "25px",
	gap: "10px",
	width: "100%",
	boxShadow: CSS.shadow,
	margin: "25px 0",
	borderRadius: "12px",
	height: "100%",
	[middleScreenMedia2]: {
		flexDirection: "column",
		height: "auto",
	},
}));

export const Shadow = styled("div")(() => ({
	position: "absolute",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%",
	backgroundColor: "rgba(0, 0, 0, 0.5)",
	zIndex: 50,
}));

export const RotateInstruction = styled("div")(() => ({
	backgroundColor: "#22ACA4",
	borderRadius: "50%",
	width: "100px",
	height: "100px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	position: "absolute",
	left: "calc(50% - 50px)",
	top: "calc(50% - 50px)",
	zIndex: 100,
	boxShadow: "0px 0px 100px 0px rgba(50, 55, 59, 1)",
}));

export const CloseButton = styled("div")(() => ({
	position: "absolute",
	top: "10px",
	right: "10px",
	cursor: "pointer",
	zIndex: 100,
}));

export const MapContainer = styled("div")(() => ({
	height: "100%",
	[largeScreenMedia]: {
		height: "100dvh",
	}
}));