import { useState } from "react";

// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
import { BoxStyle } from "../../Reports/ReportConstants";
import TextInput from "../../Inputs/TextInput";
import GeneralButton from "../../GeneralButton";

/**
 * Dialogo para crear o editar una empresa de recuperación de restos náufragos
 * @param {object} props
 * @param {boolean} props.open Indica si el dialogo esta abierto
 * @param {function} props.onClose Funcion a ejecutar al cerrar el dialogo
 * @param {object} props.data Datos de la empresa a editar
 */
export const WreckRescueCompanyDialogForm = ({ open = false, onClose = () => {}, ...props }) => {
	const theme = useTheme();
	const handleClose = (_event, reason) => {
		if (reason === "backdropClick") return;
		onClose();
	};

	const onFinished = () => {
		onClose();
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<div style={{ ...BoxStyle, height: "auto" }}>
				<div
					style={{
						position: "relative",
						borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
						padding: "15px 25px",
					}}
				>
					<div
						style={{
							color: "#212121",
							fontSize: "15px",
							fontWeight: 500,
						}}
					>
						{props?.data?.id ? "Editar" : "Crear"} empresa de recuperación de restos náufragos
					</div>
					<IconButton onClick={handleClose} sx={{ position: "absolute", right: 0, top: 0 }}>
						<CloseIcon />
					</IconButton>
				</div>

				<div style={{ padding: "25px 35px" }}>
					<WreckRescueCompanyForm {...props} onFinished={onFinished} />
				</div>
			</div>
		</Modal>
	);
};

/**
 * Formulario para crear o editar una empresa de recuperación de restos náufragos
 * @param {object} props
 * @param {function} props.onFinished Funcion a ejecutar al terminar de editar o crear
 * @param {object} props.data Datos de la empresa a editar
 */
export const WreckRescueCompanyForm = ({ onFinished = () => {}, data = undefined }) => {
	const [currentData, setCurrentData] = useState({
		name: data?.name || "",
		logo: data?.logo || "",
		logo_file: null,
	});
	const [errors] = useState({});
	const [loading, setLoading] = useState(false);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const handleCancel = () => {
		onFinished();
	};
	console.log(currentData);
	const handleSave = async (event) => {
		event.preventDefault();

		// Creacion de objeto por defecto
		// crear configuracion de request
		let config = {
			method: "POST",
			url: URLS.WRECK_RESCUE_COMPANY,
			data: {
				name: currentData.name,
				logo: currentData.logo_file,
			},
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};
		let message = "Empresa creada correctamente";
		let error_message = "Error al crear la compañía";

		if (data?.id) {
			config.method = "PATCH";
			config.url += `${data.id}/`;
			message = "Empresa actualizada correctamente";
			error_message = "Error al actualizar la compañía";
		}

		// enviar request
		setLoading(true);
		axiosInstance(config)
			.then((_response) => {
				onFinished();
				addMessage(message, "success");
			})
			.catch((_error) => {
				addMessage(error_message, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDelete = () => {
		if (
			!window.confirm(
				"¿Está seguro de eliminar esta empresa? \n" +
					"Esta acción no se puede deshacer y eliminará todos los datos asociados a esta empresa."
			)
		)
			return;

		setLoading(true);
		axiosInstance
			.delete(`${URLS.WRECK_RESCUE_COMPANY}${data.id}/`)
			.then((_res) => {
				addMessage("Empresa eliminada correctamente", "success");
				onFinished();
			})
			.catch((err) => {
				addMessage("Error al eliminar la empresa", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (event) => {
		setCurrentData({ ...currentData, [event.target.name]: event.target.value });
	};

	return (
		<form onSubmit={handleSave} style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextInput
						fullWidth
						required
						label="Nombre de empresa"
						name="name"
						value={currentData.name}
						onChange={handleChange}
						error={errors.name}
						helperText={errors.name}
						disabled={loading}
					/>
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" spacing={2}>
						<Button variant="outlined" component="label">
							{currentData.logo ? "Cambiar logo" : "Subir logo"}

							<input
								type="file"
								name="logo"
								accept="image/*"
								hidden
								onChange={(e) => {
									let file = e.target.files[0];
									setCurrentData({
										...currentData,
										logo: URL.createObjectURL(file),
										logo_file: file,
									});
								}}
							/>
						</Button>

						{currentData.logo && (
							<>
								<Button
									variant="outlined"
									color="error"
									onClick={() => {
										setCurrentData({ ...currentData, logo: "", logo_file: null });
									}}
								>
									<CloseIcon />
								</Button>

								<Box
									sx={{
										height: 50,
										width: 50,
										overflow: "hidden",
										borderStyle: "solid",
										borderWidth: 1,
										borderRadius: 1,
									}}
								>
									<img
										src={currentData.logo || ""}
										alt="logo"
										style={{ height: "100%", width: "100%" }}
									/>
								</Box>
							</>
						)}
					</Stack>
				</Grid>
			</Grid>

			<Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
				<Button variant="outlined" onClick={handleDelete} color="error" disabled={loading}>
					Eliminar
				</Button>
				<div style={{ display: "flex", gap: "20px" }}>
					<GeneralButton text="Cancelar" variant="empty" onClick={handleCancel} disabled={loading} />

					<GeneralButton text="Guardar" type="submit" disabled={loading} onClick={handleSave} />
				</div>
			</Box>
		</form>
	);
};
