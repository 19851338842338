import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import {
	Checkbox,
	FormControlLabel,
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	InputAdornment,
	IconButton,
	Slide,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { AuthContext, MessagesContext } from "../context";
import TextInput from "../components/Inputs/TextInput";

export default function Login() {
	const navigate = useNavigate();

	const initialFormData = Object.freeze({
		username: "",
		password: "",
		checkedB: false,
	});

	const [isLoaded, setIsLoaded] = useState(false);
	React.useEffect(() => {
		setIsLoaded(true);
	}, []);

	const { login } = useContext(AuthContext);
	const { addMessage } = useContext(MessagesContext);
	const [errors, setErrors] = useState([{ username: "", password: "", non_field_errors: "" }]);

	const [formData, setFormData] = useState(initialFormData);
	const handleChange = (e) => {
		if (e.target.name === "checkedB") {
			setFormData({
				...formData,
				[e.target.name]: e.target.checked,
			});
		} else {
			setFormData({
				...formData,
				[e.target.name]: e.target.value.trim(),
			});
		}
	};
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const handleSubmit = (e) => {
		e.preventDefault();
		login(formData.username, formData.password, formData.checkedB)
			.then((res) => {
				if (res.status === 200) {
					addMessage(res.data.message, res.data.severity);
					setTimeout(() => {
						navigate("/"); // Redirect to home page
					}, 500);
				}
			})
			.catch((error) => {
				if (error.response.status === 401) {
					addMessage(error.response.data.error, error.response.data.severity);
					setErrors({
						...errors,
						non_field_errors: error.response.data.error,
					});
				} else if (error.response.status === 400) {
					setErrors(error.response.data);
				}
			});
	};

	const paperStyle = {
		padding: "75px 75px 100px",
		height: "auto",
		width: "550px",
		borderRadius: "10px",
		margin: "auto",
		boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.5)",
	};
	const btnstyle = {
		margin: "8px 0",
		backgroundColor: "#22ACA4",
		color: "white",
		borderRadius: "12px",
	};

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<Slide
				direction="down"
				in={isLoaded}
				mountOnEnter
				unmountOnExit
				timeout={1000}
				style={{
					transitionDelay: "500ms",
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<div style={paperStyle}>
					<form onSubmit={handleSubmit} style={{ width: "100%" }}>
						<Grid align="center">
							<div
								style={{
									color: "#22ACA4",
									fontSize: "60px",
									fontWeight: "bold",
								}}
							>
								Rastrum
							</div>
							<h4>Inicia sesión para continuar</h4>
						</Grid>
						<TextInput
							label="Nombre de usuario"
							id="username"
							name="username"
							placeholder="Ingrese su nombre de usuario"
							fullWidth
							required
							onChange={handleChange}
							value={formData.username}
							sx={{ margin: "20px 0" }}
						/>
						<TextInput
							label="Contraseña"
							id="password"
							name="password"
							placeholder="Ingrese contraseña"
							type={showPassword ? "text" : "password"}
							fullWidth
							autoComplete="current-password"
							required
							sx={{ margin: "20px 0" }}
							onChange={handleChange}
							value={formData.password}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<FormControlLabel
							control={<Checkbox name="checkedB" style={{ color: "#22ACA4" }} onChange={handleChange} />}
							label="Recordarme"
						/>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							style={btnstyle}
							fullWidth
							onClick={handleSubmit}
						>
							Iniciar Sesión
						</Button>
					</form>
					<Typography align="center">
						<Link to="/password-reset">Olvide mi contraseña</Link>
					</Typography>
				</div>
			</Slide>
		</div>
	);
}
