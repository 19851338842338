import React, { useEffect } from "react";

// mui components
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";

// components
import { WreckRescueCompanyDialogForm, WreckRescueCompanyDetail, Admins } from "./";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";

/**
 * Lista de empresas de rescate de restos náufragos
 * @param {object} props
 * @param {function} props.openDetail Funcion para abrir el detalle de una empresa
 * @param {boolean} props.dataReload Indica si se deben recargar los datos
 * @param {function} props.setDataReload Funcion para indicar que se deben recargar los datos
 */
const WreckRescueCompanyList = ({ openDetail, dataReload, setDataReload }) => {
	const [rows, setRows] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	const columns = [
		{
			field: "id",
			headerName: "ID",
		},
		{
			field: "name",
			headerName: "Nombre",
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<GeneralButton
						text="Ver Empresa"
						onClick={() => openDetail({ id: row.id, data: row, open: true })}
						variant="small"
					/>
				</div>
			),
		},
	];

	React.useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(URLS.WRECK_RESCUE_COMPANY)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al obtener los datos", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [axiosInstance, addMessage, dataReload, setDataReload]);

	return <Table rows={rows} columns={columns} loading={loading} />;
};

/**
 * Componente que muestra la lista de empresas, permite crear nuevas empresas y acceder a su detalle
 */
const WreckRescueCompanies = ({ setHide }) => {
	const [detailDisplay, setDetailDisplay] = React.useState({
		open: false,
		id: null,
		data: null,
	});

	const [dialog, setDialog] = React.useState({
		open: false,
		id: null,
		data: null,
	});

	const [dataReload, setDataReload] = React.useState(true);

	const openDetail = ({ id, data }) => {
		setDetailDisplay({
			open: true,
			id: id,
			data: data,
		});
	};

	const exitDetailDisplay = () => {
		setDetailDisplay({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};

	const openDialog = () => {
		setDialog({
			open: true,
			id: null,
			data: null,
		});
	};

	const exitDialog = () => {
		setDialog({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};

	useEffect(() => {
		setHide(detailDisplay.open);
	}, [detailDisplay.open, setHide]);

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			{detailDisplay.open ? (
				<>
					<WreckRescueCompanyDetail onClose={exitDetailDisplay} data={detailDisplay.data} />

					<Admins companyId={detailDisplay.id} />
				</>
			) : (
				<Box sx={{ height: "100%", width: "100%", position: "relative" }}>
					<div style={{ position: "absolute", top: "-50px", left: "20px" }}>
						<GeneralButton text="Agregar Empresa" onClick={openDialog} sx={{ padding: "8px 15px" }} />
					</div>

					<WreckRescueCompanyList
						openDetail={openDetail}
						dataReload={dataReload}
						setDataReload={setDataReload}
					/>

					<WreckRescueCompanyDialogForm open={dialog.open} onClose={exitDialog} data={dialog.data} />
				</Box>
			)}
		</Box>
	);
};

export default WreckRescueCompanies;
