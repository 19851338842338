import {
	Dialog,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Checkbox,
	Input,
	Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { URLS } from "../../../global";
import { useAuthContext } from "../../../context";

const MergeCompanies = ({ onClose }) => {
	const [rows, setRows] = useState([]);
	const { axiosInstance } = useAuthContext();
	const [principal, setPrincipal] = useState(null);
	const [selectedCompanies, setSelectedCompanies] = useState([]);
	const [name, setName] = useState("");

	useEffect(() => {
		axiosInstance
			.get(URLS.SALMON_COMPANY)
			.then((response) => {
				setRows(response.data);
			})
			.catch((error) => {})
			.finally(() => {});
	}, [axiosInstance]);

	const handleSubmit = () => {
		axiosInstance
			.post(URLS.SALMON_COMPANY + "merge_companies/", {
				principal_id: principal,
				ids: selectedCompanies,
				new_name: name,
			})
			.then((response) => {
				setRows(response.data);
			})
			.catch((error) => {})
			.finally(() => {
				onClose();
			});
	};

	return (
		<Dialog open={true} onClose={onClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
			<Box sx={{ p: 2 }}>
				<h2 style={{ padding: 0, margin: 0 }}>Fusionar Empresas</h2>
				<p>
					A continuación se fusionarán las empresas seleccionadas en una única empresa, tomando como datos generales los pertenecientes a la empresa
					seleccionada como principal, los centros de cultivo tomarán como razón social el nombre de su empresa productora previa. Se eliminarán las
					empresas seleccionadas previamente una vez realizada la fusión. Se debe proporcionar un nombre para la nueva empresa.
				</p>
				<Box sx={{ p: 2 }}>
					<FormControl sx={{ minWidth: "400px", marginRight: "20px" }}>
						<InputLabel>Nombre</InputLabel>
						<Input
							value={name}
							label="Nombre"
							required
							onChange={(e) => {
								setName(e.target.value);
							}}
						></Input>
					</FormControl>
					<FormControl sx={{ minWidth: "400px" }}>
						<InputLabel>Principal</InputLabel>
						<Select
							value={principal || ""}
							label="Principal"
							onChange={(e) => {
								setPrincipal(e.target.value);
								setSelectedCompanies([...selectedCompanies.filter((id) => id !== principal), e.target.value]);
							}}
						>
							{rows.map((row) => (
								<MenuItem value={row.id}>{row.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Seleccionada</TableCell>
							<TableCell>Nombre</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.id}>
								<TableCell>
									<Checkbox
										checked={selectedCompanies.includes(row.id)}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedCompanies([...selectedCompanies, row.id]);
											} else if (row.id !== principal) {
												setSelectedCompanies(selectedCompanies.filter((id) => id !== row.id));
											}
										}}
									/>
								</TableCell>
								<TableCell>{row.name}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<Box sx={{ p: 2, justifyContent: "end", width: "100%", display: "flex" }}>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						Fusionar
					</Button>
				</Box>
			</Box>
		</Dialog>
	);
};

export default MergeCompanies;
