const PlotIcon = ({ color }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
			<g clipPath="url(#clip0_194_2301)">
				<path
					d="M14.7995 11.5268C14.7995 15.0205 14.7995 18.5142 14.7995 22.0072C14.7995 22.7057 14.5037 22.9994 13.8004 22.9994C12.246 22.9994 10.6916 23.0001 9.13646 22.9994C8.52908 22.9994 8.20312 22.6742 8.20312 22.0681C8.20312 15.0212 8.20312 7.97423 8.20312 0.927282C8.20312 0.314415 8.51949 0.00147547 9.13783 0.000790702C10.7094 -0.000578834 12.2809 0.000105934 13.8531 0.000790702C14.4811 0.000790702 14.7995 0.317838 14.7995 0.943717C14.8002 4.47164 14.7995 7.99889 14.7995 11.5268ZM13.1232 11.4994C13.1232 8.33031 13.1204 5.16052 13.128 1.99141C13.128 1.74832 13.0643 1.66752 12.8137 1.67163C11.9337 1.68601 11.0538 1.68258 10.1739 1.673C9.96024 1.67094 9.87396 1.71819 9.87396 1.95443C9.88012 8.31867 9.87944 14.6836 9.87533 21.0478C9.87533 21.2567 9.93422 21.3306 10.1499 21.3279C11.0298 21.3176 11.9098 21.3238 12.7897 21.3238C13.0116 21.3238 13.1227 21.2099 13.1232 20.9821C13.1232 17.8212 13.1232 14.6603 13.1232 11.4994Z"
					fill={color || "#848591"}
				/>
				<path
					d="M22.9992 14.7802C22.9992 17.1975 22.9992 19.6147 22.9992 22.0319C22.9992 22.6968 22.6945 22.9995 22.0275 23.0002C20.4642 23.0002 18.9016 23.0009 17.3383 23.0002C16.7295 23.0002 16.4035 22.6756 16.4029 22.0703C16.4022 17.2105 16.4022 12.35 16.4029 7.49017C16.4029 6.87594 16.7185 6.563 17.3355 6.56231C18.9071 6.56094 20.4786 6.56163 22.0501 6.56231C22.6801 6.56231 22.9985 6.87799 22.9985 7.50319C22.9992 9.92864 22.9985 12.3548 22.9985 14.7802H22.9992ZM21.3229 14.8042C21.3229 12.7115 21.3202 10.6182 21.327 8.52554C21.3277 8.30163 21.264 8.23041 21.0374 8.23383C20.1492 8.24547 19.2604 8.2441 18.3723 8.23383C18.1565 8.23109 18.0737 8.28245 18.0737 8.51733C18.0799 12.6944 18.0799 16.8722 18.0744 21.05C18.0744 21.2609 18.1374 21.3307 18.351 21.3287C19.2392 21.3191 20.128 21.3156 21.0161 21.33C21.2716 21.3341 21.3284 21.2458 21.3277 21.0068C21.3195 18.9395 21.3229 16.8722 21.3229 14.8049V14.8042Z"
					fill={color || "#848591"}
				/>
				<path
					d="M6.59689 17.9389C6.59689 19.2715 6.59757 20.6033 6.59689 21.9359C6.59689 22.5659 6.28326 22.8774 5.65054 22.8774C4.07899 22.8781 2.50745 22.8788 0.935907 22.8774C0.327148 22.8774 0.00119834 22.5529 0.000513576 21.9482C-0.000171192 19.2749 -0.000171192 16.6015 0.000513576 13.9282C0.000513576 13.3147 0.316192 13.001 0.933853 13.001C2.51361 12.9997 4.09406 12.9997 5.67382 13.001C6.2682 13.001 6.59552 13.3263 6.5962 13.9159C6.59826 15.2567 6.5962 16.5974 6.5962 17.9382L6.59689 17.9389ZM4.92057 17.9649C4.92057 16.9569 4.91647 15.949 4.924 14.941C4.92537 14.7438 4.8699 14.6705 4.6631 14.6732C3.75784 14.6828 2.85189 14.6835 1.94663 14.6732C1.72955 14.6705 1.67203 14.7458 1.67272 14.954C1.67888 16.9446 1.67888 18.9352 1.67272 20.9252C1.67272 21.1333 1.72955 21.2087 1.94663 21.2059C2.84367 21.1956 3.74072 21.1943 4.63776 21.2059C4.86716 21.2087 4.92742 21.1327 4.92537 20.9115C4.9151 19.9288 4.92057 18.9469 4.92057 17.9642V17.9649Z"
					fill={color || "#848591"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_194_2301">
					<rect width="23" height="23" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PlotIcon;
