import React from "react";

// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import AutoComplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
import { BoxStyle } from "../../Reports/ReportConstants";
import TextInput from "../../Inputs/TextInput";
import GeneralButton from "../../GeneralButton";

/**
 * Dialogo para crear o editar un tipo de objeto
 * @param {object} props
 * @param {boolean} props.open Indica si el dialogo esta abierto
 * @param {function} props.onClose Funcion a ejecutar al cerrar el dialogo
 * @param {object} props.data Datos del tipo de objeto a editar
 */
export const ObjectTypeDialogForm = ({ open, onClose, ...props }) => {
	const theme = useTheme();
	const handleClose = (_event, _reason) => {
		if (_reason === "backdropClick") return;
		onClose();
	};

	const onFinished = () => {
		onClose();
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<div style={{ ...BoxStyle, height: "auto" }}>
				<div
					style={{
						position: "relative",
						borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
						padding: "15px 25px",
					}}
				>
					<div
						style={{
							color: "#212121",
							fontSize: "15px",
							fontWeight: 500,
						}}
					>
						{props?.data?.id ? "Editar" : "Crear"} tipo de objeto
					</div>

					<IconButton onClick={handleClose} sx={{ position: "absolute", right: 0, top: 0 }}>
						<CloseIcon />
					</IconButton>
				</div>

				<div style={{ padding: "25px 35px" }}>
					<ObjectTypeForm {...props} onFinished={onFinished} />
				</div>
			</div>
		</Modal>
	);
};

/**
 * Formulario para crear o editar un tipo de objeto
 * @param {object} props
 * @param {function} props.onFinished Funcion a ejecutar al terminar la creacion o edicion
 * @param {object} props.data Datos del tipo de objeto a editar
 */
export const ObjectTypeForm = ({ onFinished = () => {}, data = undefined }) => {
	const [currentData, setCurrentData] = React.useState({});
	const [loading, setLoading] = React.useState(true);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	React.useEffect(() => {
		const requestData = async () => {
			setLoading(true);

			axiosInstance
				.get(`${URLS.OBJECT_TYPES}${data.id}/`)
				.then((response) => {
					setCurrentData(response.data);
				})
				.catch((error) => {
					console.log(error);
					addMessage("Error al obtener los datos del tipo de objeto", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		// si se esta editando, se obtienen los datos
		if (data?.id) requestData();
		// si se esta creando, se inicializan los datos
		else {
			const onCreation = () => {
				setCurrentData({
					name: "",
					description: "",
					weight: "",
					type: "",
					unit: "",
				});
			};

			onCreation();
			setLoading(false);
		}
	}, [addMessage, axiosInstance, data?.id]);

	const handleCancel = () => {
		onFinished();
	};

	const handleSave = (e) => {
		e.preventDefault();

		// Creacion de objeto por defecto
		// crear configuracion de request
		let config = {
			method: "POST",
			url: URLS.OBJECT_TYPES,
			data: {
				name: currentData.name,
				description: currentData.description,
				weight: currentData.weight,
				type: currentData.type,
				unit: currentData.unit,
			},
		};
		let message = "Tipo de objeto creado exitosamente";
		let error_message = "Error al crear el tipo de objeto";

		if (data?.id) {
			// Editar
			config.method = "PATCH";
			config.url += `${data.id}/`;
			config.data = { ...config.data, id: data.id };
			message = "Tipo de objeto editado exitosamente";
			error_message = "Error al editar el tipo de objeto";
		}

		// enviar request
		axiosInstance(config)
			.then((_res) => {
				addMessage(message, "success");
				onFinished();
			})
			.catch((_err) => {
				addMessage(error_message, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDelete = () => {
		if (
			!window.confirm(
				"¿Está seguro de eliminar este tipo de objeto?\n Esta acción no se puede deshacer y eliminará todos los objetos de este tipo."
			)
		)
			return;

		setLoading(true);
		axiosInstance
			.delete(`${URLS.OBJECT_TYPES}${data.id}/`)
			.then((_res) => {
				addMessage("Tipo de objeto eliminado exitosamente", "success");
				onFinished();
			})
			.catch((err) => {
				addMessage("Error al eliminar el tipo de objeto", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (e) => {
		if (e.target.name === "weight") {
			e.target.value = e.target.value.replace(",", "."); // Reemplazar ',' por '.
			if (isNaN(e.target.value)) return;
			if (e.target.value < 0) return;
		} else if (e.target.name === "type") {
			setCurrentData({ ...currentData, [e.target.name]: e.target.value, unit: "" });
			return;
		}
		setCurrentData({ ...currentData, [e.target.name]: e.target.value });
	};

	return (
		<form onSubmit={handleSave} style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<Stack direction="row" spacing={2}>
				<TextInput
					label="Nombre"
					InputLabelProps={{ shrink: true }}
					name={"name"}
					value={currentData["name"] || ""}
					onChange={handleChange}
					fullWidth
					required
					disabled={loading}
				/>

				<TextInput
					label="Peso (kg) / Densidad (kg/m)"
					InputLabelProps={{ shrink: true }}
					name={"weight"}
					value={currentData["weight"] || ""}
					onChange={handleChange}
					fullWidth
					disabled={loading}
					required
				/>
			</Stack>

			<Stack direction="row" spacing={2}>
				<TextInput
					label="Tipo"
					InputLabelProps={{ shrink: true }}
					name={"type"}
					value={currentData["type"] || ""}
					required
					onChange={handleChange}
					fullWidth
					select
					disabled={loading}
				>
					<MenuItem value={""}>Seleccione un tipo</MenuItem>

					{Object.keys(TYPES).map((option) => (
						<MenuItem key={option} value={TYPES[option]}>
							{TYPES[option]}
						</MenuItem>
					))}
				</TextInput>
			</Stack>

			<TextInput
				label="Descripción"
				InputLabelProps={{ shrink: true }}
				name={"description"}
				value={currentData["description"] || ""}
				onChange={handleChange}
				fullWidth
				multiline
				rows={3}
				disabled={loading}
				required
			/>

			<Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
				<Button variant="outlined" onClick={handleDelete} color="error" disabled={loading}>
					Eliminar
				</Button>
				<div style={{ display: "flex", gap: "20px" }}>
					<GeneralButton text="Cancelar" variant="empty" onClick={handleCancel} disabled={loading} />

					<GeneralButton text="Guardar" type="submit" disabled={loading} onClick={handleSave} />
				</div>
			</Box>
		</form>
	);
};

const TYPES = {
	Quantifiable: "Cuantificable",
	Measurable: "Medible",
};
