import { Data, GeneralInfo, GeneralInfoData, Logo, LogosContainer, Subtitle } from "../Common";



const GeneralData = ({ reportData }) => {

    return (
		<>
			<LogosContainer>
				<Logo src={reportData.wreckRescueCompany?.logo} alt={reportData.wreckRescueCompany?.name} />
				<Logo src={reportData.salmonCompany?.logo} alt={reportData.salmonCompany?.name} />
			</LogosContainer>

			<GeneralInfo smMaxPerRow={2} style={{ marginTop: "20px" }}>
				<GeneralInfoData>
					<Subtitle>Centro de cultivo</Subtitle>
					<Data>{reportData?.cultivationCenter?.name}</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Código</Subtitle>
					<Data>{reportData?.cultivationCenter?.code}</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Área</Subtitle>
					<Data>{reportData?.place}</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Empresa productora</Subtitle>
					<Data>{reportData?.salmonCompany?.name}</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Fecha de inicio</Subtitle>
					<Data>{reportData?.startDate}</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Fecha de término</Subtitle>
					<Data>{reportData?.endDate}</Data>
				</GeneralInfoData>
			</GeneralInfo>

			{reportData?.comments && (
				<GeneralInfo>
					<GeneralInfoData>
						<Data>Comentario:</Data>
						<Subtitle
							style={{
								fontSize: "14px",
							}}
						>
							{reportData?.comments}
						</Subtitle>
					</GeneralInfoData>
				</GeneralInfo>
			)}
		</>
	);
}

export default GeneralData;