import { Box } from "@mui/material";
import { SimpleInfo } from ".";

/**
 * Componente que muestra los datos de resumen de las estadísticas
 * @param {*} data: datos de la estadísticas
 */
const Summary = ({ data, type }) => {
	// Se selecciona los datos según el tipo de estadística
	const dataArea = type.type === "GENERAL" ? data?.byPlace : type.type === "AREA" ? data?.byCultivationCenter : data.byCultivationCenter;

	// Obtenemos el sector de menor y mayor recolección
	const minArea = dataArea?.reduce((min, current) => (current.total < min.total ? current : min), dataArea[0]);
	const maxArea = dataArea?.reduce((max, current) => (current.total > max.total ? current : max), dataArea[0]);

	// Obtenemos el día de menor y mayor recolección
	const maxDate = data?.byDate?.reduce((max, current) => (current.total > max.total ? current : max), data.byDate[0]);

	// Objeto más recolectado
	const maxObject = data?.byObjects?.reduce((max, current) => (current.total > max.total ? current : max), data.byObjects[0]);
	console.log(minArea);
	// Seleccionamos el objeto que se mostrará en el subdata del componente SimpleInfo según el tipo de estadística
	const minAreaObject =
		type.type === "GENERAL"
			? minArea?.report__cultivationCenter__place__name || ""
			: type.type === "AREA"
			? minArea?.report__cultivationCenter__name || ""
			: minArea?.type__name || "";

	const maxAreaObject =
		type.type === "GENERAL"
			? maxArea?.report__cultivationCenter__place__name || ""
			: type.type === "AREA"
			? maxArea?.report__cultivationCenter__name || ""
			: maxArea?.type__name || "";

	return (
		<Box sx={{ display: "flex", gap: "20px", padding: "0 20px 20px" }}>
			<SimpleInfo data={maxArea?.total.toFixed(0)} subdata={minAreaObject} title={type.objectName + " con mayor recolección"} />
			<SimpleInfo data={minArea?.total.toFixed(0)} subdata={maxAreaObject} title={type.objectName + " con menor recolección"} />
			<SimpleInfo data={maxDate?.total.toFixed(0)} subdata={maxDate?.report__date || ""} title="Día con mayor recolección" />
			<SimpleInfo data={maxObject?.total.toFixed(0)} subdata={maxObject?.type__name || ""} title="Objeto con mayor recolección" />
		</Box>
	);
};

export default Summary;
