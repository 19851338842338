import { TextField } from '@mui/material';
import { useEffect } from 'react';

const Description = ({ generalData, setGeneralData, isEditing, setCheckErrors}) => {
    const center = generalData.centers.find((item) => item.id === generalData.center);

    useEffect(() => {
        if (isEditing) {
            setCheckErrors(() => () => {
                return false;
            });
        }
    }, [isEditing, setCheckErrors]);


    return (
        <div style={{padding: "20px 30px"}}>
            <p>
                En el siguiente informe se detallan las labores de rescate de restos naúfragos realizadas entre {generalData.initialDate} y {generalData.finalDate} en el centro de cultivo {center?.name}, código: {center?.code}, área {center?.place?.name}, para la empresa {center?.salmonCompany?.name}.
            </p>
            <p>
                Se retiraron {generalData.allObjects.length} objetos y se tomaron {generalData.images.length} imágenes del fondo submarino. En las siguientes tablas y gráficos se detallan los objetos retirados y las imágenes tomadas.
            </p>
            {isEditing ? <TextField
                label="Añadir un comentario (opcional)..."
                variant="outlined"
                multiline
                rows={7}
                fullWidth
                style={{ flex: 1 }}
                value={generalData.description}
                onChange={(e) => setGeneralData(prev => ({ ...prev, description: e.target.value }))}
            /> :
                <p style={{whiteSpace: 'break-spaces'}}>
                    {generalData.description}
                </p>
            }
        </div>
    );
}

export default Description;