
// mui components
import Box from "@mui/material/Box";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";

// components
import { ObjectTypeDialogForm } from "./";
import BoxIcon from "../../Icons/BoxIcon";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";
import { useEffect, useState } from "react";

/**
 * Lista de tipos de objetos
 */
const ObjectTypesList = ({ openDetail, dataReload, setDataReload }) => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(URLS.OBJECT_TYPES)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					let msg = error.response?.data?.detail || "Error al obtener los tipos de objetos";
					addMessage(msg, "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [axiosInstance, addMessage, dataReload, setDataReload]);

	const columns = [
		{
			field: "name",
			headerName: "Nombre",
		},
		{
			field: "weight",
			headerName: "Peso (kg)",
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<GeneralButton
						text="Editar"
						onClick={() => openDetail(row.id, row)}
						variant="small"
						icon={<BoxIcon color="white" />}
					/>
				</div>
			),
		},
	];

	return <Table rows={rows} columns={columns} loading={loading} />;
};

/**
 * Componente para listar y agregar/modificar tipos de objetos
 */
const ObjectTypes = () => {
	const [dialog, setDialog] = useState({
		open: false,
		id: null,
		data: null,
	});

	const [dataReload, setDataReload] = useState(true);

	const openDialog = (id = null, data = null) => {
		setDialog({
			open: true,
			id: id,
			data: data,
		});
	};

	const exitDialog = () => {
		setDialog({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};

	return (
		<>
			<Box sx={{ width: "100%", position: "relative" }}>
				<div style={{ position: "absolute", top: "-60px", left: "20px" }}>
					<GeneralButton
						onClick={openDialog}
						text="Agregar tipo de objeto"
						icon={<BoxIcon color="white" />}
					/>
				</div>
			</Box>

			<ObjectTypesList openDetail={openDialog} dataReload={dataReload} setDataReload={setDataReload} />

			<ObjectTypeDialogForm open={dialog.open} data={dialog.data} onClose={exitDialog} />
		</>
	);
};

export default ObjectTypes;
