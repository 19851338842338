/**
 * MiniDrawer - Representa la barra superior y lateral izquierda que se utiliza en toda la aplicación.
 *              Incluye sus botones.
 */

import { useState } from "react";

import { Link } from "react-router-dom";

// mui styles
import { styled, useTheme } from "@mui/material/styles";
// mui components
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";

// mui icons
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EventIcon from "@mui/icons-material/Event";

// context
import { useAuthContext } from "../../context";
import UserIcon from "../Icons/UserIcon";

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: `calc(100% - ${64}px)`,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	zIndex: theme.zIndex.appBar + 1,
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export default function MiniDrawer({ children, MenuButtonSelected }) {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [loggingOut, setLoggingOut] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const { logout, username, first_name, USER_TYPE, canDelete } = useAuthContext();
	const [anchorElNotification, setAnchorElNotification] = useState(false);

	const menuItems = [
		{
			text: "Mapa",
			path: "/",
			userTypes: ["all"],
			icon: <MapOutlinedIcon />,
			textAppBar: "Bienvenido, " + first_name + "!",
		},
		{
			text: "Informes",
			path: "/reports",
			userTypes: ["all"],
			icon: <DescriptionOutlinedIcon />,
			textAppBar: "Informes",
		},
		{
			text: "Dashboard",
			path: "/dashboard",
			userTypes: ["Cliente", "Admin"],
			icon: <AssessmentOutlinedIcon />,
			textAppBar: "Dashboard",
		},
		{
			text: "Administración",
			path: "/admin",
			userTypes: ["Admin", "Super Admin", "Operador ROV"],
			icon: <SettingsOutlinedIcon />,
			textAppBar: "Administración",
		},
		{
			text: "Mi Perfil",
			path: "/profile",
			userTypes: ["all"],
			icon: <AccountCircle />,
			textAppBar: "Mi Perfil",
			hidden: true,
		},
		{
			text: "Eliminar",
			path: "/delete-requests",
			userTypes: ["Admin"],
			hidden: !canDelete,
			icon: <DeleteSweepIcon />,
			textAppBar: "Peticiones de Eliminación",
		},
		{
			text: "Calendario",
			path: "/calendario",
			userTypes: ["Cliente", "Admin"],
			icon: <EventIcon />,
			textAppBar: "Calendario",
		},
	];

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleProfileOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleProfileClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		if (loggingOut) return;
		setLoggingOut(true);
		logout();
	};

	return (
		<Box sx={{ display: "flex", maxWidth: "100%" }}>
			<CssBaseline />
			<AppBar
				open={open}
				sx={{
					height: "min(64px, 10vh) !important",
					backgroundColor: theme.palette.brandColors.primary,
					boxShadow: "0px 4px 15px 0px rgba(50, 55, 59, 0.15)",
				}}
			>
				<Toolbar>
					<div
						style={{
							justifyContent: "space-between",
							display: "flex",
							width: "100%",
							height: "100%",
							alignItems: "center",
						}}
					>
						<Typography variant="h6" noWrap component="div">
							<span style={{ fontWeight: "bold" }}>
								{menuItems.find((item) => item.path === window.location.pathname)?.textAppBar}
							</span>
						</Typography>
						<div style={{ display: "flex", height: "100%", alignItems: "center" }}>
							<Button
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleProfileOpen}
								color="inherit"
								component="div"
							>
								{/* <AccountCircle /> */}
								<div
									style={{
										borderRadius: "50%",
										border: "2px solid white",
										width: "30px",
										height: "30px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<UserIcon color={"white"} />
								</div>
								<Typography
									variant="subtitle1"
									noWrap
									component="div"
									textTransform="none"
									sx={{ marginLeft: "10px" }}
								>
									{username}
								</Typography>
							</Button>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorEl)}
								onClose={handleProfileClose}
							>
								<MenuItem component={Link} to="/profile" onClick={handleProfileClose}>
									Mi Perfil
								</MenuItem>
								<MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
							</Menu>
						</div>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open} sx={{ boxShadow: "0px 0px 15px 0px rgba(50, 55, 59, 0.15)" }}>
				<DrawerHeader>
					{open ? (
						<>
							<img src="/rastrum.png" alt="" style={{ width: "50%", height: "auto", margin: "auto" }} />
							<IconButton
								onClick={handleDrawerClose}
								sx={{
									backgroundColor: "brandColors.primary",
									color: "white",
									"&:hover": { backgroundColor: "brandColors.primary" },
								}}
							>
								{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
							</IconButton>
						</>
					) : (
						<IconButton
							onClick={handleDrawerOpen}
							sx={{
								backgroundColor: "brandColors.primary",
								color: "white",
								"&:hover": { backgroundColor: "brandColors.primary" },
							}}
						>
							<SearchIcon />
						</IconButton>
					)}
				</DrawerHeader>
				<Divider />
				<List>
					{/* Items de menu solo se muestran para los userTypes correspondientes */}
					{menuItems.map((item, index) => {
						if ((item.userTypes.includes(USER_TYPE) || item.userTypes.includes("all")) && !item.hidden) {
							return (
								<ListItem key={index} disablePadding sx={{ display: "block" }}>
									<ListItemButton
										component={Link}
										to={item.path}
										onClick={handleDrawerClose} // comentar para dejar de cerrar el drawer al hacer click
										sx={{
											minHeight: 48,
											justifyContent: open ? "initial" : "center",
											px: 2.5,
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 3 : "auto",
												justifyContent: "center",
												color:
													window.location.pathname === item.path
														? "brandColors.primary"
														: "inherit",
											}}
										>
											{item.icon}
										</ListItemIcon>
										<ListItemText
											primary={item.text}
											sx={{
												opacity: open ? 1 : 0,
												color:
													window.location.pathname === item.path
														? "brandColors.primary"
														: "inherit",
											}}
										/>
									</ListItemButton>
								</ListItem>
							);
						}
						return null;
					})}
					{/* end */}
				</List>
				<Divider />
			</Drawer>
			<Box
				component="main"
				sx={{
					marginTop: "min(10vh, 64px)",
					flexGrow: 1,
					height: "calc(100vh - min(10vh, 64px))",
					maxWidth: window.location.pathname === "/" ? "100%" : "calc(100% - 65px)",
				}}
			>
				{children}
			</Box>
		</Box>
	);
}
