import { Table, TableBody, TableCell, TableHead, TableRow, TextField, IconButton, Box } from "@mui/material";
import { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { GraphContainerStyle } from "../Create/StatesConstants";
import { useTheme } from "@mui/material/styles";
import Plot from "react-plotly.js";
import FileUpload from "./FileUpload";
import { MyPlot } from "./Graphs";

const WorksResume = ({ generalData, setGeneralData }) => {
	const theme = useTheme();
	const [additionalRows, setAdditionalRows] = useState(generalData.additionalRows);
	const [addingRow, setAddingRow] = useState({
		type: "",
		total: 0,
	});

	const addRows = additionalRows.split("|||");

	useEffect(() => {
		setGeneralData((prev) => ({ ...prev, additionalRows: additionalRows }));
	}, [additionalRows, setGeneralData, generalData]);

	let total = generalData.report.reduce((acc, item) => acc + item.total, 0);
	total += addRows.reduce((acc, item) => acc + (item === "" ? 0 : parseInt(item.split(";;")[1])), 0);

	const layout = {
		responsive: true,
		autosize: true,
		font: {
			family: "Roboto, sans-serif",
			size: 14,
		},
		yaxis: {
			automargin: true,
		},
		xaxis: {
			automargin: true,
		},
		margin: "auto",
		title: {
			font: {
				size: 16,
				family: "Roboto, sans-serif",
				color: theme.palette.brandColors.primary,
			},
		},
		modebar: { remove: ["pan", "zoom", "zoomIn2d", "zoomOut2d", "autoScale2d", "resetScale2d", "select", "lasso"] },
	};

	const ContainerStyle = {
		...GraphContainerStyle,
		border: "1px solid black",
		display: "flex",
		justifyContent: "center",
	};

	const twoContainers = {
		display: "flex",
		// justifyContent: "space-between",
	};

	const labels = generalData.report.map((item) => item.type);
	labels.push(...addRows.filter((item) => item !== "").map((item) => item.split(";;")[0]));
	const values = generalData.report.map((item) => item.total);
	values.push(...addRows.filter((item) => item !== "").map((item) => item.split(";;")[1]));

	return (
		<div style={{padding: "20px 30px"}}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Motivo</TableCell>
						<TableCell>Días</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{generalData.report.map((object, index) => (
						<TableRow key={index}>
							<TableCell>{object.type}</TableCell>
							<TableCell>{object.total}</TableCell>
						</TableRow>
					))}
					{addRows.map((row, index) => (
						<TableRow key={index}>
							<TableCell>{row.split(";;")[0]}</TableCell>
							<TableCell>{row.split(";;")[1]}</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell>
							<TextField
								variant="outlined"
								label="Motivo"
								value={addingRow.type}
								onChange={(e) => setAddingRow({ ...addingRow, type: e.target.value })}
							/>
						</TableCell>
						<TableCell>
							<TextField
								variant="outlined"
								label="Días"
								value={addingRow.total}
								onChange={(e) => setAddingRow({ ...addingRow, total: e.target.value })}
							/>
						</TableCell>
						<TableCell>
							<IconButton
								onClick={() => {
									setAdditionalRows(
										additionalRows === ""
											? addingRow.type + ";;" + addingRow.total
											: additionalRows + "|||" + addingRow.type + ";;" + addingRow.total
									);
									setAddingRow({
										type: "",
										total: 0,
									});
								}}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						</TableCell>
					</TableRow>

					<TableRow>
						<TableCell>Total</TableCell>
						<TableCell>{total}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Box sx={twoContainers}>
				<Box sx={ContainerStyle}>
					<MyPlot
						config={{ responsive: true, displayModeBar: false }}
						data={[
							{
								labels: labels,
								values: values,
								texttemplate: "%{value} días | %{percent}",
								type: "pie",
								marker: {
									colors: Object.keys(theme.palette.brandColors)
										.filter((item) => item !== "white")
										.map((item) => theme.palette.brandColors[item]),
								},
							},
						]}
						layout={{
							...layout,
							title: {
								...layout.title,
							},
						}}
						style={{ width: "100%", height: "100%" }}
					/>
				</Box>
				<Box>
					<FileUpload
						sx={GraphContainerStyle}
						setImage={(image) => setGeneralData((prev) => ({ ...prev, reportTypeImage: image }))}
						image={generalData.reportTypeImage}
					/>
				</Box>
			</Box>
		</div>
	);
};

export default WorksResume;
