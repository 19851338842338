import { styled } from "@mui/material/styles";
import TicketIcon from "../Icons/TicketIcon";

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: "10px",
}));

const Checkbox = styled("div", {
	shouldForwardProp: (prop) => prop !== "disabled" && prop !== "checked",
})(({ theme, disabled, checked }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "24px",
	height: "24px",
	borderRadius: "50%",
	border: `2px solid ${checked ? theme.palette.brandColors.primary : "black"}`,
	cursor: "pointer",
	backgroundColor: checked ? theme.palette.brandColors.primary : "white",
	transition: "all 0.3s",
	opacity: disabled ? "0.5" : "1",
}));

const Label = styled("div")(({ theme, checked }) => ({
	color: checked ? theme.palette.brandColors.primary : theme.palette.brandColors.danger,
}));

/**
 * Componente que muestra un checkbox circular.
 * @param checked Estado del checkbox.
 * @param onChange Función que se ejecuta al cambiar el estado del checkbox.
 * @param disabled Indica si el checkbox está deshabilitado.
 * @param label Etiqueta del checkbox.
 */
const CircularCheckbox = ({ checked, onChange, disabled, label}) => {
	return (
		<Container>
			<Checkbox onClick={() => !disabled && onChange(!checked)} checked={checked} disabled={disabled}>
				{checked && <TicketIcon />}
			</Checkbox>
			{label && <Label checked={checked}>{label}</Label>}
		</Container>
	);
};

export default CircularCheckbox;
