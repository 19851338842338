import { useContext, useEffect, useState } from "react";
import { AuthContext, ReportDataContext } from "../../../context";
import { URLS } from "../../../global";
import { DataGrid } from "@mui/x-data-grid";
import GeneralButton from "../../GeneralButton";
import { Box, useTheme } from "@mui/material";

const BeaconObjects = ({}) => {
	const { axiosInstance } = useContext(AuthContext);
	const { reportData, setReportData } = useContext(ReportDataContext);
	const theme = useTheme();
	const [beaconObjects, setBeaconObjects] = useState([]);
	const [filteredBeaconObjects, setFilteredBeaconObjects] = useState([]);
	const [selectedBeaconObjects, setSelectedBeaconObjects] = useState([]);

	const [selectedBeaconRescued, setSelectedBeaconRescued] = useState([]);

	useEffect(() => {
		axiosInstance
			.get(URLS.OBJECTS + "beacon_objects/" + reportData.cultivationCenter.id + "/")
			.then((res) => {
				// Ver si los balizados cambian su estado de inmediato o al informe quedar como revisado
				setFilteredBeaconObjects(
					res.data
						.filter((beacon) => !reportData.beaconObjects.map((b) => b.castawayRest.id).includes(beacon.id))
						.sort((a, b) => (a.id > b.id ? 1 : -1))
				);
				setBeaconObjects(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const columns = [
		{ field: "id", headerName: "ID", flex: 1, align: "center" },
		{ field: "latitude", headerName: "Latitud", flex: 1, align: "center" },
		{ field: "longitude", headerName: "Longitud", flex: 1, align: "center" },
		{ field: "type", headerName: "Tipo", flex: 1, align: "center", renderCell: (params) => params.value.name },
	];

	const handleSelectionChange = (selection) => {
		setSelectedBeaconObjects(selection);
	};

	const handleAddObjects = () => {
		const newBeaconObjects = beaconObjects
			.filter((beacon) => selectedBeaconObjects.includes(beacon.id))
			.map((beacon) => ({ castawayRest: beacon, id: null, new: true }));
		setReportData({ ...reportData, beaconObjects: [...reportData.beaconObjects, ...newBeaconObjects] });
		const filteredBeaconObjects = beaconObjects.filter((beacon) => !selectedBeaconObjects.includes(beacon.id));
		setFilteredBeaconObjects(filteredBeaconObjects);
		setSelectedBeaconObjects([]);
	};

	const handleDeleteObjects = () => {
		const filteredBeaconObjects = reportData.beaconObjects.filter(
			(beacon) => !selectedBeaconRescued.includes(beacon.castawayRest.id)
		);
		const deletedBeaconObjects = reportData.beaconObjects.filter(
			(beacon) => selectedBeaconRescued.includes(beacon.castawayRest.id) && !beacon.new
		);
		setReportData({
			...reportData,
			beaconObjects: filteredBeaconObjects,
			deletedBeaconObjects: deletedBeaconObjects,
		});
		setSelectedBeaconRescued([]);
	};

	const inBeaconObjects = reportData.beaconObjects.map((beacon) => ({
		...beacon.castawayRest,
	}));

	return (
		<div style={{padding: "20px 30px"}}>
			<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", maxWidth: "100%" }}>
				<GeneralButton
					text="Agregar Objetos Seleccionados"
					onClick={handleAddObjects}
					sx={{ marginBottom: 2 }}
				/>

				<GeneralButton
					text="Quitar Objetos Seleccionados"
					onClick={handleDeleteObjects}
					sx={{
						marginBottom: 2,
						backgroundColor: theme.palette.brandColors.danger,
						"&:hover": { backgroundColor: theme.palette.brandColors.danger },
					}}
				/>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Box>Objetos Balizados</Box>
				<Box sx={{ alignItems: "end", display: "flex" }}>Objetos Rescatados</Box>
			</Box>

			<Box sx={{ display: "flex", gap: "20px" }}>
				<Box sx={{ width: "50%", maxWidth: "50%" }}>
					<DataGrid
						rows={filteredBeaconObjects}
						sx={{ maxWidth: "calc(100% - 10px)" }}
						columns={columns}
						pageSize={5}
						onRowSelectionModelChange={handleSelectionChange}
						rowsPerPageOptions={[5]}
						checkboxSelection
					/>
				</Box>

				<Box sx={{ width: "50%", maxWidth: "50%" }}>
					<DataGrid
						rows={inBeaconObjects}
						sx={{ maxWidth: "calc(100% - 10px)" }}
						columns={columns}
						pageSize={5}
						onRowSelectionModelChange={setSelectedBeaconRescued}
						rowsPerPageOptions={[5]}
						checkboxSelection
					/>
				</Box>
			</Box>
		</div>
	);
};

export default BeaconObjects;
