import { generateObjectsPoints, generateStructurePointsFromCentros, findCentralPoint } from "../../../utils/map_helper";
import { useEffect, useState } from "react";
import { Map } from "../../Map";
import { MapContainerStyle } from "../Create/StatesConstants";
import { Box, Switch } from "@mui/material";
import FileUpload from "./FileUpload";
import MultipleImages from "./MultipleImages";

const ObjectsMap = ({ generalData, setGeneralData, setCheckErrors }) => {
	const [objects] = useState(generateObjectsPoints(generalData.allObjects));
	const center = generalData.centers.find((center) => center.id === generalData.center);
	const [structures] = useState(generateStructurePointsFromCentros([center]));

	useEffect(() => {
		setCheckErrors(() => () => generalData.objectMap === null);
	}, [generalData.objectMap, setCheckErrors]);

	const ContainerStyle = {
		...MapContainerStyle,
		border: "1px solid black",
		display: "flex",
		justifyContent: "center",
		width: generalData.isObjectMapHorizontal ? "1200px" : "900px",
		height: generalData.isObjectMapHorizontal ? "900px" : "1200px",
	};
	const mapCenter = findCentralPoint(center.coordinates);
	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				padding: "20px 30px",
				flexDirection: "column",
				gap: "20px",
			}}
		>
			<Box>
				Vertical
				<Switch
					checked={generalData.isObjectMapHorizontal}
					onChange={() =>
						setGeneralData((prev) => ({ ...prev, isObjectMapHorizontal: !prev.isObjectMapHorizontal }))
					}
				/>
				Horizontal
			</Box>
			<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
				<Box sx={ContainerStyle}>
					<Map
						key={generalData.isObjectMapHorizontal}
						data={{
							objects: objects,
							structures: structures,
						}}
						initial_state={{
							initial_is_clustered: false,
							initial_cluster_radius: 0,
							initial_zoom: 14,
							initial_center: mapCenter,
						}}
						smallLegends
					/>
				</Box>
			</div>
			<Box>Imagen Principal</Box>
			<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
				<FileUpload
					image={generalData.objectMap}
					sx={{ ...ContainerStyle }}
					setImage={(image) => {
						setGeneralData((prev) => {
							prev.objectMap && URL.revokeObjectURL(prev.objectMap);
							return { ...prev, objectMap: image };
						});
					}}
				/>
			</div>
			<div>Imágenes Adicionales</div>
			<MultipleImages
				images={generalData.addObjectsMap}
				setImages={(images) => setGeneralData((prev) => ({ ...prev, addObjectsMap: images }))}
				AddDeletedImage={(newImage) =>
					setGeneralData((prev) => ({ ...prev, deletedObjectsMap: [...prev.deletedObjectsMap, newImage] }))
				}
			/>
		</Box>
	);
};

export default ObjectsMap;
