

const RotateIcon = () => {
    return (
		<svg xmlns="http://www.w3.org/2000/svg" width="74" height="75" viewBox="0 0 74 75" fill="none">
			<g clipPath="url(#clip0_209_1016)">
				<path
					d="M41.8894 74.9999C39.6818 73.9362 39.3325 71.9338 41.0538 70.1836C43.3135 67.8879 45.577 65.5941 47.8425 63.3043C49.311 61.822 50.9358 61.9061 52.2383 63.5213C52.9079 64.3524 53.591 65.1717 54.2413 66.0185C54.5675 66.4448 54.8569 66.5034 55.3316 66.2179C62.3229 62.0137 66.6744 55.9067 68.2741 47.815C68.5578 46.3797 68.687 44.9248 68.7141 43.4602C68.7449 41.7472 69.8661 40.5505 71.4176 40.5681C72.9208 40.5857 74.0265 41.8039 73.9995 43.4817C73.8683 51.6086 71.0393 58.6659 65.6497 64.6457C60.7271 70.1074 54.6099 73.4199 47.4141 74.6734C46.6827 74.8005 45.9436 74.8924 45.2084 75.0019H41.8855L41.8894 74.9999Z"
					fill="white"
				/>
				<path
					d="M0 29.1852C0.283665 27.261 0.51137 25.3212 1.09607 23.4576C5.05002 10.8703 13.3998 3.19118 26.157 0.463304C27.8301 0.105453 29.534 -0.0157857 31.2476 -0.000142002C32.4382 0.0115908 33.2834 0.553255 33.7562 1.63267C34.227 2.70622 33.9955 3.72502 33.2101 4.5385C30.7671 7.06105 28.3048 9.56796 25.8078 12.0377C24.6519 13.1817 22.9672 13.0311 21.9098 11.7718C21.1939 10.9172 20.4857 10.0529 19.8199 9.15927C19.432 8.63911 19.0943 8.52765 18.5058 8.88941C11.2289 13.3733 6.80797 19.8048 5.50542 28.3756C5.35491 29.3632 5.25263 30.3565 5.24299 31.3558C5.22755 33.0825 4.48269 34.1286 3.05278 34.4356C1.80041 34.7055 0.694691 34.0211 0 32.5506V29.1833L0 29.1852Z"
					fill="white"
				/>
				<path
					d="M46.8423 3.08398C48.6581 3.12896 50.171 3.67454 51.3906 4.90648C57.3206 10.9019 63.2544 16.8935 69.1554 22.9183C71.361 25.171 71.5559 28.5833 69.7594 31.5673C69.3561 32.2361 68.8621 32.8325 68.314 33.3879C56.5004 45.3573 44.6907 57.3306 32.8732 69.2942C30.9088 71.2829 28.5854 72.3134 25.7527 71.8284C24.4771 71.6094 23.3965 70.9974 22.4876 70.0763C16.6136 64.1278 10.7338 58.1851 4.87527 52.221C2.62331 49.9272 2.38017 46.5384 4.18251 43.5231C4.59546 42.8308 5.10876 42.2188 5.67416 41.6478C17.453 29.7116 29.2319 17.7774 41.005 5.83533C42.6549 4.16145 44.5826 3.14656 46.8423 3.08594V3.08398ZM49.2332 11.6861C49.322 10.7279 48.6118 10.1647 47.9963 9.55267C46.9967 8.56125 45.8061 8.46934 44.6714 9.30042C44.4012 9.49987 44.162 9.74626 43.9246 9.98678C32.5394 21.5201 21.1561 33.0574 9.77284 44.5946C9.51812 44.8528 9.25954 45.1168 9.06078 45.4159C8.3082 46.5442 8.40854 47.7136 9.34637 48.6659C14.8904 54.2918 20.4363 59.9118 25.9881 65.5299C26.8487 66.402 27.8001 66.5369 28.8865 65.9972C29.3284 65.7782 29.6738 65.4438 30.0154 65.0977C41.3986 53.5605 52.7819 42.0252 64.1652 30.4879C64.3524 30.2982 64.5511 30.1164 64.7074 29.9013C65.2979 29.0976 65.6954 28.1707 65.1899 27.2438C64.1652 25.3704 62.4111 24.1346 60.3347 25.9825C58.9704 27.1969 57.6525 27.1538 56.3847 25.871C53.7796 23.2351 51.1764 20.5952 48.5732 17.9573C47.1935 16.5572 47.1453 15.292 48.4285 13.796C48.936 13.2055 49.2814 12.5582 49.2332 11.6841V11.6861Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_209_1016">
					<rect width="74" height="75" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default RotateIcon;