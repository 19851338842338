import FileDrop from "./FileDrop";
import { Box, Typography } from '@mui/material';

const Info = ({ handleFileUpload }) => {

    const listItemStyle = {
        margin: "10px 0"
    }

    return (
        <Box sx={{
            height: '100%',
            marginBottom: '50px',
        }}>
            <Box
                sx={{ marginBottom: 3 }}
            >
                Desde aquí puedes importar un archivo Excel con los datos de los objetos y sus imágenes.
                Para ello se descarga los archivos desde el siguiente botón. En este se descargan los siguientes archivos:
                <ul
                    sx={{ listStyle: 'inside' }}
                >
                    <li style={listItemStyle}>
                        TemplateRestos.xlsx: Archivo de excel para completar los datos de los informes y sus respectivos objetos. Algunos campos como el centro o la embarcación se debe completar luego.

                        En esta la hoja Datos Generales se deben completar los datos generales del informe, en la hoja Objetos se deben completar los datos de los objetos y en la hoja Imágenes se deben completar las descripciones de las imágenes que no pertecen a objetos.
                        En la hoja Imágenes Georreferenciadas se deben completar las descripciones, latitud y longitud de las imágenes para el informe de Prospección de Fondo. Todas las imágenes se agregan a parte del excel.
                    </li>
                    <li style={listItemStyle}>Objetos.xlsx: Archivo excel con los tipos de objetos disponibles. El contenido de este excel debe ser copiado en la hoja Tipos de Objetos del archivo TemplateRestos.</li>
                    <li style={listItemStyle}>Instructivo.docx: Instructivo para completar el archivo TemplateRestos</li>
                </ul>
                Para agregar imágenes a los objetos, estas deben seguir el formato "Número de Objeto-Estado",
                donde el número de objeto es el número de objeto en el archivo TemplateRestos en la hoja Objetos y el estado es F para la imagen submarina y S para la imagen en la superficie.
                Ejemplo: 12-F.jpg.

                Para agregar imágenes sin objetos, estan deben seguir el formato "Número de Imagen",
                donde el número de imagen es el número de imagen en el archivo TemplateRestos en la hoja Imágenes.
                Ejemplo: 5.png.

            </Box>
            <Typography>Cargar archivo Excel</Typography>
            <Box sx={{ display: '', gap: "20px" }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
                    <FileDrop handleInputChange={handleFileUpload} />

                </Box>
            </Box>
        </Box>
    );
};

export default Info;