import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context';
import * as XLSX from 'xlsx/xlsx.mjs';
import { URLS } from '../../../global';
import { Box, Breadcrumbs, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { STATES } from '../Create/StatesConstants';
import Info from './InfoStep';
import Objects from './ObjectStep';
import Images from './ImagesStep';
import GeoImages from './GeoImagesStep';


const BoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    height: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'hidden',
};

function excelDateToJSDate(date) {
    return new Date(Date.UTC(0, 0, date) - 1);;
}


const UploadFromExcel = ({ onUpload }) => {
    const [objectType, setObjectType] = useState([]);
    const [innerObjectType, setInnerObjectType] = useState([]);
    const { axiosInstance } = useContext(AuthContext);
    const [objects, setObject] = useState([]);
    const [objectsErrorsList, setObjectsErrorsList] = useState([]);
    const [generalData, setGeneralData] = useState({});
    const [imagesDescriptions, setImagesDescriptions] = useState([{}]);
    const [activeStep, setActiveStep] = useState(0);
    const [objectImages, setObjectImages] = useState([]);
    const [imagesErrorsList, setImagesErrorsList] = useState([]);
    const [reviewedObjects, setReviewedObjects] = useState([]);
    const [images, setImages] = useState([]);
    const [imagesErrors, setImagesErrors] = useState([]);
    const [reviewedImages, setReviewedImages] = useState([]);
    const [geoImages, setGeoImages] = useState([]);
    const [geoImagesDescriptions, setGeoImagesDescriptions] = useState([]);
    const [geoImagesErrors, setGeoImagesErrors] = useState([]);
    const [geoImagesReview, setGeoImagesReview] = useState([]);

    useEffect(() => {
        // Petición para obtener los objetos predeterminados
        axiosInstance.get(URLS.OBJECT_TYPES).then((response) => {
            setObjectType(response.data.map((row) => ({
                Nombre: row.name,
                ID: row.id,
                Peso: row.weight || 1, // Peso/densidad por unidad
                Unidad: row.unit || "Unidad"
            })))

            setInnerObjectType(response.data.map((row) => ({
                ...row,
                label: row.name,
                id: row.id,
                metrics: row.unit || "Unidad",
                conversionRate: row.weight || 1 // Peso/densidad por unidad
            })))
        }).catch((error) => {
            console.error(error);
        });
    }, [axiosInstance]);

    const createExcelTemplate = () => {
        // Se crea el excel de tipos de objetos
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(objectType);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Objetos");
        XLSX.writeFile(workbook, "Objetos.xlsx", { compression: false });

        // Se descarga el template
        const filePath = process.env.PUBLIC_URL + '/TemplateRestos.xlsx';
        const downloadLink = document.createElement('a');
        downloadLink.href = filePath;
        downloadLink.download = 'TemplateRestos.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Se descarga el instructivo
        downloadLink.href = process.env.PUBLIC_URL + '/Instructivo.docx';
        downloadLink.download = 'Instructivo.docx';
        downloadLink.click();

        document.body.removeChild(downloadLink);
    };

    const handleFileUpload = (file) => {

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary', cellFormula: true, bookDeps: true, xlfn: true });

                // Datos generales
                const worksheet = workbook.Sheets["Datos Generales"];
                const jsonData = XLSX.utils.sheet_to_json(worksheet)[0];

                const date = excelDateToJSDate(jsonData["Fecha"]).toISOString().split('T')[0]
                let reportType = jsonData["Tipo De Informe"];
                const comentarios = jsonData["Comentarios"];

                reportType = STATES[Object.keys(STATES).filter((type) => STATES[type].label === reportType)[0]]

                setGeneralData({
                    date: date,
                    reportType: reportType,
                    comments: comentarios,
                });

                // Objetos
                const worksheet2 = workbook.Sheets["Objetos"];
                const jsonData2 = XLSX.utils.sheet_to_json(worksheet2);
                const objectList = [];
                const failedObjects = [];
                const columns = ["Balizado", "Tipo", "Cantidad", "Latitud", "Longitud", "Notificar", "Número de Objeto", "Tipo_id"]
                jsonData2.forEach((row) => {
                    let valid = true;
                    let reason = "";
                    for (const key of columns) {
                        if (!row[key]) {
                            valid = false;
                            reason = "Faltan el campo " + key + " en el objeto";
                            break;
                        }
                    }
                    if (!valid) {
                        console.log(reason);
                        return;
                    }
                    const objectID = row["Tipo_id"]
                    const object = innerObjectType.find((object) => object.id === objectID);
                    if (!object) {
                        valid = false;
                        reason = "El tipo de objeto no existe";
                    }
                    if (valid) {
                        const cantidad = object.metrics === "Unidad" ? parseInt(String(row["Cantidad"])) : row["Cantidad"];
                        const volume = cantidad * object.conversionRate;
                        objectList.push({
                            type: object,
                            amount: cantidad,
                            volume: volume,
                            beacon: row["Balizado"] === "Sí",
                            notify: row["Notificar"] === "Sí",
                            latitude: row["Latitud"],
                            longitude: row["Longitud"],
                            comments: row["Comentarios"] || "",
                            excelId: row["Número de Objeto"],
                            database_id: -1,
                        });
                    } else {
                        failedObjects.push({
                            ...row,
                            reason: reason,
                            excelId: row["Número de Objeto"],
                        });
                    }
                });
                setObject(objectList);
                setObjectsErrorsList(failedObjects);

                // Descripciones de imágenes
                const newDescriptions = [];
                const imagesWS = workbook.Sheets["Imágenes"];
                const imagesJson = XLSX.utils.sheet_to_json(imagesWS);
                imagesJson.forEach((row) => {
                    const excelId = row["Número de Imagen"];
                    const description = row["Descripción"];
                    newDescriptions.push({
                        excelId: excelId,
                        description: description,
                    });
                });
                setImagesDescriptions(newDescriptions);

                // Imágenes Georrerenciadas
                const newDescriptions2 = [];
                const imagesWS2 = workbook.Sheets["Imágenes Georreferenciadas"];
                const imagesJson2 = XLSX.utils.sheet_to_json(imagesWS2);
                imagesJson2.forEach((row) => {
                    const excelId = row["Número de Imagen"];
                    const description = row["Descripción"];
                    const latitude = row["Latitud"];
                    const longitude = row["Longitud"];
                    newDescriptions2.push({
                        excelId: excelId,
                        description: description,
                        latitude: latitude,
                        longitude: longitude,

                    });
                });
                setGeoImagesDescriptions(newDescriptions2);
            };

            reader.readAsBinaryString(file);
        }
    };

    const buttonStyle = {
        backgroundColor: '#22ACA4',
        color: 'white',
        padding: '10px',
        '&:hover': {
            backgroundColor: '#22ACA4',
            color: 'white',
        }
    }
    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleNext = () => {
        if (activeStep < 2) {
            if (generalData?.reportType?.id === STATES.NORMAL_DAY.id || generalData?.reportType?.id === STATES.SEA_BOTTOM.id) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 2);
            }
        } else {
            handleUpload();
        }
    };

    // Se procesan los objetos e imágenes y se comprueba que estén bien
    useEffect(() => {
        const newObjects = [...objects];
        const errors = [];
        objectImages.forEach((image) => {
            const imageName = image.image.name;
            const imageNumber = imageName.split('-')[0];
            if (imageName.split('-').length !== 2) {
                errors.push({
                    name: imageName,
                    reason: 'El nombre de la imagen no es válido',
                })
                return;
            }
            const imageType = imageName.split('-')[1].replace('.jpg', '').replace('.png', '').replace('.jpeg', '');
            const object = newObjects.find((object) => object.excelId == imageNumber);
            if (object) {
                if (imageType === "S") {
                    object.inBoatImage = image.image;
                    newObjects[newObjects.indexOf(object)] = object;
                }
                if (imageType === "F") {
                    object.submarineImage = image.image;
                    newObjects[newObjects.indexOf(object)] = object;
                }
            } else {
                errors.push({
                    name: imageName,
                    reason: 'No existe el objeto con el número de objeto ' + imageNumber + ' entre los objetos válidos.',
                })
            }
        });
        let objectsWithImages = [...newObjects];
        newObjects.forEach((object, index) => {
            if (!object.submarineImage) {
                errors.push({
                    name: object.excelId + '-F',
                    reason: 'No se encontró la imagen del fondo submarino del objeto ' + object.excelId,
                })
                objectsWithImages = objectsWithImages.filter((obj) => obj.excelId !== object.excelId);
            }
            object.id = index;
        });
        setReviewedObjects(objectsWithImages);
        setImagesErrorsList(errors);
    }, [objectImages, objects]);

    // Se procesan las imágenes y las descripciones y se comprueba que estén bien
    useEffect(() => {
        const errors = [];
        const imagesWithDescription = [];
        images.forEach((image) => {
            const imageName = image.image.name;
            const imageType = imageName.replace('.jpg', '').replace('.png', '').replace('.jpeg', '');
            const description = imagesDescriptions.find((image) => image.excelId == imageType);
            if (description) {
                console.log(image);
                image.description = description.description;
                imagesWithDescription.push(image);
            } else {
                errors.push({
                    name: imageName,
                    reason: 'No existe la descripción ' + imageType,
                })
            }
        });
        setReviewedImages(imagesWithDescription);
        setImagesErrors(errors);

    }, [images, imagesDescriptions]);

    useEffect(() => {
        const errors = [];
        const imagesWithDescription = [];
        geoImages.forEach((image) => {
            const imageName = image.image.name;
            const imageType = imageName.replace('.jpg', '').replace('.png', '').replace('.jpeg', '');
            const description = geoImagesDescriptions.find((image) => image.excelId == imageType);
            if (description) {
                image.description = description.description;
                image.latitude = description.latitude;
                image.longitude = description.longitude;
                imagesWithDescription.push(image);
            } else {
                errors.push({
                    name: imageName,
                    reason: 'No existe la descripción ' + imageType,
                })
            }
        });
        setGeoImagesErrors(errors);
        setGeoImagesReview(imagesWithDescription);
    }, [geoImages, geoImagesDescriptions]);

    const handleUpload = () => {
        const newReportData = {
            ...generalData,
            cultivationCenter: null,
            boat: null,
            imagesList: reviewedImages,
            geoImagesList: geoImagesReview,
            imagesDeleted: [],
            objectsList: [...reviewedObjects],
            objectsDeleted: [],
            map: null,
            reportId: -1,
            initialCounter: reviewedObjects.length,
            isEdit: false,
        }
        console.log(newReportData);
        onUpload(newReportData);
    };

    return (
        <Box sx={BoxStyle}>
            <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', height: activeStep === 0 ? '10%' : '1%' }}
            >
                <Breadcrumbs
                    separator={<NavigateNextIcon />}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        // boxShadow: "0 0 10px 5px #e8e8e8",
                        padding: "0 20px",
                        width: "90%",
                        marginBottom: "20px"
                    }}
                >
                    <Box onClick={() => setActiveStep(0)} sx={{ cursor: 'pointer' }}>
                        Importar Archivo
                    </Box>
                    {
                        generalData?.reportType?.id === STATES.NORMAL_DAY.id &&
                        <Box onClick={() => setActiveStep(1)} sx={{ cursor: 'pointer' }}>
                            Importar Objetos e Imágenes
                        </Box>
                    }
                    {
                        generalData?.reportType?.id === STATES.SEA_BOTTOM.id &&
                        <Box onClick={() => setActiveStep(1)} sx={{ cursor: 'pointer' }}>
                            Importar Imágenes Georreferenciadas
                        </Box>
                    }
                    <Box onClick={() => setActiveStep(2)} sx={{ cursor: 'pointer' }}>
                        Importar Imágenes
                    </Box>
                </Breadcrumbs>

                {activeStep === 0 && <Button
                    variant="contained"
                    sx={{ height: '100%', justifyContent: 'flex-end', display: 'flex', width: 'fit-content', ...buttonStyle }}
                    onClick={createExcelTemplate}
                >
                    Descargar template
                </Button>}
            </Box>

            <Box sx={{ height: activeStep === 0 ? '80%' : '86%', overflow: 'auto', padding: 3 }}>
                {activeStep === 0 && <Info handleFileUpload={handleFileUpload} />}
                {activeStep === 1 && generalData?.reportType?.id === STATES.NORMAL_DAY.id &&
                    <Objects
                        objects={objects}
                        objectsErrorsList={objectsErrorsList}
                        objectImages={objectImages}
                        setObjectImages={setObjectImages}
                        imagesErrorsList={imagesErrorsList}
                    />
                }
                {activeStep === 1 && generalData?.reportType?.id === STATES.SEA_BOTTOM.id &&
                    <GeoImages
                        images={geoImages}
                        reviewedImages={geoImagesReview}
                        setImages={setGeoImages}
                        geoImagesErrors={geoImagesErrors}
                        requiredImages={geoImagesDescriptions.length}
                    />
                }
                {activeStep === 2 &&
                    <Images
                        images={images}
                        setImages={setImages}
                        imagesErrors={imagesErrors}
                        requiredImages={imagesDescriptions.length}
                    />
                }
            </Box>

            <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: "20px 0", height: '10%' }}
            >
                <Button sx={buttonStyle} onClick={handleBack}>
                    Volver
                </Button>
                <Button sx={buttonStyle} onClick={handleNext}>
                    {activeStep === 2 ? "Terminar" : "Siguiente"}
                </Button>
            </Box>
        </Box>
    );
};

export default UploadFromExcel;