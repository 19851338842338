
// mui components
import {
	useTheme,
	Tooltip,
	Checkbox,
	Autocomplete,
	ListItem,
	Box,
	ListItemText,
	Typography,
	Fab,
	Divider,
	List,
} from "@mui/material";

// mui icons
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
import TextInput from "../../Inputs/TextInput";
import DeleteIcon from "../../Icons/DeleteIcon";
import IconButtons from "../../Icons/IconButtons";
import { useEffect, useState } from "react";

const permiso_types = [
	{
		id: 1,
		name: "canViewHistory",
		description: "Ver historial",
		help: "Este permiso permite al operador todos los objetos e informes creados en este centro, sin ningún filtro.",
	},
	{
		id: 2,
		name: "canUploadReport",
		description: "Subir reportes",
		help: "Este permiso permite al operador subir reportes en este centro.",
	},
];

/**
 * Componente para mostrar un permiso de un ROVOperator
 * @param {object} props
 * @param {boolean} props.creating Estado de creación de un nuevo permiso
 * @param {array} props.centros Lista de centros disponibles para asignar permisos
 * @param {object} props.permiso Información del permiso
 * @param {function} props.reloadData Función para recargar la información del ROVOperator
 * @param {function} props.stopCreating Función para detener la creación de un nuevo permiso
 * @param {boolean} props.loading Estado de carga de la información
 * @param {function} props.setLoading Función para cambiar el estado de carga de la información
 */
const PermisoItem = ({
	creating = false,
	centros = [],
	permiso,
	reloadData = () => {},
	stopCreating = () => {},
	loading,
	setLoading,
}) => {
	const [changed, setChanged] = useState(false);
	const [original, setOriginal] = useState({});
	const [rendered, setRendered] = useState({});
    const theme = useTheme();

	useEffect(() => {
		setOriginal({ ...permiso });
		if (rendered.cultivationCenter === undefined) {
			setRendered({ ...permiso });
		}
	}, [permiso, rendered.cultivationCenter]);
	const checkChanged = (new_data) => {
		let changed = false;

		if (creating && new_data.cultivationCenter === "") {
			setChanged(changed);
			return;
		}

		for (let permiso in permiso_types) {
			let key = permiso_types[permiso].name;
			if (original[key] !== new_data[key]) {
				changed = true;
				break;
			}
		}
		if (original.initialDate !== new_data.initialDate || original.endDate !== new_data.endDate) {
			changed = true;
		}
		setChanged(changed);
	};

	const handleChange = (e) => {
		let new_data = { ...rendered };
		if (e.target.name === "cultivationCenter") {
			new_data.cultivationCenter = e.target.value;
		} else if (e.target.name === "initialDate" || e.target.name === "endDate") {
			new_data[e.target.name] = e.target.value;
		} else {
			new_data[e.target.name] = e.target.checked;
		}
		setRendered({ ...new_data });
		checkChanged(new_data);
	};

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();
	const handleSave = () => {
		let config = {
			url: URLS.PERMISSIONS,
			method: "POST",
			data: {
				ROVOperator: rendered.ROVOperator,
				cultivationCenter: rendered.cultivationCenter.id,
				canViewHistory: rendered.canViewHistory,
				canUploadReport: rendered.canUploadReport,
				initialDate: rendered.initialDate === "" ? null : rendered.initialDate,
				endDate: rendered.endDate === "" ? null : rendered.endDate,
			},
		};

		if (!creating) {
			config.url += `${rendered.id}/`;
			config.method = "PATCH";
			config.data["id"] = rendered.id;
		}

		setLoading(true);
		axiosInstance(config)
			.then((response) => {
				stopCreating();
				reloadData();
				setChanged(false);
			})
			.catch((error) => {
				addMessage("Error al guardar el permiso", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDelete = () => {
		if (creating) {
			stopCreating();
			return;
		}

		setLoading(true);
		axiosInstance
			.delete(`${URLS.PERMISSIONS}${rendered.id}/`)
			.then((response) => {
				reloadData();
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<ListItem key={permiso.id} divider sx={{ display: "flex", justifyContent: "space-between" }}>
			{creating ? (
				<Box sx={{ minWidth: "20rem" }}>
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						options={centros?.sort((a, b) => a.code - b.code) || []}
						getOptionLabel={(option) => (option ? `${option.code} | ${option.name}` : "")}
						isOptionEqualToValue={(option, value) => {
							return option?.id === value?.id;
						}}
						fullWidth
						required
						onChange={(event, newValue) => {
							setRendered({ ...rendered, cultivationCenter: newValue });
							checkChanged({ ...rendered, cultivationCenter: newValue });
						}}
						value={rendered?.cultivationCenter || ""}
						renderInput={(params) => (
							<TextInput {...params} required InputLabelProps={{ shrink: true }} label="Centro" />
						)}
					/>
				</Box>
			) : (
				<ListItemText primary={`${permiso.cultivationCenter.code} | ${permiso.cultivationCenter.name}`} />
			)}
			<Box
				sx={{
					display: "flex",
					gap: "1rem",
					alignItems: "center",
					justifyContent: "flex-start",
				}}
			>
				{!rendered.canViewHistory && (
					<Tooltip title="Este rango de fechas permite filtrar los objetos e informes de este centro que serán mostrados al operador, tanto sus informes como los de otros usuarios.">
						<Box sx={{ display: "flex", gap: "1rem" }}>
							<TextInput
								label={"Fecha de inicio"}
								name="initialDate"
								type="date"
								value={rendered.initialDate}
								onChange={handleChange}
							/>
							<TextInput
								label={"Fecha de término"}
								name="endDate"
								value={rendered.endDate}
								type="date"
								onChange={handleChange}
							/>
						</Box>
					</Tooltip>
				)}
				{permiso_types.map((permiso_type) => (
					<Tooltip key={permiso_type.id} title={permiso_type.help}>
						<Box
							key={permiso_type.id}
							sx={{
								display: "flex",
								gap: "1rem",
								alignItems: "center",
							}}
						>
							<Typography
								component="div"
								variant="body1"
								sx={{
									display: "flex",
									gap: "1rem",
									alignItems: "center",
								}}
							>
								{permiso_type.icon} {permiso_type.description}
							</Typography>
							<Checkbox
								checked={rendered[permiso_type.name] || false}
								onChange={handleChange}
								name={permiso_type.name}
							/>
						</Box>
						<Divider orientation="vertical" flexItem />
					</Tooltip>
				))}

                <IconButtons icon={<DeleteIcon />} onClick={handleDelete} variant="danger" />

				<Fab
					size="small"
					color="primary"
					aria-label="save"
					disabled={!rendered.cultivationCenter || !changed || loading}
					onClick={handleSave}
					sx={{ marginLeft: "1rem", backgroundColor: theme.palette.brandColors.primary }}
				>
					<SaveIcon />
				</Fab>
			</Box>
		</ListItem>
	);
};

/**
 * Componente que muestra la lista de permisos de un ROVOperator
 * @param {object} props
 * @param {object} props.data Información del ROVOperator (incluye los permisos)
 * @param {function} props.reloadData Función para recargar la información del ROVOperator
 * @param {boolean} props.loading Estado de carga de la información
 * @param {function} props.setLoading Función para cambiar el estado de carga de la información
 */
const PermissionsList = ({ data, reloadData = () => {}, loading, setLoading }) => {
	const [creating, setCreating] = useState(false);
	const [newPerm, setNewPerm] = useState(null);
	const [possible_centers, setPossibleCenters] = useState([]);
	const { axiosInstance } = useAuthContext();
	const theme = useTheme();

	const fetchPosibleCenters = () => {
		setLoading(true);
		axiosInstance
			.get(URLS.CENTERS)
			.then((response) => {
				let centers = response.data;
				centers = centers.filter((center) => {
					let found = false;
					data?.permissions.forEach((permission) => {
						if (permission.cultivationCenter.id === center.id) {
							found = true;
						}
					});
					return !found;
				});
				setPossibleCenters(centers);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleNewPermiso = () => {
		fetchPosibleCenters();
		setCreating(true);
		setNewPerm({
			id: -1,
			ROVOperator: data.id,
			cultivationCenter: "",
			canViewHistory: false,
			canUploadReport: false,
			initialDate: null,
			endDate: null,
		});
	};

	const stopCreating = () => {
		setCreating(false);
		setNewPerm(null);
	};

	return (
		<List
			component="div"
			disablePadding
			sx={{
				marginY: 1,
				paddingLeft: 1,
				border: "2px solid #F5F5F5",
				borderRadius: 1,
			}}
		>
			{creating ? (
				<PermisoItem
					reloadData={reloadData}
					creating={true}
					permiso={newPerm}
					key={-1}
					centros={possible_centers}
					stopCreating={stopCreating}
					loading={loading}
					setLoading={setLoading}
				/>
			) : (
				<ListItem
					key={-1}
					sx={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					Nuevo Permiso
					<Fab
						size="small"
						color="primary"
						aria-label="new"
						sx={{ marginLeft: "1rem", backgroundColor: theme.palette.brandColors.primary }}
						onClick={() => handleNewPermiso()}
					>
						<AddIcon />
					</Fab>
				</ListItem>
			)}
			{data?.permissions
				.sort((a, b) => b.id - a.id)
				.map((permiso) => (
					<PermisoItem
						permiso={permiso}
						key={permiso.id}
						reloadData={reloadData}
						loading={loading}
						setLoading={setLoading}
					/>
				))}
		</List>
	);
};

export default PermissionsList;
