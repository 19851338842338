import { PreviewMap } from "../PreviewMap";
import { useContext } from "react";
import { AuthContext } from "../../../context";
import { Modal, Box, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const BoxStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: "700px",
	height: "700px",
	maxHeight: "90%",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "hidden",
	display: "block",
	borderRadius: "10px",
	zIndex: 1000,
};

const ImageMapModal = ({ object, setObject, reportData, mapZoom, setMapZoom, isModalOpen, onClose, otherObjects }) => {
	const { canUseMapTools } = useContext(AuthContext);
	return (
		<>
			<Modal open={isModalOpen} onClose={onClose}>
				<Box sx={BoxStyle}>
					<IconButton onClick={onClose} sx={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }}>
						<CloseIcon />
					</IconButton>
					<PreviewMap
						onMapClick={(e) => {
							if (canUseMapTools === "false") return;
							setObject({
								...object,
								latitude: e.latlng.lat.toFixed(6),
								longitude: e.latlng.lng.toFixed(6),
							});
						}}
						latitud={object?.latitude}
						longitud={object?.longitude}
						input={reportData.cultivationCenter}
						mapZoom={mapZoom}
						setMapZoom={setMapZoom}
						otherObjects={otherObjects}
					/>
				</Box>
			</Modal>
		</>
	);
};

export default ImageMapModal;
