import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, Paper, TextField, Button, Slide } from "@mui/material";

import { useAuthContext, useMessagesContext } from "../context";
import { URLS } from "../global";

export default function ResetPassword() {
	const navigate = useNavigate();

	const initialFormData = Object.freeze({
		email: "",
	});

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const { pk, token } = useParams();
	const [existPKT] = useState(pk && token ? true : false);

	const [isLoaded, setIsLoaded] = useState(false);
	const [loading, setLoading] = useState(false);

	React.useEffect(() => {
		setIsLoaded(true);
	}, []);

	const [formData, setFormData] = useState(initialFormData);
	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (existPKT) {
			if (formData.password === formData.password2) {
				setLoading(true);
				axiosInstance
					.patch(`${URLS.resetpassword}${pk}/${token}/`, {
						password: formData.password,
					})
					.then((res) => {
						addMessage("Se ha cambiado la contraseña", "success", 5000);
						setTimeout(() => {
							navigate("/login");
						}, 500);
					})
					.catch((error) => {
						addMessage("Cambio de contraseña invalido, intente nuevamente.", "error", 5000);
					})
					.finally(() => {
						setLoading(false);
					});
			} else {
				addMessage("Las contraseñas no coinciden", "error", 5000);
			}
		} else {
			setLoading(true);
			axiosInstance
				.post(URLS.resetpassword, {
					email: formData.email,
				})
				.then((res) => {
					addMessage("Se ha enviado un correo para restablecer la contraseña.", "success", 5000);
				})
				.catch((error) => {
					addMessage("Error al solicitar cambio de contraseña.", "error", 5000);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const paperStyle = { padding: 20, height: "70vh", width: 280, margin: "20px auto" };
	const btnstyle = { margin: "8px 0" };

	return (
		<Grid>
			<Slide direction="down" in={isLoaded} mountOnEnter unmountOnExit timeout={1000}>
				<Paper elevation={10} style={paperStyle}>
					<form>
						{existPKT ? (
							<>
								<Grid align="center">
									<h2>Rastrum</h2>
									<h4>Ingresa la nueva contraseña.</h4>
								</Grid>

								<TextField
									label="Contraseña"
									id="password"
									name="password"
									placeholder="Ingrese contraseña"
									fullWidth
									required
									onChange={handleChange}
									type="password"
									sx={{ mb: "10px" }}
								/>

								<TextField
									label="Contraseña"
									id="password2"
									name="password2"
									placeholder="Re-ingrese contraseña"
									fullWidth
									required
									onChange={handleChange}
									type="password"
									sx={{ mb: "10px" }}
								/>
							</>
						) : (
							<>
								<Grid align="center">
									<h2>Rastrum</h2>
									<h4>Para recuperar tu contraseña ingresa el email registrado.</h4>
								</Grid>

								<TextField
									label="Correo de usuario"
									id="email"
									name="email"
									placeholder="Ingrese correo de usuario"
									fullWidth
									required
									onChange={handleChange}
									sx={{ mb: "10px" }}
								/>
							</>
						)}

						<Button type="submit" color="primary" variant="contained" style={btnstyle} fullWidth onClick={handleSubmit} disabled={loading}>
							Enviar
						</Button>
					</form>
				</Paper>
			</Slide>
		</Grid>
	);
}
