
import { React, useState, useContext } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { MessagesContext } from '../../../context';

// Componente para arrastrar y soltar archivos excel en el formulario de carga de datos desde excel
export default function SingleDropZone({handleInputChange}) {
  
    const [file, setFile] = useState(null);
    const { addMessage } = useContext(MessagesContext);
    const maxSize = 100 * 1024 * 1024; // 20 MB
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xslx'],
           },
        onDrop: acceptedFiles => {
            if (acceptedFiles.length > 1) {
                addMessage(`Selecciona solo 1 archivo`, "error");
                return;
            }
            
            if (acceptedFiles[0].size > maxSize){
                addMessage(`El archivo excede el tamaño máximo permitido de ${maxSize/1024/1024}MB`, "error");
                return
            } else {
                setFile(acceptedFiles[0]);
                handleInputChange(acceptedFiles[0]);
            }
        }
    });
    
    
    return (
        <>
            <div {...getRootProps({className: 'dropzone'})} 
            style={{
                border: '2px solid #22ACA4', 
                borderRadius: '10px', 
                backgroundColor: '#DEF3F1', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '150px',
                width: '220px',
                position: 'relative',
            }}>
                {!(file) ? (
                    <>
                        <input  {...getInputProps()}/>  
                               
                        <CloudUploadOutlinedIcon style={{color: '#22ACA4', fontSize: '3rem'}} /> 
                        <p style={{color: '#22ACA4', textAlign: 'center'}}>Arrastra un archivo, o  haz click para seleccionar</p>
                    </>
                ) : ((typeof file === "object") ? (
                    <>
                        <div key={file.preview} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{border: '1px solid grey', maxWidth: '80%', maxHeight: '170px', position: 'relative'}}>
                            <IconButton style={{position: 'absolute', top: 0, right: 0, backgroundColor: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}
                                        onClick={(event) => {
                                            event.stopPropagation(); // prevent to open file explorer
                                            setFile(null);
                                            handleInputChange(null);
                                        }}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        <Typography variant="caption" display="block" gutterBottom>
                            {file.name.length > 25 ? `${file.name.slice(0, 25)}...` : file.name}
                        </Typography>
                        </div>
                    
                    </>
                ) : (
                    <>
                        <div key={file} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{border: '1px solid grey', maxWidth: '80%', maxHeight: '170px', position: 'relative'}}>
                            <IconButton style={{position: 'absolute', top: 0, right: 0, backgroundColor: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}}
                                        onClick={(event) => {
                                            event.stopPropagation(); // prevent to open file explorer
                                            setFile(null);
                                            handleInputChange(null);
                                        }}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        <Typography variant="caption" display="block" gutterBottom>
                            {file.name}
                        </Typography>
                        </div>
                    </>
                ))}
            </div>
        </>
    );
}