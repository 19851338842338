import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

const Input = styled("input")(({ theme }) => ({
	borderRadius: "10px",
	marginLeft: "10px",
	width: "240px",
	border: "none",
	outline: "none",
}));

const InputContainer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	border: `1px solid ${theme.palette.brandColors.lightGray}`,
	borderRadius: "10px",
	padding: "10px",
}));

const Search = ({ search, setSearch, style }) => {
	return (
		<InputContainer style={style}>
			<SearchIcon />
			<Input
				type="text"
				placeholder={"Buscar..."}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				style={style}
			/>
		</InputContainer>
	);
};

export default Search;
