import Plot from "react-plotly.js";
import { useTheme } from "@mui/material/styles";
import { GraphContainerStyle } from "../Create/StatesConstants";
import { Box } from "@mui/material";
import FileUpload from "./FileUpload";
import { useRef } from "react";
import html2canvas from "html2canvas";
import IconButton from "@mui/material/IconButton";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

export const MyPlot = (props) => {
	const plotRef = useRef(null);
	const takeScreenshot = () => {
		html2canvas(plotRef.current.el, { scale: 2 })
			.then((canvas) => {
				const imgData = canvas.toDataURL("image/png");
				const link = document.createElement("a");
				link.href = imgData;
				link.download = "plot-screenshot.png";
				link.click();
			})
			.catch((err) => {
				console.error("Error taking screenshot:", err);
			});
	};
	return (
		<div style={{ position: "relative", display: "flex", width: "100%", height: "100%" }}>
			<IconButton
				onClick={takeScreenshot}
				style={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }}
				color="primary"
			>
				<CameraAltIcon />
			</IconButton>
			<Plot {...props} ref={plotRef} />
		</div>
	);
};

const Graphs = ({ generalData, setGeneralData }) => {
	const theme = useTheme();

	const layout = {
		responsive: true,
		autosize: true,
		font: {
			family: "Roboto, sans-serif",
			size: 14,
		},
		yaxis: {
			automargin: true,
		},
		xaxis: {
			automargin: true,
		},
		padding: 30,
		margin: "auto",
		title: {
			font: {
				size: 16,
				family: "Roboto, sans-serif",
				color: theme.palette.brandColors.primary,
			},
		},
	};

	const ContainerStyle = {
		...GraphContainerStyle,
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
	};

	const twoContainers = {
		display: "flex",
	};

	const quantifiableObjects = generalData.byObject.unitaryObjects.map((item) => item.total / item.type__weight);
	return (
		<div style={{ padding: "20px 30px", display: "flex", flexDirection: "column", alignItems: "center" }}>
			A continuación tome una captura de pantalla de cada uno de los gráficos y súbalo en el campo a su derecha
			<Box sx={{ margin: "20px 0", textAlign: "center", color: "#22ACA4" }}>
				Gráfico de Objetos Cuantificables Por Cantidad
			</Box>
			<Box sx={twoContainers}>
				<Box sx={ContainerStyle}>
					<MyPlot
						config={{ responsive: true, displayModeBar: false }}
						data={[
							{
								y: generalData.byObject.unitaryObjects.map((item) => item.type__name),
								x: quantifiableObjects,
								texttemplate: `%{value}`,
								type: "bar",
								orientation: "h",
								marker: {
									color: theme.palette.brandColors.primary,
								},
								textfont: {
									color: "#FFFFFF",
								},
								hoverlabel: {
									bgcolor: "rgba(34, 172, 164, 0.23)",
									font: {
										color: "black",
									},
								},
							},
						]}
						layout={{
							...layout,
							title: {
								...layout.title,
							},
						}}
						style={{ width: "100%", height: "100%" }}
					/>
				</Box>
				<Box>
					<FileUpload
						sx={GraphContainerStyle}
						setImage={(image) => setGeneralData((prev) => ({ ...prev, byQuantityImage: image }))}
						image={generalData.byQuantityImage}
					/>
				</Box>
			</Box>
			<Box sx={{ margin: "20px 0", textAlign: "center", color: "#22ACA4" }}>
				Gráfico de Objetos Cuantificables Por Peso
			</Box>
			<Box sx={twoContainers}>
				<Box sx={ContainerStyle}>
					<MyPlot
						config={{ responsive: true, displayModeBar: false }}
						data={[
							{
								y: generalData.byObject.unitaryObjects.map((item) => item.type__name),
								x: generalData.byObject.unitaryObjects.map((item) => item.total),
								texttemplate: "%{value} kg",
								type: "bar",
								orientation: "h",
								marker: {
									color: theme.palette.brandColors.primary,
								},
								textfont: {
									color: "#FFFFFF",
								},
								hoverlabel: {
									bgcolor: "rgba(34, 172, 164, 0.23)",
									font: {
										color: "black",
									},
								},
							},
						]}
						layout={{
							...layout,
							title: {
								...layout.title,
							},
						}}
						style={{ width: "100%", height: "100%" }}
					/>
				</Box>
				<Box>
					<FileUpload
						sx={GraphContainerStyle}
						setImage={(image) => setGeneralData((prev) => ({ ...prev, byQuantityVolumeImage: image }))}
						image={generalData.byQuantityVolumeImage}
					/>
				</Box>
			</Box>
			<Box sx={{ margin: "20px 0", textAlign: "center", color: "#22ACA4" }}>
				Gráfico de Objetos Medibles por Cantidad
			</Box>
			<Box sx={twoContainers}>
				<Box sx={ContainerStyle}>
					<MyPlot
						config={{ responsive: true, displayModeBar: false }}
						data={[
							{
								y: generalData.byObject.measureObjects.map((item) => item.type__name),
								x: generalData.byObject.measureObjects.map((item) => item.total / item.type__weight),
								texttemplate: "%{value}",
								type: "bar",
								orientation: "h",
								marker: {
									color: theme.palette.brandColors.primary,
								},
								textfont: {
									color: "#FFFFFF",
								},
								hoverlabel: {
									bgcolor: "rgba(34, 172, 164, 0.23)",
									font: {
										color: "black",
									},
								},
							},
						]}
						layout={{
							...layout,
							title: {
								...layout.title,
							},
						}}
						style={{ width: "100%", height: "100%" }}
					/>
				</Box>
				<Box>
					<FileUpload
						sx={GraphContainerStyle}
						setImage={(image) => setGeneralData((prev) => ({ ...prev, byMeasurableImage: image }))}
						image={generalData.byMeasurableImage}
					/>
				</Box>
			</Box>
			<Box sx={{ margin: "20px 0", textAlign: "center", color: "#22ACA4" }}>
				Gráfico de Objetos Medibles por Peso
			</Box>
			<Box sx={twoContainers}>
				<Box sx={ContainerStyle}>
					<MyPlot
						config={{ responsive: true, displayModeBar: false }}
						data={[
							{
								y: generalData.byObject.measureObjects.map((item) => item.type__name),
								x: generalData.byObject.measureObjects.map((item) => item.total),
								texttemplate: "%{value} kg",
								type: "bar",
								orientation: "h",
								marker: {
									color: theme.palette.brandColors.primary,
								},
								textfont: {
									color: "#FFFFFF",
								},
								hoverlabel: {
									bgcolor: "rgba(34, 172, 164, 0.23)",
									font: {
										color: "black",
									},
								},
							},
						]}
						layout={{
							...layout,
							title: {
								...layout.title,
							},
						}}
						style={{ width: "100%", height: "100%" }}
					/>
				</Box>
				<Box>
					<FileUpload
						sx={GraphContainerStyle}
						setImage={(image) => setGeneralData((prev) => ({ ...prev, byMeasurableVolumeImage: image }))}
						image={generalData.byMeasurableVolumeImage}
					/>
				</Box>
			</Box>
		</div>
	);
};

export default Graphs;
