import { Box, Button, Step, useTheme, CircularProgress } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { URLS } from "../../../global";
import { AuthContext, MessagesContext } from "../../../context";

import GeneralData from "./GeneralData";
import Graphs from "./Graphs";
import ObjectsMap from "./ObjectsMap";
import ImagesMap from "./ImagesMap";
import PreviewReport from "./PreviewReport";
import WorksResume from "./WorksResume";
import Description from "./Description";
import ProspectionDescription from "./ProspectionDescription";
import Stepper from "../../Stepper/Stepper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const GENERAL_STEPS = [
	{
		id: 0,
		label: "Datos Generales",
	},
	{
		id: 1,
		label: "Resumen",
	},
	{
		id: 2,
		label: "Mapa Objetos",
	},
	{
		id: 3,
		label: "Mapa Imágenes",
	},
	{
		id: 4,
		label: "Gráficos",
	},
	{
		id: 5,
		label: "Resumen Días Trabajados",
	},
	{
		id: 6,
		label: "Vista Previa",
	},
];

const GEO_STEPS = [
	{
		id: 0,
		label: "Datos Generales",
	},
	{
		id: 1,
		label: "Resumen",
	},
	{
		id: 2,
		label: "Mapa Imágenes",
	},
	{
		id: 3,
		label: "Vista Previa",
	},
];

const BoxStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	height: "90vh",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "auto",
	borderRadius: "10px",
	display: "flex",
	flexDirection: "column",
};

const ConsolidatedReport = ({ initialData, showPreview, onClose }) => {
	const { USER_TYPE, axiosInstance } = useContext(AuthContext);
	const { addMessage } = useContext(MessagesContext);
	const theme = useTheme();

	const [generalData, setGeneralData] = useState({
		center: -1,
		centers: [],
		initialDate: "",
		finalDate: "",
		description: "",
		objectMap: null,
		isObjectMapHorizontal: false,
		addObjectsMap: [],
		deletedObjectsMap: [],
		imageMap: null,
		addImagesMap: [],
		deletedImagesMap: [],
		isImageMapHorizontal: false,
		allObjects: [],
		byObject: [],
		images: [],
		byQuantityImage: null,
		byQuantityVolumeImage: null,
		byMeasurableImage: null,
		byMeasurableVolumeImage: null,
		geoImages: [],
		type: USER_TYPE === "Operador ROV" ? "PROSPECCION" : "",
		report: [],
		additionalRows: "",
		reportTypeImage: null,
		...initialData,
	});

	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);

	const handleNext = () => {
		if (checkErrors()) return;
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const [activeStep, setActiveStep] = useState(showPreview ? 6 : 0);
	const [checkErrors, setCheckErrors] = useState(() => () => {});

	useEffect(() => {
		axiosInstance.get(URLS.CENTERS).then((response) => {
			setGeneralData((prev) => ({ ...prev, centers: response.data }));
			setLoading(false);
		});
	}, [axiosInstance]);

	const handleBack = () => {
		if (activeStep === 0) return;
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	useEffect(() => {
		if (!generalData.center || !generalData.initialDate || !generalData.finalDate || !generalData.type) return;
		setLoading(true);
		const id = generalData.center;
		axiosInstance
			.get(
				URLS.CONSOLIDATED_STATS +
					id +
					"/" +
					generalData.type +
					"/" +
					generalData.initialDate +
					"/" +
					generalData.finalDate +
					"/"
			)
			.then((response) => {
				setLoading(false);
				setGeneralData((prev) => ({
					...prev,
					allObjects: response.data.allObjects,
					byObject: response.data.byObject,
					images: response.data.images,
					report: response.data.report,
					geoImages: response.data.geoImages,
				}));
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				addMessage(error.response.data.msg, "error");
			});
	}, [generalData.center, generalData.initialDate, generalData.finalDate, generalData.type, axiosInstance]);

	const uploadImage = async (image, index) => {
		if (image.id) return Promise.resolve(image.id);
		const formData = new FormData();
		formData.append("map", image.image);
		formData.append("isHorizontal", image.isHorizontal);
		formData.append("position", index);

		let id;
		await axiosInstance
			.post(URLS.MAPS, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				id = response.data.id;
				return Promise.resolve(response.data.id);
			});

		return Promise.resolve(id);
	};

	const deleteImage = async (id) => {
		await axiosInstance.delete(URLS.MAPS + id + "/").then((response) => {
			return Promise.resolve(response.data.id);
		});
	};

	const uploadImages = async (images) => {
		if (images.length === 0) return Promise.resolve([]);
		let ids;
		await Promise.all(images.map((image, index) => uploadImage(image, index))).then((response) => {
			ids = response;
			return Promise.resolve(response);
		});
		return Promise.resolve(ids);
	};

	const deleteImages = async (images) => {
		if (images.length === 0) return Promise.resolve([]);
		await Promise.all(images.map((image) => deleteImage(image)));

		return Promise.resolve([]);
	};

	const handleImages = async () => {
		let ids = { objects: [], images: [] };
		await Promise.all([
			uploadImages(generalData.addObjectsMap),
			uploadImages(generalData.addImagesMap),
			deleteImages(generalData.deletedObjectsMap),
			deleteImages(generalData.deletedImagesMap),
		]).then((response) => {
			ids.objects = response[0];
			ids.images = response[1];
			return Promise.resolve(response);
		});

		return Promise.resolve(ids);
	};

	const handleSubmit = async () => {
		setLoading(true);
		const ids = await handleImages();
		if (checkErrors()) return;
		const formData = new FormData();
		formData.append("cultivationCenter", generalData.center);
		formData.append("startDate", generalData.initialDate);
		formData.append("endDate", generalData.finalDate);
		formData.append("comments", generalData.description);
		if (generalData.objectMap instanceof File) formData.append("objectMap", generalData.objectMap);
		if (generalData.imageMap instanceof File) formData.append("imageMap", generalData.imageMap);
		if (generalData.byQuantityImage instanceof File)
			formData.append("quantityObjectTypeImage", generalData.byQuantityImage);
		if (generalData.byQuantityVolumeImage instanceof File)
			formData.append("quantityObjectTypeVolumeImage", generalData.byQuantityVolumeImage);
		if (generalData.byMeasurableImage instanceof File)
			formData.append("measurableObjectTypeImage", generalData.byMeasurableImage);
		if (generalData.byMeasurableVolumeImage instanceof File)
			formData.append("measurableObjectTypeVolumeImage", generalData.byMeasurableVolumeImage);
		if (generalData.reportTypeImage instanceof File)
			formData.append("reportTypeImage", generalData.reportTypeImage);
		formData.append("additionalRows", generalData.additionalRows);
		formData.append("type", generalData.type);
		formData.append("isObjectMapHorizontal", generalData.isObjectMapHorizontal);
		formData.append("isImageMapHorizontal", generalData.isImageMapHorizontal);
		formData.append("addObjectsMap", ids.objects.join(","));
		formData.append("addImagesMap", ids.images.join(","));

		if (generalData.edit) {
			axiosInstance
				.put(URLS.CONSOLIDATED_REPORTS + generalData.id + "/", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					onClose();
					console.log(response);
				});
		} else {
			axiosInstance
				.post(URLS.CONSOLIDATED_REPORTS, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					onClose();
				});
		}
	};

	return (
		<Box sx={BoxStyle}>
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
				{!showPreview && (
					<Stepper activeStep={activeStep} steps={generalData.type === "RRN" ? GENERAL_STEPS : GEO_STEPS} />
				)}
				{loading ? (
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
						<CircularProgress />
					</Box>
				) : (
					<>
						{activeStep === 0 && (
							<GeneralData
								generalData={generalData}
								setGeneralData={setGeneralData}
								errors={errors}
								setErrors={setErrors}
								setCheckErrors={setCheckErrors}
							/>
						)}

						{generalData.type === "RRN" ? (
							<>
								{activeStep === 1 && (
									<Description
										generalData={generalData}
										isEditing={true}
										setGeneralData={setGeneralData}
										setCheckErrors={setCheckErrors}
									/>
								)}
								{activeStep === 2 && (
									<ObjectsMap
										generalData={generalData}
										setGeneralData={setGeneralData}
										setCheckErrors={setCheckErrors}
									/>
								)}
								{activeStep === 3 && (
									<ImagesMap
										generalData={generalData}
										setGeneralData={setGeneralData}
										setCheckErrors={setCheckErrors}
									/>
								)}
								{activeStep === 4 && (
									<Graphs
										generalData={generalData}
										setGeneralData={setGeneralData}
										setCheckErrors={setCheckErrors}
									/>
								)}
								{activeStep === 5 && (
									<WorksResume generalData={generalData} setGeneralData={setGeneralData} />
								)}
								{activeStep === 6 && <PreviewReport generalData={generalData} />}
							</>
						) : (
							<>
								{activeStep === 1 && (
									<ProspectionDescription
										generalData={generalData}
										isEditing={true}
										setGeneralData={setGeneralData}
										setCheckErrors={setCheckErrors}
									/>
								)}
								{activeStep === 2 && (
									<ImagesMap
										generalData={generalData}
										setGeneralData={setGeneralData}
										setCheckErrors={setCheckErrors}
										geoImages={true}
									/>
								)}
								{activeStep === 3 && <PreviewReport generalData={generalData} />}
							</>
						)}
					</>
				)}
				<Box sx={{ display: "flex", justifyContent: "center", gap: "20px", margin: "20px 0" }}>
					{!showPreview && (
						<>
							<Button
								onClick={handleBack}
								startIcon={<ArrowBackIcon sx={{ color: theme.palette.brandColors.primary }} />}
								sx={{
									color: theme.palette.brandColors.primary,
									borderRadius: "10px",
									border: `1px solid ${theme.palette.brandColors.primary}`,
									"&:hover": {
										color: theme.palette.brandColors.primary,
										backgroundColor: "white",
										border: `1px solid ${theme.palette.brandColors.primary}`,
									},
									"&:disabled": {
										color: theme.palette.brandColors.primary,
										border: `1px solid ${theme.palette.brandColors.primary}`,
										opacity: 0.5,
									},
								}}
								disabled={loading || activeStep === 0}
							>
								Anterior
							</Button>
							{(generalData.type === "RRN" && activeStep !== 6) ||
							(generalData.type === "PROSPECCION" && activeStep !== 3) ||
							generalData.type === -1 ? (
								<Button
									onClick={handleNext}
									sx={{
										backgroundColor: theme.palette.brandColors.primary,
										borderRadius: "10px",
										"&:hover": {
											backgroundColor: theme.palette.brandColors.primary,
										},
									}}
									disabled={loading}
									variant="contained"
									endIcon={<ArrowForwardIcon />}
								>
									Siguiente
								</Button>
							) : (
								<Button
									onClick={handleSubmit}
									sx={{
										backgroundColor: theme.palette.brandColors.primary,
										borderRadius: "10px",
										"&:hover": {
											backgroundColor: theme.palette.brandColors.primary,
										},
									}}
									variant="contained"
									disabled={loading}
								>
									Guardar
								</Button>
							)}
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default ConsolidatedReport;
