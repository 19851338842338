import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/** 
 * Componente auxiliar para mostrar un dato de estadística de resumen
 * @param {string} data: dato a mostrar
 * @param {string} subdata: dato secundario a mostrar
 * @param {string} title: título del dato
 */
const SimpleInfo = ({data, subdata, title}) => {
    const theme = useTheme();

    return (
        <Box sx={{boxShadow: "0 0 10px 5px #e8e8e8", padding: "10px", width: "100%"}}>
            <Typography sx={{fontSize: "18px", color: "black"}}>
                {data ? data + " kgs": "No hay datos"}
            </Typography>
            <Typography sx={{fontSize: "14px", color: theme.palette.brandColors.text}}>
                {subdata}
            </Typography>
            <Typography sx={{fontSize: "18px", color: theme.palette.brandColors.primary}}>
                {title}
            </Typography>
        </Box>

    )
}

export default SimpleInfo;