import { useState, useEffect } from "react";
import { findCentralPoint } from "../../utils/map_helper";

import { FormControl, TextField, MenuItem, InputAdornment, Tooltip } from "@mui/material";

import HelpOutlineTwoToneIcon from "@mui/icons-material/HelpOutlineTwoTone";

import { default as LatLonInput, COORD_TYPE as LAT_LON, HelpTooltipText as LatLonHelp } from "./LatLonInput";
import { default as UtmInput, COORD_TYPE as UTM, HelpTooltipText as UtmHelp } from "./UtmInput";
import { toLatLon, fromLatLon } from "../../utils/utm";
import TextInput from "../Inputs/TextInput";

const COORD_TYPES = {
	LAT_LON: LAT_LON,
	UTM: UTM,
};

/**
 * Componente para ingresar coordenadas geográficas. Sus componentes hijos manejan el ingreso de formatos especificos, mientras
 * que este componente maneja los cambios de formato y la conversión de coordenadas. Por detras, este componente maneja los valores
 * solamente como lat-lon.
 * @param {Function} setter Función para actualizar el valor de las coordenadas en el componente padre. Se llama cada vez que uno de los hijos actualiza las coordenadas.
 * @param {Object} coordinates Objeto con los valores de las coordenadas en formato lat-lon.
 * @param {Object} center Objeto con los valores de las coordenadas de un centro de cultivo.
 * @param {Object} error Objeto con los valores de los errores de los inputs.
 * @param {Object} helperText Objeto con los valores de los mensajes de error de los inputs.
 */
const CoordinatesInput = ({
	setter = () => {},
	coordinates,
	center = { coordinates: undefined },
	error = { latitude: false, longitude: false },
	helperText = { latitude: "", longitude: "" },
	column,
	children,
}) => {
	const [inputType, setInputType] = useState(LAT_LON.key);
	const [placeHolders, setPlaceHolders] = useState({
		latitude: "Pej: -34.005",
		longitude: "Pej: -72.856",
		easting: "Pej: 177155",
		northing: "Pej: 569335",
		zoneNum: "Pej: 18",
		zoneLetter: "Pej: G",
	});

	const [inputValues, setInputValues] = useState({
		latitude: coordinates?.latitude || "",
		longitude: coordinates?.longitude || "",
		easting: undefined,
		northing: undefined,
		zoneNum: undefined,
		zoneLetter: undefined,
		hemisphere: undefined,
	});

	/**
	 * función que se ejecuta cuando se actualiza el centro, actualiza los placeholders de los inputs para que muestren en su punto central.
	 */
	useEffect(() => {
		if (center?.coordinates !== undefined) {
			let centralPoint = findCentralPoint(center.coordinates);
			let latitude = centralPoint[0];
			let longitude = centralPoint[1];
			let { easting, northing, zoneNum, zoneLetter } = fromLatLon(latitude, longitude);
			setPlaceHolders({
				latitude: latitude.toFixed(6),
				longitude: longitude.toFixed(6),
				easting: easting.toFixed(0),
				northing: northing.toFixed(0),
				zoneNum: zoneNum.toFixed(0),
				zoneLetter: zoneLetter,
			});
		}
	}, [center]);
	/**
	 * Función para cambiar el tipo de coordenada de entrada. Actualiza los valores de la nueva entrada a partir de los valores de la entrada anterior.
	 * @param {Event} e Evento de cambio de valor del input.
	 */
	const changeInputType = (e) => {
		if (
			inputType === COORD_TYPES.LAT_LON.key &&
			// todos los valores de entrada deben ser validos y no vacios/undefined
			inputValues.latitude !== "" &&
			!isNaN(parseFloat(inputValues.latitude)) &&
			inputValues.longitude !== "" &&
			!isNaN(parseFloat(inputValues.longitude))
		) {
			// actualizar los valores de entrada desde lat-lon
			let easting, northing, zoneNum, zoneLetter, hemisphere;
			try {
				({ easting, northing, zoneNum, zoneLetter } = fromLatLon(
					parseFloat(inputValues.latitude).toFixed(6),
					parseFloat(inputValues.longitude).toFixed(6)
				));
				hemisphere = inputValues.latitude >= 0 ? "N" : "S";
			} catch (err) {}

			setInputValues({
				...inputValues,
				easting: isNaN(easting) ? undefined : Number(easting).toFixed(0),
				northing: isNaN(northing) ? undefined : Number(northing).toFixed(0),
				zoneNum: isNaN(zoneNum) ? undefined : Number(zoneNum),
				zoneLetter: zoneLetter !== "" ? zoneLetter : undefined,
				hemisphere: hemisphere !== "" ? hemisphere : undefined,
			});
		} else if (
			inputType === COORD_TYPES.UTM.key &&
			// todos los valores de entrada deben ser validos y no vacios/undefined
			inputValues.easting !== "" &&
			!isNaN(inputValues.easting) &&
			inputValues.northing !== "" &&
			!isNaN(inputValues.northing) &&
			inputValues.zoneNum !== "" &&
			!isNaN(inputValues.zoneNum) &&
			inputValues.zoneLetter !== "" &&
			inputValues.zoneLetter !== undefined
		) {
			// actualizar los valores de entrada desde UTM
			let latitude, longitude;
			try {
				({ latitude, longitude } = toLatLon(
					Number(inputValues.easting),
					Number(inputValues.northing),
					Number(inputValues.zoneNum),
					inputValues.zoneLetter
				));
			} catch (err) {}
			setInputValues({
				...inputValues,
				latitude: isNaN(latitude) ? undefined : parseFloat(latitude).toFixed(6),
				longitude: isNaN(longitude) ? undefined : parseFloat(longitude).toFixed(6),
			});
			setter({ latitude: parseFloat(latitude).toFixed(6), longitude: parseFloat(longitude).toFixed(6) });
		}

		setInputType(e.target.value);
	};

	useEffect(() => {
		if (coordinates) {
			if (
				coordinates.latitude !== undefined &&
				coordinates.longitude !== undefined &&
				inputType === COORD_TYPES.LAT_LON.key
			) {
				setInputValues({
					latitude: coordinates.latitude,
					longitude: coordinates.longitude,
					easting: undefined,
					northing: undefined,
					zoneNum: undefined,
					zoneLetter: undefined,
					hemisphere: undefined,
				});
			} else if (coordinates.latitude && coordinates.longitude && inputType === COORD_TYPES.UTM.key) {
				let easting, northing, zoneNum, zoneLetter, hemisphere;
				try {
					({ easting, northing, zoneNum, zoneLetter } = fromLatLon(
						parseFloat(coordinates.latitude),
						parseFloat(coordinates.longitude)
					));
					hemisphere = coordinates.latitude >= 0 ? "N" : "S";
				} catch (err) {
				}

				setInputValues((prev) => ({
					...prev,
					easting: isNaN(easting) ? undefined : Number(easting).toFixed(0),
					northing: isNaN(northing) ? undefined : Number(northing).toFixed(0),
					zoneNum: isNaN(zoneNum) ? undefined : Number(zoneNum),
					zoneLetter: zoneLetter !== "" ? zoneLetter : undefined,
					hemisphere: hemisphere !== "" ? hemisphere : undefined,
				}));
			}
		}
	}, [coordinates, inputType]);

	useEffect(() => {
		setter({ latitude: inputValues.latitude, longitude: inputValues.longitude });
	}, [inputValues.latitude, inputValues.longitude]);

	return (
		<div style={{ display: "flex", gap: "20px", flexDirection: column ? "column" : "row" }}>
			<div style={{ display: "flex", gap: "20px" }}>
				<TextInput
					select
					value={inputType || ""}
					onChange={changeInputType}
					required
					label="Tipos de Coordenadas"
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Tooltip
									placement="right"
									title={
										<>
											{inputType === COORD_TYPES.LAT_LON.key && <LatLonHelp />}
											{inputType === COORD_TYPES.UTM.key && <UtmHelp />}
										</>
									}
								>
									<HelpOutlineTwoToneIcon fontSize="small" color="gray" />
								</Tooltip>
							</InputAdornment>
						),
					}}
				>
					<MenuItem key={0} value={""} disabled>
						Seleccione un tipo de coordenada
					</MenuItem>
					{Object.keys(COORD_TYPES).map((key) => {
						return (
							<MenuItem key={key} value={key}>
								{COORD_TYPES[key].nombre}
							</MenuItem>
						);
					})}
				</TextInput>
				{children}
			</div>

			{inputType === COORD_TYPES.LAT_LON.key && (
				<LatLonInput
					error={error}
					required={true}
					helperText={helperText}
					placeholder={placeHolders}
					inputValues={inputValues}
					setInputValues={setInputValues}
				/>
			)}

			{inputType === COORD_TYPES.UTM.key && (
				<UtmInput
					error={error}
					required={true}
					helperText={helperText}
					placeholder={placeHolders}
					inputValues={inputValues}
					setInputValues={setInputValues}
				/>
			)}
		</div>
	);
};

export default CoordinatesInput;
