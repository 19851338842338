import { Stack, Typography, Box, FormControl, DialogContent, Button } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context";
import { URLS } from "../../global";
import { DataGrid } from "@mui/x-data-grid";
import GeneralButton from "../GeneralButton";
import AddIcon from "@mui/icons-material/Add";

const TakeoverForm = ({ boatLog, takeover, onClose }) => {
	const { axiosInstance } = useAuthContext();
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState({
		ROVOperator: takeover?.ROVOperator || "",
		initialDate: takeover?.initialDate || "",
		endDate: takeover?.endDate || "",
	});
	const [error, setError] = useState({
		ROVOperator: false,
		initialDate: false,
		endDate: false,
	});
	const [ROVOperators, setROVOperators] = useState([]);

	useEffect(() => {
		axiosInstance
			.get(URLS.OPERATORS)
			.then((response) => {
				setROVOperators(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [axiosInstance]);

	const handleSave = () => {
		const errors = {};
		setLoading(true);
		if (!data.ROVOperator) {
			errors.ROVOperator = true;
		}

		if (!data.initialDate) {
			errors.initialDate = true;
		}
		if (!data.endDate) {
			errors.endDate = true;
		}

		if (data.initialDate > data.endDate) {
			errors.initialDate = true;
			errors.endDate = true;
		}
		setError(errors);

		if (data.initialDate && data.endDate && data.ROVOperator) {
			const formData = {
				initialDate: data.initialDate,
				endDate: data.endDate,
				ROVOperator: data.ROVOperator.id,
				boatLog: boatLog,
			};
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
				method: takeover ? "PUT" : "POST",
				data: formData,
				url: takeover ? URLS.TAKEOVERS + takeover.id + "/" : URLS.TAKEOVERS,
			};

			setLoading(true);
			axiosInstance(config)
				.then((response) => {
					console.log(response);
					setLoading(false);
					onClose(true);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<DialogContent sx={{ marginTop: "20px", height: "50vh" }}>
				<Stack direction="row" spacing={2}>
					<FormControl sx={{ width: "100%" }}>
						<Typography>Operador</Typography>
						<Autocomplete
							disablePortal
							required
							id="ROVOperator"
							ListboxProps={{ style: { maxHeight: 250 } }}
							options={ROVOperators}
							onChange={(e, value) => setData({ ...data, ROVOperator: value })}
							value={data.ROVOperator || ""}
							renderInput={(params) => (
								<TextField {...params} error={error.ROVOperator} helperText={error.ROVOperator ? "Campo Requerido" : ""} />
							)}
							isOptionEqualToValue={(option, value) => option?.user?.username === value?.user?.username}
							getOptionLabel={(option) => {
								return option?.user?.username || "";
							}}
						/>
					</FormControl>

					<FormControl sx={{ width: "100%" }}>
						<Typography>Fecha Inicio</Typography>
						<TextField
							required
							id="initialDate"
							type="date"
							error={error.initialDate}
							helperText={error.initialDate ? "Campo Requerido" : ""}
							onChange={(e) =>
								setData({
									...data,
									initialDate: e.target.value,
								})
							}
							value={data.initialDate}
						/>
					</FormControl>

					<FormControl sx={{ width: "100%" }}>
						<Typography>Fecha Término</Typography>
						<TextField
							required
							id="endDate"
							type="date"
							helperText={error.endDate ? "Campo Requerido" : ""}
							error={error.endDate}
							onChange={(e) => setData({ ...data, endDate: e.target.value })}
							value={data.endDate}
						/>
					</FormControl>
				</Stack>
			</DialogContent>

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "20px",
				}}
			>
				<Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="end" flexGrow={1}>
					<Button variant="contained" disabled={loading} onClick={handleSave}>
						Guardar
					</Button>
				</Stack>
			</Box>
		</>
	);
};

const TakeoverList = ({ takeovers, setTakeover, setOpen }) => {
	return (
		<DialogContent sx={{ marginTop: "20px", height: "70vh" }}>
			<GeneralButton
				text="Añadir Relevo"
				icon={<AddIcon />}
				onClick={() => {
					setTakeover(null);
					setOpen(true);
				}}
			/>
			<DataGrid
				sx={{ height: "80%" }}
				columns={[
					{ field: "id", headerName: "ID", width: 70, align: "center" },
					{ field: "initialDate", headerName: "Fecha Inicio", width: 150, align: "center" },
					{ field: "endDate", headerName: "Fecha Término", width: 150, align: "center" },
					{ field: "ROVOperator", headerName: "Operador", width: 150, align: "center", renderCell: (params) => params.row.ROVOperator.user.username },
				]}
				rows={takeovers ? takeovers : []}
				onRowClick={(row) => setTakeover(row.row)}
			/>
		</DialogContent>
	);
};

const Takeovers = ({ boatLog, setReload }) => {
	const [takeover, setTakeover] = useState(null);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (takeover) {
			setOpen(true);
		}
	}, [takeover]);

	return (
		<>
			{open ? (
				<TakeoverForm
					boatLog={boatLog.id}
					takeover={takeover}
					onClose={(reload) => {
						setTakeover(null);
						setOpen(false);
						setReload(reload);
					}}
				/>
			) : (
				<TakeoverList takeovers={boatLog?.takeovers} setTakeover={setTakeover} setOpen={setOpen} />
			)}
		</>
	);
};

export default Takeovers;
