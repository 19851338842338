import { generateImagePoints, generateFeedingBacteriumPoints } from "../../../utils/map_helper";
import { useEffect, useContext } from "react";
import { Map } from "../../Map";
import { MapContainerStyle } from "./StatesConstants";
import { Box } from "@mui/material";
import { ReportErrorContext } from "../../../context";
import { ReportDataContext } from "../../../context";
import { generateStructurePointsFromCentros } from "../../../utils/map_helper";

const ObjectsMap = ({ isFeedingImages }) => {
	const { reportData, setReportData, imageDatabaseIds } = useContext(ReportDataContext);
	const images = !isFeedingImages ? generateImagePoints(imageDatabaseIds) : []
	const feedingBacteriumImages = isFeedingImages ? generateFeedingBacteriumPoints(imageDatabaseIds) : []
	const { setCheckErrors } = useContext(ReportErrorContext);
	useEffect(() => {
		// Al cargar el componente se asume que ya se creó/editó el resto del informe, por lo que ahora solo se editarán las imágenes (si vuelve hacia atrás)
		// y el mapa
		setReportData((prev) => ({ ...prev, isEdit: true, isMap: true }));
	}, [setReportData]);

	useEffect(() => {
		setCheckErrors(() => {
			return () => reportData.map === null;
		});
	}, [reportData.map, setCheckErrors]);

	const ContainerStyle = {
		...MapContainerStyle,
		border: "1px solid black",
		display: "flex",
		justifyContent: "center",
	};

	const centro = generateStructurePointsFromCentros([reportData.cultivationCenter]);

	const data = {
		structures: centro,
		images: images,
		feedingBacteriumImages: feedingBacteriumImages,
	};

	return (
		<Box>
			<Box sx={{ width: "100%", justifyContent: "center", display: "flex", padding: "10px 25px" }}>
				<Box sx={ContainerStyle}>
					<Map
						data={data}
						initial_state={{
							initial_is_clustered: false,
							initial_cluster_radius: 0,
							initial_zoom: 14,
						}}
						title={reportData.cultivationCenter.name}
						setMapImage={(image) => {
							setReportData((prev) => {
								prev.map && URL.revokeObjectURL(prev.map);
								return { ...prev, map: new File([image], "mapa.png") };
							});
						}}
					/>
				</Box>
			</Box>
			{reportData.map && (
				<Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
					<img
						alt=""
						style={ContainerStyle}
						src={typeof reportData.map === "object" ? URL.createObjectURL(reportData.map) : reportData.map}
					/>
				</Box>
			)}
		</Box>
	);
};

export default ObjectsMap;
