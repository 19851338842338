import { Box, Typography} from '@mui/material';
import UploadImages from './UploadImages';
import { DataGrid } from '@mui/x-data-grid';

const Objects = ({ objects, objectsErrorsList, objectImages, setObjectImages, imagesErrorsList }) => {

    const columnStyle = {
        flex: 1,
        align: 'center',
    }

    const objectsColumns = [
        {
            field: 'excelId',
            headerName: 'Número de Objeto',
            ...columnStyle,
        }, {
            field: 'type',
            headerName: 'Tipo de Objeto',
            ...columnStyle,
            renderCell: (params) => (params.row.type.name)
        }, {
            field: 'amount',
            ...columnStyle,
            headerName: 'Cantidad',
        }, {
            field: 'volume',
            ...columnStyle,
            headerName: 'Volumen',
        }, {
            field: 'beacon',
            ...columnStyle,
            headerName: 'Balizado',
            renderCell: (params) => (params.row.beacon ? "Sí" : "No")
        }, {
            field: 'notify',
            ...columnStyle,
            headerName: 'Notificar',
            renderCell: (params) => (params.row.notify ? "Sí" : "No")
        }, {
            field: 'latitude',
            ...columnStyle,
            headerName: 'Latitud',
        }, {
            field: 'longitude',
            ...columnStyle,
            headerName: 'Longitud',
        }
    ]

    return (
        <>
            <Box sx={{ marginBottom: 3 }}>
                <Typography>Objetos Cargados Correctamente: {objects.length}</Typography>
                <DataGrid
                    rows={objects}
                    columns={objectsColumns}
                    getRowId={(row) => row.excelId}
                />
            </Box>

            {
                objectsErrorsList.length > 0 &&
                <Box sx={{ marginBottom: 3 }}>
                    <Typography>Objetos con errores: {objectsErrorsList.length}</Typography>
                    <DataGrid
                        rows={objectsErrorsList}
                        columns={[
                            {
                                'field': 'excelId',
                                'headerName': 'Número de Objeto',
                                'flex': 1,
                                'align': 'center',
                            }, {
                                'field': 'reason',
                                'headerName': 'Motivo',
                                'flex': 1,
                                'align': 'center',
                            }
                        ]
                        }
                        getRowId={(row) => row.excelId}
                    />
                </Box>
            }


            <Box sx={{ marginBottom: 3 }}>
                Imágenes de los Objetos
                <UploadImages
                    imageHasDescription={false}
                    images={objectImages}
                    setImages={setObjectImages}
                />
            </Box>

            {
                imagesErrorsList.length > 0 &&
                <Box sx={{ marginBottom: 3 }}>
                    <Typography>Imágenes con errores: {imagesErrorsList.length}</Typography>
                    <DataGrid
                        rows={imagesErrorsList}
                        columns={[
                            {
                                'field': 'name',
                                'headerName': 'Nombre',
                                'align': 'center',
                            }, {
                                'field': 'reason',
                                'headerName': 'Motivo',
                                'flex': 1,
                                'align': 'center',
                            }
                        ]
                        }
                        getRowId={(row) => row.name}
                    />
                </Box>
            }
        </>
    )
};

export default Objects;