import { useEffect, useState, useCallback } from "react";
// mui components
import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
// components
import { WreckRescueCompanyDialogForm } from "./";
import EditIcon from "../../Icons/EditIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import IconButtons from "../../Icons/IconButtons";

/**
 * Componente para mostrar el detalle de una empresa
 * @param {Object} props
 * @param {Function} props.onClose Función para cerrar el componente
 * @param {Object} props.data Datos de la empresa
 */
const WreckRescueCompanyDetail = ({ onClose, data = { id: undefined, name: "" } }) => {
	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();
	const theme = useTheme();

	const [objectDetails, setObjectDetails] = useState({
		id: data.id,
		name: data.name,
		admins: [],
	});

	const [loading, setLoading] = useState(false);

	const requestData = useCallback(() => {
		axiosInstance
			.get(`${URLS.WRECK_RESCUE_COMPANY}${data.id}/`)
			.then((response) => {
				setObjectDetails(response.data);
			})
			.catch((error) => {
				addMessage("Error al cargar la empresa", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [axiosInstance, addMessage, data?.id]);

	useEffect(() => {
		if (data.id) {
			requestData();
		}
	}, [data?.id, requestData]);

	const [dialogData, setDialogData] = useState({
		open: false,
		id: undefined,
		data: {},
	});

	const editEmpresa = () => {
		setDialogData({
			open: true,
			data: {
				id: objectDetails.id,
				name: objectDetails.name || "",
				logo: objectDetails.logo || null,
			},
		});
	};

	const deleteEmpresa = () => {
		if (
			!window.confirm(
				`¿Está seguro que desea eliminar la empresa ${data.name}? \n\n` +
					`Esta acción no se puede deshacer y eliminará toda la información relacionada a esta empresa.`
			)
		)
			return;

		axiosInstance
			.delete(`${URLS.WRECK_RESCUE_COMPANY}${data.id}/`)
			.then((response) => {
				addMessage("Empresa eliminada exitosamente", "success");
				onClose();
			})
			.catch((error) => {
				addMessage("Error al eliminar la empresa", "error");
			});
	};

	const closeDialog = () => {
		setDialogData({
			open: false,
			data: {},
		});

		requestData();
	};

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 1,
						bgcolor: "rgba(255, 255, 255, 0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<WreckRescueCompanyDialogForm open={dialogData.open} onClose={closeDialog} data={dialogData.data} />

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					padding: "0 34px",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div
						style={{
							display: "inline-block",
							marginLeft: 10,
							color: theme.palette.brandColors.primary,
							fontSize: "16px",
							fontWeight: 700,
						}}
					>
						{data.name}
					</div>
				</div>
				<div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
					<IconButtons icon={<EditIcon />} onClick={editEmpresa} />
					<IconButtons icon={<DeleteIcon />} onClick={deleteEmpresa} variant="danger" />
					<div
						style={{
							cursor: "pointer",
							textDecoration: "underline",
							fontWeight: 700,
							color: theme.palette.brandColors.primary,
							fontSize: "13px",
						}}
						onClick={onClose}
					>
						Volver al listado
					</div>
				</div>
			</Box>
		</Box>
	);
};

export default WreckRescueCompanyDetail;
