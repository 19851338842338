

const EyeIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13" fill="none">
			<g clipPath="url(#clip0_63_67)">
				<path
					d="M19.9998 6.70065C19.5859 7.48732 19.0623 8.19283 18.4881 8.85884C17.4719 10.0372 16.3011 11.0132 14.9455 11.7463C13.8139 12.3577 12.6175 12.7521 11.3507 12.9117C9.4157 13.1552 7.54755 12.8977 5.75404 12.0959C4.58951 11.5754 3.544 10.8601 2.59766 9.98961C1.66697 9.13315 0.872527 8.15658 0.191872 7.07559C-0.0571104 6.68009 -0.0685938 6.3387 0.182476 5.93833C1.46184 3.89701 3.10606 2.27119 5.20649 1.16586C6.40807 0.533389 7.68587 0.157369 9.03309 0.0421285C12.131 -0.222438 14.8322 0.759542 17.1889 2.82413C18.1958 3.70602 19.0534 4.72479 19.7649 5.87557C19.8489 6.01191 19.922 6.15529 19.9998 6.29596V6.70119V6.70065ZM10.0008 1.32817C9.86459 1.33737 9.72836 1.35198 9.59212 1.35576C8.79976 1.37578 8.02567 1.51429 7.27194 1.76208C4.81448 2.57147 2.9526 4.19295 1.47906 6.34195C1.38719 6.47558 1.42321 6.57026 1.50203 6.68496C2.05532 7.49056 2.67491 8.23449 3.38845 8.89239C5.41267 10.7595 7.7412 11.7788 10.4899 11.6387C11.5474 11.5851 12.5653 11.3546 13.5398 10.931C15.599 10.0361 17.201 8.56344 18.4887 6.69632C18.5967 6.53996 18.5774 6.43176 18.4761 6.28514C17.8404 5.36375 17.1153 4.52839 16.275 3.80341C14.4575 2.23549 12.3889 1.34765 10.0014 1.32709L10.0008 1.32817Z"
					fill="white"
				/>
				<path
					d="M13.7614 6.51462C13.7593 8.68201 12.079 10.4047 9.9713 10.4009C7.8928 10.3971 6.22979 8.65442 6.23187 6.48216C6.23396 4.31585 7.91472 2.59265 10.0219 2.59644C12.1004 2.60023 13.764 4.3429 13.7614 6.51462ZM7.51071 6.4946C7.54099 7.91157 8.57972 9.05749 9.98331 9.06669C11.3634 9.07588 12.47 7.96514 12.4742 6.50218C12.4784 5.08088 11.4161 3.94146 10.0099 3.93009C8.64549 3.91873 7.54934 5.02299 7.51071 6.4946Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_63_67">
					<rect width="20" height="13" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default EyeIcon;