// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// mui colors
import grey from "@mui/material/colors/grey";

// context
import { useAuthContext, useMessagesContext } from "../../../context";

// global
import { URLS } from "../../../global";
import TextInput from "../../Inputs/TextInput";
import GeneralButton from "../../GeneralButton";
import { useEffect, useState } from "react";
import RadioGroup from "../../Inputs/RadioGroup";

/**
 * Formulario para crear o editar una empresa productora
 * @param {object} props
 * @param {function} props.onFinished Funcion a ejecutar al terminar de editar o crear
 * @param {object} props.data Datos de la empresa productora a editar
 */
const SalmonCompanyForm = ({ onFinished = () => {}, data = undefined }) => {
	const [currentData, setCurrentData] = useState({});
	const [errors] = useState({});
	const [loading, setLoading] = useState(true);
	const theme = useTheme();

	const { axiosInstance, USER_TYPE } = useAuthContext();
	const { addMessage } = useMessagesContext();

	useEffect(() => {
		if (data) {
			setCurrentData(data);
		} else {
			setCurrentData({
				name: "",
				address_street: "",
				address_commune: "",
				address_region: "",
				address: "",
				logo: "",
				logo_file: null,
				diaryReportFileType: "HTML",
				consolidatedReportFileType: "HTML",
			});
		}

		setLoading(false);
	}, [data]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Obtener company id del usuario
		if (USER_TYPE?.toUpperCase() !== "ADMIN") {
			addMessage("Solo puedes modificar la empresa productora desde una cuenta de Admin", "error");
			return;
		}
		let wrc_id;

		setLoading(true);
		await axiosInstance
			.get(URLS.WRECK_RESCUE_COMPANY)
			.then((res) => {
				wrc_id = res.data[0].id;
			})
			.catch((err) => {
				addMessage("Error al obtener la empresa del usuario activo", "error");
				return;
			});

		// Creacion de objeto por defecto
		// crear configuracion de request
		const formData = new FormData();
		formData.append("name", currentData.name);
		formData.append("address", currentData.address);
		formData.append("wreckRescueCompany", wrc_id);
		formData.append("diaryReportFileType", currentData.diaryReportFileType);
		formData.append("consolidatedReportFileType", currentData.consolidatedReportFileType);
		// agregar logo si existe o si fue limpiado
		if (currentData.logo_file) {
			formData.append("logo", currentData.logo_file);
		} else if (currentData.logo === "") {
			formData.append("logo", "");
		}

		let config = {
			method: "POST",
			url: URLS.SALMON_COMPANY,
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};
		let message = "Empresa productora creada exitosamente";
		let error_message = "Error al crear la empresa productora";

		if (data?.id) {
			// Editar
			config.method = "PATCH";
			config.url += `${data.id}/`;
			config.data.append("id", data.id);
			message = "Empresa productora editada exitosamente";
			error_message = "Error al editar la empresa productora";
		}

		// enviar request
		setLoading(true);
		axiosInstance(config)
			.then((_res) => {
				addMessage(message, "success");
				onFinished();
			})
			.catch((_err) => {
				addMessage(error_message, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onChange = (e) => {
		let { name, value } = e.target;
		let new_data = { ...currentData, [name]: value };
		if (name === "address_commune" || name === "address_region" || name === "address_street") {
			new_data = {
				...new_data,
				address: [new_data.address_street, new_data.address_commune, new_data.address_region]
					.filter(Boolean)
					.join(", "),
			};
		}
		setCurrentData(new_data);
	};

	return (
		<div style={{ padding: "20px 30px", width: "100%" }}>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<form
				onSubmit={handleSubmit}
				style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}
			>
				<Stack direction="row" spacing={2}>
					<TextInput
						label="Nombre titular"
						name="name"
						value={currentData.name || ""}
						onChange={onChange}
						error={errors.name}
						required
					/>
				</Stack>

				<Stack direction="row" spacing={2}>
					<TextInput
						label="Calle y número"
						name="address_street"
						value={currentData.address_street || ""}
						onChange={onChange}
						error={errors.address_street}
					/>

					<TextInput
						label="Comuna"
						name="address_commune"
						value={currentData.address_commune || ""}
						onChange={onChange}
						error={errors.address_commune}
					/>

					<TextInput
						label="Región"
						name="address_region"
						value={currentData.address_region || ""}
						onChange={onChange}
						error={errors.address_region}
					/>
				</Stack>

				<Stack direction="row" spacing={2}>
					<Button
						sx={{
							backgroundColor: "white",
							color: theme.palette.brandColors.primary,
							borderRadius: "10px",
							border: `1px solid ${theme.palette.brandColors.primary}`,
							padding: "8px 20px",
							"&:hover": {
								backgroundColor: "white",
								color: theme.palette.brandColors.primary,
							},
						}}
					>
						{currentData.logo ? "Cambiar logo" : "Subir logo"}

						<input
							type="file"
							name="logo"
							accept="image/*"
							hidden
							onChange={(e) => {
								let file = e.target.files[0];
								setCurrentData({
									...currentData,
									logo: URL.createObjectURL(file),
									logo_file: file,
								});
							}}
						/>
					</Button>

					{currentData.logo && (
						<>
							<Button
								variant="outlined"
								color="error"
								onClick={() => {
									setCurrentData({ ...currentData, logo: "", logo_file: null });
								}}
							>
								<CloseIcon />
							</Button>

							<Box
								sx={{
									height: 50,
									width: 50,
									overflow: "hidden",
									borderStyle: "solid",
									borderWidth: 1,
									borderColor: grey[500],
									borderRadius: 1,
								}}
							>
								<img
									src={currentData.logo || ""}
									alt="logo"
									style={{ height: "100%", width: "100%" }}
								/>
							</Box>
						</>
					)}
				</Stack>

				<Stack direction="row" spacing={2}>
					<RadioGroup
						label="Tipo de informe diario"
						options={[
							{ name: "HTML", label: "HTML" },
							{ name: "PDF", label: "PDF" },
						]}
						value={currentData.diaryReportFileType}
						onChange={(value) => {
							setCurrentData({ ...currentData, diaryReportFileType: value });
						}}
					/>

					<RadioGroup
						label="Tipo de informe consolidado"
						options={[
							{ name: "HTML", label: "HTML" },
							{ name: "PDF", label: "PDF" },
						]}
						value={currentData.consolidatedReportFileType}
						onChange={(value) => {
							setCurrentData({ ...currentData, consolidatedReportFileType: value });
						}}
					/>
				</Stack>

				<Stack direction="row" spacing={2} justifyContent="center">
					<GeneralButton text="Cancelar" variant="empty" onClick={onFinished} disabled={loading} />
					<GeneralButton text="Guardar" type="submit" disabled={loading} />
				</Stack>
			</form>
		</div>
	);
};

export default SalmonCompanyForm;
