import { useContext, useEffect, useState } from "react";
import { Button, IconButton, Box, Grid, TextField, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { ReportDataContext, ReportErrorContext, MessagesContext, AuthContext } from "../../../context";
import { CoordinatesInput } from "../../CoordInput";
import ImageMapModal from "./ImageMapModal";
import { useTheme } from "@mui/material/styles";
import IconButtons from "../../Icons/IconButtons";
import DeleteIcon from "../../Icons/DeleteIcon";
import RadioGroup from "../../Inputs/RadioGroup";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const VideosStep = () => {
	const { reportData, setReportData } = useContext(ReportDataContext); // files = [{file: File, comment: String}]
	const { setCheckErrors } = useContext(ReportErrorContext);
	const { addMessage } = useContext(MessagesContext);
	const [errors, setErrors] = useState([]);
	const theme = useTheme();

	useEffect(() => {
		setCheckErrors(() => () => {
			let hasErrors = false;
			const newErrors = [];
			reportData.videos.forEach((item, index) => {
				const newError = { error: false };
				if (!item.latitude) {
					newError.error = true;
					newError.latitude = "Coordenadas inválidas";
				}
				if (!item.longitude) {
					newError.error = true;
					newError.longitude = "Coordenadas inválidas";
				}
				if (!item.type) {
					newError.error = true;
					newError.type = "Selecciona un tipo";
				}
				newErrors.push(newError);
			});
			newErrors.forEach((item) => {
				if (item.error) {
					hasErrors = true;
				}
			});
			setErrors(newErrors);
			return hasErrors;
		});
	}, [setCheckErrors, reportData.videos]);
	// console.log(errors);
	const maxVideos = 20;
	const maxSize = 300 * 1024 * 1024; // 300 MB

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"video/*": [".mp4"],
		},
		maxFiles: maxVideos,
		onDrop: (acceptedFiles) => {
			const updatedFiles = acceptedFiles.map((file) => {
				if (file.size > maxSize) {
					addMessage(
						`El video ${file.name} excede el tamaño máximo permitido de ${maxSize / 1024 / 1024}MB`,
						"error"
					);
					return null;
				} else {
					return {
						video: Object.assign(file, { preview: URL.createObjectURL(file) }),
						description: "",
						latitude: "",
						longitude: "",
						type: "",
					};
				}
			});

			//no se toman los que son nulos (los que excedieron el tamaño)
			const validFiles = updatedFiles.filter((file) => file !== null);
			setReportData({ ...reportData, videos: [...reportData.videos, ...validFiles] });
		},
	});

	const handleCommentChange = (index, comment) => {
		const updatedFiles = [...reportData.videos];
		updatedFiles[index].description = comment;
		setReportData({ ...reportData, videos: [...updatedFiles] });
	};
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const { canUseMapTools } = useContext(AuthContext);
	const [zoom, setZoom] = useState(16); // [zoom, setZoom

	const onRowClick = (index) => {
		setIsModalOpen(true);
		setSelectedVideo(index);
	};
	const onModalClose = () => {
		setIsModalOpen(false);
		setSelectedVideo(null);
	};

	return (
		<div style={{ padding: "20px 30px" }}>
			<ImageMapModal
				object={reportData.videos[selectedVideo]}
				setObject={(obj) => {
					setReportData((prev) => {
						prev.videos[selectedVideo].latitude = obj.latitude;
						prev.videos[selectedVideo].longitude = obj.longitude;
						return { ...prev };
					});
				}}
				otherObjects={reportData.videos
					.filter((_, i) => i !== selectedVideo)
					.filter((item) => item.latitude && item.longitude)}
				reportData={reportData}
				mapZoom={zoom}
				setMapZoom={setZoom}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				onClose={onModalClose}
			/>
			<div
				{...getRootProps({ className: "dropzone" })}
				style={{
					border: `4px dashed ${theme.palette.brandColors.gray}`,
					borderRadius: "10px",
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "200px",
					position: "relative",
				}}
			>
				<input {...getInputProps()} />
				<CloudUploadOutlinedIcon style={{ color: "grey", fontSize: "3rem" }} />
				<p style={{ color: "grey", textAlign: "center", margin: "0px 0" }}>
					<strong>Arrastra los videos aquí, o click para seleccionar</strong>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>{reportData.videos.length} agregada(s)</em>
				</p>
				<p style={{ color: "grey", textAlign: "center", fontSize: "0.8rem", margin: "0px 0" }}>
					<em>máximo {maxVideos}</em>
				</p>
			</div>

			<div>
				{reportData.videos.map((item, index) => (
					<Box
						key={index}
						sx={{
							height: "auto",
							display: "flex",
							padding: "20px 0",
							gap: "20px",
						}}
					>
						<div
							style={{
								height: "100%",
								width: "auto",
								maxHeight: "150px",
								maxWidth: "100%",
								borderRadius: "10px",
							}}
						>
							<video
								controls
								width="100%"
								height="100%"
								src={typeof item.video === "object" ? item.video.preview : item.video}
							/>
							<div
								style={{
									marginTop: "20px",
								}}
							>
								<RadioGroup
									label="Tipo"
									options={[
										{ label: "Drone", name: "DRONE" },
										{ label: "ROV", name: "ROV" },
									]}
									value={item.type}
									onChange={(e) => {
										setReportData((prev) => {
											const newVideos = [...prev.videos];
											newVideos[index].type = e;
											return { ...prev };
										});
									}}
								/>
								{errors[index]?.type && (
									<div
										style={{
											display: "flex",
											color: theme.palette.brandColors.danger,
											marginTop: "7px",
											paddingLeft: "15px",
										}}
									>
										<PriorityHighIcon
											style={{
												fontSize: "16px",
												marginRight: "5px",
												color: theme.palette.brandColors.danger,
											}}
										/>
										<div style={{ fontSize: "12px", fontWeight: 500 }}>{errors[index].type}</div>
									</div>
								)}
							</div>
						</div>
						<div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
							<div>
								<CoordinatesInput
									setter={(latlon) => {
										setReportData((prev) => {
											prev.videos[index].latitude = latlon.latitude;
											prev.videos[index].longitude = latlon.longitude;
											return { ...prev };
										});
									}}
									coordinates={{ latitude: item.latitude, longitude: item.longitude }}
									error={{
										latitude: errors[index]?.latitude,
										longitude: errors[index]?.longitude,
									}}
									key={index}
									column
								>
									{canUseMapTools && (
										<div style={{ display: "flex", alignItems: "center" }}>
											<Button
												sx={{
													backgroundColor: theme.palette.brandColors.primary,
													color: "white",
													padding: "5px 20px",
													borderRadius: "10px",
													boxSizing: "border-box",
													textWrap: "nowrap",
													"&:hover": {
														backgroundColor: theme.palette.brandColors.primary,
														color: "white",
													},
												}}
												onClick={() => onRowClick(index)}
											>
												Ver ubicación en mapa
											</Button>
										</div>
									)}
								</CoordinatesInput>
							</div>
							<div style={{ display: "flex", gap: "15px" }}>
								<TextField
									label="Añadir un comentario (opcional)..."
									variant="outlined"
									multiline
									rows={5}
									fullWidth
									style={{ flex: 1 }}
									value={item.description}
									onChange={(e) => handleCommentChange(index, e.target.value)}
								/>
							</div>
						</div>
						<IconButtons
							variant="danger"
							onClick={() => {
								// elimina la imagen de la lista
								// si la imagen ya estaba en la db, tambien se agrega a la lista de eliminados
								if (item.id) {
									setReportData({
										...reportData,
										videosDeleted: [...reportData.videosDeleted, item],
										videos: reportData.videos.filter((_, i) => i !== index),
									});
								} else {
									setReportData({
										...reportData,
										videos: reportData.videos.filter((_, i) => i !== index),
									});
								}
							}}
							icon={<DeleteIcon />}
						/>
					</Box>
				))}
			</div>
		</div>
	);
};

export default VideosStep;
