import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const VideoInput = ({ video, setVideo }) => {
	return (
		<div
			style={{
				display: "flex",
				position: "relative",
				width: "100%",
				height: "100%",
				justifyContent: "center",
				alignItems: "center",
				padding: "20px",
				textAlign: "center",

				border: "2px dashed grey",
				borderRadius: "10px",
				backgroundColor: "#f5f5f5",
			}}
		>
			<IconButton onClick={() => setVideo(null)} sx={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }}>
				<CloseIcon />
			</IconButton>
			<input
				type="file"
				accept="video/*"
				style={{ width: "100%", height: "100%", position: "absolute", opacity: 0 }}
				onChange={(e) => {
					setVideo({ video: e.target.files[0], preview: URL.createObjectURL(e.target.files[0]) });
				}}
			/>
			{video ? (
				<video controls width="100%" height="100%" src={typeof video === "object" ? video.preview : video} />
			) : (
				<>Sube o arrastra el video</>
			)}
		</div>
	);
};

export default VideoInput;
