import ConsolidatedReport from "../Offline/Consolidated/ConsolidatedReport";


const PreviewReport = ({ generalData }) => {
	const addRows = generalData?.additionalRows?.split("|||");
	const cc = generalData.centers.find((center) => center.id === generalData.center);

	return (
		<div style={{ padding: "20px 0", display: "flex", flexDirection: "column", height: "100%" }}>
			<ConsolidatedReport
				data={{
					startDate: generalData.startDate,
					endDate: generalData.finalDate,
					cultivationCenter: cc,
					place: cc.place?.name || cc.place,
					salmonCompany: cc.salmonCompany,
					type: generalData.type,
					comments: generalData.comments,
					objects: generalData.allObjects,
					geoImages: generalData.images || generalData.geoImages,
					byQuantity: generalData?.byObject?.unitaryObjects?.map((item) => ({
						...item,
						ratio: item.total / item.type__weight,
						count: item.total,
					})),
					byMeasurable: generalData?.byObject?.measureObjects?.map((item) => ({
						...item,
						ratio: item.total / item.type__weight,
						count: item.total,
					})),
					reports: generalData.report,
					additionalRows: addRows?.map((item) => [item.split(";;")[0], parseInt(item.split(";;")[1])]),
					wreckRescueCompany: null,
				}}
			/>
		</div>
	);
};

export default PreviewReport;
