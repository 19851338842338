import { useEffect, useState, useCallback, createContext, useReducer, useContext } from "react";

import { Alert, Snackbar } from "@mui/material";

export const MessagesContext = createContext({});

/**
 * Function para utilizar el contexto de mensajes
 * @returns {Object} Objeto con los estados y funciones del contexto, contiene lo siguiente:
 * @param {Object} snackBar Objeto con los estados y funciones del snackbar
 * @param {Boolean} setSnackBar Función para actualizar el snackbar
 * @param {Array} messages Array con los mensajes
 * @param {Function} messagesDispatch Función para actualizar los mensajes (add, remove, clear)
 * @param {Function} addMessage Función para agregar un mensaje a la cola
 * @param {Function} clearMessages Función para limpiar la cola de mensajes
 */
export const useMessagesContext = () => useContext(MessagesContext);

const default_snackbar_settings = {
	open: false,
	message: "",
	severity: "success", // options: "success", "info", "warning", "error"
	autoHideDuration: 5000, // if null, snackbar will not autohide, otherwise, time in ms
};

const MessagesProvider = ({ children }) => {
	const [snackBar, setSnackBar] = useState(default_snackbar_settings);
	const [messages, messagesDispatch] = useReducer((messages, { type, value }) => {
		switch (type) {
			case "add":
				return [...messages, value];
			case "remove":
				return messages.filter((_, index) => index !== value);
			case "clear":
				return [];
			default:
				return messages;
		}
	}, []);

	const closeMessage = useCallback(() => {
		setSnackBar({ ...snackBar, open: false });
		setTimeout(() => {
			messagesDispatch({ type: "remove", value: 0 });
		}, 100);
	}, [snackBar]);

	const addMessage = useCallback(
		(
			message,
			severity = default_snackbar_settings.severity,
			timeout_ms = default_snackbar_settings.autoHideDuration
		) => {
			const newMessage = {
				message: message,
				severity: severity,
				autoHideDuration: timeout_ms,
			};
			messagesDispatch({ type: "add", value: newMessage });
		},
		[]
	);

	const clearMessages = useCallback(() => {
		setSnackBar(default_snackbar_settings);
		messagesDispatch({ type: "clear" });
	}, []);

	const nextMessage = useCallback(() => {
		if (messages.length > 0) {
			const nextMessage = messages[0];
			setSnackBar({
				open: true,
				message: nextMessage.message,
				severity: nextMessage.severity,
				autoHideDuration: nextMessage.autoHideDuration,
			});
		} else {
			setSnackBar({ ...snackBar, open: false });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messages]);

	useEffect(() => {
		nextMessage();
	}, [nextMessage]);

	return (
		<MessagesContext.Provider
			value={{
				snackBar,
				setSnackBar,
				messages,
				messagesDispatch,
				addMessage,
				clearMessages,
			}}
		>
			<>
				{children}

				<Snackbar open={snackBar.open} autoHideDuration={snackBar.autoHideDuration} onClose={closeMessage}>
					<Alert onClose={closeMessage} severity={snackBar.severity} sx={{ width: "100%" }}>
						{snackBar.message}
					</Alert>
				</Snackbar>
			</>
		</MessagesContext.Provider>
	);
};

export default MessagesProvider;
