

const InfoIcon = ({color}) => {
    return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<g clipPath="url(#clip0_138_72)">
				<path
					d="M12.9689 24H11.0024C10.6097 23.9437 10.2114 23.9109 9.82439 23.8274C5.0121 22.7932 1.87646 19.9177 0.407163 15.2253C0.185266 14.5171 0.0951942 13.7803 -0.000976562 13.0463V10.9869C0.0093442 10.9335 0.023418 10.8805 0.0285784 10.8265C0.248598 8.69348 0.973397 6.75085 2.22784 5.00943C5.15894 0.942208 10.1988 -0.853618 14.9773 0.386905C21.1238 1.98246 24.9795 7.93791 23.7818 14.1677C22.8487 19.0201 19.8731 22.1465 15.1504 23.6027C14.4401 23.8218 13.7041 23.9118 12.9689 24ZM1.91915 11.6112C1.65551 16.9921 5.88186 22.0076 11.8773 22.0982C17.2661 22.1798 21.9823 17.9245 22.07 12.1308C22.1511 6.76164 17.914 2.02186 12.1053 1.93556C6.57335 1.85349 2.09601 6.24011 1.91915 11.6112Z"
					fill={color || "#848591"}
				/>
				<path
					d="M12.9412 13.2307C12.9412 14.2527 12.9468 15.2742 12.937 16.2961C12.9346 16.5236 13.0092 16.6061 13.2344 16.5897C13.4441 16.5747 13.6557 16.5827 13.8663 16.5883C14.3833 16.6024 14.7971 17.0306 14.7938 17.5441C14.7905 18.0601 14.3786 18.4892 13.8588 18.492C12.6105 18.4986 11.3621 18.4986 10.1138 18.492C9.59445 18.4892 9.18162 18.0591 9.1788 17.5441C9.17599 17.0311 9.59023 16.6019 10.1063 16.5883C10.3244 16.5827 10.5435 16.5771 10.7616 16.5893C10.9638 16.6005 11.0384 16.5259 11.0351 16.32C11.0258 15.6963 11.0318 15.072 11.0318 14.4482C11.0318 13.4652 11.0318 12.4826 11.0318 11.4996C11.0318 11.262 10.9094 11.143 10.6645 11.1427C10.4848 11.1427 10.3052 11.1478 10.1264 11.1394C9.60899 11.1155 9.2093 10.7229 9.18021 10.2169C9.153 9.74129 9.49875 9.29057 10.0007 9.23758C10.7063 9.163 11.4198 9.16394 12.1254 9.2357C12.6316 9.28729 12.9379 9.67938 12.9398 10.1892C12.9431 11.2032 12.9407 12.2172 12.9412 13.2312V13.2307Z"
					fill={color || "#848591"}
				/>
				<path
					d="M10.7979 5.82552C10.7969 5.16234 11.3172 4.63096 11.9768 4.62205C12.6298 4.6136 13.1702 5.14874 13.174 5.80723C13.1777 6.48541 12.6504 7.02242 11.9829 7.02055C11.3242 7.01867 10.7988 6.48916 10.7979 5.82552Z"
					fill={color || "#848591"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_138_72">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default InfoIcon;