import { DataGrid } from "@mui/x-data-grid";
import { Tooltip, Menu, MenuItem, Checkbox, BottomNavigationAction, BottomNavigation } from "@mui/material";
import { generateObjectsPoints } from "../../utils/map_helper";
import { useEffect, useState, React, useContext, useCallback } from "react";
import CreateButton from "../CreateButton";
import { AuthContext, MessagesContext } from "../../context";
import "../../global/css.css";
import * as XLSX from "xlsx/xlsx.mjs";

import { fromLatLon } from "../../utils/utm";

import BoxIcon from "../Icons/BoxIcon";
import CameraIcon from "../Icons/CameraIcon";
import FishIcon from "../Icons/FishIcon";
import Table from "../Table/Table";
import Search from "../Table/Search";
import DownloadIcon from "../Icons/DownloadIcon";
import GeneralButton from "../GeneralButton";
import IconButtons from "../Icons/IconButtons";
import EyeIcon from "../Icons/EyeIcon";

const estado_color_map = {
	Balizado: "var(--red)",
	"En Embarcación": "var(--green)",
	Entregado: "#ffc176",
};

const parseObjectsToJSON = (objects) => {
	const downloadColumns = [
		{ name: "id", exportName: "ID" },
		{ name: "creationDate", exportName: "Fecha" },
		{ name: "state", exportName: "Estado" },
		{ name: "volume", exportName: "Volumen (kg)" },
		{ name: "cultivationCenter", exportName: "Centro" },
		{ name: "latitude", exportName: "Latitud" },
		{ name: "latitudeUTM", exportName: "Latitud (utm)" },
		{ name: "longitudeUTM", exportName: "Longitud (utm)" },
		{ name: "comments", exportName: "Comentarios" },
		{ name: "notify", exportName: "Importante" },
		{ name: "type", exportName: "Tipo" },
		{ name: "ammount", exportName: "Cantidad" },
	];

	return objects.map((row) => {
		const newRow = {};

		for (let col of downloadColumns) {
			if (col.name === "longitudeUTM" || col.name === "latitudeUTM") {
				continue;
			}
			if (col.name === "type") {
				newRow[col.exportName] = row[col.name].name;
				continue;
			}
			newRow[col.exportName] = row[col.name];
		}
		try {
			const utmCoordinates = fromLatLon(row.latitude, row.longitude);
			newRow["latitudeUTM"] = parseInt(utmCoordinates.easting);
			newRow["longitudeUTM"] = parseInt(utmCoordinates.northing);
			newRow["Huso/Num. Zona"] = utmCoordinates.zoneNum;
			newRow["Banda/Letra"] = utmCoordinates.zoneLetter;
		} catch (e) {
			newRow["latitudeUTM"] = "Error";
			newRow["longitudeUTM"] = "Error";
			newRow["Huso/Num. Zona"] = "Error";
			newRow["Banda/Letra"] = "Error";
		}

		return newRow;
	});
};

const parseImagesToJSON = (images) => {
	const downloadColumns = [
		{ name: "id", exportName: "ID" },
		{ name: "latitude", exportName: "Latitud" },
		{ name: "longitude", exportName: "Longitud" },
		{ name: "date", exportName: "Fecha" },
		{ name: "cultivationCenter", exportName: "Centro" },
		{ name: "description", exportName: "Descripción" },
	];

	return images.map((row) => {
		const newRow = {};

		for (let col of downloadColumns) {
			newRow[col.exportName] = row.properties[col.name];
		}
		try {
			const utmCoordinates = fromLatLon(row.properties.latitude, row.properties.longitude);
			newRow["latitudeUTM"] = parseInt(utmCoordinates.easting);
			newRow["longitudeUTM"] = parseInt(utmCoordinates.northing);
			newRow["Huso/Num. Zona"] = utmCoordinates.zoneNum;
			newRow["Banda/Letra"] = utmCoordinates.zoneLetter;
		} catch (e) {
			newRow["latitudeUTM"] = "Error";
			newRow["longitudeUTM"] = "Error";
			newRow["Huso/Num. Zona"] = "Error";
			newRow["Banda/Letra"] = "Error";
		}

		return newRow;
	});
};

const parseBacteriumToJSON = (bacterium) => {
	const downloadColumns = [
		{ name: "id", exportName: "ID" },
		{ name: "latitude", exportName: "Latitud" },
		{ name: "longitude", exportName: "Longitud" },
		{ name: "date", exportName: "Fecha" },
		{ name: "cultivationCenter", exportName: "Centro" },
		{ name: "founds", exportName: "Descripción" },
	];

	return bacterium.map((row) => {
		const newRow = {};

		for (let col of downloadColumns) {
			newRow[col.exportName] = row.properties[col.name];
		}
		try {
			const utmCoordinates = fromLatLon(row.properties.latitude, row.properties.longitude);
			newRow["latitudeUTM"] = parseInt(utmCoordinates.easting);
			newRow["longitudeUTM"] = parseInt(utmCoordinates.northing);
			newRow["Huso/Num. Zona"] = utmCoordinates.zoneNum;
			newRow["Banda/Letra"] = utmCoordinates.zoneLetter;
		} catch (e) {
			newRow["latitudeUTM"] = "Error";
			newRow["longitudeUTM"] = "Error";
			newRow["Huso/Num. Zona"] = "Error";
			newRow["Banda/Letra"] = "Error";
		}
		return newRow;
	});
};

const Export = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const downloadAsExcel = () => {
		if (props.selectedTable === 0) {
			const worksheet = XLSX.utils.json_to_sheet(parseObjectsToJSON(props.rows));
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Objetos");
			XLSX.writeFile(workbook, "Objetos.xlsx", { compression: false });
		}
		if (props.selectedTable === 1) {
			const worksheet = XLSX.utils.json_to_sheet(parseImagesToJSON(props.images));
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Imágenes");
			XLSX.writeFile(workbook, "Imágenes.xlsx", { compression: false });
		}
		if (props.selectedTable === 2) {
			const worksheet = XLSX.utils.json_to_sheet(parseBacteriumToJSON(props.bacteriumPoints));
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Alimentación y Bacterias");
			XLSX.writeFile(workbook, "Alimentación y Bacterias.xlsx", { compression: false });
		}
	};

	const downloadAsCSV = () => {
		if (props.selectedTable === 0) {
			const worksheet = XLSX.utils.json_to_sheet(parseObjectsToJSON(props.rows));
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Objetos");
			XLSX.writeFile(workbook, "Objetos.csv", { bookType: "csv" });
		}
		if (props.selectedTable === 1) {
			const worksheet = XLSX.utils.json_to_sheet(parseImagesToJSON(props.images));
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Imágenes");
			XLSX.writeFile(workbook, "Imágenes.csv", { bookType: "csv" });
		}
		if (props.selectedTable === 2) {
			const worksheet = XLSX.utils.json_to_sheet(parseBacteriumToJSON(props.bacteriumPoints));
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Alimentación y Bacterias");
			XLSX.writeFile(workbook, "Alimentación y Bacterias.csv", { bookType: "csv" });
		}
	};

	return (
		<>
			<div style={{ display: "flex", alignItems: "center", textWrap: "nowrap" }}>
				<GeneralButton
					text="Exportar"
					variant="empty"
					icon={<DownloadIcon color="#22ACA4" />}
					onClick={(event) => {
						setAnchorEl(event.currentTarget);
					}}
				/>
			</div>

			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				<MenuItem onClick={downloadAsExcel}>Descargar como excel</MenuItem>
				<MenuItem onClick={downloadAsCSV}>Descargar como csv</MenuItem>
			</Menu>
		</>
	);
};

function RenderEstado({ row }) {
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<div
				style={{
					width: "15px",
					minWidth: "15px",
					height: "15px",
					borderRadius: "50%",
					backgroundColor: estado_color_map[row.state],
					display: "inline-block",
					marginRight: "5px",
				}}
			></div>
			<div style={{ fontSize: "14px" }}>{row.state}</div>
		</div>
	);
}

const VISIBLE_COLUMNS = ["id", "state", "volume", "creationDate", "cultivationCenter"];
const IMAGE_COLUMNS = ["id", "latitude", "longitude", "date", "cultivationCenter"];

export default function ObjectGrid({
	rows,
	setSelectedObject,
	setMapObjects,
	setStep,
	steps,
	images,
	setFilteredImagePoints,
	bacteriumPoints,
	setFilteredBacteriumPoints,
	setMapCenter,
}) {
	const [selectedItems, setSelectedItems] = useState([]);
	const { USER_TYPE } = useContext(AuthContext);
	const { addMessage } = useContext(MessagesContext);
	const [selectedTable, setSelectedTable] = useState(0);
	const [filteredImages, setFilteredImages] = useState(images.features);
	const [filteredBacterium, setFilteredBacterium] = useState(bacteriumPoints.features);

	const onRowClick = (row) => {
		setSelectedObject(row);
	};

	useEffect(() => {
		setFilteredImages(images.features);
	}, [images]);

	useEffect(() => {
		setFilteredBacterium(bacteriumPoints.features);
	}, [bacteriumPoints]);

	const [input, setInput] = useState(""); // input para el filtro
	const [innerRows, setInnerRows] = useState(rows);

	const filterRows = useCallback(() => {
		const filteredRows = [];
		rows.forEach((row) => {
			for (let key of VISIBLE_COLUMNS) {
				if (row[key] === undefined) continue; // TODO: habia un error aqui que me obligo a poner esto :c
				if (row[key].toString().toLowerCase().includes(input.toLowerCase())) {
					filteredRows.push(row);
					break;
				}
			}
		});
		setInnerRows(filteredRows);
		setMapObjects((prevMapObjects) => {
			return generateObjectsPoints(filteredRows);
		});

		const filteredImage = !images.features
			? []
			: images.features.filter((row) => {
					for (let key of IMAGE_COLUMNS) {
						if (row.properties[key] === undefined) continue;
						if (row.properties[key].toString().toLowerCase().includes(input.toLowerCase())) {
							return true;
						}
					}
					return false;
			  });

		setFilteredImages(filteredImage);
		setFilteredImagePoints({
			type: "FeatureCollection",
			features: filteredImage,
		});

		const filteredBacterium = !bacteriumPoints.features
			? []
			: bacteriumPoints.features.filter((row) => {
					for (let key of IMAGE_COLUMNS) {
						if (row.properties[key] === undefined) continue;
						if (row.properties[key].toString().toLowerCase().includes(input.toLowerCase())) {
							return true;
						}
					}
					return false;
			  });

		setFilteredBacterium(filteredBacterium);
		setFilteredBacteriumPoints({
			type: "FeatureCollection",
			features: filteredBacterium,
		});
	}, [rows, setInnerRows, input, setMapObjects]);

	useEffect(() => {
		filterRows();
	}, [filterRows]);

	// gets called when the user clicks on the "Nota de Salida" button
	const handleSelectedItems = () => {
		const selectedObjects = rows.filter((row) => selectedItems.includes(row.id));
		if (selectedObjects.filter((obj) => obj.state === "Balizado").length > 0) {
			addMessage(
				"No se puede realizar una nota de salida con objetos balizados, se quitarán dichos objetos",
				"error"
			);
		}
		if (selectedObjects.filter((obj) => obj.receptionNote).length > 0) {
			addMessage(
				"No se puede realizar una nota de salida con objetos que ya tienen nota de salida, se quitarán dichos objetos",
				"error"
			);
		}
		const filteredObjects = selectedObjects.filter((obj) => obj.state !== "Balizado" && !obj.receptionNote);
		if (filteredObjects.length === 0) {
			addMessage("No se puede realizar una nota de salida con objetos balizados o con nota de salida", "error");
			return;
		}
		setSelectedObject(filteredObjects.map((obj) => obj.id));
		setStep(steps.NOTA_SALIDA);
	};

	return (
		<>
			<BottomNavigation
				showLabels
				value={selectedTable}
				onChange={(event, newValue) => {
					setSelectedTable(newValue);
				}}
			>
				<BottomNavigationAction
					label="Objetos"
					icon={<BoxIcon color={selectedTable === 0 ? "#22ACA4" : "#848591"} />}
				/>
				<BottomNavigationAction
					label="Imágenes Prospección"
					icon={<CameraIcon color={selectedTable === 1 ? "#22ACA4" : "#848591"} />}
				/>
				<BottomNavigationAction
					label="Alimentación y Bacterias"
					icon={<FishIcon color={selectedTable === 2 ? "#22ACA4" : "#848591"} />}
				/>
			</BottomNavigation>

			{selectedTable === 0 && (
				<>
					<div style={{ display: "flex", gap: "15px", margin: "20px 0" }}>
						<Search search={input} setSearch={setInput} style={{ width: "100%" }} />
						{USER_TYPE === "Operador ROV" ? (
							<Tooltip title={selectedItems.length < 1 ? "Selecciones alguna cantidad de objetos" : ""}>
								<div style={{ display: "flex", alignItems: "center", textWrap: "nowrap" }}>
									<CreateButton
										text="Nota de Salida"
										onClick={handleSelectedItems}
										disabled={selectedItems.length === 0}
									/>
								</div>
							</Tooltip>
						) : (
							<></>
						)}
						<Export
							selectedTable={selectedTable}
							rows={innerRows}
							images={filteredImages}
							bacteriumPoints={filteredBacterium}
						/>
					</div>
					<Table
						onRowClick={onRowClick}
						variant="small"
						sorting={{
							field: "creationDate",
							sort: "desc",
						}}
						columns={(USER_TYPE === "Operador ROV"
							? [
									{
										field: "select",
										renderCell: (row) => (
											<Checkbox
												checked={selectedItems.includes(row.id)}
												onClick={(e) => {
													e.preventDefault();
													if (selectedItems.includes(row.id)) {
														setSelectedItems(
															selectedItems.filter((item) => item !== row.id)
														);
													} else {
														setSelectedItems([...selectedItems, row.id]);
													}
												}}
											/>
										),
									},
							  ]
							: []
						).concat([
							{ field: "id", headerName: "ID", sortable: true },
							{
								field: "estadoCircle",
								headerName: "Estado",
								sortable: true,
								valueGetter: (row) => row.state,
								renderCell: (row) => <RenderEstado row={row} />,
							},
							{ field: "creationDate", headerName: "Fecha", sortable: true },
							{ field: "volume", headerName: "Volumen (kg)", sortable: true },
							{ field: "cultivationCenter", headerName: "Centro", sortable: true },
						])}
						rows={rows}
						filter={input}
					/>
				</>
			)}

			{selectedTable === 1 && (
				<>
					<div style={{ display: "flex", gap: "15px", margin: "20px 0" }}>
						<Search search={input} setSearch={setInput} style={{ width: "100%" }} />
						<Export
							selectedTable={selectedTable}
							rows={innerRows}
							images={filteredImages}
							bacteriumPoints={filteredBacterium}
						/>
					</div>
					<Table
						columns={[
							{ field: "id", headerName: "ID", sortable: true },
							{ field: "latitude", headerName: "Latitud", sortable: true },
							{ field: "longitude", headerName: "Longitud", sortable: true },
							{ field: "date", headerName: "Fecha", flex: 1, sortable: true },
							{ field: "cultivationCenter", headerName: "Centro", flex: 1, sortable: true },
							{
								field: "actions",
								renderCell: (row) => (
									<div style={{ display: "flex", justifyContent: "end" }}>
										<IconButtons
											icon={<EyeIcon />}
											onClick={() => setMapCenter([row.latitude, row.longitude])}
										/>
									</div>
								),
							},
						]}
						rows={images.features.map((row) => ({
							...row.properties,
						}))}
						filter={input}
						sorting={{
							field: "date",
							sort: "desc",
						}}
					/>
				</>
			)}

			{selectedTable === 2 && (
				<>
					<div style={{ display: "flex", gap: "15px", margin: "20px 0" }}>
						<Search search={input} setSearch={setInput} style={{ width: "100%" }} />
						<Export
							selectedTable={selectedTable}
							rows={innerRows}
							images={filteredImages}
							bacteriumPoints={filteredBacterium}
						/>
					</div>
					<Table
						columns={[
							{ field: "id", headerName: "ID", sortable: true },
							{ field: "latitude", headerName: "Latitud", sortable: true },
							{ field: "longitude", headerName: "Longitud", sortable: true },
							{ field: "date", headerName: "Fecha", sortable: true },
							{ field: "cultivationCenter", headerName: "Centro", sortable: true },
							{
								field: "actions",
								renderCell: (row) => (
									<div style={{ display: "flex", justifyContent: "end" }}>
										<IconButtons
											icon={<EyeIcon />}
											onClick={() => setMapCenter([row.latitude, row.longitude])}
										/>
									</div>
								),
							},
						]}
						rows={bacteriumPoints.features.map((row) => ({
							...row.properties,
						}))}
						filter={input}
						sorting={{
							field: "date",
							sort: "desc",
						}}
					/>
				</>
			)}
		</>
	);
}
