

const UserIcon = ({color}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
			<path
				d="M7 7.5C8.65685 7.5 10 6.15685 10 4.5C10 2.84315 8.65685 1.5 7 1.5C5.34315 1.5 4 2.84315 4 4.5C4 6.15685 5.34315 7.5 7 7.5Z"
				stroke={color || "#22ACA4"}
				strokeWidth="1.5"
			/>
			<path
				d="M13 13.125C13 14.9887 13 16.5 7 16.5C1 16.5 1 14.9887 1 13.125C1 11.2613 3.6865 9.75 7 9.75C10.3135 9.75 13 11.2613 13 13.125Z"
				stroke={color || "#22ACA4"}
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export default UserIcon;