import { useTheme } from "@mui/material";

const IconButtons = ({ icon, onClick, disabled, variant = "primary" }) => {
	const theme = useTheme();
	return (
		<div
			style={{
				height: "30px",
				minHeight: "30px",
				width: "30px",
				minWidth: "30px",
				justifyContent: "center",
				display: "flex",
				alignItems: "center",
				cursor: disabled ? "not-allowed" : "pointer",
				borderRadius: "50%",
				opacity: disabled ? "0.5" : "1",
				backgroundColor:
					variant === "primary" ? theme.palette.brandColors.primary : theme.palette.brandColors.danger,
			}}
			onClick={(e) => {
				if (!disabled) {
					onClick(e);
				}
			}}
		>
			{icon}
		</div>
	);
};

export default IconButtons;
