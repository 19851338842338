import { useState } from "react";
import GeneralButton from "../../GeneralButton";
import {
	ContextImage,
	GeneralInfo,
	ImageContainer,
	ImagesContainer,
	middleScreenMedia,
	ObjectID,
	Pair,
	TabContent,
} from "./Common";
import {
	CloseButton,
	RotateInstruction,
	See,
	Shadow,
	SwitchContainer,
	Table,
	TableContainer,
	TableHead,
	TableTab,
	TableTitle,
	Td,
	Th,
} from "./Consolidated/ConsolidatedCommon";
import CloseIcon from "@mui/icons-material/Close";
import RotateIcon from "../../Icons/RotateIcon";

const createDescription = (img) => {
	let descriptions = [];

	if (img.hasFeed) descriptions.push(`alimento grado ${img.feedRate}`);
	if (img.hasBacterium) descriptions.push(`bacterias grado ${img.bacteriumRate}`);
	if (img.hasFeces) descriptions.push(`fecas grado ${img.fecesRate}`);

	const descrip = descriptions.length > 0 ? descriptions.join(", ") + "." : "Sin observaciones.";
	return descrip.charAt(0).toUpperCase() + descrip.slice(1);
};

const genTableData = (images, cages) => {
	const rows = [];

	for (let i = 0; i < cages.length; i++) {
		const cage = cages[i];
		const cageImages = images.filter((img) => img.cage === cage.name);

		const maxFeedRate = cageImages.reduce((acc, img) => (img.feedRate > acc ? img.feedRate : acc), -1);
		const maxBacteriumRate = cageImages.reduce((acc, img) => (img.bacteriumRate > acc ? img.bacteriumRate : acc), -1);
		const maxFecesRate = cageImages.reduce((acc, img) => (img.fecesRate > acc ? img.fecesRate : acc), -1);

		rows.push({
			cage: cage.name,
			depth: cage.depth,
			feedRate: maxFeedRate === -1 ? "-" : maxFeedRate,
			bacteriumRate: maxBacteriumRate === -1 ? "-" : maxBacteriumRate,
			fecesRate: maxFecesRate === -1 ? "-" : maxFecesRate,
		});
	}

	return rows;
}

const FeedingBacteriumImages = ({ images, cages }) => {
	const [switchState, setSwitchState] = useState(0);
	const [tableOpen, setTableOpen] = useState(false);
	const [summaryOpen, setSummaryOpen] = useState(false);
	const [rotate, setRotate] = useState(false);
	

	const onOpenTable = () => {
		setRotate(true);

		setTimeout(() => {
			setRotate(false);
			setTableOpen(true);
		}, 1000);
	};

	const onOpenSummary = () => {
		setRotate(true);

		setTimeout(() => {
			setRotate(false);
			setSummaryOpen(true);
		}, 1000);
	};

	return (
		<>
			{rotate && (
				<Shadow>
					<RotateInstruction>
						<RotateIcon />
					</RotateInstruction>
				</Shadow>
			)}

			<SwitchContainer>
				<GeneralButton
					variant={switchState === 0 ? "contained" : "empty"}
					onClick={() => setSwitchState(0)}
					text="Tabla"
					sx={{
						textTransform: "none",
						[middleScreenMedia]: {
							fontSize: "12px",
							textWrap: "nowrap",
						},
					}}
				/>
				<GeneralButton
					variant={switchState === 1 ? "contained" : "empty"}
					onClick={() => setSwitchState(1)}
					text="Imágenes"
					sx={{
						textTransform: "none",
						[middleScreenMedia]: {
							fontSize: "12px",
							textWrap: "nowrap",
						},
					}}
				/>

				<GeneralButton
					variant={switchState === 2 ? "contained" : "empty"}
					onClick={() => setSwitchState(2)}
					text="Resumen por jaulas"
					sx={{
						textTransform: "none",
						[middleScreenMedia]: {
							fontSize: "12px",
							textWrap: "nowrap",
						},
					}}
				/>
			</SwitchContainer>

			<TabContent
				value={switchState}
				index={0}
				style={{
					height: "100%",
					padding: "0 20px",
					margin: 0,
					display: "flex",
					flexDirection: "column",
					overflow: "unset",
				}}
			>
				<TableTab>
					<TableTitle>
						Tabla de imágenes
						<See onClick={onOpenTable}>Ver</See>
					</TableTitle>
					<TableContainer>
						<Table open={tableOpen}>
							{tableOpen && (
								<CloseButton onClick={() => setTableOpen(false)}>
									<CloseIcon />
								</CloseButton>
							)}

							<TableHead>
								<tr>
									<Th>ID</Th>
									<Th>Latitud</Th>
									<Th>Longitud</Th>
									<Th>Descripción</Th>
									<Th>Jaula</Th>
								</tr>
							</TableHead>
							<tbody>
								{images?.map((img) => (
									<tr key={img.id}>
										<Td>{img.id}</Td>
										<Td>{img.latitude}</Td>
										<Td>{img.longitude}</Td>
										<Td>{createDescription(img)}</Td>
										<Td>{img.cage}</Td>
									</tr>
								))}
							</tbody>
						</Table>
					</TableContainer>
				</TableTab>
			</TabContent>

			<TabContent
				value={switchState}
				index={1}
				style={{
					height: "100%",
					padding: "0 20px",
					margin: 0,
					display: "flex",
					flexDirection: "column",
					overflow: "unset",
				}}
			>
				<GeneralInfo>
					<ImagesContainer>
						{images?.map((img) => (
							<ImageContainer>
								<ContextImage
									src={typeof img.image === "string" ? img.image : URL.createObjectURL(img.image)}
									onLoad={(e) => URL.revokeObjectURL(e.target.src)}
								/>
								<div>
									<ObjectID color={"#4f5989"}>ID: {img.id}</ObjectID>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "2px",
											marginTop: "20px",
										}}
									>
										<Pair title="Latitud" value={img.latitude} />
										<Pair title="Longitud" value={img.longitude} />
										<Pair title="Jaula" value={img.cage || "-"} />
										<Pair title="Descripción" value={img.description} />
										{img.hasFeed && <Pair title="Alimento grado" value={img.feedRate} />}
										{img.hasBacterium && <Pair title="Bacterias grado" value={img.bacteriumRate} />}
										{img.hasFeces && <Pair title="Fecas grado" value={img.fecesRate} />}
									</div>
								</div>
							</ImageContainer>
						))}
					</ImagesContainer>

					{images?.length === 0 && (
						<div
							style={{
								justifyContent: "center",
								alignItems: "center",
								display: "flex",
								fontSize: "20px",
								width: "100%",
							}}
						>
							No se han subido imágenes para este informe
						</div>
					)}
				</GeneralInfo>
			</TabContent>

			<TabContent
				value={switchState}
				index={2}
				style={{
					height: "100%",
					padding: "0 20px",
					margin: 0,
					display: "flex",
					flexDirection: "column",
					overflow: "unset",
				}}
			>
				<TableTab>
					<TableTitle>
						Tabla resumen por jaulas
						<See onClick={onOpenSummary}>Ver</See>
					</TableTitle>
					<TableContainer>
						<Table open={summaryOpen}>
							{summaryOpen && (
								<CloseButton onClick={() => setSummaryOpen(false)}>
									<CloseIcon />
								</CloseButton>
							)}

							<TableHead>
								<tr>
									<Th>Jaula</Th>	
									<Th>Profundidad</Th>
									<Th>Alimento</Th>
									<Th>Bacterias</Th>
									<Th>Fecas</Th>
								</tr>
							</TableHead>
							<tbody>
								{genTableData(images, cages).map((img) => (
									<tr key={img.cage}>
										<Td>{img.cage}</Td>
										<Td>{img.depth}</Td>
										<Td>{img.feedRate}</Td>
										<Td>{img.bacteriumRate}</Td>
										<Td>{img.fecesRate}</Td>
									</tr>
								))}
									
							</tbody>
						</Table>
					</TableContainer>
				</TableTab>
			</TabContent>
		</>
	);
};

export default FeedingBacteriumImages;
