import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import OfflineReport from "./components/Reports/Offline/OfflineReport";
import ReceptionNote from "./components/Reports/Offline/ReceptionNote";
import ConsolidatedReport from "./components/Reports/Offline/Consolidated/ConsolidatedReport";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<App />
	// <OfflineReport />
	// <ReceptionNote />
	// <ConsolidatedReport />
);
