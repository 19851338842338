import { useState, useEffect, useMemo } from "react";
import { Box, TextField, Slider } from "@mui/material";
import Map from "./Map";
import { Autocomplete } from "@mui/material";
import { daysBetween, findCentralPoint, generateBoatPoints } from "../../utils/map_helper";
import { useAuthContext } from "../../context";
import { useTheme } from "@mui/material/styles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	BottomFilters,
	CenterFilter,
	DateFilterContainer,
	DateInput,
	FilterCheckbox,
	FilterName,
	FilterRow,
	ObjectsFilter,
	ObjectsFilterContent,
	ObjectsFilterTitle,
	ObjectsFilterTitleIcon,
	ObjectsFilterTitleText,
	OpenIcon,
	TopFilters,
} from "./StyledComponents";

const MapAddorns = ({
	mapKey,
	initial_state,
	data,
	centros,
	loading,
	sx,
	eMapCenter,
	showTable,
	hideDates,
	hideSelect,
	hideShowInMap,
	initialDate,
	setInitialDate,
	endDate,
	setEndDate,
}) => {
	const [zoom, setZoom] = useState(initial_state?.initial_zoom ? initial_state.initial_zoom : 7);
	const [center, setCenter] = useState(initial_state?.initial_center ? initial_state.initial_center : undefined);
	const [cluster_radius, setClusterRadius] = useState(80);
	const [selectedCentro, setSelectedCentro] = useState(null);
	const [mapCenter, setMapCenter] = useState(null);
	const [boatData, setBoatData] = useState([]);

	const [show, setShow] = useState({
		isOpen: false,
		ROVVideos: {
			isOpen: false,
			show: true,
			new: true,
			week: true,
			twoWeeks: true,
			month: true,
		},
		DroneVideos: {
			isOpen: false,
			show: true,
			new: true,
			week: true,
			twoWeeks: true,
			month: true,
		},
		prospectionImages: true,
		feedingAndBacteriumImages: true,
	});

	const parsedCentros = useMemo(() => {
		return centros
			?.map((centro) => {
				const boat = data.boats.find((b) => b.cultivationCenter === centro.id);
				return { ...centro, boat: boat ? true : false };
			})
			.sort((a, b) => {
				if (a.salmonCompany.name < b.salmonCompany.name) {
					return -1;
				}
				if (a.salmonCompany.name > b.salmonCompany.name) {
					return 1;
				}

				return b.boat ? 1 : a.boat ? -1 : a.name > b.name ? 1 : -1;
			});
	}, [data.boats, centros]);

	useEffect(() => {
		if (eMapCenter) {
			setMapCenter(eMapCenter);
		}
	}, [eMapCenter]);

	useEffect(() => {
		if (mapCenter) {
			setMapCenter(null);
		}
	}, [mapCenter]);

	useEffect(() => {
		if (centros.length > 0) {
			const boatsInCenters = data?.boats?.filter((boat) => boat.cultivationCenter);
			const boatData = boatsInCenters.map((boat) => {
				const center = centros.filter((centro) => centro.id === boat.cultivationCenter)[0];
				if (!center) {
					return;
				}
				const coordinates = findCentralPoint(center.coordinates);
				return {
					name: boat.name,
					latitude: coordinates[0],
					longitude: coordinates[1],
				};
			});

			setBoatData(generateBoatPoints(boatData.filter((b) => b !== undefined)));
		}
	}, [centros, data.boats]);

	useEffect(() => {
		if (!selectedCentro) {
			return;
		}

		let centro_x = 0;
		let centro_y = 0;
		const coordenadas = selectedCentro.coordinates.split(";");
		coordenadas.forEach((coordenada) => {
			const [x, y] = coordenada.split(",");
			centro_x += parseFloat(x);
			centro_y += parseFloat(y);
		});
		centro_x /= coordenadas.length;
		centro_y /= coordenadas.length;
		setMapCenter([centro_x, centro_y]);
	}, [selectedCentro]);

	const filteredROVVideos = useMemo(() => {
		const videos = data.videos?.features?.filter((v) => {
			const video = v.properties;
			if (video.type !== "ROV") {
				return false;
			}
			const videoDate = new Date(video.date);
			const now = new Date();
			const days = daysBetween(videoDate, now);
			if (days >= 30 && show.ROVVideos.month) {
				return true;
			} else if (days >= 14 && days < 30 && show.ROVVideos.twoWeeks) {
				return true;
			} else if (days >= 7 && days < 14 && show.ROVVideos.week) {
				return true;
			} else if (days < 7 && show.ROVVideos.new) {
				return true;
			}
			return false;
		});
		return videos || [];
	}, [data.videos, show.ROVVideos.new, show.ROVVideos.week, show.ROVVideos.twoWeeks, show.ROVVideos.month]);

	const filteredDroneVideos = useMemo(() => {
		const videos = data.videos?.features?.filter((v) => {
			const video = v.properties;
			if (video.type !== "DRONE") {
				return false;
			}
			const videoDate = new Date(video.date);
			const now = new Date();
			const days = daysBetween(videoDate, now);
			if (days >= 30 && show.DroneVideos.month) {
				return true;
			} else if (days >= 14 && days < 30 && show.DroneVideos.twoWeeks) {
				return true;
			} else if (days >= 7 && days < 14 && show.DroneVideos.week) {
				return true;
			} else if (days < 7 && show.DroneVideos.new) {
				return true;
			}
			return false;
		});
		return videos || [];
	}, [data.videos, show.DroneVideos.new, show.DroneVideos.week, show.DroneVideos.twoWeeks, show.DroneVideos.month]);

	return (
		<Box
			sx={{
				position: "relative",
				height: "100%",
				margin: "auto",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				...sx,
			}}
		>
			<Map
				externalCenter={mapCenter}
				initial_state={{
					...initial_state,
					initial_zoom: zoom,
					initial_center: center,
					initial_cluster_radius: cluster_radius,
				}}
				data={{
					objects: data.objects,
					structures: data.structures,
					images: show.prospectionImages ? data.images : [],
					boats: boatData,
					videos: {
						type: "FeatureCollection",
						features: filteredROVVideos.concat(filteredDroneVideos),
					},
					feedingBacteriumImages: show.feedingAndBacteriumImages ? data.feedingBacteriumImages : [],
				}}
				setOuterZoom={setZoom}
				loading={loading}
				mapKey={mapKey + String(cluster_radius)}
				setOuterCenter={setCenter}
			/>
			{!loading && (
				<>
					<TopFilters>
						{!showTable && !hideDates && (
							<DateFilterContainer>
								<div>Filtrar periodo</div>
								<DateInput
									type="date"
									value={initialDate.toISOString().split("T")[0]}
									onChange={(e) => setInitialDate(new Date(e.target.value))}
								/>
								<div>a</div>
								<DateInput
									type="date"
									value={endDate.toISOString().split("T")[0]}
									onChange={(e) => setEndDate(new Date(e.target.value))}
								/>
							</DateFilterContainer>
						)}
						{!hideSelect && (
							<CenterFilter>
								<Autocomplete
									disablePortal
									id="centros"
									options={parsedCentros}
									value={selectedCentro}
									onChange={(e, value) => setSelectedCentro(value)}
									renderInput={(params) => (
										<TextField
											{...params}
											sx={{
												borderRadius: "12px",
												border: "none",
											}}
											InputProps={{
												...params?.InputProps,
												style: {
													...params?.InputProps?.style,
													borderRadius: "12px",
													border: "none",
												},
											}}
											inputProps={{
												...params?.inputProps,
												style: {
													...params?.inputProps?.style,
													padding: "5px 10px",
													borderRadius: "12px",
													border: "none",
												},
											}}
										/>
									)}
									getOptionLabel={(option) => option.name}
									// groupBy={USER_TYPE !== "Cliente" ? (option) => option.salmonCompany.name : null}
									sx={{
										width: "200px",
									}}
									renderOption={(option, value) => (
										<Box
											sx={{
												padding: "5px 15px",
												cursor: "pointer",
												"&:hover": { backgroundColor: "rgb(196,196,196)" },
											}}
											onClick={() => setSelectedCentro(value)}
											key={option.id}
										>
											{value.name}
											{value.boat && (
												<img
													src="https://rastrumapp.com/boat.svg"
													alt=""
													width={15}
													style={{ marginLeft: "7px" }}
												/>
											)}
										</Box>
									)}
								/>
							</CenterFilter>
						)}
						{!hideShowInMap && (
							<ObjectsFilter>
								<ObjectsFilterTitle
									isOpen={show.isOpen}
									onClick={() => setShow({ ...show, isOpen: !show.isOpen })}
								>
									<ObjectsFilterTitleText>Mostrar en mapa</ObjectsFilterTitleText>
									<ObjectsFilterTitleIcon>
										{show.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									</ObjectsFilterTitleIcon>
								</ObjectsFilterTitle>

								<ObjectsFilterContent isOpen={show.isOpen}>
									<FilterRow>
										<div>Videos ROV</div>
										<FilterName>
											<FilterCheckbox
												type="checkbox"
												checked={show.ROVVideos.show}
												onChange={() =>
													setShow({
														...show,
														ROVVideos: {
															...show.ROVVideos,
															show: !show.ROVVideos.show,
															new: !show.ROVVideos.show,
															week: !show.ROVVideos.show,
															twoWeeks: !show.ROVVideos.show,
															month: !show.ROVVideos.show,
														},
													})
												}
											/>
											<OpenIcon
												isOpen={show.ROVVideos.isOpen}
												setIsOpen={() =>
													setShow({
														...show,
														ROVVideos: {
															...show.ROVVideos,
															isOpen: !show.ROVVideos.isOpen,
														},
													})
												}
											/>
										</FilterName>
									</FilterRow>

									<ObjectsFilterContent isOpen={show.ROVVideos.isOpen} small>
										<FilterRow small>
											<div>Nuevos</div>
											<FilterName small>
												<FilterCheckbox
													type="checkbox"
													checked={show.ROVVideos.new}
													onChange={() =>
														setShow({
															...show,
															ROVVideos: { ...show.ROVVideos, new: !show.ROVVideos.new },
														})
													}
												/>
											</FilterName>
										</FilterRow>
										<FilterRow small>
											<div>1 semana</div>
											<FilterName small>
												<FilterCheckbox
													type="checkbox"
													checked={show.ROVVideos.week}
													onChange={() =>
														setShow({
															...show,
															ROVVideos: {
																...show.ROVVideos,
																week: !show.ROVVideos.week,
															},
														})
													}
												/>
											</FilterName>
										</FilterRow>
										<FilterRow small>
											<div>2 semanas</div>
											<FilterName small>
												<FilterCheckbox
													type="checkbox"
													checked={show.ROVVideos.twoWeeks}
													onChange={() =>
														setShow({
															...show,
															ROVVideos: {
																...show.ROVVideos,
																twoWeeks: !show.ROVVideos.twoWeeks,
															},
														})
													}
												/>
											</FilterName>
										</FilterRow>
										<FilterRow small>
											<div>1 mes</div>
											<FilterName small>
												<FilterCheckbox
													type="checkbox"
													checked={show.ROVVideos.month}
													onChange={() =>
														setShow({
															...show,
															ROVVideos: {
																...show.ROVVideos,
																month: !show.ROVVideos.month,
															},
														})
													}
												/>
											</FilterName>
										</FilterRow>
									</ObjectsFilterContent>

									<FilterRow>
										<div>Videos Drone</div>
										<FilterName>
											<FilterCheckbox
												type="checkbox"
												checked={show.DroneVideos.show}
												onChange={() =>
													setShow({
														...show,
														DroneVideos: {
															...show.DroneVideos,
															show: !show.DroneVideos.show,
															new: !show.DroneVideos.show,
															week: !show.DroneVideos.show,
															twoWeeks: !show.DroneVideos.show,
															month: !show.DroneVideos.show,
														},
													})
												}
											/>
											<OpenIcon
												isOpen={show.DroneVideos.isOpen}
												setIsOpen={() =>
													setShow({
														...show,
														DroneVideos: {
															...show.DroneVideos,
															isOpen: !show.DroneVideos.isOpen,
														},
													})
												}
											/>
										</FilterName>
									</FilterRow>

									<ObjectsFilterContent isOpen={show.DroneVideos.isOpen} small>
										<FilterRow small>
											<div>Nuevos</div>
											<FilterName small>
												<FilterCheckbox
													type="checkbox"
													checked={show.DroneVideos.new}
													onChange={() =>
														setShow({
															...show,
															DroneVideos: {
																...show.DroneVideos,
																new: !show.DroneVideos.new,
															},
														})
													}
												/>
											</FilterName>
										</FilterRow>
										<FilterRow small>
											<div>1 semana</div>
											<FilterName small>
												<FilterCheckbox
													type="checkbox"
													checked={show.DroneVideos.week}
													onChange={() =>
														setShow({
															...show,
															DroneVideos: {
																...show.DroneVideos,
																week: !show.DroneVideos.week,
															},
														})
													}
												/>
											</FilterName>
										</FilterRow>
										<FilterRow small>
											<div>2 semanas</div>
											<FilterName small>
												<FilterCheckbox
													type="checkbox"
													checked={show.DroneVideos.twoWeeks}
													onChange={() =>
														setShow({
															...show,
															DroneVideos: {
																...show.DroneVideos,
																twoWeeks: !show.DroneVideos.twoWeeks,
															},
														})
													}
												/>
											</FilterName>
										</FilterRow>
										<FilterRow small>
											<div>1 mes</div>
											<FilterName small>
												<FilterCheckbox
													type="checkbox"
													checked={show.DroneVideos.month}
													onChange={() =>
														setShow({
															...show,
															DroneVideos: {
																...show.DroneVideos,
																month: !show.DroneVideos.month,
															},
														})
													}
												/>
											</FilterName>
										</FilterRow>
									</ObjectsFilterContent>

									<FilterRow>
										<div>Prospección de Fondo</div>
										<FilterName>
											<FilterCheckbox
												type="checkbox"
												checked={show.prospectionImages}
												onChange={() =>
													setShow({ ...show, prospectionImages: !show.prospectionImages })
												}
												style={{ marginRight: "23px" }}
											/>
										</FilterName>
									</FilterRow>

									<FilterRow>
										<div>Alimentación y Bacterias</div>
										<FilterName>
											<FilterCheckbox
												type="checkbox"
												checked={show.feedingAndBacteriumImages}
												onChange={() =>
													setShow({
														...show,
														feedingAndBacteriumImages: !show.feedingAndBacteriumImages,
													})
												}
												style={{ marginRight: "23px" }}
											/>
										</FilterName>
									</FilterRow>
								</ObjectsFilterContent>
							</ObjectsFilter>
						)}
					</TopFilters>
					{showTable && !hideDates && (
						<BottomFilters>
							<DateFilterContainer>
								<div>Filtrar periodo</div>
								<DateInput
									type="date"
									value={initialDate.toISOString().split("T")[0]}
									onChange={(e) => setInitialDate(new Date(e.target.value))}
								/>
								<div>a</div>
								<DateInput
									type="date"
									value={endDate.toISOString().split("T")[0]}
									onChange={(e) => setEndDate(new Date(e.target.value))}
								/>
							</DateFilterContainer>
						</BottomFilters>
					)}
				</>
			)}
			{!loading && (
				<Box
					sx={{
						fontSize: "11px",
						height: "70px",
						padding: "10px",
						width: "180px",
						position: "absolute",
						bottom: "85px",
						right: "75px",
						zIndex: 1000,
						backgroundColor: "white",
						border: "1px solid rgb(196,196,196)",
						borderRadius: 2,
						"@media (maxWidth: 600px)": {
							bottom: "100px",
						},
					}}
				>
					Radio de Clustering
					<Slider
						size="small"
						value={cluster_radius}
						onChange={(_, value) => setClusterRadius(value)}
						min={1}
						max={200}
					/>
				</Box>
			)}
		</Box>
	);
};

export default MapAddorns;
