import { useTheme, styled } from "@mui/material/styles";

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	width: "100%",
}));

const Label = styled("div")(({ theme }) => ({
	marginLeft: "7px",
}));

const Radio = styled("div", {
	shouldForwardProp: (prop) => prop !== "disabled",
})(({ theme, disabled }) => ({
	marginRight: "5px",
	borderRadius: "50%",
	padding: "4px",
	border: `1px solid #d7d7d7`,
	cursor: disabled ? "not-allowed" : "pointer",
}));

const InputContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "disabled",
})(({ theme, disabled }) => ({
	display: "flex",
	alignItems: "center",
	marginBottom: "10px",
	"&:last-child": {
		marginBottom: 0,
	},
	opacity: disabled ? 0.5 : 1,
}));

const RadioName = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	marginRight: "10px",
	width: "40%",
}));

const RadioCircle = styled("div", {
	shouldForwardProp: (prop) => prop !== "isChecked",
})(({ theme, isChecked }) => ({
	width: "9px",
	height: "9px",
	borderRadius: "50%",
	backgroundColor: isChecked ? "#22ACA4" : "white",
}));



/**
 * Componente que muestra input tipo radio.
 * @param label Texto a mostrar en el label.
 * @param options Opciones del radio.
 * @param value Valor seleccionado.
 * @param onChange Función que se ejecuta al cambiar el valor.
 * @param sx Estilos personalizados.
 * @param disabled Indica si el input está deshabilitado.
 * @returns JSX.Element
 */
const RadioGroup = ({ label, options, value, onChange, sx, disabled }) => {
	return (
		<Container style={sx}>
			<RadioName>{label}</RadioName>

			<div>
				{options.map((option, i) => (
					<InputContainer key={i} disabled={disabled}>
						<Radio onClick={() => !disabled && onChange(option.name)} disabled={disabled}>
							<RadioCircle isChecked={value === option.name} />
						</Radio>
						<Label>{option.label}</Label>
					</InputContainer>
				))}
			</div>
		</Container>
	);
};

export default RadioGroup;
