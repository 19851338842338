import { useEffect, useContext, useState } from "react";
import { URLS } from "../../../global";
import { AuthContext } from "../../../context/AuthContext";
import { Box, Modal } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Preview from "./Preview";
import { Table } from "../../Table";
import IconButtons from "../../Icons/IconButtons";
import DownloadIcon from "../../Icons/DownloadIcon";
import EyeIcon from "../../Icons/EyeIcon";
import DownloadItem from "../DownloadItem";

const ReceptionNote = () => {
	const { axiosInstance } = useContext(AuthContext);
	const [receptionNote, setReceptionNote] = useState([]);
	const [downloadState, setDownloadState] = useState({
		downloading: false,
	});
	const [selectedReport, setSelectedReport] = useState(null);
	const theme = useTheme();

	useEffect(() => {
		axiosInstance.get(URLS.RECEPTION_NOTE).then((response) => {
			setReceptionNote(response.data);
		});
	}, [axiosInstance]);

	const downloadPDF = (ID, onClose) => {
		setDownloadState({
			downloading: true,
		});
		axiosInstance.get(URLS.RECEPTION_NOTE + ID + "/get_pdf/", { responseType: "blob" }).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			const fileName = `Nota_de_salida_${ID}.pdf`;
			link.href = url;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setDownloadState({
				downloading: false,
			});
			onClose();
		});
	};

	const downloadHTML = (ID, onClose) => {
		setDownloadState({
			downloading: true,
		});
		axiosInstance.get(URLS.RECEPTION_NOTE + ID + "/get_html/", { responseType: "blob" }).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			const fileName = `Nota_de_salida_${ID}.html`;
			link.href = url;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setDownloadState({
				downloading: false,
			});
			onClose();
		});
	};

	const columns = [
		{ field: "id", headerName: "ID", sortable: true },
		{ field: "date", headerName: "Fecha", sortable: true },
		{ field: "ROVOperatorName", headerName: "Operador ROV", sortable: true },
		{ field: "objectsCount", headerName: "Cantidad de objetos", sortable: true },
		{ field: "salmonCompanyName", headerName: "Empresa Productora", sortable: true },
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", gap: "10px", justifyContent: "end" }}>
					<IconButtons icon={<EyeIcon />} onClick={() => onRowClick(row)} />
					<DownloadItem id={row.id} downloadHTML={downloadHTML} downloadPDF={downloadPDF} disabled={downloadState.downloading} />
				</div>
			),
		},
	];

	const onRowClick = (row) => {
		setSelectedReport(row);
	};

	return (
		<>
			<Table
				columns={columns}
				rows={receptionNote}
				sorting={{
					field: "date",
					sort: "desc",
				}}
			/>
			<Modal open={selectedReport !== null} onClose={() => setSelectedReport(null)}>
				<Preview receptionNote={selectedReport} />
			</Modal>
		</>
	);
};

export default ReceptionNote;
