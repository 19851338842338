export const validateNumberInput = (value) => {
  let new_value = value;

  // only allow numbers, minuses, commas and dots
  let non_allowed_chars = /[^0-9.,-]/g;

  new_value = new_value.replace(non_allowed_chars, "");

  // replace commas with dots
  new_value = new_value.replace(/,/g, ".");

  // if the value has multiple dots, remove all but the first one
  const dots = new_value.match(/\./g);
  if (dots && dots.length > 1) {
    let first_dot_index = new_value.indexOf(".");
    new_value = new_value.replace(/\./g, (m, i) => (i === first_dot_index ? m : ""));
  }

  // if the value has minuses, remove all but the leading one
  const minuses = new_value.match(/-/g);
  if (minuses && minuses.length >= 1) {
    new_value = new_value.replace(/-/g, (m, i) => (i === 0 ? m : ""));
  }

  return new_value;
};
