import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import UploadImages from './UploadImages';

const Images = ({ images, setImages, imagesErrors, requiredImages }) => {
    return (
        <>
            <Box sx={{ marginBottom: 3 }}>
                Imágenes {images.length < requiredImages ? `(Faltan ${requiredImages - images.length})` : ''}
                <UploadImages
                    images={images}
                    setImages={setImages}
                />
            </Box>

            {
                imagesErrors.length > 0 &&
                <Box sx={{ marginBottom: 3 }}>
                    <Typography>Imágenes con errores: {imagesErrors.length}</Typography>
                    <DataGrid
                        rows={imagesErrors}
                        columns={[
                            {
                                'field': 'name',
                                'headerName': 'Nombre',
                                'flex': 1,
                                'align': 'center',
                            }, {
                                'field': 'reason',
                                'headerName': 'Motivo',
                                'flex': 1,
                                'align': 'center',
                            }
                        ]
                        }
                        getRowId={(row) => row.name}
                    />
                </Box>
            }
        </>
    )
}

export default Images;