import { Stack, Box, Dialog, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context";
import { URLS } from "../../global";
import CloseIcon from "@mui/icons-material/Close";
import BoatLogForm from "./BoatLogForm";
import Takeover from "./Takeover";
import AdditionalCycle from "./AdditionalCycle";
import { IconButton } from "@mui/material";

const EventEdit = ({ boatLog, onClose, open, setOpen, setBoatLog }) => {
	const { axiosInstance } = useAuthContext();
	const [activeStep, setActiveStep] = useState(0);
	const [boatLogData, setBoatLogData] = useState(undefined);
	const [reload, setReload] = useState(false);
	const handleStepClick = (step) => {
		if (!boatLog) return;
		setActiveStep(step);
	};

	useEffect(() => {
		if (boatLog) setReload(true);
		else {
			setBoatLogData(undefined);
			setActiveStep(0);
		}
	}, [boatLog]);

	useEffect(() => {
		if (reload) {
			axiosInstance
				.get(URLS.BOAT_LOGS + boatLog + "/")
				.then((response) => {
					setBoatLogData(response.data);
					setOpen(true);
					setReload(false);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [reload, axiosInstance, boatLog, setOpen]);

	return (
		<>
			<Dialog open={open} fullWidth maxWidth="lg">
				<DialogTitle sx={{ borderBottom: "1px solid #ccc", padding: "20px", display: "flex" }}>
					<Stack direction="row" justifyContent="space-between" sx={{ width: "100%", alignItems: "center" }}>
						<Box
							onClick={() => handleStepClick(0)}
							sx={{
								cursor: boatLog ? "pointer !important" : "none",
								color: activeStep === 0 ? "#22ACA4" : "inherit",
							}}
						>
							Agregar Ciclo
						</Box>
						<Box
							onClick={() => handleStepClick(1)}
							sx={{
								cursor: boatLog ? "pointer !important" : "none",
								color: activeStep === 1 ? "#22ACA4" : "inherit",
							}}
						>
							Agregar Relevo
						</Box>
						<Box
							onClick={() => handleStepClick(2)}
							sx={{
								cursor: boatLog ? "pointer !important" : "none",
								color: activeStep === 2 ? "#22ACA4" : "inherit",
							}}
						>
							Agregar Ciclo Adicional
						</Box>
					</Stack>
					<IconButton
						onClick={() => {
							setOpen(false);
							onClose();
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				{activeStep === 0 && <BoatLogForm setBoatLogId={setBoatLog} boatLogData={boatLogData} />}
				{activeStep === 1 && <Takeover boatLog={boatLogData} setReload={setReload} />}
				{activeStep === 2 && <AdditionalCycle boatLog={boatLogData} setReload={setReload} />}
			</Dialog>
		</>
	);
};

export default EventEdit;
