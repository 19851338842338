import { Box, Modal } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { AuthContext, MessagesContext } from "../../../context";
import { URLS } from "../../../global";
import ConsolidatedReport from "./ConsolidatedReport";
import ReportPDFViewer from "./ReportPDFViewer";
import RenderEstado from "../StateRender";

import { Table } from "../../Table";
import IconButtons from "../../Icons/IconButtons";
import EyeIcon from "../../Icons/EyeIcon";
import EditIcon from "../../Icons/EditIcon";
import DownloadIcon from "../../Icons/DownloadIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import DownloadItem from "../DownloadItem";

const Reports = ({ initialId, setInitialId, reloadData }) => {
	const { axiosInstance, USER_TYPE } = useContext(AuthContext);
	const [reports, setReports] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedReport, setSelectedReport] = useState(null);
	const [isConsolidatedModalOpen, setIsConsolidatedModalOpen] = useState(false);
	const [reloadNeeded, setReloadNeeded] = useState(true);
	const [downloadState, setDownloadState] = useState({
		downloading: false,
	});
	const { addMessage } = useContext(MessagesContext);

	useEffect(() => {
		if (!reloadNeeded && !reloadData) return;
		setReloadNeeded(false);
		setLoading(true);
		axiosInstance
			.get(URLS.CONSOLIDATED_REPORTS)
			.then((res) => {
				setReports(
					res.data.map((report) => {
						return {
							...report,
							id: report.id,
							initialDate: report.startDate,
							finalDate: report.endDate,
							center: report.cultivationCenter,
							state: report.state,
							byQuantityImage: report.quantityObjectTypeImage,
							byQuantityVolumeImage: report.quantityObjectTypeVolumeImage,
							byMeasurableImage: report.measurableObjectTypeImage,
							byMeasurableVolumeImage: report.measurableObjectTypeVolumeImage,
							description: report.comments,
						};
					})
				);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [axiosInstance, reloadNeeded, reloadData]);

	const downloadPDF = (ID, onClose) => {
		setDownloadState({
			downloading: true,
		});
		axiosInstance.get(URLS.CONSOLIDATED_REPORTS + ID + "/get_pdf/", { responseType: "blob" }).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			const contentDisposition = response.headers["content-disposition"];
			let fileName = "Informe"; // Default name if header is missing
			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(/filename='(.+)'/);
				if (fileNameMatch.length === 2) {
					fileName = fileNameMatch[1];
				}
			}
			link.href = url;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setDownloadState({
				downloading: false,
			});
			onClose();
		});
	};

	const downloadHTML = (ID, onClose) => {
		setDownloadState({
			downloading: true,
		});
		axiosInstance.get(URLS.CONSOLIDATED_REPORTS + ID + "/get_html/", { responseType: "blob" }).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			let fileName = "Informe"; // Default name if header is missing

			const contentDisposition = response.headers["content-disposition"];

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(/filename='(.+)'/);
				if (fileNameMatch.length === 2) {
					fileName = fileNameMatch[1];
				}
			}
			link.href = url;
			link.setAttribute("download", fileName);
			link.click();
			setDownloadState({
				downloading: false,
			});
			onClose();
		});
	};

	const handleDelete = (deleteId) => {
		axiosInstance.delete(`${URLS.CONSOLIDATED_REPORTS}${deleteId}/`).then((response) => {
			addMessage("Se ha creado una petición de elimnación para el consolidado", "success");
		});
	};

	const columns = [
		{ field: "id", headerName: "ID", sortable: true },
		{ field: "initialDate", headerName: "Fecha de Inicio", sortable: true },
		{ field: "finalDate", headerName: "Fecha de Fin", sortable: true },
		{ field: "cultivationCenter_name", headerName: "Centro de Cultivo", sortable: true },
		{
			field: "type",
			headerName: "Tipo",
			renderCell: (row) => (row.type === "RRN" ? "RRN" : "PROSPECCIÓN"),
		},
	]
		.concat(
			USER_TYPE !== "Cliente"
				? [
						{
							field: "state",
							headerName: "Estado",
							sortable: true,
							renderCell: (row) => <RenderEstado row={row} url={URLS.CONSOLIDATED_REPORTS} setLoading={setLoading} setRows={setReports} />,
						},
				  ]
				: []
		)
		.concat([
			{
				field: "actions",
				renderCell: (row) => (
					<div style={{ display: "flex", gap: "10px", justifyContent: "end" }}>
						<IconButtons icon={<EyeIcon />} onClick={() => onRowClick(row)} />
						{USER_TYPE !== "Cliente" && (
							<IconButtons
								icon={<EditIcon />}
								onClick={() => {
									setIsEditModalOpen(true);
									setSelectedReport({ ...row, edit: true });
								}}
								disabled={row.state === "Revisado"}
							/>
						)}
						
						<DownloadItem
							id={row.id}
							downloadHTML={downloadHTML}
							downloadPDF={downloadPDF}
							disabled={downloadState.downloading}
						/>
						{USER_TYPE === "Admin" && (
							<IconButtons variant="danger" icon={<DeleteIcon />} onClick={() => handleDelete(row.id)} />
						)}
					</div>
				),
			},
		]);

	const onRowClick = (row) => {
		setSelectedReport({ ...row, edit: true });
		setIsConsolidatedModalOpen(true);
	};

	useEffect(() => {
		if (initialId === null) return;
		const index = reports.findIndex((row) => row.id === parseInt(initialId));
		if (index === -1) return;
		setSelectedReport({ ...reports[index] });
		setIsConsolidatedModalOpen(true);
		setInitialId(null);
	}, [initialId, reports, setInitialId]);

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return (
		<>
			<Modal
				open={isConsolidatedModalOpen}
				onClose={() => {
					setIsConsolidatedModalOpen(false);
				}}
			>
				<Box>
					<ReportPDFViewer report={selectedReport} />
				</Box>
			</Modal>
			<Modal
				open={isEditModalOpen}
				onClose={() => {
					setIsEditModalOpen(false);
				}}
			>
				<Box>
					<ConsolidatedReport
						initialData={selectedReport}
						onClose={() => {
							setIsEditModalOpen(false);
							setReloadNeeded(true);
						}}
					/>
				</Box>
			</Modal>

			<Table
				columns={columns}
				rows={reports}
				loading={loading}
				sorting={{
					field: "finalDate",
					sort: "desc",
				}}
			/>
		</>
	);
};

export default Reports;
