// Cambiar la apariencia de los informes recién añadidos
export const StyledDataGrid = {
	width: "100%",
	maxWidth: "100%",
	overflow: "hidden",
	"& .super-app-theme--Recent": {
		position: "relative",
		width: "100%",
		maxWidth: "100%",
		"&::before": {
			content: '"nuevo!"',
			position: "absolute",
			top: 8,
			right: 21,
			backgroundColor: "red",
			color: "white",
			padding: "1px 2px",
			borderRadius: "5px",
			animation: "shake 7s infinite", // Apply the shake animation
		},
	},
	"& .super-app-theme--Old": {
		backgroundColor: "white",
	},
	"@keyframes shake": {
		"0%": { transform: "translateX(0)" },
		"1%": { transform: "translateX(5px) rotate(5deg)" },
		"2%": { transform: "translateX(-5px) rotate(-5deg)" },
		"3%": { transform: "translateX(5px) rotate(5deg)" },
		"4%": { transform: "translateX(-5px) rotate(-5deg)" },
		"5%": { transform: "translateX(0)" },
	},
};

export const isWithinOneDay = (fecha_creacion) => {
	const currentDate = new Date();
	const reportDate = new Date(fecha_creacion);
	const timeDifference = Math.abs(currentDate - reportDate);
	const oneDayInMillis = 24 * 60 * 60 * 1000; // milliseconds in a day
	return timeDifference < oneDayInMillis;
};

// Estilos del modal
export const BoxStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "60vw",
	height: "90vh",
	overflow: "auto",
	maxHeight: "95vh",
	borderRadius: "10px",
	backgroundColor: "white",
	boxShadow: 24,
};

export const initialData = {
	cultivationCenter: null, // Nombre del centro
	date: "", // Fecha del informe
	endDate: "", // Fecha de término del informe (solo para informes de varios días)
	boat: "", // Embarcación
	reportType: null, // Tipo de informe
	comments: "", // Comentarios
	notify: null, // Debe ser notificado
	imagesList: [], // Lista de imágenes si corresponde según el tipo de informe
	geoImagesList: [], // Lista de imágenes georeferenciadas para el informe de prospección de fondo
	geoImagesDeleted: [], // Lista de imágenes georeferenciadas a eliminar para el informe de prospección de fondo
	imagesDeleted: [], // Lista de imágenes a eliminar si corresponde según el tipo de informe
	objectsList: [], // Lista de objetos si corresponde según el tipo de informe
	objectsDeleted: [], // Lista de objetos eliminados si corresponde según el tipo de informe
	map: null, // Mapa si corresponde según el tipo de informe
	beaconObjects: [], // Lista desbalizados (Solo para informes de día normal)
	deletedBeaconObjects: [], // Lista de objetos balizados eliminados
	reportId: -1, // Si el informe ya fue subido, id del informe en la db
	initialCounter: 0,
	videos: [], // Lista de videos
	videosDeleted: [], // Lista de videos a eliminar
	feedingBacteriumImages: [], // Lista de imágenes para el informe de alimentación y bacterias
	feedingBacteriumImagesDeleted: [], // Lista de imágenes a eliminar para el informe de alimentación y bacterias
};
