import React, { useEffect } from "react";

// mui components
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";

// components
import { PolygonForm } from "./";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";
import IconButtons from "../../Icons/IconButtons";
import EditIcon from "../../Icons/EditIcon";
import DeleteIcon from "../../Icons/DeleteIcon";

/**
 * Componente para mostrar el detalle de un poligono. Debido a que los poligonos solamente tienen
 * coordenadas, se utiliza el componente PolygonForm para mostrar los datos y permitir su edicion.
 * @param {object} props
 * @param {object} props.data Datos del poligono
 * @param {function} props.onClose Funcion para cerrar el detalle
 * @param {number} props.centerId ID del centro al que pertenece el poligono
 */
export const PolygonDetail = ({ data, onClose, centerId }) => {
	const theme = useTheme();

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
					padding: "15px 25px",
				}}
			>
				<div
					style={{
						color: "#212121",
						fontSize: "15px",
						fontWeight: 500,
					}}
				>
					{data?.id ? "Editar polígono" : "Agregar polígono"}
				</div>
				<div
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						fontWeight: 700,
						color: theme.palette.brandColors.primary,
						fontSize: "13px",
					}}
					onClick={onClose}
				>
					Volver al listado
				</div>
			</Box>

			{data && <PolygonForm data={data} onFinished={onClose} centerId={centerId} />}
		</Box>
	);
};

/**
 * Componente para mostrar una lista de poligonos
 * @param {object} props
 * @param {number} props.centerId ID del centro al que pertenecen los poligonos
 * @param {function} props.openDetail Funcion para abrir el detalle de un poligono
 * @param {boolean} props.dataReload Bandera para recargar los datos de la tabla
 * @param {function} props.setDataReload Funcion para cambiar la bandera de recarga de datos
 */
export const PolygonsList = ({ centerId, openDetail = () => {}, dataReload = false, setDataReload = () => {} }) => {
	const [rows, setRows] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	React.useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(`${URLS.CENTERS}${centerId}/polygons/`)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al cargar los poligonos del centro", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [centerId, axiosInstance, addMessage, dataReload, setDataReload]);

	const handleDelete = (id) => {
		if (!window.confirm("¿Está seguro de eliminar este poligono?")) return;

		setLoading(true);
		axiosInstance
			.delete(`${URLS.POLYGONS}${id}/`)
			.then((_res) => {
				addMessage("Poligono eliminado exitosamente", "success");
				setDataReload(true);
			})
			.catch((err) => {
				addMessage("Error al eliminar el poligono", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	let columns = [
		{ field: "id", headerName: "ID", flex: 0.1 },
		{
			field: "name",
			headerName: "Nombre",
			flex: 0.3,
			description: "Nombre del poligono",
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
					<IconButtons icon={<EditIcon />} onClick={() => openDetail(row.id, row)} />
					<IconButtons icon={<DeleteIcon />} onClick={() => handleDelete(row.id)} variant="danger" />
				</div>
			),
		},
	];

	return <Table rows={rows} columns={columns} loading={loading} />;
};

/**
 * Componente para enlistar, agregar y editar poligonos de un centro
 * @param {object} props
 * @param {number} props.centerId ID del centro al que pertenecen los poligonos
 */
const Polygons = ({ centerId, setHide }) => {
	const [dataReload, setDataReload] = React.useState(true);
	const [centerPolygon, setCenterPolygon] = React.useState([]);
	const [detailData, setDetailData] = React.useState({
		open: false,
		data: null,
	});

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	React.useEffect(() => {
		const getCenterPolygon = async () => {
			if (!centerId) return;

			axiosInstance
				.get(`${URLS.CENTERS}${centerId}/`)
				.then((response) => {
					let coordinates = response.data.coordinates;
					let points = [];
					coordinates.split(";").forEach((point) => {
						let [lat, lon] = point.split(",");
						lat = parseFloat(lat);
						lon = parseFloat(lon);
						points.push([lat, lon]);
					});
					setCenterPolygon(points);
				})
				.catch((error) => {
					addMessage("Error al cargar las coordenadas del centro", "error");
				});
		};

		getCenterPolygon();
	}, [axiosInstance, addMessage, centerId]);

	const openDetail = (_id, data = null) => {
		setDetailData({
			open: true,
			data: {
				...data,
				centerPolygon: centerPolygon,
			},
		});
	};

	const closeDetail = () => {
		setDetailData({ open: false, data: null });
		setDataReload(true);
	};

	useEffect(() => {
		setHide(detailData.open);
	}, [detailData.open, setHide]);

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			{detailData.open && <PolygonDetail data={detailData.data} onClose={closeDetail} centerId={centerId} />}

			{!detailData.open && (
				<div style={{ padding: "35px 0" }}>
					<div style={{ padding: "0 34px 20px" }}>
						<GeneralButton onClick={openDetail} text="Agregar polígono" />
					</div>

					<PolygonsList
						centerId={centerId}
						openDetail={openDetail}
						dataReload={dataReload}
						setDataReload={setDataReload}
					/>
				</div>
			)}
		</Box>
	);
};

export default Polygons;
