

const EditIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				d="M16 3.4212C15.9933 3.95255 15.6947 4.68699 15.0911 5.29014C11.9063 8.47565 8.71889 11.6586 5.53404 14.8446C5.40985 14.9687 5.26667 15.0344 5.09989 15.0698C3.68657 15.3703 2.27325 15.6719 0.86044 15.9745C0.26155 16.1027 -0.103327 15.736 0.0259964 15.138C0.334936 13.7075 0.642335 12.2771 0.947169 10.8457C0.978987 10.6954 1.04827 10.5733 1.15552 10.4661C4.2793 7.34628 7.40513 4.22796 10.5238 1.10298C10.9328 0.693187 11.3731 0.347505 11.9284 0.162868C13.9555 -0.510032 16.0021 0.965527 15.999 3.42069L16 3.4212ZM1.59635 14.5169C1.6215 14.5112 1.65229 14.5041 1.68359 14.4974C2.64633 14.2902 3.60856 14.0809 4.57182 13.8783C4.6919 13.8532 4.78222 13.7988 4.86587 13.7147C7.20498 11.3755 9.54461 9.03725 11.8873 6.70159C11.991 6.59799 12.0033 6.53952 11.8914 6.42925C11.1124 5.66095 10.339 4.88702 9.56975 4.10949C9.46814 4.00691 9.41374 4.00845 9.31213 4.11C6.97508 6.4513 4.63494 8.78902 2.29429 11.1267C2.20499 11.2155 2.14752 11.3119 2.12134 11.4375C2.01357 11.9632 1.89759 12.4874 1.78469 13.0121C1.68924 13.4557 1.59481 13.8994 1.49936 14.343C1.4814 14.4266 1.45009 14.5153 1.59635 14.5169ZM14.7196 3.04936C14.7129 2.95089 14.7201 2.81651 14.6878 2.68419C14.5241 2.0236 14.1289 1.56098 13.4761 1.36711C12.8059 1.16811 12.1793 1.2871 11.6595 1.77844C11.2504 2.16464 10.8625 2.57238 10.4596 2.96474C10.3647 3.05757 10.3626 3.11604 10.4581 3.21092C11.2392 3.98486 12.0171 4.76187 12.791 5.54299C12.8957 5.64864 12.9563 5.63685 13.0528 5.53735C13.4269 5.15218 13.8087 4.77418 14.1874 4.39414C14.5482 4.03204 14.7345 3.59866 14.7206 3.04936H14.7196Z"
				fill="white"
			/>
		</svg>
	);
};

export default EditIcon;