import { useState } from "react";
import ObjectGrid from "./ObjectGrid";
import { Box, CircularProgress } from "@mui/material";
import ObjectInfo from "./ObjectInfo";
import ObjectExit from "./ObjectExit";

const steps = {
	LISTA_OBJETOS: 0,
	OBJETO: 1,
	NOTA_SALIDA: 2,
};

const ObjectController = ({
	rows,
	setRows,
	focusObject,
	loading,
	setMapObjects,
	selectedObject,
	setSelectedObject,
	selectMultipleObjects,
	images,
	filteredImagePoints,
	setFilteredImagePoints,
	bacteriumPoints,
	setFilteredBacteriumPoints,
	setMapCenter
}) => {
	const [step, setStep] = useState(steps.LISTA_OBJETOS);
	const handleSelectObject = (object) => {
		setSelectedObject(object);
		setStep(steps.OBJETO);
	};

	const handleBackToObjects = () => {
		setStep(steps.LISTA_OBJETOS);
		setSelectedObject(null);
	};

	const handleBackToObjectInfo = () => {
		setStep(steps.OBJETO);
	};

	return (
		<Box
			sx={{
				width: "100%",
				minHeight: "90%",
				maxHeight: "100%",
				height: "100%",
				margin: "auto",
				display: "flex",
				flexDirection: "column",
			}}
		>
			{loading ? (
				<Box
					sx={{
						height: "100%",
						justifyContent: "center",
						width: "100%",
						alignItems: "center",
						display: "flex",
						margin: "auto",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					{step === steps.LISTA_OBJETOS ? (
						<ObjectGrid
							rows={rows}
							loading={loading}
							setRows={setRows}
							setSelectedObject={handleSelectObject}
							selectMultipleObjects={selectMultipleObjects}
							steps={steps}
							setStep={setStep}
							setMapObjects={setMapObjects}
							images={images}
							filteredImagePoints={filteredImagePoints}
							setFilteredImagePoints={setFilteredImagePoints}
							bacteriumPoints={bacteriumPoints}
							setFilteredBacteriumPoints={setFilteredBacteriumPoints}
							setMapCenter={setMapCenter}
						/>
					) : null}
					{step === steps.OBJETO ? (
						<ObjectInfo
							handleBackToObjects={handleBackToObjects}
							selectedObject={selectedObject}
							focusObject={focusObject}
							steps={steps}
							setStep={setStep}
							setM
						/>
					) : null}
					{step === steps.NOTA_SALIDA ? (
						<ObjectExit
							handleBackToObjects={handleBackToObjects}
							handleBackToObjectInfo={handleBackToObjectInfo}
							selectedObject={selectedObject}
							setStep={setStep}
						/>
					) : null}
				</>
			)}
		</Box>
	);
};

export default ObjectController;
