import { useEffect, useState } from "react";
import {
	Box,
	Button,
	Modal,
	DialogTitle,
	Stack,
	Typography,
	IconButton,
	DialogContent,
	TextField,
	Autocomplete,
	Checkbox,
	Tooltip,
	FormControl,
} from "@mui/material";
import { useAuthContext, useMessagesContext } from "../../../context";
import { URLS } from "../../../global";
import { useTheme } from "@mui/material/styles";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import BoatIcon from "../../Icons/BoatIcon";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";
import { BoxStyle } from "../../Reports/ReportConstants";
import TextInput from "../../Inputs/TextInput";

const ListBoats = ({ boats, selectBoat, loading }) => {
	const columns = [
		{ field: "name", headerName: "Nombre", sortable: true },
		{ field: "registrationNumber", headerName: "Matrícula", sortable: true },
		{ field: "callSign", headerName: "Señal de Llamado", sortable: true },
		{
			field: "isActive",
			headerName: "Activo",
			renderCell: (row) => (row.isActive === true ? "Sí" : "No"),
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<GeneralButton
						text="Editar"
						onClick={() => selectBoat(row)}
						variant="small"
						icon={<BoatIcon color="white" />}
					/>
				</div>
			),
		},
	];

	return <Table rows={boats} columns={columns} loading={loading} />;
};

const BoatForm = ({ boat, setDataReload, open, handleClose, centers }) => {
	const [currentData, setCurrentData] = useState(boat);
	const [loading, setLoading] = useState(false);
	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();
	const theme = useTheme();

	useEffect(() => {
		setCurrentData(boat);
	}, [boat]);

	const handleSave = (e) => {
		e.preventDefault();
		if (loading) return;
		if (currentData.name === "" || currentData.registrationNumber === "" || currentData.callSign === "") {
			addMessage("Por favor, complete todos los campos", "error");
			return;
		}
		setLoading(true);
		let url = URLS.BOATS;
		let method = "POST";
		if (!currentData.isCreating) {
			url += `${currentData.id}/`;
			method = "PATCH";
		}
		currentData.isActive = currentData.isActive === true || currentData.isActive === "True" ? true : false;
		currentData.startDate = currentData.startDate || null;
		currentData.endDate = currentData.endDate || null;
		axiosInstance({
			method: method,
			url: url,
			data: currentData,
		})
			.then((response) => {
				addMessage("Embarcación guardada correctamente", "success");
				setDataReload(true);
				handleClose();
			})
			.catch((error) => {
				addMessage("Error al guardar la embarcación", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (event) => {
		if (event.target.name === "isActive") {
			setCurrentData({ ...currentData, [event.target.name]: event.target.checked });
			return;
		}
		setCurrentData({ ...currentData, [event.target.name]: event.target.value });
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<div style={{ ...BoxStyle, height: "auto" }}>
				<div
					style={{
						position: "relative",
						borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
						padding: "15px 25px",
					}}
				>
					<div
						style={{
							color: "#212121",
							fontSize: "15px",
							fontWeight: 500,
						}}
					>
						{boat?.isCreating ? "Crear" : "Editar"} Embarcación
					</div>

					<IconButton onClick={handleClose} sx={{ position: "absolute", right: 0, top: 0 }}>
						<CloseIcon />
					</IconButton>
				</div>

				<div style={{ padding: "25px 35px" }}>
					<form onSubmit={handleSave} style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
						<Stack direction="row" spacing={2}>
							<TextInput
								label="Nombre"
								InputLabelProps={{ shrink: true }}
								name={"name"}
								value={currentData["name"] || ""}
								onChange={handleChange}
								fullWidth
								required
								disabled={loading}
							/>

							<TextInput
								label="Matrícula"
								InputLabelProps={{ shrink: true }}
								name={"registrationNumber"}
								value={currentData["registrationNumber"] || ""}
								onChange={handleChange}
								fullWidth
								disabled={loading}
								required
							/>
						</Stack>

						<Stack direction="row" spacing={2}>
							<TextInput
								label="Señal de llamado"
								InputLabelProps={{ shrink: true }}
								name={"callSign"}
								value={currentData["callSign"] || ""}
								onChange={handleChange}
								fullWidth
								required
								disabled={loading}
							/>
							<Tooltip title="Determina si la embarcación es utilizada actualmente o no, permitiendo o no su selección en el formulario de creación de objetos.">
								<Box sx={{ width: "100%", display: "flex" }}>
									<Typography sx={{ alignItems: "center", display: "flex" }}>Activa</Typography>
									<Checkbox
										label="Activo"
										name={"isActive"}
										checked={currentData.isActive || currentData.isActive === "True" || ""}
										onChange={handleChange}
										disabled={loading}
									/>
								</Box>
							</Tooltip>
						</Stack>

						<Box sx={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "15px" }}>
							<GeneralButton text="Cancelar" variant="empty" onClick={handleClose} disabled={loading} />

							<GeneralButton text="Guardar" type="submit" disabled={loading} onClick={handleSave} />
						</Box>
					</form>
				</div>
			</div>
		</Modal>
	);
};

const Boats = () => {
	const [boats, setBoats] = useState([]);
	const [dataReload, setDataReload] = useState(true);
	const { axiosInstance } = useAuthContext();
	const theme = useTheme();
	const emptyBoat = {
		name: "",
		registrationNumber: "",
		callSign: "",
		cultivationCenter: undefined,
		isActive: true,
		isCreating: true,
	};
	const [selectedBoat, setSelectedBoat] = useState(emptyBoat);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [centers, setCenters] = useState([]);

	useEffect(() => {
		if (!dataReload) return;
		axiosInstance.get(URLS.BOATS).then((response) => {
			const boats = response.data.map((boat) => {
				return {
					...boat,
					cultivationCenter: centers.find((center) => center.id === boat.cultivationCenter),
				};
			});
			setBoats(boats);
			setDataReload(false);
		});
	}, [dataReload, centers, axiosInstance]);

	useEffect(() => {
		axiosInstance.get(URLS.CENTERS).then((response) => {
			setCenters(response.data);
			setDataReload(true);
		});
	}, [axiosInstance]);

	return (
		<>
			<Box sx={{ width: "100%", position: "relative" }}>
				<div style={{ position: "absolute", top: "-60px", left: "20px" }}>
					<GeneralButton
						onClick={() => {
							setSelectedBoat(emptyBoat);
							setIsModalOpen(true);
						}}
						text="Agregar embarcación"
						icon={<BoatIcon color="white" />}
					/>
				</div>
			</Box>
			<ListBoats
				boats={boats}
				selectBoat={(boat) => {
					setSelectedBoat(boat);
					setIsModalOpen(true);
				}}
				dataReload={dataReload}
			/>
			<BoatForm
				boat={selectedBoat}
				setDataReload={setDataReload}
				open={isModalOpen}
				handleClose={() => {
					setSelectedBoat(emptyBoat);
					setIsModalOpen(false);
				}}
				centers={centers}
			/>
		</>
	);
};

export default Boats;
