// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

// mui colors
import tealColor from "@mui/material/colors/teal";
import redColor from "@mui/material/colors/red";

// mui icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveIcon from "@mui/icons-material/Remove";

// components
import { LatLonInput } from "../../CoordInput";
import { PointsPreviewer } from "./";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
import TextInput from "../../Inputs/TextInput";
import GeneralButton from "../../GeneralButton";
import { useCallback, useEffect, useState } from "react";

const MIN_POINTS = 3;
const INITIAL_POINTS = 4;
const MAX_POINTS = 9;

/**
 * Formulario para crear o editar un centro de cultivo
 * @param {object} props
 * @param {function} props.onFinished Funcion a ejecutar al terminar de editar o crear
 * @param {object} props.data Datos del centro de cultivo a editar
 * @param {number} props.companyId Id de la empresa productora a la que pertenece el centro de cultivo
 */
const CultivationCenterForm = ({ onFinished = () => {}, data = undefined, companyId = undefined }) => {
	const [currentData, setCurrentData] = useState({});
	const [errors] = useState({});
	const [loading, setLoading] = useState(true);
	const theme = useTheme();

	const [pointId, setPointId] = useState(0);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	const [places, setPlaces] = useState([]);

	const requestPlaces = useCallback(async () => {
		setLoading(true);
		axiosInstance
			.get(`${URLS.SALMON_COMPANY}${companyId}/places/`)
			.then((response) => {
				setPlaces(response.data);
			})
			.catch((error) => {
				addMessage("Error al cargar posibles areas", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [addMessage, axiosInstance, companyId]);

	useEffect(() => {
		requestPlaces();
	}, [requestPlaces]);

	const requestData = useCallback(() => {
		setLoading(true);
		axiosInstance
			.get(`${URLS.CENTERS}${data.id}/`)
			.then((response) => {
				let data = response.data;
				// procesar coordenadas
				let points = [];
				data.coordinates.split(";").forEach((point, index) => {
					let [lat, lon] = point.split(",");
					lat = parseFloat(lat).toFixed(6);
					lon = parseFloat(lon).toFixed(6);
					points.push({ id: index, latitude: lat, longitude: lon });
				});
				data.points = points;
				setPointId(points.length);

				setCurrentData({
					name: data.name,
					code: data.code,
					place: data.place?.id,
					manager: data.manager,
					email: data.email,
					surface: data.surface,
					socialReason: data.socialReason,
					points: points,
				});
			})
			.catch((error) => {
				console.log(error);
				addMessage("Error al obtener los datos del centro de cultivo", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [addMessage, axiosInstance, data?.id]);

	const onCreation = useCallback(() => {
		let default_point = { latitude: "", longitude: "" };
		let points = [];
		for (let i = 0; i < INITIAL_POINTS; i++) {
			points.push({ ...default_point, id: i });
		}

		setCurrentData({
			name: "",
			code: "",
			place: "",
			manager: "",
			email: "",
			surface: "",
			socialReason: "",
			points: points,
		});
		setPointId(INITIAL_POINTS);
	}, []);

	useEffect(() => {
		// si se esta editando, se obtienen los datos
		if (data?.id) requestData();
		// si se esta creando, se inicializan los datos
		else {
			onCreation();
			setLoading(false);
		}
	}, [addMessage, axiosInstance, data?.id, onCreation, requestData]);

	const handleCancel = () => {
		onFinished();
	};

	const handleSave = (e) => {
		e.preventDefault();

		let coordinates = processCoordinates(currentData.points);

		// Creacion de objeto por defecto
		// crear configuracion de request
		let config = {
			method: "POST",
			url: URLS.CENTERS,
			data: {
				salmonCompany: companyId,
				name: currentData.name,
				code: currentData.code,
				place: currentData.place || null,
				manager: currentData.manager || null,
				email: currentData.email || null,
				surface: currentData.surface || null,
				socialReason: currentData.socialReason || null,
				coordinates: coordinates,
			},
		};
		let message = "Centro de cultivo creado exitosamente";
		let error_message = "Error al crear el centro de cultivo";

		if (data?.id) {
			// Editar
			config.method = "PATCH";
			config.url += `${data.id}/`;
			config.data = { ...config.data, id: data.id };
			message = "Centro de cultivo editado exitosamente";
			error_message = "Error al editar el centro de cultivo";
		}

		// enviar request
		setLoading(true);
		axiosInstance(config)
			.then((_res) => {
				addMessage(message, "success");
				onFinished();
			})
			.catch((_err) => {
				addMessage(error_message, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDelete = () => {
		if (
			!window.confirm(
				"¿Está seguro de eliminar este centro de cultivo?\n" +
					"Esta acción no se puede deshacer y eliminará todos los informes y objetos asociados al centro de cultivo."
			)
		)
			return;

		setLoading(true);
		axiosInstance
			.delete(`${URLS.CENTERS}${data.id}/`)
			.then((_res) => {
				addMessage("Centro de cultivo eliminado exitosamente", "success");
				onFinished();
			})
			.catch((err) => {
				addMessage("Error al eliminar el centro de cultivo", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (e) => {
		setCurrentData({ ...currentData, [e.target.name]: e.target.value });
	};

	const handlePointChange = (latlon, index) => {
		let newPoints = [...currentData.points];
		newPoints[index] = { ...newPoints[index], ...latlon };
		setCurrentData({ ...currentData, points: newPoints });
	};

	const handleAddPoint = () => {
		setCurrentData({
			...currentData,
			points: [...currentData.points, { id: pointId, lat: "", lon: "" }],
		});
		setPointId(pointId + 1);
	};

	const handleDeletePoint = (index) => {
		let newPoints = [...currentData.points];
		newPoints.splice(index, 1);
		setCurrentData({ ...currentData, points: newPoints });
	};

	const processCoordinates = (points) => {
		let coordinates = "";
		points.forEach((point) => {
			// si no tiene latitud o longitud, no se agrega
			if (!point.latitude || !point.longitude) return;
			// si no es un numero, no se agrega
			if (isNaN(point.latitude) || isNaN(point.longitude)) return;
			let lat = parseFloat(point.latitude).toFixed(6);
			let lon = parseFloat(point.longitude).toFixed(6);
			coordinates += `${lat},${lon};`;
		});
		// quitar el ultimo ";"
		coordinates = coordinates.slice(0, -1);
		return coordinates;
	};
	return (
		<div style={{ padding: "20px 30px", width: "100%" }}>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}
			<form
				onSubmit={handleSave}
				style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}
			>
				<Stack direction="row" spacing={2}>
					<TextInput
						label="Nombre Centro de Cultivo"
						InputLabelProps={{ shrink: true }}
						name={"name"}
						value={currentData["name"] || ""}
						onChange={handleChange}
						fullWidth
						required
						error={errors["name"]}
						helperText={errors["name"]}
						disabled={loading}
					/>

					<TextInput
						label="Codigo de Sernapesca"
						InputLabelProps={{ shrink: true }}
						name={"code"}
						value={currentData["code"] || ""}
						onChange={handleChange}
						fullWidth
						required
						error={errors["code"]}
						helperText={errors["code"]}
						disabled={loading}
					/>

					<TextInput
						label="Area"
						required={true}
						InputLabelProps={{ shrink: true }}
						name={"place"}
						value={loading || places.length === 0 ? "" : currentData["place"] || ""}
						onChange={handleChange}
						fullWidth
						select
						error={errors["place"]}
						helperText={errors["place"]}
						disabled={loading}
					>
						<MenuItem value={""}>Seleccione un área</MenuItem>

						{places.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.name}
							</MenuItem>
						))}
					</TextInput>
				</Stack>

				<Stack direction="row" spacing={2}>
					<TextInput
						label={"Nombre Encargado"}
						InputLabelProps={{ shrink: true }}
						name={"manager"}
						value={currentData["manager"] || ""}
						onChange={handleChange}
						fullWidth
						error={errors["manager"]}
						helperText={errors["manager"]}
						disabled={loading}
					/>

					<TextInput
						label={"Correo Encargado"}
						InputLabelProps={{ shrink: true }}
						name={"email"}
						value={currentData["email"] || ""}
						onChange={handleChange}
						fullWidth
						type="email"
						error={errors["email"]}
						helperText={errors["email"]}
						disabled={loading}
					/>

					<TextInput
						label={"Superficie centro"}
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: <InputAdornment position="end">m²</InputAdornment>,
						}}
						name={"surface"}
						value={currentData["surface"] || ""}
						onChange={handleChange}
						onKeyPress={(e) => {
							// si no es un numero, no se escribe
							if (!/[0-9]/.test(e.key)) {
								e.preventDefault();
							}
						}}
						fullWidth
						error={errors["surface"]}
						helperText={errors["surface"]}
						disabled={loading}
					/>

					<TextInput
						label={"Razón Social"}
						InputLabelProps={{ shrink: true }}
						name={"socialReason"}
						value={currentData["socialReason"] || ""}
						onChange={handleChange}
						fullWidth
						error={errors["socialReason"]}
						helperText={errors["socialReason"]}
						disabled={loading}
					/>
				</Stack>

				<Typography variant="h6">Puntos Geográficos</Typography>

				<Grid container flexWrap="wrap" columnSpacing={1} rowSpacing={1}>
					{/* points */}
					{currentData.points?.map((point, index) => (
						<Grid item xs={12} md={6} lg={4} key={point.id} position="relative">
							<FormControl
								component="fieldset"
								sx={{
									border: "1px solid #ccc",
									borderRadius: "5px",
									p: 1,
								}}
								error={errors[`point${index}`]?.latitude || errors[`point${index}`]?.longitude}
								required={index < MIN_POINTS}
								disabled={loading}
							>
								<FormLabel component="legend">Punto {index + 1}</FormLabel>
								<LatLonInput
									inputValues={point}
									setInputValues={(latlon) => handlePointChange(latlon, index)}
									error={errors[`point${index}`]}
									helperText={errors[`point${index}`]}
									disabled={loading}
									required={index < MIN_POINTS}
								/>
							</FormControl>

							{currentData.points?.length > MIN_POINTS && (
								// para quitar un punto
								<IconButton
									onClick={() => handleDeletePoint(index)}
									size="small"
									sx={{
										position: "absolute",
										top: 14,
										right: 0,
										color: redColor[300],
									}}
									tabIndex={-1}
									disabled={loading}
								>
									<RemoveIcon fontSize="small" />
								</IconButton>
							)}
						</Grid>
					))}

					{currentData.points?.length < MAX_POINTS && (
						// para agregar un punto
						<IconButton
							onClick={handleAddPoint}
							size="large"
							sx={{ alignSelf: "end", color: tealColor[300] }}
							tabIndex={-1}
							disabled={loading}
						>
							<AddCircleIcon fontSize="large" />
						</IconButton>
					)}
				</Grid>

				{/* Previsualizacion de mapa */}
				<Accordion
					sx={{
						borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
						borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography variant="h6">Previsualización de puntos</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							component="fieldset"
							sx={{
								width: "100%",
								height: "400px",
								border: "1px solid #ccc",
								borderRadius: "5px",
							}}
						>
							<PointsPreviewer points={currentData.points} />
						</Box>
					</AccordionDetails>
				</Accordion>

				<Box sx={{ display: "flex", justifyContent: "space-between" }} mt={2}>
					{data?.id && (
						<Stack direction="row" spacing={2}>
							<Button variant="outlined" onClick={handleDelete} color="error" disabled={loading}>
								Eliminar
							</Button>
						</Stack>
					)}

					<Stack direction="row" spacing={2} justifyContent="end" flexGrow={1}>
						<GeneralButton text="Cancelar" variant="empty" onClick={handleCancel} disabled={loading} />
						<GeneralButton text="Guardar" type="submit" disabled={loading} />

					</Stack>
				</Box>
			</form>
		</div>
	);
};

export default CultivationCenterForm;
