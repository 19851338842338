import { useEffect, useState } from "react";
import {
	AppName,
	Container,
	Content,
	Data,
	GeneralInfo,
	GeneralInfoData,
	Logo,
	LogosContainer,
	ReportTitle,
	ReportType,
	Subtitle,
	Tab,
	TabContent,
	Tabs,
} from "../Common";
import "../common.css";
import { generateImagePoints, generateObjectsPoints } from "../../../../utils/map_helper";
import MapAddorns from "../../../Map/MapAddorns";
import GeneralButton from "../../../GeneralButton";
import Plot from "react-plotly.js";
import { useTheme } from "@mui/material/styles";
import {
	PlotContainer,
	PlotStats,
	PlotTab,
	StatTitle,
	SwitchContainer,
	TabContentWrapper,
	Table,
	TableHead,
	Td,
	Th,
} from "./ConsolidatedCommon";
import Objects from "../Objects";
import GeoImages from "../GeoImages";
import GeneralData from "./GeneralData";
import MapContent from "./MapContent";
import GeoImagesContent from "./GeoImagesContent";
import InfoIcon from "../../../Icons/InfoIcon";
import MapIcon from "../../../Icons/MapIcon";
import BoxIcon from "../../../Icons/BoxIcon";
import PlotIcon from "../../../Icons/PlotIcon";
import ProspectionIcon from "../../../Icons/ProspectionIcon";
import CalendarIcon from "../../../Icons/CalendarIcon";
import Stats from "./Stats";
import ConObjects from "./ConObjects";
import WorkSummary from "./WorkSummary";

const TABS = {
	DATA: 0,
	MAP: 1,
	STATS: 2,
	OBJECTS: 3,
	GEO_IMAGES: 4,
	WORKED_DAYS: 5,
};

const GeneralConsolidated = ({ reportData }) => {
	const [tab, setTab] = useState(TABS.DATA);

	const theme = useTheme();

	return (
		<Container>
			<ReportTitle>
				<ReportType>Informe consolidado</ReportType>

				<AppName>Rastrum</AppName>
			</ReportTitle>
			<Content nt={TABS.MAP === tab || TABS.STATS === tab || TABS.WORKED_DAYS === tab}>
				<Tabs
					centered
					value={tab}
					onChange={(e, v) => setTab(v)}
					tabs={6}
					perRow={2}
					TabIndicatorProps={{ style: { display: "none" } }}
				>
					<Tab
						label={<div className="text">Datos</div>}
						icon={<InfoIcon color={tab === TABS.DATA ? theme.palette.brandColors.primary : "#848591"} />}
					/>
					<Tab
						label={<div className="text">Mapa</div>}
						icon={<MapIcon color={tab === TABS.MAP ? theme.palette.brandColors.primary : "#848591"} />}
					/>
					<Tab
						label={<div className="text">Estadísticas</div>}
						icon={<PlotIcon color={tab === TABS.STATS ? theme.palette.brandColors.primary : "#848591"} />}
					/>

					<Tab
						label={<div className="text">Objetos</div>}
						icon={<BoxIcon color={tab === TABS.OBJECTS ? theme.palette.brandColors.primary : "#848591"} />}
					/>

					<Tab
						label={<div className="text">Prospección</div>}
						icon={
							<ProspectionIcon
								color={tab === TABS.GEO_IMAGES ? theme.palette.brandColors.primary : "#848591"}
							/>
						}
					/>

					<Tab
						label={<div className="text">Días trabajados</div>}
						icon={
							<CalendarIcon
								color={tab === TABS.WORKED_DAYS ? theme.palette.brandColors.primary : "#848591"}
							/>
						}
					/>
				</Tabs>

				<TabContent value={tab} index={TABS.DATA}>
					<GeneralData reportData={reportData} />
				</TabContent>

				<TabContent value={tab} index={TABS.MAP} style={{ height: "100%", padding: 0, margin: 0 }}>
					<MapContent reportData={reportData} includeSwitch={true} open={tab === TABS.MAP} />
				</TabContent>

				<TabContent value={tab} index={TABS.STATS} style={{ height: "100%", padding: 0, margin: 0 }}>
					<Stats reportData={reportData} />
				</TabContent>

				<TabContent value={tab} index={TABS.OBJECTS} style={{ margin: 0 }}>
					<ConObjects reportData={reportData} />
				</TabContent>

				<TabContent value={tab} index={TABS.GEO_IMAGES} style={{ margin: 0 }}>
					<GeoImagesContent reportData={reportData} />
				</TabContent>

				<TabContent
					value={tab}
					index={TABS.WORKED_DAYS}
					style={{ height: "100%", padding: "0 20px", margin: 0, display: "flex", flexDirection: "column" }}
				>
					<WorkSummary reportData={reportData} />
				</TabContent>
			</Content>
		</Container>
	);
};

export default GeneralConsolidated;
