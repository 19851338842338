import React from "react";
import { FormControl, Typography, TextField, Grid, Button } from "@mui/material";
import { useState, useContext } from "react";
import { AuthContext, MessagesContext } from "../../context";
import { useTheme } from "@mui/material/styles";
import URLS from "../../global/URLS";
import TextInput from "../Inputs/TextInput";
import SingleDropZone from "../SingleDropZone";
import GeneralButton from "../GeneralButton";

const ObjectExit = ({ handleBackToObjects, handleBackToObjectInfo, selectedObject }) => {
	const { axiosInstance, username } = useContext(AuthContext);
	const { addMessage } = useContext(MessagesContext);

	const [loading, setLoading] = useState(false);

	const theme = useTheme();

	const [formData, setFormData] = useState({
		date: "",
		receptor: "",
		cargoReceptor: "",
		rutReceptor: "",
		boat: "",
		comments: "",
		receptionImage: null,
		objetctsIDs: selectedObject,
	});
	const [formErrors, setFormErrors] = useState({
		date: false,
		receptor: false,
		cargoReceptor: false,
		rutReceptor: false,
		boat: false,
		comments: false,
		receptionImage: false,
	});

	const handleSubmit = (event) => {
		event.preventDefault();

		if (!validateForm()) {
			addMessage("Por favor, complete todos los campos.", "error");
			return;
		}

		const nformData = new FormData();
		nformData.append("date", formData.date);
		nformData.append("receptor", formData.receptor);
		nformData.append("cargoReceptor", formData.cargoReceptor);
		nformData.append("rutReceptor", formData.rutReceptor);
		nformData.append("boat", formData.boat);
		nformData.append("comments", formData.comments);

		if (Array.isArray(selectedObject)) {
			nformData.append("objectsIDs", selectedObject.join(","));
		} else {
			nformData.append("objectsIDs", selectedObject.id);
		}
		if (formData.receptionImage) {
			nformData.append("receptionImage", formData.receptionImage);
		}

		setLoading(true);
		axiosInstance
			.post(URLS.RECEPTION_NOTE, nformData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				addMessage("Nota de salida creada con éxito", "success");
				// navigate to previous component
				if (Array.isArray(selectedObject)) {
					handleBackToObjects();
				} else {
					handleBackToObjectInfo();
				}
			})
			.catch((error) => {
				console.error(error);
				addMessage(error.response?.data?.error, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const validateForm = () => {
		let isValid = true;
		const errors = {};

		for (const key in formData) {
			if (!formData[key]) {
				isValid = false;
				errors[key] = true;
			} else {
				errors[key] = false;
			}
		}

		setFormErrors(errors);
		return isValid;
	};

	const handleInputChange = (e) => {
		const { name, value, type, files } = e.target;

		if (type === "file") {
			setFormData({ ...formData, [name]: files[0] });
		} else {
			setFormData({ ...formData, [name]: value });
		}
		setFormErrors({ ...formErrors, [name]: false });
	};

	const handleBack = () => {
		if (Array.isArray(selectedObject)) {
			handleBackToObjects();
		} else {
			handleBackToObjectInfo();
		}
	};

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
					padding: "0 0 10px",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>Nota de Salida</div>
				<div
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						fontWeight: 700,
						color: theme.palette.brandColors.primary,
						fontSize: "13px",
					}}
					onClick={handleBackToObjects}
				>
					Volver al listado
				</div>
			</div>
			<div style={{ margin: "20px 0" }}>
				<form onSubmit={handleSubmit} encType="multipart/form-data">
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<TextInput variant="outlined" value={username} disabled label="Operador" />
						</Grid>
						<Grid item xs={12} md={6}>
							<TextInput
								variant="outlined"
								type="date"
								name="date"
								value={formData.date}
								onChange={handleInputChange}
								label="Fecha"
								error={formErrors.date ? "Este campo es requerido" : null}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextInput
								variant="outlined"
								name="receptor"
								value={formData.receptor}
								onChange={handleInputChange}
								label="Persona que recibe"
								error={formErrors.receptor ? "Este campo es requerido" : null}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<TextInput
								variant="outlined"
								name="rutReceptor"
								onChange={handleInputChange}
								value={formData.rutReceptor}
								label={"Rut de quién recibe"}
								error={formErrors.rutReceptor ? "Este campo es requerido" : null}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<TextInput
								variant="outlined"
								name="cargoReceptor"
								value={formData.cargoReceptor}
								onChange={handleInputChange}
								label={"Cargo de quién recibe"}
								error={formErrors.cargoReceptor ? "Este campo es requerido" : null}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextInput
								variant="outlined"
								name="boat"
								onChange={handleInputChange}
								value={formData.boat}
								label={"Embarcación"}
								error={formErrors.boat ? "Este campo es requerido" : null}
							/>
						</Grid>
						<Grid item xs={12} md={7}>
							<TextInput
								variant="outlined"
								name="comments"
								value={formData.comments}
								onChange={handleInputChange}
								label={"Comentarios"}
								error={formErrors.comments ? "Este campo es requerido" : null}
								multiline
								rows={8}
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<div
								style={{
									marginBottom: "5px",
								}}
							>
								Imagen de la recepción
							</div>
							<SingleDropZone
								inputChangeName="receptionImage"
								handleInputChange={(_, value) => setFormData({ ...formData, receptionImage: value })}
							/>
						</Grid>
					</Grid>
					<div style={{ display: "flex", gap: "10px", justifyContent: "center", marginTop: "20px" }}>
						<GeneralButton
							text="Cancelar"
							onClick={handleBack}
							disabled={loading}
							variant="empty"
						/>
						<GeneralButton
							text="Guardar"
							type="submit"
							disabled={loading}
						/>
					</div>
				</form>
			</div>
		</>
	);
};

export default ObjectExit;
