import React from "react";
import { Grid, TextField, InputAdornment, Typography } from "@mui/material";

import { toLatLon, fromLatLon } from "../../utils/utm";
import TextInput from "../Inputs/TextInput";

export const COORD_TYPE = {
	key: "UTM",
	name: "Universal Transverse Mercator",
	nombre: "UTM",
	short_name: "UTM",
	fields: {
		easting: {
			nombre: "Este",
			type: Number,
			min: 100000,
			max: 999999,
			step: 100,
			shiftStep: 10000,
		},
		northing: {
			nombre: "Norte",
			type: Number,
			min: 0,
			max: 10000000,
			step: 1000,
			shiftStep: 100000,
		},
		zoneNum: {
			nombre: "Huso/Num. Zona",
			type: Number,
			min: 1,
			max: 60,
			step: 1,
			shiftStep: 10,
		},
		zoneLetter: {
			nombre: "Banda/Letra",
			type: String,
		},
	},
};

export const HelpTooltipText = () => {
	return (
		<Typography variant="body2" component="div" sx={{ maxWidth: 300 }}>
			Puedes pegar coordenadas en el formato: <br />
			<span>
				<b>Huso Banda</b> <i>espacio</i> <b>Este</b> <i>espacio</i> <b>Norte</b>
				<br />
				<i>ejemplo: 18G 654000 5370000</i>
			</span>
			<br />
			O en el formato: <br />
			<span>
				<b>Huso</b> <i>espacio</i> <b>Este</b> <i>espacio</i> <b>Norte</b> <i>espacio</i> <b>Hemisferio</b>
				<br />
				<i>ejemplo: 18 654000 5370000 S</i>
			</span>
			<br />
			<span>Este ultimo intentara obtener la banda automaticamente.</span>
			<br />
			<span>
				En caso de no saber el huso o banda, puedes utilizar{" "}
				<a
					target="_blank"
					rel="noreferrer"
					href="https://hub.arcgis.com/datasets/esri::world-utm-grid"
					style={{
						color: "lightskyblue",
					}}
				>
					esta herramienta
				</a>{" "}
				para obtenerlo.
			</span>
		</Typography>
	);
};

/**
 * Componente para ingresar coordenadas geograficas en formato UTM.
 * @param {Object} placeholder Texto de ayuda para los inputs.
 * @param {Object} error Errores en los inputs.
 * @param {Object} helperText Texto de ayuda para los errores.
 * @param {Object} initialValue Valores iniciales.
 * @param {Boolean} disabled Deshabilitar inputs.
 * @returns {JSX.Element}
 */
const Inputs = ({
	placeholder = { easting: "", northing: "", zoneNum: "", zoneLetter: "" },
	error = {
		easting: false,
		northing: false,
		zoneNum: false,
		zoneLetter: false,
	},
	helperText = { easting: "", northing: "", zoneNum: "", zoneLetter: "" },
	inputValues,
	setInputValues,
	disabled = false,
}) => {
	const [adornment, setAdornment] = React.useState({
		easting: "",
		northing: "",
		zoneNum: "",
		zoneLetter: inputValues.hemisphere,
	});

	const cleanInput = (v) => {
		try {
			// letras a mayusculas
			v = v.toUpperCase();
			// solo numeros y letras CDEFGHJKLMNPQRSTUVWXX
			let non_allowed_chars = /[^0-9CDEFGHJKLMNPQRSTUVWXX]/g;
			// eliminar caracteres no permitidos
			v = v.replace(non_allowed_chars, "");

			return v;
		} catch (err) {
			return "";
		}
	};

	const handleChange = (e) => {
		let { name, value, selectionStart } = e.target;

		value = cleanInput(value);
		// si es zona, solo permitir numeros
		if (name === "zoneNum") {
			value = value.replace(/[^0-9]/g, "");
		}
		// si es letra, solo permitir una letra (de C a X, excepto O e I)
		else if (name === "zoneLetter") {
			value = value.replace(/[^CDEFGHJKLMNPQRSTUVWXX]/g, "");
			value = value.slice(-1);
			let hemisphere = value ? (value >= "N" ? "N" : "S") : undefined;
			setAdornment((prev) => ({ ...prev, [name]: hemisphere }));
		}

		// preservar cursor
		e.target.selectionStart = selectionStart;
		e.target.selectionEnd = selectionStart;

		updateUtm({ ...inputValues, [name]: value });
	};

	const handlePaste = (e) => {
		const paste = e.clipboardData.getData("text").trim();
		const sep = /[; ]+/;
		let values = paste.split(sep);

		if (values.length < 3) {
			return;
		}
		e.preventDefault();

		let easting, northing, zoneNum, zoneLetter, hemisphere;
		if (values.length === 3) {
			// obtener huso y letra
			zoneNum = cleanInput(values[0].slice(0, -1));
			zoneLetter = cleanInput(values[0].slice(-1));
			// obtener este y norte
			easting = cleanInput(values[1]);
			northing = cleanInput(values[2]);
			// obtener hemisferio
			hemisphere = zoneLetter ? (zoneLetter >= "N" ? "N" : "S") : undefined;
		} else if (values.length === 4) {
			// obtener huso
			zoneNum = cleanInput(values[0]);
			zoneLetter = undefined;
			// obtener este y norte
			easting = cleanInput(values[1]);
			northing = cleanInput(values[2]);
			// obtener hemisferio (si escribieron el huso tambien sirve)
			hemisphere = cleanInput(values[3]);

			// obtener letra
			if (hemisphere) {
				try {
					let { latitude, longitude } = toLatLon(easting, northing, zoneNum, "", hemisphere === "N");
					zoneLetter = fromLatLon(latitude, longitude).zoneLetter;
				} catch (err) {
					zoneLetter = undefined;
				}
			}
		}

		updateUtm({
			zoneNum,
			zoneLetter,
			easting,
			northing,
			hemisphere,
		});
	};

	const updateUtm = (utm) => {
		let latitude, longitude;
		console.log(utm);
		try {
			if (utm.hemisphere === "N" || utm.hemisphere === "S") {
				({ latitude, longitude } = toLatLon(
					utm.easting,
					utm.northing,
					utm.zoneNum,
					"",
					utm.hemisphere === "N"
				));
			} else {
				({ latitude, longitude } = toLatLon(utm.easting, utm.northing, utm.zoneNum, utm.zoneLetter));
			}
		} catch (err) {}

		setInputValues({
			latitude: parseFloat(latitude ? latitude.toFixed(6) : ""),
			longitude: parseFloat(longitude ? longitude.toFixed(6) : ""),
			easting: utm.easting,
			northing: utm.northing,
			zoneNum: utm.zoneNum,
			zoneLetter: utm.zoneLetter,
			hemisphere: utm.hemisphere,
		});
	};

	return (
		<Grid container spacing={2}>
			{Object.keys(COORD_TYPE.fields).map((key) => (
				<Grid item xs={12} md={12 / Object.keys(COORD_TYPE.fields).length} key={key}>
					<TextInput
						key={key}
						name={key}
						label={COORD_TYPE.fields[key].nombre}
						value={inputValues[key] || ""}
						fullWidth
                        required={true}
						InputLabelProps={{ shrink: true }}
						placeholder={placeholder[key] || ""}
						disabled={disabled}
						error={
							error[key] ||
							// valores fuera de rango
							(inputValues[key] !== "" &&
								(inputValues[key] < COORD_TYPE.fields[key].min ||
									inputValues[key] > COORD_TYPE.fields[key].max))
						}
						onChange={handleChange}
						onPaste={handlePaste}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{key === "zoneLetter"
										? adornment[key]
											? `Hem. ${adornment[key]}`
											: ""
										: adornment[key] || ""}
								</InputAdornment>
							),
						}}
						onKeyDown={(e) => {
							if (COORD_TYPE.fields[key].type !== Number) {
								return;
							}

							// manejar eventos de flechas arriba y abajo
							if (e.key === "ArrowUp" || e.key === "ArrowDown") {
								e.preventDefault();
								let value = parseInt(e.target.value);
								// si esta presionada la tecla shift, sumar shiftStep
								let step;
								if (e.shiftKey) {
									step = COORD_TYPE.fields[key].shiftStep;
								} else {
									step = COORD_TYPE.fields[key].step;
								}
								// sumar o restar segun la flecha
								if (e.key === "ArrowUp") {
									value += step;
								} else {
									value -= step;
								}
								// limitar a los valores maximo y minimo
								value = Math.min(
									Math.max(value, COORD_TYPE.fields[key].min),
									COORD_TYPE.fields[key].max
								);
								// actualizar input y desencadenar evento onChange
								e.target.value = value;
								handleChange(e);
							}
						}}
					/>
				</Grid>
			))}
		</Grid>
	);
};

export default Inputs;
