import { useEffect, useState } from "react";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import { useAuthContext } from "../context";

import { URLS } from "../global";


import GanttCalendar from "../components/Gantt/GanttCalendar";

const orderByOptions = [
	{ value: "boat", label: "Embarcación", id: "boat" },
	{ value: "cultivationCenter", label: "Centro de cultivo", id: "cultivationCenter" },
];

const orderByOption = (tasks, orderBy) => {
	if (orderBy === "boat") {
		return tasks.sort((a, b) => a.boat.name.localeCompare(b.boat.name));
	} else if (orderBy === "cultivationCenter") {
		return tasks.sort((a, b) => a.cultivationCenter.name.localeCompare(b.cultivationCenter.name));
	}
};

const Calendar = () => {
	const { axiosInstance, USER_TYPE } = useAuthContext();
	const [tasks, setTasks] = useState([]);
	const [selectedBoatLog, setSelectedBoatLog] = useState(null);
	const [reloadData, setReloadData] = useState(true);
	const [open, setOpen] = useState(false);
	const [orderBy, setOrderBy] = useState(orderByOptions[0].id);


	useEffect(() => {
		if (!reloadData) return;
		axiosInstance
			.get(URLS.BOAT_LOGS)
			.then((response) => {
				const data = response.data;
				const parsedTasks = {};
				data.forEach((element) => {
					if (!parsedTasks[element.boat.id + " - " + element.cultivationCenter.id]) {
						parsedTasks[element.boat.id + " - " + element.cultivationCenter.id] = {
							boat: element.boat,
							cultivationCenter: element.cultivationCenter,
							logs: [
								{
									initialDate: element.initialDate,
									endDate: element.endDate,
									boatLog: element.id,
								},
							],
							additionalDays: element.additionalLogs,
							turns: element.takeovers,
						};
					} else {
						parsedTasks[element.boat.id + " - " + element.cultivationCenter.id].logs.push({
							initialDate: element.initialDate,
							endDate: element.endDate,
							boatLog: element.id,
						});

						parsedTasks[element.boat.id + " - " + element.cultivationCenter.id].additionalDays.push(
							...element.additionalLogs
						);

						parsedTasks[element.boat.id + " - " + element.cultivationCenter.id].turns.push(
							...element.takeovers
						);
					}
				});
				const parsedTasksArr = Object.values(parsedTasks);
				setTasks(parsedTasksArr);
				setReloadData(false);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [axiosInstance, reloadData]);


	return (
		<Box sx={{ p: 3, position: "relative" }}>

			<FormControl sx={{ position: "absolute", top: "100px", left: "24px" }}>
				<InputLabel id="orderBy">Ordenar por</InputLabel>
				<Select value={orderBy} onChange={(e) => setOrderBy(e.target.value)} label="Ordenar por">
					{orderByOptions.map((option) => (
						<MenuItem key={option.id} value={option.id}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			
			<GanttCalendar
				tasks={orderByOption(tasks, orderBy)}
				onBoatLogClick={(id) => setSelectedBoatLog(id)}

				boatLog={selectedBoatLog}
				setBoatLog={setSelectedBoatLog}
				open={open}
				setOpen={setOpen}
				onClose={() => {
					setSelectedBoatLog(null);
					setReloadData(true);
					setOpen(false);
				}
				}
				canEdit={USER_TYPE === "Admin"}
			/>
		</Box>
	);
};

export default Calendar;
