// mui components
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// components
import { CultivationCenterForm, Cages, Polygons } from "./";
import { BoxStyle } from "../../Reports/ReportConstants";
import { useState } from "react";

/**
 * Dialogo para crear o editar un centro de cultivo, contiene 3 tabs:
 * - Informacion general del centro de cultivo
 * - Jaulas del centro de cultivo
 * - Poligonos del centro de cultivo
 * @param {object} props
 * @param {boolean} props.open Indica si el dialogo esta abierto
 * @param {function} props.onClose Funcion a ejecutar al cerrar el dialogo
 * @param {object} props.data Datos del centro de cultivo a editar
 * @param {number} props.companyId Id de la empresa productora a la que pertenece el centro de cultivo
 */
const EditCultivationCenterDialog = ({ open, onClose, ...props }) => {
	const [tab, setTab] = useState(0);
	const [hide, setHide] = useState(false);
	const theme = useTheme();

	const handleChangeTab = (_event, newValue) => {
		setTab(newValue);
	};

	const handleClose = (_event, _reason) => {
		if (_reason === "backdropClick") return;
		onClose();
	};

	const onFinished = () => {
		onClose();
	};

	const tabsContent = [
		<CultivationCenterForm {...props} onFinished={onFinished} />,
		<Cages centerId={props?.data?.id} setHide={setHide} />,
		<Polygons centerId={props?.data?.id} setHide={setHide} />,
	];

	return (
		<Modal open={open} onClose={handleClose}>
			<div style={{ ...BoxStyle, height: "auto" }}>
				<div
					style={{
						position: "relative",
						borderBottom: !hide ? `1px solid ${theme.palette.brandColors.lightGray}` : "none",
					}}
				>
					{!hide && (
						<>
							<Tabs value={tab} sx={{ width: "100%" }} onChange={handleChangeTab}>
								<Tab label={`${props?.data?.id ? "Editar" : "Crear"} Centro`} />
								{props.data?.id ? <Tab label="Modificar jaulas" /> : null}
								{props.data?.id ? <Tab label="Modificar polígonos" /> : null}
							</Tabs>
							<IconButton onClick={handleClose} sx={{ position: "absolute", right: 0, top: 0 }}>
								<CloseIcon />
							</IconButton>
						</>
					)}
				</div>

				<div>{tabsContent[tab]}</div>
			</div>
		</Modal>
	);
};

export default EditCultivationCenterDialog;
