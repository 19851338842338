const BACKEND_URL = "https://rastrumapp.com/";
// const BACKEND_URL = "http://localhost:8000/";

const URLS = {
	backend: BACKEND_URL,
	// auth urls
	login: BACKEND_URL + "api/login/",
	logout: BACKEND_URL + "api/logout/",
	resetpassword: BACKEND_URL + "api/password-reset/",

	// token urls
	obtain_token: BACKEND_URL + "api/token/obtain/",
	refresh_token: BACKEND_URL + "api/token/refresh/",
	verify_token: BACKEND_URL + "api/token/verify/",

	// view urls
	GET_OBJECTS: BACKEND_URL + "api/object/",
	GET_JAULAS: BACKEND_URL + "api/jaulas/",
	GET_OBJECT: BACKEND_URL + "api/object/<int:objeto_id>/",
	GET_REPORTS: BACKEND_URL + "api/report/",
	// GET_PDF: BACKEND_URL + "api/generate-pdf/",
	GET_NOTIFICACIONES: BACKEND_URL + "api/notificaciones/",
	POST_IMAGE: BACKEND_URL + "api/image/",
	SAVE_REPORT: BACKEND_URL + "api/save_report/",

	// viewsets urls
	OBJECTS: BACKEND_URL + "api/object/",
	USERS: BACKEND_URL + "api/user/",
	CLIENTS: BACKEND_URL + "api/client/",
	OPERATORS: BACKEND_URL + "api/operator/",
	ADMINS: BACKEND_URL + "api/admin/",
	CENTERS: BACKEND_URL + "api/center/",
	PERMISSIONS: BACKEND_URL + "api/center_permission/",
	WRECK_RESCUE_COMPANY: BACKEND_URL + "api/wreck_rescue_company/",
	SALMON_COMPANY: BACKEND_URL + "api/salmon_company/",
	REPORTS: BACKEND_URL + "api/report/",
	CONSOLIDATED_REPORTS: BACKEND_URL + "api/consolidated_report/",
	PLACES: BACKEND_URL + "api/place/",
	OBJECT_TYPES: BACKEND_URL + "api/object_type/",
	STATS: BACKEND_URL + "api/stats/",
	CONSOLIDATED_STATS: BACKEND_URL + "api/consolidated_stats/",
	CONCESSIONS: BACKEND_URL + "api/concesion/",
	CAGES: BACKEND_URL + "api/cage/",
	POLYGONS: BACKEND_URL + "api/polygon/",
	IMAGES: BACKEND_URL + "api/image/",
	GEO_IMAGES: BACKEND_URL + "api/geo_image/",
	GEO_VIDEO: BACKEND_URL + "api/geo_video/",
	BOATS: BACKEND_URL + "api/boat/",
	DELETE_PETITION: BACKEND_URL + "api/delete_petition/",
	RECEPTION_NOTE: BACKEND_URL + "api/reception_note/",
	BOAT_LOGS: BACKEND_URL + "api/boat_log/",
	TAKEOVERS: BACKEND_URL + "api/takeover/",
	ADDITIONAL_BOAT_LOGS: BACKEND_URL + "api/additional_boat_log/",
	MAPS: BACKEND_URL + "api/maps/",
	EMAIL_RECIPIENTS: BACKEND_URL + "api/email_recipient/",
	RESCUED_OBJECTS: BACKEND_URL + "api/object_rescue/",
	FEEDING_AND_BACTERIUM_IMAGES: "api/feeding_bacterium_image/",
};

export default URLS;
