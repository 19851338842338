

const DownloadIcon = ({color}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
			<g clipPath="url(#clip0_63_56)">
				<path
					d="M7.15058 9.02899C7.19406 8.95156 7.17307 8.87069 7.17307 8.79376C7.17457 6.19833 7.17407 3.60291 7.17407 1.00798C7.17407 0.884694 7.17657 0.762394 7.20406 0.64108C7.28603 0.274673 7.61789 0.00442974 7.99474 -8.56963e-06C8.37359 -0.00444688 8.71695 0.273194 8.79941 0.647984C8.8264 0.769298 8.8279 0.891598 8.8279 1.01488C8.8279 3.6039 8.8279 6.1934 8.8279 8.78241V9.03047C8.92936 9.02406 8.96684 8.95304 9.01582 8.90521C9.805 8.12702 10.5902 7.3449 11.3819 6.56918C11.8322 6.12831 12.5294 6.25159 12.7498 6.81378C12.8808 7.14715 12.8073 7.45536 12.5489 7.71278C12.268 7.9924 11.9851 8.27004 11.7032 8.54817C10.6986 9.53989 9.69405 10.5316 8.68846 11.5223C8.22514 11.9795 7.77633 11.9775 7.31002 11.5174C6.03603 10.2609 4.76205 9.00433 3.48907 7.74681C3.14971 7.41196 3.09773 6.96665 3.35312 6.63033C3.667 6.21658 4.23227 6.18699 4.62611 6.57312C5.41279 7.3449 6.19397 8.12259 6.97915 8.89584C7.02913 8.94515 7.06662 9.01567 7.15158 9.02899H7.15058Z"
					fill={color || "white"}
				/>
				<path
					d="M7.99177 14.9995C5.61074 14.9995 3.2292 14.9995 0.848171 14.999C0.437838 14.999 0.122466 14.7677 0.0265053 14.4038C-0.101942 13.9165 0.251414 13.4308 0.763206 13.3923C0.812686 13.3884 0.862666 13.3899 0.912645 13.3899C5.63773 13.3899 10.3628 13.3899 15.0879 13.3899C15.5862 13.3899 15.9046 13.6345 15.9875 14.0758C16.0725 14.529 15.7151 14.9748 15.2473 14.9975C15.1729 15.001 15.0979 14.9995 15.0229 14.9995C12.6789 14.9995 10.3353 14.9995 7.99127 14.9995H7.99177Z"
					fill={color || "white"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_63_56">
					<rect width="16" height="15" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default DownloadIcon;