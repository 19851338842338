import { useState } from "react";
import { Grid, TextField, InputAdornment, Typography } from "@mui/material";
import TextInput from "../Inputs/TextInput";

export const COORD_TYPE = {
	key: "LAT_LON",
	name: "Geographic coordinate system",
	nombre: "Latitud y Longitud",
	short_name: "LAT-LON",
	fields: {
		latitude: {
			nombre: "Latitud",
			type: Number,
			min: -80,
			max: 84,
			step: 0.001,
			shiftStep: 1.0,
		},
		longitude: {
			nombre: "Longitud",
			type: Number,
			min: -180,
			max: 180,
			step: 0.001,
			shiftStep: 1.0,
		},
	},
};

export const HelpTooltipText = () => {
	return (
		<Typography variant="body2" component="div" sx={{ maxWidth: 300 }}>
			Puedes pegar coordenadas en el formato: <br />
			<span>
				<b>Latitud</b> <i>espacio</i> <b>Longitud</b>
				<br />
				Además, puedes pegar coordenadas en el formato
				<br />
				<b>Coordenadas decimales (Formato simple)</b>:
				<br />
				<i>ejemplo: -41.807 -73.146</i>
				<br />
				<b>Coordenadas GD (Grados decimales)</b>:
				<br />
				<i>ejemplo: 41.807° S 73.146° O</i>
				<br />
				<b>Coordenadas GMS (Grados, minutos, segundos)</b>:
				<br />
				<i>ejemplo: 41°48'25.2'' S 73°8'45.6'' O</i>
			</span>
		</Typography>
	);
};

/**
 * Componente para ingresar coordenadas geográficas cartesianas en formato decimal, grados decimales, grados y minutos decimales o grados, minutos y segundos.
 * @param {Function} setter Función para actualizar el valor de las coordenadas en el componente padre, solo se llama cuando el valor es un número válido.
 * @param {Object} placeholder Objeto con los valores de los placeholders de los inputs.
 * @param {Object} error Objeto con los valores de los errores de los inputs.
 * @param {Object} helperText Objeto con los valores de los mensajes de error de los inputs.
 * @param {Object} initialValue Objeto con los valores iniciales de los inputs.
 * @param {Boolean} disabled Indica si los inputs están deshabilitados.
 * @returns {JSX.Element}
 */
const Inputs = ({
	placeholder = { latitude: "", longitude: "" },
	error = { latitude: false, longitude: false },
	inputValues,
	setInputValues,
	disabled = false,
	required = false,
}) => {
	const [adornment, setAdornment] = useState({
		latitude: "",
		longitude: "",
	});
	const [numAdornment, setNumAdornment] = useState({
		latitude: {
			negative: false,
		},
		longitude: {
			negative: false,
		},
	});

	const cleanInput = (v, type) => {
		try {
			// letras a mayusculas
			v = v.toUpperCase();
			// solo numeros, puntos, comas, -, + y letras N, S, E, O
			let non_allowed_chars = /[^0-9.,NSEO+-]/g;
			// eliminar caracteres no permitidos
			v = v.replace(non_allowed_chars, "");
			// reemplazar comas por puntos
			v = v.replace(/,/g, ".");
			// si el valor tiene puntos, eliminar todos menos el ultimo
			const dots = v.match(/\./g);
			const newAdds = numAdornment;

			if (dots && dots.length > 1) {
				let last_dot_index = v.lastIndexOf(".");
				v = v.replace(/\./g, (m, i) => (i === last_dot_index ? m : ""));
			}

			// si el valor tiene signo +, eliminarlo y eliminar signo -
			const pluses = v.match(/\+/g);
			newAdds[type].negative = v[v.length - 1] === "-";
			if (pluses && pluses.length >= 1) {
				v = v.replace(/\+/g, "");
				v = v.replace(/-/g, "");
			}
			setNumAdornment(newAdds);
			// si el valor tiene guiones, eliminar todos y escribirlo al principio
			const minuses = v.match(/-/g);
			if (minuses && minuses.length >= 1) {
				v = v.replace(/-/g, "");
				v = "-" + v;
			}

			// si el valor tiene letras, eliminar todas y retornar la primera
			const letters = v.match(/[NSEO]/g);
			if (letters && letters.length >= 1) {
				v = v.replace(/[NSEO]/g, "");
			}

			return [v, letters ? letters[0] : ""]; // [valor, letra]
		} catch (e) {
			return ["", ""];
		}
	};

	const enforceSign = (value, name, letter, change = true) => {
		// si el valor tenia letras, escribirla como adorno
		if (letter && letter.length >= 1) {
			// añadir adorno de N o S
			if (name === "latitude" && (letter[0] === "N" || letter[0] === "S")) {
				if (change)
					setAdornment({
						...adornment,
						[name]: letter[0],
					});

				// forzar signo en caso de que no lo tenga
				if (!value.startsWith("-") && letter[0] === "S") {
					value = "-" + value;
				} else if (value.startsWith("-") && letter[0] === "N") {
					value = value.replace(/-/g, "");
				}
			}

			// añadir adorno de E o O
			if (name === "longitude" && (letter[0] === "E" || letter[0] === "O")) {
				if (change)
					setAdornment({
						...adornment,
						[name]: letter[0],
					});

				// forzar signo en caso de que no lo tenga
				if (!value.startsWith("-") && letter[0] === "O") {
					value = "-" + value;
				} else if (value.startsWith("-") && letter[0] === "E") {
					value = value.replace(/-/g, "");
				}
			}
		}

		// si el valor no tenia letras, añadir el adorno correspondiente
		else {
			if (name === "latitude") {
				if (value.startsWith("-")) {
					letter = "S";
				} else {
					letter = "N";
				}
			}
			if (name === "longitude") {
				if (value.startsWith("-")) {
					letter = "O";
				} else {
					letter = "E";
				}
			}

			if (change)
				setAdornment({
					...adornment,
					[name]: letter,
				});
		}

		return [value, letter];
	};

	const handleChange = (e) => {
		let { name, value, selectionStart } = e.target;

		// limpiar el valor
		let clean = cleanInput(value, e.target.name);
		value = clean[0];
		let letters = clean[1];
		[value, letters] = enforceSign(value, name, letters);

		// preservar el cursor
		e.target.selectionStart = selectionStart;
		e.target.selectionEnd = selectionStart;

		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handlePaste = (e) => {
		let paste = e.clipboardData.getData("text").trim();
		const sep = /[; ]+/;
		paste = paste.split(sep);

		if (paste.length < 2) {
			return;
		}

		e.preventDefault();

		let lat, lon, lat_letter, lon_letter;
		if (paste.length === 2) {
			lat = paste[0];
			lat_letter = "";
			lon = paste[1];
			lon_letter = "";
		} else if (paste.length === 4) {
			lat = paste[0];
			lat_letter = paste[1];
			lon = paste[2];
			lon_letter = paste[3];

			// si tiene grado, minuto y segundo, convertir a decimal
			let lat_dms = lat.match(/(\d+)°(\d+)'(\d+\.?\d*)''/);
			if (lat_dms) {
				let lat_deg = parseInt(lat_dms[1]);
				let lat_min = parseInt(lat_dms[2]);
				let lat_sec = parseFloat(lat_dms[3]);
				lat = String(lat_deg + lat_min / 60 + lat_sec / 3600);
			} else {
				// si tiene grado y minuto decimal, convertir a decimal
				let lat_dm = lat.match(/(\d+)°(\d+\.\d*)'/);
				if (lat_dm) {
					let lat_deg = parseInt(lat.match(/(\d+)°/)[1]);
					let lat_min = parseFloat(lat.match(/°(\d+\.\d*)'/)[1]);
					lat = String(lat_deg + lat_min / 60);
				}
			}

			// si tiene grado, minuto y segundo, convertir a decimal
			let lon_dms = lon.match(/(\d+)°(\d+)'(\d+\.?\d*)''/);
			if (lon_dms) {
				let lon_deg = parseInt(lon_dms[1]);
				let lon_min = parseInt(lon_dms[2]);
				let lon_sec = parseFloat(lon_dms[3]);
				lon = String(lon_deg + lon_min / 60 + lon_sec / 3600);
			} else {
				// si tiene grado y minuto decimal, convertir a decimal
				let lon_dm = lon.match(/(\d+)°(\d+\.\d*)'/);
				if (lon_dm) {
					let lon_deg = parseInt(lon.match(/(\d+)°/)[1]);
					let lon_min = parseFloat(lon.match(/°(\d+\.\d*)'/)[1]);
					lon = String(lon_deg + lon_min / 60);
				}
			}
		}

		// limpiar los valores
		let clean = cleanInput(lat, "latitude");
		lat = clean[0];
		lat_letter = clean[1] ? clean[1] : cleanInput(lat_letter, "latitude")[1];
		clean = cleanInput(lon, "longitude");
		lon = clean[0];
		lon_letter = clean[1] ? clean[1] : cleanInput(lon_letter, "longitude")[1];

		// añadir signo
		[lat, lat_letter] = enforceSign(lat, "latitude", lat_letter, false);
		[lon, lon_letter] = enforceSign(lon, "longitude", lon_letter, false);

		// añadir adorno
		setAdornment({
			latitude: lat_letter,
			longitude: lon_letter,
		});

		// añadir valores
		setInputValues((prev) => ({
			...prev,
			latitude: String(parseFloat(lat).toFixed(6)),
			longitude: String(parseFloat(lon).toFixed(6)),
		}));
	};

	// EJEMPLOS DE COORDENADAS
	// Sistema

	// Estándar decimal simple
	// -35.675147 -71.542969

	// Grados decimales (GD)
	// 35.6751° S 71.543° O

	// Grados y Minutos Decimales (GMD)
	// 35°40.509' S 71°32.578' O

	// Grados, Minutos y Segundos (GMS)
	// 35°40'30.5'' S 71°32'34.7'' O

	return (
		<Grid container spacing={2}>
			{Object.keys(COORD_TYPE.fields).map((key) => (
				<Grid item xs={12} md={6} key={key}>
					<TextInput
						key={key}
						name={key}
						label={COORD_TYPE.fields[key].nombre}
						value={numAdornment[key].negative ? "-" : "" + (inputValues[key] || "")}
						onChange={handleChange}
						onPaste={handlePaste}
						InputLabelProps={{ shrink: true }}
						small
						InputProps={{
							endAdornment: <InputAdornment position="end">{adornment[key]}</InputAdornment>,
						}}
						placeholder={placeholder[key] || ""}
						disabled={disabled}
						error={
							error[key] ||
							// valores fuera de rango
							(inputValues[key] !== "" && (inputValues[key] < COORD_TYPE.fields[key].min || inputValues[key] > COORD_TYPE.fields[key].max))
						}
						required={required}
						onKeyDown={(e) => {
							if (COORD_TYPE.fields[key].type !== Number) {
								return;
							}

							// manejar eventos de flechas arriba y abajo
							if (e.key === "ArrowUp" || e.key === "ArrowDown") {
								e.preventDefault();
								let value = parseFloat(e.target.value);
								// si esta presionada la tecla shift, sumar shiftStep
								let step;
								if (e.shiftKey) {
									step = COORD_TYPE.fields[key].shiftStep;
								} else {
									step = COORD_TYPE.fields[key].step;
								}
								// sumar o restar segun la flecha
								if (e.key === "ArrowUp") {
									value += step;
								} else {
									value -= step;
								}
								// limitar a los valores maximo y minimo
								value = Math.min(Math.max(value, COORD_TYPE.fields[key].min), COORD_TYPE.fields[key].max);
								value = value.toFixed(6);
								// actualizar input y desencadenar evento onChange
								e.target.value = value;
								handleChange(e);
							}
						}}
					/>
				</Grid>
			))}
		</Grid>
	);
};

export default Inputs;
