import { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import { MessagesContext } from '../../../context';

/**
 * Componente para agregar múltiples imágenes con descripciones o sin ellas
 * @param {boolean} imageHasDescription: booleano que indica si las imágenes tienen descripción
 * @param {function} setImages: función para actualizar la lista de imágenes
 * @param {Array} images: lista de imágenes
 * @returns 
 */
const UploadImages = ({ imageHasDescription, setImages, images }) => {
    const maxImages = 100;
    const maxSize = 100 * 1024 * 1024; // 100 MB
    const { addMessage } = useContext(MessagesContext);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
        maxFiles: maxImages,
        onDrop: acceptedFiles => {
            const updatedFiles = acceptedFiles.map(file => {
                if (file.size > maxSize) {
                    addMessage(`La imagen ${file.name} excede el tamaño máximo permitido de ${maxSize / 1024 / 1024}MB`, "error");
                    return null;
                }
                else {
                    return {
                        image: file,
                        description: ''
                    }
                };
            });

            //no se toman los que son nulos (los que excedieron el tamaño)
            const validFiles = updatedFiles.filter(file => file !== null);
            setImages(prev => [...prev, ...validFiles]);
        }
    });
    console.log(images);

    const handleCommentChange = (index, comment) => {
        setImages(prev => {
            const updatedFiles = [...prev];
            if (imageHasDescription) updatedFiles[index].description = comment;
            return [...updatedFiles];
        })
    }

    return (
        <>
            <div {...getRootProps({ className: 'dropzone' })}
                style={{
                    border: '2px solid #22ACA4',
                    borderRadius: '10px',
                    backgroundColor: '#DEF3F1',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '150px',
                    position: 'relative',
                }}>
                <input {...getInputProps()} />
                <CloudUploadOutlinedIcon style={{ color: '#22ACA4', fontSize: '3rem' }} />
                <p style={{ color: '#22ACA4', textAlign: 'center', margin: '0px 0' }}><strong>Arrastra las imágenes aquí, o click para seleccionar</strong></p>
                <p style={{ color: '#22ACA4', textAlign: 'center', fontSize: '0.8rem', margin: '0px 0' }}><em>{images.length} agregada(s)</em></p>
                <p style={{ color: '#22ACA4', textAlign: 'center', fontSize: '0.8rem', margin: '0px 0' }}><em>máximo {maxImages}</em></p>
            </div>

            <div sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                {images.map((item, index) => (
                    <Box
                        key={item.image.name}
                        sx={{
                            border: '2px solid #22ACA4',
                            maxWidth: '100%',
                            height: 'auto',
                            maxHeight: '250px',
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '10px',
                            margin: '10px',
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <IconButton
                                style={{
                                    margin: '10px',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    backgroundColor: 'white',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                }}
                                onClick={() => {
                                    // elimina la imagen de la lista
                                    // si la imagen ya estaba en la db, tambien se agrega a la lista de eliminados
                                    setImages(prev => prev.filter((_, i) => i !== index));
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Grid item xs={12} md={imageHasDescription ? 6 : 12} style={{ textAlign: 'center' }}>
                                {/* <img
                                    src={typeof item.image === "object" ? item.image.preview : item.image}
                                    alt='Error con la imagen'
                                    // Data uri is revoked when submited/exit
                                    style={{ display: 'block', margin: '0 auto', width: 'auto', maxHeight: '180px', maxWidth: '100%' }}
                                /> */}
                                <Typography variant="caption" display="block" gutterBottom>{item.image.name}</Typography>
                            </Grid>
                            {imageHasDescription && <Grid item xs={12} md={5}>
                                <TextField
                                    label="Añadir un comentario (opcional)..."
                                    variant="outlined"
                                    multiline
                                    rows={7}
                                    fullWidth
                                    style={{ flex: 1 }}
                                    value={item.description}
                                    onChange={(e) => handleCommentChange(index, e.target.value)}
                                    InputProps={{ defaultValue: item.description }}
                                />
                            </Grid>}
                        </Grid>
                    </Box>
                ))}
            </div>
        </>
    );
};

export default UploadImages;