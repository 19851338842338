import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, Tooltip, CircleMarker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { findCentralPoint, generateStructurePointsFromCentros } from "../../utils/map_helper";

const MapEvents = ({ onMapClick, setZoom }) => {
	useMapEvents({
		click: (e) => {
			onMapClick && onMapClick(e);
		},
		zoomend: (e) => {
			setZoom && setZoom(e.target.getZoom());
		},
	});
	return null;
};

export function PreviewMap({ latitud, longitud, key, input, onMapClick, mapZoom, setMapZoom, otherObjects = [] }) {
	const [mapKey, setMapKey] = useState(0);
	//Actualiza las coordenadas del mapa
	useEffect(() => {
		setMapKey((prevKey) => prevKey + 1);
	}, [latitud, longitud, key]);

	//por si se ingresa un valor invalido, el mapa no muere
	if (isNaN(latitud)) {
		latitud = "";
	}
	if (isNaN(longitud)) {
		longitud = "";
	}
	//esto deberia ser las coordinates que estan en la data del centro
	//const centroString = "-33.4565,-70.6645;-33.4565,-70.6635;-33.4575,-70.6635;-33.4575,-70.6645;"
	const centroString = input.coordinates;

	const CentralPoint = findCentralPoint(centroString);
	const mapCoordinates = [latitud !== "" ? latitud : CentralPoint[0], longitud !== "" ? longitud : CentralPoint[1]];
	const centro = generateStructurePointsFromCentros([input]);

	const otherObjectsCoordinates = otherObjects.map((object) => {
		return {
			lat: object.latitude,
			lon: object.longitude,
		};
	});

	return (
		<>
			<MapContainer
				key={mapKey}
				center={!(isNaN(latitud) || isNaN(longitud)) ? mapCoordinates : [latitud, longitud]}
				zoom={mapZoom}
				attributionControl={false}
			>
				<MapEvents onMapClick={onMapClick} setZoom={setMapZoom} />
				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={25} />
				{/* Centro */}
				{centro?.features.map((feature) => (
					<GeoJSON
						data={feature.geometry}
						style={{
							color: feature.properties.color,
							fillOpacity: 0.2,
						}}
					/>
				))}

				<CircleMarker center={mapCoordinates} radius={7} color="black" weight={0.5} fillOpacity={1}>
					<Tooltip direction="top" permanent offset={[0, -10]}>
						Aquí se esta agregando el objeto
						{/* (
            {`LAT: ${mapCoordinates[0].toFixed(4)} | LON: ${mapCoordinates[1].toFixed(4)}`}
            ) */}
					</Tooltip>
				</CircleMarker>
				{/* Otros objetos */}
				{otherObjectsCoordinates.map((object) => (
					<CircleMarker
						center={[object.lat, object.lon]}
						radius={7}
						color="white"
						weight={0.5}
						fillOpacity={1}
					></CircleMarker>
				))}
			</MapContainer>
		</>
	);
}
