import { useState } from "react";
import { dummyData } from "./Common";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../App";
import NormalDay from "./NormalDay";
import Prospection from "./Prospection";
import FeedingBacterium from "./FeedingBacterium";
import GenericReport from "./GenericReport";

const OfflineReport = ({ data }) => {
	const [reportData, setReportData] = useState(
		data || JSON.parse(document.getElementById("reportData")?.textContent || "{}")
	);
	// const [reportData, setReportData] = useState(data || dummyData);

	return (
		<ThemeProvider theme={theme}>
			{reportData?.type === "Día normal" && <NormalDay reportData={reportData} />}
			{reportData?.type === "Prospección de fondo" && <Prospection reportData={reportData} />}
			{reportData?.type === "Alimentación y bacterias" && <FeedingBacterium reportData={reportData} />}
			{reportData?.type !== "Día normal" &&
				reportData?.type !== "Prospección de fondo" &&
				reportData?.type !== "Alimentación y bacterias" && <GenericReport reportData={reportData} />}
		</ThemeProvider>
	);
};

export default OfflineReport;
