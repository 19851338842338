// mui components
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

// mui icons
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
// components
import { AdminDialogForm } from "./";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";

/**
 * Componente para listar administradores de una empresa
 * @param {Object} props
 * @param {Number} props.companyId ID de la empresa a la que pertenecen los usuarios
 * @param {Function} props.openDetail Función para abrir el dialogo de detalle de un usuario
 * @param {Boolean} props.dataReload Indica si los datos deben ser recargados
 * @param {Function} props.setDataReload Función para indicar que los datos deben ser recargados
 */
const AdminList = ({ companyId, openDetail, dataReload, setDataReload }) => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);

	const { addMessage } = useMessagesContext();
	const { axiosInstance } = useAuthContext();

	useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(`${URLS.WRECK_RESCUE_COMPANY}${companyId}/admins/`)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al cargar los datos", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [companyId, axiosInstance, addMessage, dataReload, setDataReload]);

	const columns = [
		{
			field: "first_name",
			headerName: "Nombre",
			renderCell: (row) => row.user.first_name,
			valueGetter: (row) => row.user.first_name,
		},
		{
			field: "last_name",
			headerName: "Apellido",
			renderCell: (row) => row.user.last_name,
			valueGetter: (row) => row.user.last_name,
		},
		{
			field: "email",
			headerName: "Correo",
			renderCell: (row) => row.user.email,
			valueGetter: (row) => row.user.email,
		},
		{
			field: "is_active",
			headerName: "Activo",
			renderCell: (row) => {
				return row.user.is_active === true ? <CheckIcon /> : <CloseIcon />;
			},
			valueGetter: (row) => {
				return row.user.is_active;
			},
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<GeneralButton text="Editar" onClick={() => openDetail(row.id, row)} variant={"small"} />
				</div>
			),
		},
	];

	return <Table rows={rows} columns={columns} loading={loading} />;
};

/**
 * Componente para listar y agregar/modificar administradores de una empresa
 * @param {Object} props
 * @param {Number} props.companyId ID de la empresa a la que pertenecen los usuarios
 */
const Admins = ({ companyId }) => {
	const [dialog, setDialog] = useState({
		open: false,
		id: null,
		data: null,
	});

	const [dataReload, setDataReload] = useState(true);

	const openDialog = (id = null, data = null) => {
		setDialog({
			open: true,
			id: id,
			data: data,
		});
	};

	const exitDialog = () => {
		setDialog({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			<div style={{ padding: "20px 34px", display: "flex", justifyContent: "space-between" }}>
				<div>Administradores</div>

				<GeneralButton text={"Agregar administrador"} onClick={() => openDialog()} />
			</div>

			<AdminList
				companyId={companyId}
				openDetail={openDialog}
				dataReload={dataReload}
				setDataReload={setDataReload}
			/>

			<AdminDialogForm open={dialog.open} onClose={exitDialog} data={dialog.data} companyId={companyId} />
		</Box>
	);
};

export default Admins;
