import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "../../Icons/DeleteIcon";
import IconButtons from "../../Icons/IconButtons";

const FileUpload = ({ image, setImage, sx }) => {
	const theme = useTheme();

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			setImage(selectedFile);
		}
	};

	const onDelete = () => {
		setImage(null);
	};

	return (
		<>
			<Box
				sx={{
					width: "200px",
					height: "200px",
					color: theme.palette.brandColors.primary,
					position: "relative",
					border: "3px solid " + theme.palette.brandColors.primary,
					...sx,
				}}
			>
				<div style={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }}>
					<IconButtons
						onClick={onDelete}
						icon={<DeleteIcon />}
						variant="danger"
					>
						<DeleteIcon />
					</IconButtons>
				</div>
				<Box
					sx={{
						display: "flex",
						textAlign: "center",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "100%",
					}}
				>
					{!image ? (
						"Archivo"
					) : (
						<img
							style={{ width: "100%", height: "100%", aspectRatio: "1/1" }}
							alt=""
							src={image && typeof image === "object" ? URL.createObjectURL(image) : image}
							onLoad={() => {
								URL.revokeObjectURL(image);
							}}
						/>
					)}
				</Box>
				<input
					type="file"
					accept="image/*"
					onChange={handleFileChange}
					style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0", opacity: 0 }}
				/>
			</Box>
		</>
	);
};

export default FileUpload;
