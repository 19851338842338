import React from 'react'
import {    Typography, Dialog, DialogActions, 
            DialogContent, DialogContentText, 
            Button, Grid    
        } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export default function WarningDialog(props) {
  return (
    <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                  width: '500px', 
                },
              }}
        >
        <DialogContent>
            <Grid container alignItems="center">
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                    <WarningIcon sx={{ color: 'yellow', fontSize: '5rem' }} />
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='h6'>{props.title}</Typography>
                    <DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
                </Grid>
            </Grid>
        </DialogContent>
            
        <DialogActions>
          <Button onClick={props.handleButton1} variant='contained'>
            {props.Button1Text}
          </Button>
          <Button onClick={props.handleButton2} color="primary" autoFocus>
            {props.Button2Text}
          </Button>
        </DialogActions>
      </Dialog>
  )
}

