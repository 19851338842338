
// Componente para abstraer las páginas que no necesitan de un usuario
const AnonymousRoute = ({component}) => {
    return (
        <>
            {component}
        </>
    );
};

export default AnonymousRoute;