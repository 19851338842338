import { Box, Typography, Select, MenuItem, FormControl, TextField, FormHelperText } from "@mui/material";
import { useEffect } from "react";
import TextInput from "../../Inputs/TextInput";

const GeneralData = ({ generalData, setGeneralData, errors, setErrors, TYPES, setCheckErrors }) => {
	const checkGeneralDataErrors = () => {
		const newErrors = {};
		if (generalData.type === -1) {
			newErrors.type = {
				error: true,
				msg: "Debe seleccionar un tipo de consolidado",
			};
		}

		if (generalData.center === -1) {
			newErrors.center = {
				error: true,
				msg: "Debe seleccionar un centro de cultivo",
			};
		}
		if (generalData.initialDate === "") {
			newErrors.initialDate = {
				error: true,
				msg: "Debe seleccionar una fecha de inicio",
			};
		}
		if (generalData.finalDate === "") {
			newErrors.finalDate = {
				error: true,
				msg: "Debe seleccionar una fecha de término",
			};
		}
		if (
			generalData.initialDate !== "" &&
			generalData.finalDate !== "" &&
			generalData.initialDate >= generalData.finalDate
		) {
			newErrors.initialDate = {
				error: true,
				msg: "La fecha de inicio debe ser menor a la fecha de término",
			};
			newErrors.finalDate = {
				error: true,
				msg: "La fecha de término debe ser mayor a la fecha de inicio",
			};
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length > 0;
	};

	useEffect(() => {
		setCheckErrors(() => () => checkGeneralDataErrors());
	}, [generalData, setCheckErrors]);

	return (
		<div style={{ padding: "20px 30px" }}>
			<Box sx={{ display: "flex", margin: "10px 0", gap: "20px" }}>
				<TextInput
					label="Centro"
					required
					select
					error={errors?.center?.msg}
					value={generalData.center}
					onChange={(e) => setGeneralData((prev) => ({ ...prev, center: e.target.value }))}
				>
					<MenuItem value={-1} key={-1}>
						<em>Seleccionar Centro</em>
					</MenuItem>
					{generalData.centers.map((center) => (
						<MenuItem value={center.id} key={center.id}>
							{center.name}
						</MenuItem>
					))}
				</TextInput>
				<TextInput
					label="Tipo"
					required
					select
					error={errors?.type?.msg}
					value={generalData.type}
					onChange={(e) => setGeneralData((prev) => ({ ...prev, type: e.target.value }))}
				>
					<MenuItem value={-1} key={-1}>
						<em>Seleccionar Tipo</em>
					</MenuItem>
					<MenuItem value={"RRN"} key={1}>
						General
					</MenuItem>
					<MenuItem value={"PROSPECCION"} key={2}>
						Prospección de Fondo
					</MenuItem>
				</TextInput>
			</Box>
			<Box sx={{ display: "flex", margin: "10px 0", gap: "20px" }}>
				<TextInput
					label={"Fecha de Inicio"}
					required
					type="date"
					error={errors?.initialDate?.msg}
					value={generalData.initialDate}
					onChange={(e) => setGeneralData((prev) => ({ ...prev, initialDate: e.target.value }))}
				/>
				<TextInput
					label={"Fecha de Fin"}
					required
					type="date"
					error={errors?.finalDate?.msg}
					value={generalData.finalDate}
					onChange={(e) => setGeneralData((prev) => ({ ...prev, finalDate: e.target.value }))}
				/>
			</Box>
		</div>
	);
};

export default GeneralData;
