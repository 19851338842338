// mui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";

// components
import { SalmonCompanyDetail, CultivationCenters, EditSalmonCompanyDialog, MergeCompanies } from "./";
import GeneralButton from "../../GeneralButton";
import { Table } from "../../Table";
import FishIcon from "../../Icons/FishIcon";
import { useEffect, useState } from "react";

/**
 * Componente que muestra la lista de empresas productoras
 * @param {Object} props
 * @param {Function} props.openDetail Función para abrir el detalle de empresa productora
 * @param {Boolean} props.dataReload Indica si los datos deben ser recargados
 * @param {Function} props.setDataReload Función para indicar que los datos deben ser recargados
 */
export const SalmonCompanyList = ({ openDetail, dataReload, setDataReload }) => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);

	const { axiosInstance } = useAuthContext();
	const { addMessage } = useMessagesContext();

	useEffect(() => {
		const requestData = async () => {
			axiosInstance
				.get(URLS.SALMON_COMPANY)
				.then((response) => {
					setRows(response.data);
				})
				.catch((error) => {
					addMessage("Error al cargar las empresas productoras", "error");
				})
				.finally(() => {
					setLoading(false);
				});
		};

		if (dataReload) {
			requestData();
			setDataReload(false);
		}
	}, [axiosInstance, addMessage, dataReload, setDataReload]);

	const columns = [
		{
			field: "name",
			headerName: "Nombre titular",
			sortable: true,
		},
		{
			field: "location",
			headerName: "Ubicación",
			sortable: true,
		},
		{
			field: "actions",
			renderCell: (row) => (
				<div style={{ display: "flex", justifyContent: "end" }}>
					<GeneralButton
						text="Ver Empresa"
						onClick={() => openDetail({ id: row.id, data: row, open: true })}
						variant="small"
					/>
				</div>
			),
		},
	];

	return <Table rows={rows} columns={columns} loading={loading} />;
};

/**
 * Componente que enlista las empresas productoras, permite agregar nuevas empresas productoras y acceder a su detalle
 */
const SalmonCompanies = ({ setHide }) => {
	const theme = useTheme();
	const [detailDisplay, setDetailDisplay] = useState({
		open: false,
		id: null,
		data: null,
	});

	const [dialog, setDialog] = useState({
		open: false,
		id: null,
		data: null,
	});

	const [dataReload, setDataReload] = useState(true);

	const openDetail = ({ id, data }) => {
		setDetailDisplay({
			open: true,
			id: id,
			data: data,
		});
	};

	const exitDetailDisplay = () => {
		setDetailDisplay({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};

	const openDialog = () => {
		setDialog({
			open: true,
			id: null,
			data: null,
		});
	};

	const exitDialog = () => {
		setDialog({
			open: false,
			id: null,
			data: null,
		});

		setDataReload(true);
	};

	const [isMerging, setIsMerging] = useState(false);

	const openMergeCompanies = () => {
		setIsMerging(true);
	};

	const exitMergeCompanies = () => {
		setDataReload(true);
		setIsMerging(false);
	};

	useEffect(() => {
		setHide(detailDisplay.open);
	}, [detailDisplay.open, setHide]);

	return (
		<>
			{isMerging && <MergeCompanies onClose={exitMergeCompanies} />}
			{detailDisplay.open ? (
				<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
					<SalmonCompanyDetail onClose={exitDetailDisplay} data={detailDisplay.data} />

					<CultivationCenters companyId={detailDisplay.data?.id} />
				</div>
			) : (
				<>
					<Box sx={{ width: "100%", position: "relative" }}>
						<div style={{ position: "absolute", top: "-60px", left: "20px" }}>
							<GeneralButton
								text="Agregar Empresa"
								onClick={openDialog}
								sx={{ padding: "8px 15px" }}
								icon={<FishIcon color="white" />}
							/>
						</div>
						<div style={{ position: "absolute", top: "-60px", right: "20px" }}>
							<GeneralButton
								text="Fusionar Empresas"
								onClick={openMergeCompanies}
								sx={{
									padding: "8px 15px",
									backgroundColor: "white",
									color: theme.palette.brandColors.primary,
									marginBottom: "20px",
									borderRadius: "10px",
									border: `1px solid ${theme.palette.brandColors.primary}`,
									padding: "6px 20px",
								}}
							/>
						</div>
					</Box>

					<SalmonCompanyList openDetail={openDetail} dataReload={dataReload} setDataReload={setDataReload} />

					<EditSalmonCompanyDialog open={dialog.open} onClose={exitDialog} data={dialog.data} />
				</>
			)}
		</>
	);
};

export default SalmonCompanies;
