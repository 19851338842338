/**
 * @fileoverview Contiene funciones para el mapa.
 * NOTAS:
 * - los geojsons son FeatureCollections, no Features.
 * - las coordenadas de los puntos son [lon, lat], no [lat, lon]. (https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.1)
 *   (pero leaflet usa [lat, lon], tener en consideracion)
 */

export const estado_color_map = {
	Balizado: "red",
	"En Embarcación": "rgb(4, 208, 0)",
	Entregado: "#ffc176",
};
export const feature_type_map = {
	Objeto: "Objeto",
	Jaula: "Jaula",
	Centro: "Centro",
	Poligono: "Poligono",
	Embarcacion: "Embarcacion",
};
export const tipo_color_map = {
	Jaula: "gray",
	Centro: "white",
	Poligono: "aquamarine",
};

/**
 * Función para dado un string parsearlo como una lista de puntos
 * @param {string} coordinates_string String con los puntos separados por ;
 * @returns {Array} Lista de puntos en formato [lon, lat]
 */
export const parse_coordinates = (coordinates_string) => {
	let coordinates = [];
	let points = coordinates_string.split(";");
	for (let point in points) {
		if (points[point] === "") {
			continue;
		}
		const lat_lon = points[point].split(",");
		coordinates.push([parseFloat(lat_lon[1]), parseFloat(lat_lon[0])]);
	}
	return coordinates;
};

/**
 * Dado los datos de objetos retorna el objeto que los representa
 * @param {Array} data Datos de los objetos
 * @returns {Array} Lista de puntos en formato geojson
 */
export const generateObjectsPoints = (data) => {
	let points = [];
	for (let point in data) {
		points.push({
			type: "Feature",
			properties: {
				// map properties
				data_type: feature_type_map.Objeto,
				color: estado_color_map[data[point].state],
				selected: false,
				// object properties
				id: data[point].id,
				type: data[point].type,
				state: data[point].state,
				volume: data[point].volume,
				submarineImage: data[point].submarineImage,
				inBoatImage: data[point].inBoatImage,
				report: data[point].report,
				receptionNote: data[point].receptionNote,
				comments: data[point].comments,
				notify: data[point].notify,
				// location properties
				latitude: data[point].latitude,
				longitude: data[point].longitude,
			},
			geometry: {
				type: "Point",
				coordinates: { lat: data[point].latitude, lon: data[point].longitude },
			},
		});
	}

	const features = {
		type: "FeatureCollection",
		features: points,
	};

	return features;
};

export const generateImagePoints = (data) => {
	const points = data.map((image) => {
		return {
			type: "Feature",
			properties: {
				// map properties
				data_type: feature_type_map.Objeto,
				color: "#48beff",
				id: image.id,
				description: image.description,
				image: image.image,
				// location properties
				latitude: image.latitude,
				longitude: image.longitude,
				date: image.date,
				cultivationCenter: image.cultivationCenter,
				state: "IMAGE",
			},
			geometry: {
				type: "Point",
				coordinates: { lat: image.latitude, lon: image.longitude },
			},
		};
	});

	const features = {
		type: "FeatureCollection",
		features: points,
	};

	return features;
};

export const generateFeedingBacteriumPoints = (data) => {
	const points = data.map((image) => {
		return {
			type: "Feature",
			properties: {
				// map properties
				data_type: feature_type_map.Objeto,
				color: "#4f5989",
				id: image.id,
				description: image.description,
				image: image.image,
				// location properties
				latitude: image.latitude,
				longitude: image.longitude,
				date: image.date,
				cultivationCenter: image.cultivationCenter,
				state: "FEEDING_BACTERIUM",
				hasFeed: image.hasFeed,
				hasBacterium: image.hasBacterium,
				hasFeces: image.hasFeces,
				feedRate: image.feedRate,
				bacteriumRate: image.bacteriumRate,
				fecesRate: image.fecesRate,
			},
			geometry: {
				type: "Point",
				coordinates: { lat: image.latitude, lon: image.longitude },
			},
		};
	});

	const features = {
		type: "FeatureCollection",
		features: points,
	};

	return features;
};

export const daysBetween = (date1, date2) => {
	const one_day = 1000 * 60 * 60 * 24;
	const date1_ms = date1.getTime();
	const date2_ms = date2.getTime();
	const difference_ms = date2_ms - date1_ms;
	return Math.round(difference_ms / one_day);
};

export const generateVideoPoints = (data) => {
	const points = data.map((video) => {
		const days = daysBetween(new Date(video.date), new Date());
		let borderColor = "white";
		if (days >= 30) {
			borderColor = "#777"
		} else if (days >= 14) {
			borderColor = "#a0a0a0";
		} else if (days >= 7) {
			borderColor = "#c0c0c0";
		}

		return {
			type: "Feature",
			properties: {
				// map properties
				data_type: feature_type_map.Objeto,
				id: video.id,
				description: video.description,
				video: video.video,
				// location properties
				latitude: video.latitude,
				longitude: video.longitude,
				date: video.date,
				color: video.type === "ROV"? "#C14CE2" : "#FFBDE1",
				borderColor: borderColor,
				type: video.type,
				state: "VIDEO",
			},
			geometry: {
				type: "Point",
				coordinates: { lat: video.latitude, lon: video.longitude },
			},
		};
	});

	const features = {
		type: "FeatureCollection",
		features: points,
	};

	return features;
};

export const generateBoatPoints = (data) => {
	const points = data.map((boat) => {
		return {
			type: "Feature",
			properties: {
				// map properties
				data_type: feature_type_map.Embarcacion,
				color: "yellow",
				name: boat.name,
				// location properties
				latitude: boat.latitude,
				longitude: boat.longitude,
			},
			geometry: {
				type: "Point",
				coordinates: { lat: boat.latitude, lon: boat.longitude },
			},
		};
	});

	const features = {
		type: "FeatureCollection",
		features: points,
	};

	return features;
};

/**
 * Genera los poligonos de los centros de cultivo y las jaulas
 * @param {Array} data Datos de los centros de cultivo y las jaulas
 * @returns {Array} Lista de estructuras en formato geojson
 */
export const generateStructurePointsFromCentros = (data) => {
	let centers = [];
	let cages = [];
	let polygons = [];

	data.forEach((center) => {
		// agrego el centro
		centers.push({
			type: "Feature",
			properties: {
				// map properties
				data_type: feature_type_map.Centro,
				color: tipo_color_map.Centro,
				selected: false,
				// centro properties
				id: center.id,
				salmonCompany: center.salmonCompany,
				coordinates: center.coordinates,
				code: center.code,
				name: center.name ? center.name : `Centro ${center.id}`,
				place: center.place,
				manager: center.manager,
				email: center.email,
				surface: center.surface,
				wreckRescueCompany: center.wreckRescueCompany,
			},
			geometry: {
				type: "Polygon",
				coordinates: [parse_coordinates(center.coordinates)],
			},
		});
		// agrego las jaulas
		center.cages?.forEach((cage) => {
			cages.push({
				type: "Feature",
				properties: {
					// map properties
					data_type: feature_type_map.Jaula,
					color: tipo_color_map.Jaula,
					selected: false,
					// jaula properties
					id: cage.id,
					name: cage.name,
					coordinates: cage.coordinates,
					cultivationCenter: center.name,
					depth: cage.depth,
				},
				geometry: {
					type: "Polygon",
					coordinates: [parse_coordinates(cage.coordinates)],
				},
			});
		});
		// agrego los poligonos
		center.polygons?.forEach((polygon) => {
			polygons.push({
				type: "Feature",
				properties: {
					// map properties
					data_type: feature_type_map.Poligono,
					color: tipo_color_map.Poligono,
					selected: false,
					// poligono properties
					id: polygon.id,
					name: polygon.name ? polygon.name : `Poligono ${polygon.id}`,
					description: polygon.description,
					coordinates: polygon.coordinates,
					cultivationCenter: center.name,
				},
				geometry: {
					type: "Polygon",
					coordinates: [parse_coordinates(polygon.coordinates)],
				},
			});
		});
	});

	const features = {
		type: "FeatureCollection",
		features: [].concat(centers, cages, polygons),
	};

	return features;
};

/**
 * Funcion para parsear coordenadas y obtener el punto central de estas
 * @param {Array} coordinatesString String con los puntos separados por ;
 * @returns {Array} Posicion del centro en formato [lat, lon]
 */
export const findCentralPoint = (coordinatesString) => {
	const coordinates = parse_coordinates(coordinatesString);

	const totalVertices = coordinates.length;
	const sumaLatitud = coordinates.reduce((sum, punto) => sum + punto[1], 0);
	const sumaLongitud = coordinates.reduce((sum, punto) => sum + punto[0], 0);

	const puntoCentral = [sumaLatitud / totalVertices, sumaLongitud / totalVertices];

	return puntoCentral;
};

export const pointInPolygon = (point, polygon) => {
	let x = point[0],
		y = point[1];
	let inside = false;
	for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
		let xi = polygon[i][0],
			yi = polygon[i][1];
		let xj = polygon[j][0],
			yj = polygon[j][1];
		let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
		if (intersect) inside = !inside;
	}
	return inside;
};
