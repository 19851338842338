import { Stack, Typography, Box, FormControl, DialogContent, Button } from "@mui/material";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context";
import { URLS } from "../../global";
import { DataGrid } from "@mui/x-data-grid";
import GeneralButton from "../GeneralButton";
import AddIcon from "@mui/icons-material/Add";

const AdditionalCycleForm = ({ boatLog, addCycle, onClose }) => {
	const { axiosInstance } = useAuthContext();
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState({
		initialDate: addCycle?.initialDate || "",
		endDate: addCycle?.endDate || "",
		description: addCycle?.description || "",
	});
	const [error, setError] = useState({
		description: false,
		initialDate: false,
		endDate: false,
	});

	const handleSave = () => {
		const errors = {};
		setLoading(true);
		if (!data.description) {
			errors.description = true;
		}

		if (!data.initialDate) {
			errors.initialDate = true;
		}
		if (!data.endDate) {
			errors.endDate = true;
		}

		if (data.initialDate > data.endDate) {
			errors.initialDate = true;
			errors.endDate = true;
		}
		setError(errors);

		if (data.initialDate && data.endDate && data.description) {
			const formData = {
				initialDate: data.initialDate,
				endDate: data.endDate,
				description: data.description,
				boatLog: boatLog,
			};
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
				method: addCycle ? "PUT" : "POST",
				data: formData,
				url: addCycle ? URLS.ADDITIONAL_BOAT_LOGS + addCycle.id + "/" : URLS.ADDITIONAL_BOAT_LOGS,
			};

			setLoading(true);
			axiosInstance(config)
				.then((response) => {
					setLoading(false);
					onClose(true);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<DialogContent sx={{ marginTop: "20px", height: "50vh" }}>
				<Stack direction="row" spacing={2}>
					<FormControl sx={{ width: "100%" }}>
						<Typography>Descripción</Typography>
						<TextField
							required
							id="description"
							error={error.description}
							helperText={error.description ? "Campo Requerido" : ""}
							onChange={(e) => setData({ ...data, description: e.target.value })}
							value={data.description}
						/>
					</FormControl>

					<FormControl sx={{ width: "100%" }}>
						<Typography>Fecha Inicio</Typography>
						<TextField
							required
							id="initialDate"
							type="date"
							error={error.initialDate}
							helperText={error.initialDate ? "Campo Requerido" : ""}
							onChange={(e) =>
								setData({
									...data,
									initialDate: e.target.value,
								})
							}
							value={data.initialDate}
						/>
					</FormControl>

					<FormControl sx={{ width: "100%" }}>
						<Typography>Fecha Término</Typography>
						<TextField
							required
							id="endDate"
							type="date"
							helperText={error.endDate ? "Campo Requerido" : ""}
							error={error.endDate}
							onChange={(e) => setData({ ...data, endDate: e.target.value })}
							value={data.endDate}
						/>
					</FormControl>
				</Stack>
			</DialogContent>

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "20px",
				}}
			>
				<Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="end" flexGrow={1}>
					<Button variant="contained" disabled={loading} onClick={handleSave}>
						Guardar
					</Button>
				</Stack>
			</Box>
		</>
	);
};

const AdditionalCycleList = ({ additionalLogs, setAddCycle, setOpen }) => {
	return (
		<DialogContent sx={{ marginTop: "20px", height: "70vh" }}>
			<GeneralButton
				text="Añadir Relevo"
				icon={<AddIcon />}
				onClick={() => {
					setAddCycle(null);
					setOpen(true);
				}}
			/>
			<DataGrid
				sx={{ height: "80%" }}
				columns={[
					{ field: "id", headerName: "ID", width: 70, align: "center" },
					{ field: "initialDate", headerName: "Fecha Inicio", width: 150, align: "center" },
					{ field: "endDate", headerName: "Fecha Término", width: 150, align: "center" },
					{ field: "description", headerName: "Descripción", width: 150, align: "center" },
				]}
				rows={additionalLogs}
				onRowClick={(row) => setAddCycle(row.row)}
			/>
		</DialogContent>
	);
};

const AdditionalCycle = ({ boatLog, setReload }) => {
	const [addCycle, setAddCycle] = useState(null);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (addCycle) {
			setOpen(true);
		}
	}, [addCycle]);

	return (
		<>
			{open ? (
				<AdditionalCycleForm
					boatLog={boatLog.id}
					addCycle={addCycle}
					onClose={(reload) => {
						setAddCycle(null);
						setOpen(false);
						setReload(reload);
					}}
				/>
			) : (
				<AdditionalCycleList additionalLogs={boatLog?.additionalLogs ? boatLog.additionalLogs : []} setAddCycle={setAddCycle} setOpen={setOpen} />
			)}
		</>
	);
};

export default AdditionalCycle;
