import { useState, useEffect, useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const TableContainer = styled("table")(({ theme }) => ({
	minWidth: "100%",
	textAlign: "left",
	tableLayout: "auto",
	borderCollapse: "collapse",
}));

const TableHeader = styled("th", {
	shouldForwardProp: (prop) => prop !== "sortable" && prop !== "ialign" && prop !== "variant",
})(({ theme, sortable, ialign, variant }) => ({
	cursor: sortable ? "pointer" : "default",
	userSelect: "none",
	padding: variant === "small" ? "10px 0 2px 10px" : "10px 0 2px 30px",
	borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	textAlign: ialign ? ialign : "left",
}));

const TableCell = styled("td", {
	shouldForwardProp: (prop) => prop !== "ialign" && prop !== "variant",
})(({ ialign, theme, variant }) => ({
	padding: variant === "small" ? "10px 10px 10px 10px" : "10px 20px 10px 30px",
	borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
	textAlign: ialign ? ialign : "left",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	height: "54px",
	fontSize: "14px",
}));

const Table = ({ rows, columns, onRowClick, sorting, filter, firstCellRender, variant }) => {
	const [innerRows, setInnerRows] = useState([...rows]);
	const [sortingColumn, setSortingColumn] = useState({ ...sorting });
	const tableRef = useRef(null);
	const [page, setPage] = useState(1);
	const rowsPerPage = 20;
	const pages = Math.ceil(rows.length / rowsPerPage);

	const loadMoreRows = useCallback(() => {
		setPage((prev) => Math.min(prev + 1, pages));
	}, [page, rows, innerRows]);

	const handleScroll = useCallback(
		(event) => {
			const bottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight < 25;
			if (bottom) {
				loadMoreRows();
			}
		},
		[loadMoreRows]
	);

	useEffect(() => {
		const container = tableRef.current;
		container.addEventListener("scroll", handleScroll);

		return () => {
			container.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll, tableRef]);

	useEffect(() => {
		if (sortingColumn.field) {
			setInnerRows([
				...rows.sort((a, b) => {
					if (sortingColumn.sort === "asc") {
						return a[sortingColumn.field] > b[sortingColumn.field] ? 1 : -1;
					} else {
						return a[sortingColumn.field] < b[sortingColumn.field] ? 1 : -1;
					}
				}),
			]);
		}
	}, [sortingColumn, rows]);

	useEffect(() => {
		if (filter) {
			setInnerRows([
				...rows.filter((row) => {
					return columns.some((column) => {
						return (
							(column.valueGetter &&
								column.valueGetter(row).toString().toLowerCase().includes(filter.toLowerCase())) ||
							(row[column.field] &&
								row[column.field].toString().toLowerCase().includes(filter.toLowerCase()))
						);
					});
				}),
			]);
		} else {
			setInnerRows([...rows]);
		}
	}, [filter, rows]);

	return (
		<div ref={tableRef} style={{ width: "100%", overflow: "auto", maxHeight: "100%", display: "flex" }}>
			<TableContainer
				style={{
					minWidth: "100%",
					textAlign: "left",
					tableLayout: "auto",
					borderCollapse: "collapse",
				}}
			>
				<tbody>
					<tr style={{ position: "sticky", top: "0", backgroundColor: "white", zIndex: 1 }}>
						{columns.map((column, index) => (
							<TableHeader
								key={index}
								sortable={column.sortable}
								variant={variant}
								ialign={column.align}
								onClick={() => {
									if (!column.sortable) return;
									if (sortingColumn.field === column.field) {
										setSortingColumn({
											field: column.field,
											sort: sortingColumn.sort === "asc" ? "desc" : "asc",
										});
									} else {
										setSortingColumn({
											field: column.field,
											sort: "asc",
										});
									}
								}}
							>
								<div style={{ display: "flex", alignItems: "center", width: "100%", flex: "1" }}>
									{column.headerName}
									{sortingColumn.field === column.field ? (
										sortingColumn.sort === "asc" ? (
											<ArrowUpwardIcon sx={{ width: "15px", height: "15px", ml: "5px" }} />
										) : (
											<ArrowDownwardIcon sx={{ width: "15px", height: "15px", ml: "5px" }} />
										)
									) : (
										<ArrowDownwardIcon
											sx={{ width: "15px", height: "15px", ml: "5px", visibility: "hidden" }}
										/>
									)}
								</div>
							</TableHeader>
						))}
					</tr>
					{innerRows.length === 0 && !firstCellRender && (
						<tr>
							<td
								colSpan={columns.length}
								style={{
									padding: "20px 20px 20px 30px",
									fontWeight: "500",
									textAlign: "center",
								}}
							>
								No hay datos
							</td>
						</tr>
					)}

					{firstCellRender && (
						<tr>
							{firstCellRender.map((cell, index) => (
								<TableCell ialign={cell.align} key={index} variant={variant}>
									{cell.renderCell && cell.renderCell()}
								</TableCell>
							))}
						</tr>
					)}

					{innerRows.slice(0, page * rowsPerPage).map((row, index) => (
						<tr
							key={index}
							onClick={(e) => {
								if (onRowClick && !e.defaultPrevented) {
									onRowClick(row);
								}
							}}
							style={{ cursor: onRowClick ? "pointer" : "default" }}
						>
							{columns.map((column, index) => (
								<TableCell key={index} ialign={column.align} variant={variant}>
									{column.renderCell ? column.renderCell(row) : row[column.field] || "-"}
								</TableCell>
							))}
						</tr>
					))}
				</tbody>
			</TableContainer>
		</div>
	);
};

export default Table;
