import { useState } from "react";
import {
	AppName,
	Container,
	Content,
	Data,
	Pair,
	ReportTitle,
	ReportType,
	Subtitle,
	Tab,
	TabContent,
	Tabs,
} from "./Common";
import "./common.css";
import Objects from "./Objects";
import MapContent from "./MapContent";
import GeneralData from "./GeneralData";
import ImagesContent from "./ImagesContent";
import InfoIcon from "../../Icons/InfoIcon";
import { useTheme } from "@mui/material/styles";
import ImageIcon from "../../Icons/ImageIcon";
import BoxIcon from "../../Icons/BoxIcon";
import MapIcon from "../../Icons/MapIcon";
import KayakingIcon from "@mui/icons-material/Kayaking";
import CameraIcon from "../../Icons/CameraIcon";

const NormalDay = ({ reportData }) => {
	const TABS = {
		DATA: 0,
		IMAGES: 1,
		OBJECTS: 2,
		RESCUED_OBJECTS: reportData?.rescuedObjects?.length > 0 ? 3 : -1,
		MAP: reportData?.rescuedObjects?.length > 0 ? 4 : 3,
	};

	const [tab, setTab] = useState(TABS.DATA);
	const theme = useTheme();

	return (
		<Container>
			<ReportTitle>
				<ReportType>Informe diario de trabajo</ReportType>

				<AppName>Rastrum</AppName>
			</ReportTitle>
			<Content nt={TABS.MAP === tab}>
				<Tabs
					centered
					value={tab}
					onChange={(e, v) => setTab(v)}
					tabs={4 + (reportData?.rescuedObjects?.length > 0 ? 1 : 0)}
				>
					<Tab
						label={<div className="text">Datos</div>}
						icon={<InfoIcon color={tab === TABS.DATA ? theme.palette.brandColors.primary : "#848591"} />}
					/>
					<Tab
						label={<div className="text">Imágenes</div>}
						icon={
							<CameraIcon color={tab === TABS.IMAGES ? theme.palette.brandColors.primary : "#848591"} />
						}
					/>
					<Tab
						label={<div className="text">Objetos</div>}
						icon={<BoxIcon color={tab === TABS.OBJECTS ? theme.palette.brandColors.primary : "#848591"} />}
					/>
					{reportData?.rescuedObjects?.length > 0 && (
						<Tab
							label={<div className="text">Objetos rescatados</div>}
							icon={
								<KayakingIcon
									color={tab === TABS.RESCUED_OBJECTS ? theme.palette.brandColors.primary : "#848591"}
								/>
							}
						/>
					)}
					<Tab
						label={<div className="text">Mapa</div>}
						icon={<MapIcon color={tab === TABS.MAP ? theme.palette.brandColors.primary : "#848591"} />}
					/>
				</Tabs>

				<TabContent value={tab} index={TABS.DATA}>
					<GeneralData reportData={reportData} />
				</TabContent>

				<TabContent value={tab} index={TABS.IMAGES}>
					<ImagesContent images={reportData?.images} />
				</TabContent>

				<TabContent value={tab} index={TABS.OBJECTS}>
					<Objects
						objects={reportData?.objects}
						summary={
							<div style={{ display: "flex", justifyContent: "center", margin: "20px 0", gap: "10px" }}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Data md>Cantidad de objetos</Data>
									<Subtitle md>: {reportData?.objects?.length}</Subtitle>
								</div>
								<div>|</div>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Data md>Balizados</Data>
									<Subtitle md>
										: {reportData?.objects?.filter((obj) => obj.state === "Balizado").length}
									</Subtitle>
								</div>
							</div>
						}
					/>
				</TabContent>

				<TabContent value={tab} index={TABS.RESCUED_OBJECTS}>
					<Objects
						objects={reportData?.rescuedObjects?.map((obj) => ({ ...obj, state: "En Embarcación" }))}
						summary={
							<div style={{ display: "flex", justifyContent: "center", margin: "20px 0", gap: "10px" }}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Data md>Cantidad de objetos rescatados</Data>
									<Subtitle md>: {reportData?.rescuedObjects?.length}</Subtitle>
								</div>
							</div>
						}
						addDescription={(obj) => (
							<>
								<div>|</div>
								<Pair title="Fecha de la baliza" value={obj.date} />
							</>
						)}
					/>
				</TabContent>

				<TabContent value={tab} index={TABS.MAP} style={{ height: "100%", padding: 0, margin: 0 }}>
					<MapContent open={TABS.MAP === tab} reportData={reportData} />
				</TabContent>
			</Content>
		</Container>
	);
};

export default NormalDay;
