import { useEffect, useState } from "react";
import GeneralButton from "../../../GeneralButton";
import MapAddorns from "../../../Map/MapAddorns";
import { MapContainer, SwitchContainer, TabContentWrapper } from "./ConsolidatedCommon";
import {
	generateImagePoints,
	generateObjectsPoints,
	generateStructurePointsFromCentros,
} from "../../../../utils/map_helper";
import { middleScreenMedia } from "../Common";

const MapContent = ({ reportData, includeSwitch, open }) => {
	const [mapSwitch, setMapSwitch] = useState(includeSwitch ? 0 : 1);
	const [mapImages, setMapImages] = useState([]);
	const [mapObjects, setMapObjects] = useState([]);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [structures, setStructures] = useState([]);

	useEffect(() => {
		if (open) {
			setIsFullScreen(true);
		}
	}, [open]);

	useEffect(() => {
		if (reportData) {
			setMapObjects(generateObjectsPoints(reportData.objects || []));
			setMapImages(generateImagePoints(reportData.geoImages || []));
			setStructures(generateStructurePointsFromCentros([reportData.cultivationCenter]));
		}
	}, [reportData]);

	return (
		<>
			<TabContentWrapper>
				{includeSwitch && (
					<SwitchContainer>
						<GeneralButton
							variant={mapSwitch === 0 ? "contained" : "empty"}
							onClick={() => setMapSwitch(0)}
							text="Objetos encontrados"
							sx={{
								textTransform: "none",
								[middleScreenMedia]: {
									fontSize: "12px",
									textWrap: "nowrap",
								},
							}}
						/>
						<GeneralButton
							variant={mapSwitch === 1 ? "contained" : "empty"}
							onClick={() => setMapSwitch(1)}
							text="Prospección de fondo"
							sx={{
								textTransform: "none",
								[middleScreenMedia]: {
									fontSize: "12px",
									textWrap: "nowrap",
								},
							}}
						/>
					</SwitchContainer>
				)}
				<MapContainer>
					<MapAddorns
						mapKey={String(isFullScreen)}
						data={{
							objects: mapSwitch === 0 ? mapObjects : [],
							structures: structures,
							images: mapSwitch === 1 ? mapImages : [],
							boats: [],
							videos: [],
							feedingBacteriumImages: [],
						}}
						centros={[]}
						sx={{ width: "100%" }}
						showTable={true}
						hideDates
						hideSelect
						hideShowInMap
					/>
				</MapContainer>
			</TabContentWrapper>
		</>
	);
};

export default MapContent;
