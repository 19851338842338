import { ContextImage, Data, GeneralInfo, ImageContainer, ImagesContainer, Subtitle } from "./Common";

const ImagesContent = ({ images }) => {
	return (
		<GeneralInfo style={{ marginTop: "20px" }}>
			<ImagesContainer>
				{images?.map((img) => (
					<ImageContainer>
						<ContextImage
							src={typeof img.image === "string" ? img.image : URL.createObjectURL(img.image)}
							onLoad={(e) => URL.revokeObjectURL(e.target.src)}
						/>
						<div style={{ display: "flex", gap: "5px" }}>
							<Data md>Descripción: </Data>
							<Subtitle md>{img.description}</Subtitle>
						</div>
					</ImageContainer>
				))}
			</ImagesContainer>

			{images?.length === 0 && (
				<div
					style={{
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						fontSize: "20px",
						width: "100%",
					}}
				>
					No se han subido imágenes para este informe
				</div>
			)}
		</GeneralInfo>
	);
};

export default ImagesContent;
