

const TicketIcon = () => {
    return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
			<path
				d="M0.000244301 3.3458C0.0498375 3.18292 0.121662 3.03191 0.257982 2.91937C0.6066 2.63088 1.05538 2.65154 1.38959 2.97517C2.08731 3.65067 2.78235 4.32855 3.4791 5.00477C3.60467 5.12657 3.57682 5.13013 3.70752 5.00311C5.32333 3.43342 6.93889 1.86325 8.55397 0.292616C8.71936 0.131871 8.9021 0.0100669 9.1464 0.000806859C9.48085 -0.0117772 9.7359 0.122849 9.89518 0.403973C10.0552 0.686522 10.0286 0.96907 9.83875 1.23405C9.80186 1.28557 9.75569 1.33116 9.71 1.3758C7.87652 3.15847 6.04279 4.9409 4.20882 6.72333C3.90149 7.02202 3.54286 7.08067 3.19009 6.89048C3.10409 6.84418 3.03276 6.77913 2.96362 6.71217C2.08804 5.8612 1.21344 5.00928 0.335914 4.16021C0.18347 4.01276 0.0539906 3.85558 0 3.64925V3.3458H0.000244301Z"
				fill="white"
			/>
		</svg>
	);
}

export default TicketIcon;