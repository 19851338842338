import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import DownloadIcon from "../Icons/DownloadIcon";
import IconButtons from "../Icons/IconButtons";

const DownloadItem = ({ id, downloadHTML, downloadPDF, disabled }) => {
	const [menuAnchor, setMenuAnchor] = useState(null);
	return (
		<>
			<IconButtons icon={<DownloadIcon />} onClick={(e) => setMenuAnchor(e.currentTarget)} disabled={disabled} />
			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor) && !disabled}
				onClose={() => setMenuAnchor(null)}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				transformOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MenuItem onClick={() => downloadHTML(id, () => setMenuAnchor(null))}>Descargar HTML</MenuItem>
				<MenuItem onClick={() => downloadPDF(id, () => setMenuAnchor(null))}>Descargar PDF</MenuItem>
			</Menu>
		</>
	);
};

export default DownloadItem;
