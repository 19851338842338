import { Data, GeneralInfo, GeneralInfoData, Logo, LogosContainer, Subtitle } from "./Common";

const GeneralData = ({ reportData }) => {
	return (
		<>
			<LogosContainer>
				<Logo src={reportData.wreckRescueCompany?.logo} alt={reportData.wreckRescueCompany?.name} />
				<Logo src={reportData.salmonCompany?.logo} alt={reportData.salmonCompany?.name} />
			</LogosContainer>

			<GeneralInfo smMaxPerRow={2} style={{ marginTop: "20px" }}>
				<GeneralInfoData>
					<Subtitle>Tipo de informe</Subtitle>
					<Data>Día Normal</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Centro de cultivo</Subtitle>
					<Data>{reportData?.cultivationCenter?.name}</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Código</Subtitle>
					<Data>{reportData?.cultivationCenter?.code}</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Área</Subtitle>
					<Data>{reportData?.place}</Data>
				</GeneralInfoData>

				<GeneralInfoData>
					<Subtitle>Operador ROV</Subtitle>
					<Data>{reportData?.ROVOperator}</Data>
				</GeneralInfoData>
				<GeneralInfoData>
					<Subtitle>Fecha {reportData?.endDate ? "de inicio" : ""}</Subtitle>
					<Data>{reportData?.date}</Data>
				</GeneralInfoData>

				{
					reportData?.endDate && (
						<GeneralInfoData>
							<Subtitle>Fecha de término</Subtitle>
							<Data>{reportData?.endDate}</Data>
						</GeneralInfoData>
					)
				}

				<GeneralInfoData>
					<Subtitle>Embarcación</Subtitle>
					<Data>{reportData?.boat}</Data>
				</GeneralInfoData>
			</GeneralInfo>

			<GeneralInfo>
				<GeneralInfoData>
					<Data>Comentario:</Data>
					<Subtitle
						style={{
							fontSize: "14px",
						}}
					>
						{reportData?.comments}
					</Subtitle>
				</GeneralInfoData>
			</GeneralInfo>
		</>
	);
};

export default GeneralData;
