import React from "react";

// mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

// mui colors
import tealColor from "@mui/material/colors/teal";
import redColor from "@mui/material/colors/red";

// mui icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveIcon from "@mui/icons-material/Remove";

// components
import { LatLonInput } from "../../CoordInput";
import { PointsPreviewer } from "./";

// context
import { useAuthContext, useMessagesContext } from "../../../context";
// global
import { URLS } from "../../../global";
import TextInput from "../../Inputs/TextInput";
import GeneralButton from "../../GeneralButton";

const MIN_POINTS = 3;
const INITIAL_POINTS = 4;
const MAX_POINTS = 9;

/**
 * Formulario para crear o editar una jaula
 * @param {object} props
 * @param {function} props.onFinished Funcion a ejecutar al terminar de crear o editar una jaula
 * @param {object} props.data Datos de la jaula a editar
 * @param {number} props.centerId Id del centro de cultivo al que pertenece la jaula
 */
const CageForm = ({ onFinished = () => { }, data = undefined, centerId }) => {
    const [currentData, setCurrentData] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [loading, setLoading] = React.useState(false);
	const theme = useTheme();

    const [pointId, setPointId] = React.useState(0);
    const [cageNumber, setCageNumber] = React.useState("");
    const [cageDepth, setCageDepth] = React.useState("");

    const { axiosInstance } = useAuthContext();
    const { addMessage } = useMessagesContext();

    React.useEffect(() => {
        const obtainData = () => {
            // procesar coordenadas
            let points = [];
            data.coordinates.split(";").forEach((point, index) => {
                let [lat, lon] = point.split(",");
                lat = parseFloat(lat);
                lon = parseFloat(lon);
                points.push({ id: index, latitude: lat, longitude: lon });
            });
            setPointId(points.length);
            setCurrentData({
                points: points,
            });
        };

        // si se esta editando, se obtienen los datos
        if (data?.name) setCageNumber(data.name);
        if (data?.depth) setCageDepth(data.depth);
        // si se esta creando, se inicializan los datos
        if (data?.id) obtainData();
        else {
            const onCreation = () => {
                let default_point = { latitude: "", longitude: "" };
                let points = [];
                for (let i = 0; i < INITIAL_POINTS; i++) {
                    points.push({ ...default_point, id: i });
                }

                setCurrentData({
                    points: points,
                });
                setPointId(INITIAL_POINTS);
            };

            onCreation();
            setLoading(false);
        }
    }, [data]);
    const handleCancel = () => {
        onFinished();
    };

    const handleSave = (e) => {
        e.preventDefault();

        let coordinates = processCoordinates(currentData.points);

        // Creacion de objeto por defecto
        // crear configuracion de request
        let config = {
            method: "POST",
            url: URLS.CAGES,
            data: {
                coordinates: coordinates,
                cultivationCenter: centerId,
                name: cageNumber,
                depth: cageDepth,
            },
        };
        let message = "Jaula creada exitosamente";
        let error_message = "Error al crear la jaula";

        if (data?.id) {
            // Editar
            config.method = "PATCH";
            config.url += `${data.id}/`;
            message = "Jaula editada exitosamente";
            error_message = "Error al editar la jaula";
        }

        // Enviar request
        setLoading(true);
        axiosInstance(config)
            .then((_res) => {
                addMessage(message, "success");
                onFinished();
            })
            .catch((err) => {
                addMessage(error_message, "error");
                setErrors(err.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = () => {
        if (!window.confirm("¿Está seguro de eliminar esta jaula?")) return;

        setLoading(true);
        axiosInstance
            .delete(`${URLS.CAGES}${data.id}/`)
            .then((_res) => {
                addMessage("Jaula eliminada exitosamente", "success");
                onFinished();
            })
            .catch((err) => {
                addMessage("Error al eliminar la jaula", "error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePointChange = (latlon, index) => {
        let newPoints = [...currentData.points];
        newPoints[index] = { ...newPoints[index], ...latlon };
        setCurrentData({ ...currentData, points: newPoints });
    };

    const handleAddPoint = () => {
        setCurrentData({
            ...currentData,
            points: [...currentData.points, { id: pointId, lat: "", lon: "" }],
        });
        setPointId(pointId + 1);
    };

    const handleDeletePoint = (index) => {
        let newPoints = [...currentData.points];
        newPoints.splice(index, 1);
        setCurrentData({ ...currentData, points: newPoints });
    };

    const processCoordinates = (points) => {
        let coordinates = "";
        points.forEach((point) => {
            // si no tiene latitud o longitud, no se agrega
            if (!point.latitude || !point.longitude) return;
            // si no es un numero, no se agrega
            if (isNaN(point.latitude) || isNaN(point.longitude)) return;

            let lat = parseFloat(point.latitude).toFixed(6);
            let lon = parseFloat(point.longitude).toFixed(6);
            coordinates += `${lat},${lon};`;
        });
        // quitar el ultimo ";"
        coordinates = coordinates.slice(0, -1);
        return coordinates;
    };

    return (
		<form
			onSubmit={handleSave}
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "10px",
				padding: "20px 30px",
			}}
		>
			{loading && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1,
						bgcolor: "rgba(255,255,255,0.5)",
					}}
				>
					<CircularProgress />
				</Box>
			)}
			<div style={{ display: "flex", gap: "20px" }}>
				<TextInput
					value={cageNumber}
					onChange={(e) => setCageNumber(e.target.value)}
					label="Nombre de la jaula"
					variant="outlined"
					fullWidth
					required
					disabled={loading}
				/>

				<TextInput
					value={cageDepth}
					type="number"
					onChange={(e) => setCageDepth(e.target.value)}
					label="Profundidad de la jaula (m)"
					variant="outlined"
					fullWidth
					required
					disabled={loading}
				/>
			</div>

			<Typography variant="h6">Puntos Geográficos</Typography>

			<Grid container flexWrap="wrap" columnSpacing={1} rowSpacing={1}>
				{/* points */}
				{currentData.points?.map((point, index) => (
					<Grid item xs={12} md={6} lg={4} key={point.id} position="relative">
						<FormControl
							component="fieldset"
							sx={{
								border: "1px solid #ccc",
								borderRadius: "5px",
								p: 1,
							}}
							error={errors[`point${index}`]?.latitude || errors[`point${index}`]?.longitude}
							required={index < MIN_POINTS}
							disabled={loading}
						>
							<FormLabel component="legend">Punto {index + 1}</FormLabel>
							<LatLonInput
								inputValues={point}
								setInputValues={(latlon) => handlePointChange(latlon, index)}
								error={errors[`point${index}`]}
								helperText={errors[`point${index}`]}
								disabled={loading}
								required={index < MIN_POINTS}
							/>
						</FormControl>

						{currentData.points?.length > MIN_POINTS && (
							// para quitar un punto
							<IconButton
								onClick={() => handleDeletePoint(index)}
								size="small"
								sx={{
									position: "absolute",
									top: 14,
									right: 0,
									color: redColor[300],
								}}
								tabIndex={-1}
								disabled={loading}
							>
								<RemoveIcon fontSize="small" />
							</IconButton>
						)}
					</Grid>
				))}

				{currentData.points?.length < MAX_POINTS && (
					// para agregar un punto
					<IconButton
						onClick={handleAddPoint}
						size="large"
						sx={{ alignSelf: "end", color: tealColor[300] }}
						tabIndex={-1}
						disabled={loading}
					>
						<AddCircleIcon fontSize="large" />
					</IconButton>
				)}
			</Grid>
			{/* </Stack> */}

			{/* Previsualizacion de mapa */}
			<Accordion
				sx={{
					borderTop: `1px solid ${theme.palette.brandColors.lightGray}`,
                    borderBottom: `1px solid ${theme.palette.brandColors.lightGray}`,
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
					<Typography variant="h6">Previsualización de puntos</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box
						component="fieldset"
						sx={{
							width: "100%",
							height: "400px",
							border: "1px solid #ccc",
							borderRadius: "5px",
						}}
					>
						<PointsPreviewer
							points={currentData?.points || []}
							color="orange"
							extraPoligons={[{ color: "white", points: data?.centerPolygon }]}
						/>
					</Box>
				</AccordionDetails>
			</Accordion>

			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				{data?.id && (
					<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
						<Button variant="outlined" onClick={handleDelete} color="error" disabled={loading}>
							Eliminar
						</Button>
					</Stack>
				)}

				<Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="end" flexGrow={1}>
                    <GeneralButton
                        text="Cancelar"
                        onClick={handleCancel}
                        variant="empty"
                        disabled={loading}
                    />
                    <GeneralButton
                        text="Guardar"
                        type="submit"
                        disabled={loading}
                    />
				</Stack>
			</Box>
		</form>
	);
};

export default CageForm;
