import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import NearMeDisabledIcon from "@mui/icons-material/NearMeDisabled";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BuildIcon from "@mui/icons-material/Build";
import ChecklistIcon from "@mui/icons-material/Checklist";
import LightModeIcon from "@mui/icons-material/LightMode";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import WaterIcon from "@mui/icons-material/Water";
import SetMealIcon from "@mui/icons-material/SetMeal";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";

// Todos los tipos de informes, con sus respectivos nombres, pasos e iconos
export const STATES = {
	NORMAL_DAY: {
		id: 0,
		label: "Día normal",
		steps: [
			"Datos generales",
			"Agregar objetos",
			"Agregar imágenes extra",
			"Agregar videos",
			"Rescate de Balizados",
			"Vista previa",
			"Agregar Mapa",
		],
		optionalStepLabel: [false, false, true, true, false, false],
		icon: <LightModeIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	SEA_BOTTOM: {
		id: 1,
		label: "Prospección de fondo",
		steps: [
			"Datos generales",
			"Agregar imágenes Georreferenciadas",
			"Agregar videos",
			"Agregar imágenes",
			"Vista previa",
			"Agregar Mapa",
		],
		optionalStepLabel: [false, false, true, true, false, false],
		icon: <WaterIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	CLOSED_PORT: {
		id: 2,
		label: "Puerto cerrado",
		steps: ["Datos generales", "Agregar imágenes (obligatorio)", "Vista previa"],
		optionalStepLabel: [false, false, false],
		icon: <DoDisturbOnIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	BAD_WEATHER: {
		id: 3,
		label: "Mal clima",
		steps: ["Datos generales", "Agregar imágenes", "Vista previa"],
		optionalStepLabel: [false, false, false],
		icon: <ThunderstormIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	REFUEL_DAY: {
		id: 4,
		label: "Carga de combustible",
		steps: ["Datos generales", "Agregar imágenes", "Vista previa"],
		optionalStepLabel: [false, false, false],
		icon: <LocalGasStationIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	ROV_FAILURE: {
		id: 5,
		label: "Falla de equipo ROV",
		steps: ["Datos generales", "Agregar imágenes", "Vista previa"],
		optionalStepLabel: [false, false, false],
		icon: <NearMeDisabledIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	BOAT_FAILURE: {
		id: 6,
		label: "Falla de embarcación",
		steps: ["Datos generales", "Agregar imágenes", "Vista previa"],
		optionalStepLabel: [false, false, false],
		icon: <PriorityHighIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	CHANGE: {
		id: 7,
		label: "Relevo",
		steps: ["Datos generales", "Vista previa"],
		optionalStepLabel: [false, false],
		icon: <ChangeCircleIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	MAINTENENCE: {
		id: 8,
		label: "Mantenimiento de embarcación",
		steps: ["Datos generales", "Vista previa"],
		optionalStepLabel: [false, false],
		icon: <BuildIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	REVIEW_BOAT: {
		id: 9,
		label: "Revista embarcación",
		steps: ["Datos generales", "Vista previa"],
		optionalStepLabel: [false, false],
		icon: <ChecklistIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	OBJECTS_UPLOAD_ERROR: {
		id: 10,
		label: "Reintentar subida de datos",
		steps: ["Agregar objetos", "Agregar imágenes extra"],
		optionalStepLabel: [true, true],
		icon: <ChecklistIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
		hidden: true,
	},
	IMAGES_UPLOAD_ERROR: {
		id: 11,
		label: "Reintentar subida de datos",
		steps: ["Agregar imágenes"],
		optionalStepLabel: [true, true],
		icon: <ChecklistIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
		hidden: true,
	},
	CASTAWAY_REST_DELIVERY: {
		id: 12,
		label: "Entrega de restos náufragos",
		steps: ["Datos generales", "Agregar imágenes", "Vista previa"],
		optionalStepLabel: [false, false, false],
		icon: <LocalShippingIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	SAILING: {
		id: 13,
		label: "Navegación",
		steps: ["Datos generales", "Agregar imágenes", "Vista previa"],
		optionalStepLabel: [false, false, false],
		icon: <DirectionsBoatIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
	},
	FEEDING_AND_BACTERIUM: {
		id: 14,
		label: "Alimentación y bacterias",
		steps: [
			"Datos generales",
			"Agregar imágenes Georreferenciadas",
			"Agregar videos",
			"Agregar imágenes",
			"Vista previa",
			"Agregar Mapa",
		],
		optionalStepLabel: [false, false, true, true, false, false],
		icon: <SetMealIcon fontSize="large" sx={{ color: "var(--primary)" }} />,
		extraIcons: [<CoronavirusIcon fontSize="large" sx={{ color: "var(--primary)" }} />],
	},
};

export default STATES;

// Estilo para los mapas o las imágenes de estos
export const MapContainerStyle = {
	height: "525px",
	width: "900px",
};

export const GraphContainerStyle = {
	height: "auto",
	width: "600px",
	minHeight: "450px",
};
