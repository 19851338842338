import GeneralDataForm from "./GeneralDataForm";
import ObjectMapVisualizer from "./ObjectMapVisualizer";
import ReportObjectGrid from "./ReportObjectGrid";
import ReportPreview from "../ReportPreviewAndSubmit";
import ImagesStep from "./ImagesStep";
import GeographicImages from "./GeographicImages";
import ImageMap from "./ImageMap";
import { STATES } from "./StatesConstants";
import { ReportDataContext } from "../../../context";
import { useContext } from "react";
import BeaconObjects from "./BeaconObjects";
import VideosStep from "./VideosStep";
import FeedingBacteriumImages from "./FeedingBacteriumImages";

/**
 * Componente auxiliar que renderiza los componentes del form de informes según el tipo de informe seleccionado
 * y el paso en el que se encuentra el usuario.
 * @param activeStep: paso en el que se encuentra el usuario
 */
const RenderFormContent = ({ activeStep, edit }) => {
	const { reportData } = useContext(ReportDataContext);

	switch (reportData.reportType?.id) {
		case STATES.NORMAL_DAY.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ReportObjectGrid edit={edit} />}
					{activeStep === 2 && <ImagesStep />}
					{activeStep === 3 && <VideosStep />}
					{activeStep === 4 && <BeaconObjects />}
					{activeStep === 5 && <ReportPreview inputs={reportData} />}
					{activeStep === 6 && <ObjectMapVisualizer />}
				</>
			);
		case STATES.CLOSED_PORT.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ImagesStep atLeastOneImage={true} />}
					{activeStep === 2 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.SEA_BOTTOM.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <GeographicImages />}
					{activeStep === 2 && <VideosStep />}
					{activeStep === 3 && <ImagesStep />}
					{activeStep === 4 && <ReportPreview inputs={reportData} />}
					{activeStep === 5 && <ImageMap />}
				</>
			);
		case STATES.BAD_WEATHER.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ImagesStep />}
					{activeStep === 2 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.REFUEL_DAY.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ImagesStep />}
					{activeStep === 2 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.ROV_FAILURE.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ImagesStep />}
					{activeStep === 2 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.BOAT_FAILURE.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ImagesStep />}
					{activeStep === 2 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.CHANGE.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.MAINTENENCE.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.REVIEW_BOAT.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.OBJECTS_UPLOAD_ERROR.id:
			return (
				<>
					{activeStep === 0 && <ReportObjectGrid edit={edit} />}
					{activeStep === 1 && <ImagesStep />}
				</>
			);
		case STATES.IMAGES_UPLOAD_ERROR.id:
			return <>{activeStep === 0 && <ImagesStep />}</>;
		case STATES.CASTAWAY_REST_DELIVERY.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ImagesStep />}
					{activeStep === 2 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.SAILING.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm />}
					{activeStep === 1 && <ImagesStep />}
					{activeStep === 2 && <ReportPreview inputs={reportData} />}
				</>
			);
		case STATES.FEEDING_AND_BACTERIUM.id:
			return (
				<>
					{activeStep === 0 && <GeneralDataForm hasEndDate={true} />}
					{activeStep === 1 && <FeedingBacteriumImages />}
					{activeStep === 2 && <VideosStep />}
					{activeStep === 3 && <ImagesStep />}
					{activeStep === 4 && <ReportPreview />}
					{activeStep === 5 && <ImageMap isFeedingImages={true} />}
				</>
			);
		default:
			return <>ERROR</>;
	}
};

export default RenderFormContent;
