import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

/**
 * Componente que muestra un input de texto (Envuelve el componente de Material-UI).
 */
const TextInput = ({
	label = undefined,
	value,
	onChange,
	error = undefined,
	required = undefined,
	placeholder = "",
	name = undefined,
	type = undefined,
	inputProps = undefined,
	InputProps = undefined,
	disabled = undefined,
	onKeyPress = undefined,
	onPaste = undefined,
	onKeyDown = undefined,
	multiline = undefined,
	rows = undefined,
	sx = undefined,
	inputSx = undefined,
	maxLength = undefined,
	select = undefined,
	onClick = undefined,
	children,
	small
}) => {
	const theme = useTheme();

	return (
		<div style={{ width: "100%", ...sx }}>
			<div
				style={{
					paddingLeft: "15px",
					marginBottom: label ? "7px" : 0,
					color: "#212121",
					fontWeight: 500,
					fontSize: small ? "13px" : "15px",
					textWrap: "nowrap",
				}}
			>
				{label}
				{required && <span style={{ color: theme.palette.brandColors.primary }}> *</span>}
			</div>
			<TextField
				fullWidth
				select={select}
				onClick={onClick}
				variant={"outlined"}
				value={value === undefined || value === null ? "" : value}
				onChange={(e) => {
					if (maxLength && typeof value === "string" && e.target.value.length > maxLength) return;
					onChange(e);
				}}
				error={Boolean(error)}
				required={required}
				placeholder={placeholder}
				name={name}
				type={type}
				inputProps={inputProps}
				InputProps={InputProps}
				disabled={Boolean(disabled)}
				onKeyPress={onKeyPress}
				onPaste={onPaste}
				onKeyDown={onKeyDown}
				multiline={multiline}
				rows={rows}
				sx={{ backgroundColor: "inherit", ...inputSx }}
			>
				{children}
			</TextField>
			{maxLength && typeof value === "string" && (
				<div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "15px", fontSize: "12px" }}>
					{value?.length}/{maxLength}
				</div>
			)}
			{error && (
				<div
					style={{
						display: "flex",
						color: theme.palette.brandColors.danger,
						marginTop: "7px",
						paddingLeft: "15px",
					}}
				>
					<PriorityHighIcon
						style={{ fontSize: "16px", marginRight: "5px", color: theme.palette.brandColors.danger }}
					/>
					<div style={{ fontSize: "12px", fontWeight: 500 }}>{error}</div>
				</div>
			)}
		</div>
	);
};

export default TextInput;
