// leaflet css
import "leaflet/dist/leaflet.css";

// leaflet
import L from "leaflet";
// react-leaflet
import { MapContainer, TileLayer, ScaleControl, Polygon, useMap } from "react-leaflet";

// mui components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

// mui icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCallback, useEffect, useState } from "react";

const MAP_TILE_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
	bottomleft: "leaflet-bottom leaflet-left",
	bottomright: "leaflet-bottom leaflet-right",
	topleft: "leaflet-top leaflet-left",
	topright: "leaflet-top leaflet-right",
};

const DEFAULT_CENTER = [0, 0];
const DEFAULT_ZOOM = 1;

/**
 * Control para actualizar el centro y el zoom del mapa cuando cambian los puntos.
 * Adicionalmente, tambien controla el zoom del mapa.
 * @param {object} props
 * @param {function} props.position Posicion del control en el mapa
 * @param {Array} props.points Lista de puntos a considerar para calcular el centro
 * @param {Array} props.extraPoligons Poligonos adicionales a considerar
 */
const CenterUpdateControl = ({ position, points, extraPoligons }) => {
	const map = useMap();
	const [zoom, setZoom] = useState(map.getZoom());

	const onZoomInClick = useCallback(() => {
		let new_zoom = map.getZoom() + 1;
		map.setZoom(new_zoom);
		setZoom(new_zoom);
	}, [map]);

	const onZoomOutClick = useCallback(() => {
		let new_zoom = map.getZoom() - 1;
		map.setZoom(new_zoom);
		setZoom(new_zoom);
	}, [map]);

	const calculateCenter = useCallback((point_list) => {
		if (point_list.length === 0) return;

		let point = [0, 0];
		point_list.forEach((p) => {
			point[0] += p[0];
			point[1] += p[1];
		});
		point[0] /= point_list.length;
		point[1] /= point_list.length;
		return point;
	}, []);

	const calculateBounds = useCallback((point_list) => {
		if (point_list.length === 0) return;
		let bounds = L.latLngBounds(point_list);
		return bounds;
	}, []);

	const calculateZoom = useCallback(
		(point_list) => {
			if (point_list.length === 0) return;

			let bounds = calculateBounds(point_list);
			let zoom = map.getBoundsZoom(bounds);
			return zoom;
		},
		[map, calculateBounds]
	);

	const centerViewOnExtraPoligon = useCallback(
		(index) => {
			if (!extraPoligons?.length) return;
			if (!extraPoligons[index]?.points.length) return;

			let center = calculateCenter(extraPoligons[index].points);
			let new_zoom = calculateZoom(extraPoligons[index].points);

			if (isNaN(center[0]) || isNaN(center[1]) || isNaN(new_zoom)) return;

			setZoom(new_zoom);
			map.setView(center, new_zoom);
		},
		[map, extraPoligons, calculateCenter, calculateZoom]
	);

	// Actualizar el centro y el zoom del mapa cuando cambian los puntos
	useEffect(() => {
		if (points.length === 0) {
			// si no hay puntos, se intenta centrar en el primer poligono extra
			if (extraPoligons?.length) {
				centerViewOnExtraPoligon(0);
			}
			return;
		}

		// si hay puntos, se centra en ellos
		let center = calculateCenter(points);
		let new_zoom = calculateZoom(points);

		if (isNaN(center[0]) || isNaN(center[1]) || isNaN(new_zoom)) {
			// si no se pudo calcular el centro o el zoom, se intenta centrar en el primer poligono extra
			if (extraPoligons?.length) {
				centerViewOnExtraPoligon(0);
			}

			return;
		}

		setZoom(new_zoom);
		map.setView(center, new_zoom);
	}, [map, points, extraPoligons, calculateCenter, calculateZoom, centerViewOnExtraPoligon]);

	const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topleft;

	return (
		<div className={positionClass}>
			<Stack
				className="leaflet-control leaflet-bar"
				direction="column"
				spacing={0}
				divider={<Divider />}
				sx={{ backgroundColor: "white" }}
			>
				<IconButton
					size="large"
					onClick={onZoomInClick}
					sx={{
						width: 30,
						height: 30,
						backgroundColor: "white",
						color: "#101828",
						borderRadius: "0px",
					}}
					disabled={zoom >= 18}
				>
					<AddIcon />
				</IconButton>

				<IconButton
					onClick={onZoomOutClick}
					sx={{
						width: 30,
						height: 30,
						backgroundColor: "white",
						color: "#101828",
						borderRadius: "0px",
					}}
					disabled={zoom <= 1}
				>
					<RemoveIcon />
				</IconButton>
			</Stack>
		</div>
	);
};

/**
 * Componente para previsualizar puntos en un mapa como un poligono. Adicionalmente, se pueden agregar poligonos adicionales en formato { color: string, points: [[lat, lng], ...] }
 * @param {object} props
 * @param {string} props.color Color del poligono
 * @param {Array} props.points Lista de puntos a previsualizar (debe ser una lista de objetos con atributos latitude y longitude)
 * @param {object} props.extraPoligons Poligonos adicionales a previsualizar (debe ser un objeto con atributos color y points)
 */
const PointsPreviewer = ({ color = "white", points = [], extraPoligons = [{ color: undefined, points: [] }] }) => {
	const [validPoints, setValidPoints] = useState([]);

	useEffect(() => {
		let new_points = [];
		points.forEach((point) => {
			if (isNaN(point?.latitude) || isNaN(point?.longitude) || !point?.latitude || !point?.longitude) return;
			new_points.push([parseFloat(point.latitude), parseFloat(point.longitude)]);
		});
		setValidPoints(new_points);
	}, [points]);
	return (
		<MapContainer
			zoomControl={false}
			center={DEFAULT_CENTER}
			zoom={DEFAULT_ZOOM}
			scrollWheelZoom={false}
			doubleClickZoom={false}
			attributionControl={false}
			dragging={false}
			style={{ height: "100%", width: "100%" }}
		>
			<TileLayer url={MAP_TILE_URL} />
			<ScaleControl position="bottomleft" />
			<CenterUpdateControl position="bottomright" points={validPoints} extraPoligons={extraPoligons} />

			<Polygon positions={validPoints} pathOptions={{ color: color }} />

			{extraPoligons.map((polygon, index) => (
				<Polygon key={index} positions={polygon?.points || []} pathOptions={{ color: polygon?.color }} />
			))}
		</MapContainer>
	);
};

export default PointsPreviewer;
