import { useState } from "react";
import { AppName, Container, Content, ReportTitle, ReportType, Tab, TabContent, Tabs } from "./Common";
import "./common.css";
import GeneralData from "./GeneralData";
import ImagesContent from "./ImagesContent";
import InfoIcon from "../../Icons/InfoIcon";
import CameraIcon from "../../Icons/CameraIcon";
import { useTheme } from "@mui/material/styles";

const TABS = {
	DATA: 0,
	IMAGES: 1,
};

const GenericReport = ({ reportData }) => {
	const [tab, setTab] = useState(TABS.DATA);
	const theme = useTheme();

	return (
		<Container>
			<ReportTitle>
				<ReportType>{reportData?.type}</ReportType>

				<AppName>Rastrum</AppName>
			</ReportTitle>
			<Content nt={TABS.MAP === tab}>
				<Tabs centered value={tab} onChange={(e, v) => setTab(v)} tabs={2}>
					<Tab
						label={<div className="text">Datos</div>}
						icon={<InfoIcon color={tab === TABS.DATA ? theme.palette.brandColors.primary : "#848591"} />}
					/>
					<Tab
						label={<div className="text">Imágenes</div>}
						icon={
							<CameraIcon color={tab === TABS.IMAGES ? theme.palette.brandColors.primary : "#848591"} />
						}
					/>
				</Tabs>

				<TabContent value={tab} index={TABS.DATA}>
					<GeneralData reportData={reportData} />
				</TabContent>

				<TabContent value={tab} index={TABS.IMAGES}>
					<ImagesContent images={reportData?.images} />
				</TabContent>
			</Content>
		</Container>
	);
};

export default GenericReport;
