

const DeleteIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
			<g clipPath="url(#clip0_65_100)">
				<path
					d="M0.773626 9.76938C0.773626 8.3306 0.77071 6.89132 0.775084 5.45254C0.777028 4.83667 1.3733 4.44476 1.91028 4.70442C2.22421 4.85646 2.36174 5.11806 2.36174 5.46605C2.35883 8.24758 2.3598 11.0291 2.36125 13.8106C2.36125 13.9183 2.36757 14.0273 2.38361 14.134C2.49975 14.899 3.07416 15.4179 3.85072 15.4208C5.95395 15.429 8.05767 15.4309 10.1614 15.4198C11.0313 15.4155 11.646 14.761 11.6465 13.8777C11.6479 11.0783 11.6479 8.27847 11.6455 5.47908C11.6455 5.27251 11.6858 5.08476 11.8175 4.92258C12.026 4.6663 12.3526 4.56783 12.667 4.66388C12.9863 4.76138 13.2045 5.03311 13.2302 5.36807C13.2351 5.4342 13.2331 5.50032 13.2331 5.56644C13.2331 8.34218 13.2336 11.1174 13.2331 13.8932C13.2331 15.6664 11.889 16.999 10.1016 16.9995C8.02851 17 5.95541 17 3.88231 16.9995C2.3598 16.999 1.12303 15.9937 0.827082 14.5158C0.789177 14.3271 0.772168 14.1355 0.772654 13.9419C0.774598 12.5509 0.773626 11.1604 0.774112 9.76938H0.773626Z"
					fill="white"
				/>
				<path
					d="M7.00434 3.88794C4.93756 3.88794 2.87077 3.88939 0.803989 3.88698C0.216464 3.88649 -0.164528 3.31503 0.071162 2.78991C0.21695 2.46508 0.482769 2.31305 0.838492 2.31305C1.7657 2.31305 2.6934 2.31305 3.62061 2.31305C3.78745 2.31305 3.8712 2.23003 3.87185 2.064C3.87185 1.67884 3.87039 1.2932 3.87233 0.908043C3.87574 0.321137 4.19841 0.000173256 4.78399 -0.000309397C6.26277 -0.00079205 7.74203 -0.00079205 9.2208 -0.000309397C9.8093 -0.000309397 10.1315 0.318242 10.1349 0.904665C10.1373 1.30189 10.1417 1.69959 10.1325 2.09633C10.1286 2.25899 10.1777 2.31594 10.3482 2.31498C11.2939 2.30774 12.2391 2.30918 13.1848 2.31305C13.6566 2.31498 13.9983 2.6509 13.9992 3.10267C14.0007 3.54236 13.6668 3.88408 13.2222 3.88601C12.4524 3.88987 11.6827 3.88746 10.9129 3.88746C9.60957 3.88746 8.30671 3.88746 7.00337 3.88746L7.00434 3.88794ZM6.99511 2.31208C7.449 2.31208 7.90337 2.30918 8.35725 2.31401C8.48166 2.31546 8.53463 2.27057 8.5288 2.14557C8.52297 2.02538 8.51908 1.90424 8.52977 1.78502C8.54532 1.6161 8.47486 1.57073 8.3106 1.57266C7.54813 1.5799 6.78517 1.57603 6.0227 1.57603C5.46385 1.57603 5.46774 1.57603 5.47697 2.12916C5.4794 2.27202 5.52994 2.31642 5.6699 2.31449C6.11164 2.30822 6.55386 2.31208 6.9956 2.31208H6.99511Z"
					fill="white"
				/>
				<path
					d="M9.34404 9.66172C9.34404 10.5464 9.34501 11.4311 9.34404 12.3163C9.34307 12.7956 9.02039 13.1339 8.56699 13.1363C8.10144 13.1388 7.75982 12.8004 7.75933 12.3221C7.7569 10.5406 7.7569 8.75868 7.75933 6.97721C7.75982 6.58529 8.01397 6.2706 8.38039 6.18952C8.73805 6.10988 9.09475 6.27784 9.2658 6.60508C9.32898 6.72623 9.34501 6.8551 9.34501 6.98976C9.34356 7.88073 9.34404 8.77123 9.34404 9.6622V9.66172Z"
					fill="white"
				/>
				<path
					d="M4.66278 9.63905C4.66278 8.76014 4.66181 7.88172 4.66278 7.0028C4.66326 6.51243 4.98643 6.17505 5.45003 6.17554C5.91267 6.17602 6.24749 6.51726 6.24798 6.99991C6.24992 8.76931 6.24992 10.5392 6.24798 12.3086C6.24749 12.7338 5.98216 13.0615 5.59825 13.1282C5.20414 13.1967 4.8421 12.9877 4.70166 12.6136C4.66472 12.5147 4.66229 12.4138 4.66229 12.3115C4.66229 11.4205 4.66229 10.53 4.66229 9.63905H4.66278Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_65_100">
					<rect width="14" height="17" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default DeleteIcon;