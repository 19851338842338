import { CircularProgress, Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context";
import { URLS } from "../../global";

const BoxStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: "60vw",
	height: "90vh",
	maxHeight: "90%",
	bgcolor: "background.paper",
	boxShadow: 24,
	overflow: "hidden",
	display: "block",
};

const ReportPDFViewer = ({ report }) => {
	const [loading, setLoading] = useState(true);
	const [pdf, setPdf] = useState(null);

	const { axiosInstance } = useContext(AuthContext);

	useEffect(() => {
		axiosInstance.get(URLS.REPORTS + report.id + "/get_pdf/", { responseType: "blob" }).then((res) => {
			setPdf(URL.createObjectURL(res.data));
			setLoading(false);
		});
		return () => {
			if (pdf) {
				URL.revokeObjectURL(pdf);
			}
		};
	}, [axiosInstance, report.id]);

	return (
		<Box sx={BoxStyle}>
			{loading ? (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
					<CircularProgress />
				</Box>
			) : (
				<iframe src={pdf} title="Reporte" width="100%" height="100%" style={{ border: "none" }} />
			)}
		</Box>
	);
};

export default ReportPDFViewer;
